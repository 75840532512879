
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { DateTime } from "luxon";
import axios from "axios";
import address from "@/variable";

@Options({
  components: {},
  props: {
    "onUpdate:modelValue": Function,
    modelValue: Object,
    isClear: Boolean,
  },
})
export default class JMBillingsearch extends Vue {
  modelValue!: any;
  selected: string = "";
  name = "";
  inputType: String = "";
  search: any = {
    year: new Date().getFullYear() + 543,
  };
  init: boolean = false;
  DateTime = DateTime;
  rates: Array<any> = [];
  // updated(){
  //   if(this.search !== this.modelValue){
  //     this.search = this.modelValue
  //   }
  // }
  mounted() {
    this.getRates()
    this.search = this.modelValue;
    try {
      console.log("search", this.$route.query.searchparam);
      this.search = JSON.parse(this.$route.query.searchparam as string);
    } catch (error) {
      console.log(error);
    }
  }
  getRates() {
    axios.get(`${address}/arearates`).then((response) => {
      this.rates = response.data;
      this.search.code = this.rates.map((el) => el.code);
    });
  }

  get days() {
    try {
      return DateTime.fromObject({
        month: this.search.month,
        year: this.search.year - 543,
      })
        .endOf("month")
        .toObject().day;
    } catch (error) {
      0;
    }
  }

  @Watch("search", { deep: true })
  emitUp() {
    this.$emit("update:modelValue", this.search);
  }
  emitClear() {
    this.search = {
      year: 0,
      month: 13,
      cat: 0,
      code: 0,
    };
    this.$emit("update:modelValue", this.search);
    this.$emit("clear");
  }
  emitSearchClear() {
    this.search = {};
    this.$emit("update:modelValue", this.search);
    this.$emit("search");
  }
}
