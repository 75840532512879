
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon";
import { useStore } from "vuex";
import * as _ from "lodash";
import Chart from "chart.js";

@Options({
  components: {},
  props: {},
})
export default class ReportCollectionStatistic extends Vue {
  store = useStore();
  doc:Array<any> = []
  docs: Array<any> = [];
  areaincomes: Array<any> = [];
  collects:Array<any> = [0,0,0,0,0,0,0,0,0,0]
  today: Date = new Date();
  reportDate:Date = new Date()

  get groupped(){
    let result:any = _.groupBy(this.processed,'budgetYear')
    let array:Array<any> = []
    Object.keys(result).forEach(element => {
      array.push({
        budgetYear:element,
        children:result[element],
        sum:result[element].map((el:any)=>el.amount).reduce((a:number,b:number)=>a+b,0)
      })
    });
    return array
  }

  get processed(){
    let result = this.docs.map(el=>{
      let collections = el.collections.map((c:any)=>{
      let recordYear = (DateTime.fromISO(c.recordDate).toObject().year??0) + 543
      let recordMonth = DateTime.fromISO(c.recordDate).toObject().month
      let budgetYear = recordYear + (recordMonth!>=10?1:0)
      let contractYear = (c.month >= 10 ? 1 : 0) + (c.year??0)
      return {
        ...c,
        name:el.area,
        prefix:el.prefix,
        recordYear,
        recordMonth,
        budgetYear,
        contractYear
      }
      })
      return collections
    })
    result = _.flatten(result).filter((el:any)=>{
      return true//el.contractYear <= el.budgetYear
    })
    return result
  }
  get todayFormat(){
    return this.today.toThaiShort()
  }
  getPercent(c:number,p:number){
    try {
      let res = (c * 100) / p
      if(Number.isNaN(res)) return 0
      else if(!Number.isFinite(res)) return 0
      return res
    } catch (error) {
      return 0
    }
    // let diff = c-p
    // let percent = (diff/p)
    // if(Number.isNaN(percent)) return 0
    // else if(!Number.isFinite(percent)) return c
    // return percent+1
  }
  mounted(){
    this.getData()
    this.getAreaIncome()
  }
  getData() {
    axios.get(`${address}/report-income-collectionstatistic`).then((response) => {
      this.docs = response.data;
      this.initChart1();
      // this.initChart2();
    });
  }
  getAreaIncome(){
    axios.get(`${address}/areaincomes`).then((response) => {
      this.areaincomes = response.data;
    });
  }
  initChart1() {
    var element = this.$refs.chart1 as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    var myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: this.groupped.map(el=>el.budgetYear),
        datasets: [
          {
            label: "ล้านบาท",
            data: this.groupped.map(el=>el.sum),
            barThickness: 30,
            backgroundColor: "#AA6afc",
            borderColor: "#AAE8BF",
            borderWidth: 1,
          },
        ],
      },
    });
  }
  resolveMB = (n:number) =>{
    return parseFloat(Math.abs(n).formatMB())
  }
  print(){
    window.print();
  }
}
