
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from 'vue-class-component';
import { useStore } from "vuex";
import { DateTime } from "luxon"

@Options({})
export default class ReceiptList extends Vue {
  store = useStore();
  docs: Array<any> =[];
  invoices: Array<any> =[];
  receipts: Array<any>=[];
  invoice:any = {}
  doc: any = {
    // complaint:[],
    // payment:[],
  };
  isPaid:boolean = false;
  meter:String="";
  sequence:String="";
  find: any = {};
  debt: Array<any>=[];
  thailand = require('@/data/thailand.json')
  mounted(){
    this.getData()
  }
  calcTax(item: any): number {
    let basisAmount = item.qty * item.rate * 0.07;
    return basisAmount || 0;
  }
  sum(item: any): number {
    let sumAmount = 0;
    if (item.calculationType == "บาท/ลบ.ม.") {
      sumAmount = item.qty * item.rate;
    } else {
      sumAmount = item.rate;
    }
    console.log(item.qty,item.rate,sumAmount,item.calculationType)
    return sumAmount ?? 0;
  }
  submit() {
    this.postData();
  }
  postData() {
    axios
      .post(`${address}/usagerequest`, this.doc)
      .then((response) => {
        console.log(response);
        alert("ระบบได้รับคำร้องของคุณแล้ว!");
      });
  }
  getData() {
    let find = {}
    let str = this.$route.params.code??""
    if(str.length==11){
      find = {
        meter:this.$route.params.code
      }
    }else{
      find = {
        sequence:this.$route.params.code
      }
    }
    let query = {
      // search:find,
      search:{
        ...find
      },
      limit:-1,
      skip:0,
      sort:{
        year:-1,
        month:-1,
      }
    }
    console.log(query)
    ///`${address}/invoices-paginate`
    // axios.post(`${address}/report-customer-latest`,query).then(response=>{
    //   this.invoice = response.data
    // }) 
    axios.post(`${address}/invoices-paginate`,query).then(response=>{
      this.docs = response.data.docs
      this.fetchData()
    }) 
  }
  getThaiMonth(n: number){
      switch (n) {
        case 1:
          return "ม.ค.";
          break;
        case 2:
          return "ก.พ.";
          break;
        case 3:
          return "มี.ค.";
          break;
        case 4:
          return "เม.ย.";
          break;
        case 5:
          return "พ.ค.";
          break;
        case 6:
          return "มิ.ย.";
          break;
        case 7:
          return "ก.ค.";
          break;
        case 8:
          return "ส.ค.";
          break;
        case 9:
          return "ก.ย.";
          break;
        case 10:
          return "ต.ค.";
          break;
        case 11:
          return "พ.ย.";
          break;
        case 12:
          return "ธ.ค.";
          break;

        default:
          return ".";
          break;
      }
  }


  fetchData() {
    this.invoices = []
    this.receipts = []
    let meter = this.docs[0].meter
    let query = {
      search: { meter: meter },
      limit: 999999,
      skip: 0,
      sort: {
        sequence: -1,
      },
    };
    axios.post(`${address}/invoices-paginate`, query).then((response) => {
      this.invoices = response.data.docs;
      let query = {
        search: { meter: meter },
        limit: 999999,
        skip: 0,
        sort: {
          sequence: -1,
        }
      };
      axios.post(`${address}/receipts-paginate`, query).then((receipts) => {
        this.receipts = receipts.data.docs;
      });
    });
  }

  display0(invoices: Array<any>) {
    let debts = invoices.filter(invoice=>invoice.isPaid===false&&invoice.totalAmount>0)
    let mapDebts = debts.map(debt=>({month:debt.month,year:debt.year,yearMonth:parseInt(String(debt.year)+String(debt.month).padStart(2,'0'))}))
    let sortDebts = mapDebts.sort((a,b)=>a.yearMonth-b.yearMonth)
    let debtText:Array<any> = []
    let arrayDebtText:Array<any> = []
    let latest:any = {}
    for(const [i,debt] of sortDebts.entries()){
      let current = DateTime.fromObject({
        year: debt.year-543,
        month: debt.month,
        day: 10
      })
      let formatDate = current.reconfigure({ outputCalendar: "buddhist" }).setLocale("th").toFormat("LLLyy")
      debtText.push({text:formatDate, gap: ((latest.yearMonth??0) - (debt.yearMonth??0))})
      latest = debt
    }
    for(const [i,debt] of debtText.entries()){
      if(debt.gap===-1){
        arrayDebtText.push({text:"-"})
        try {
          if(debtText[i+1].gap!==-1) arrayDebtText.push({text:debt.text})
        } catch (error) {
          
        }
      } else {
        arrayDebtText.push({text:debt.text})
      }
    }
    let finalDebtAmount = debts.reduce((acc,debt)=>acc+debt.totalAmount,0)
    let finalDebtText = arrayDebtText.map(el=>el.text).join("/").replace(/\/-(.*?)([ก-ฮ])/g,"-$2")
    return {
      debtAmount:finalDebtAmount,
      debtText:finalDebtText,
      debts
    }
  }
}
