
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import address from "@/variable"
@Options({
  components: {
  },
  props: {
  },
})
export default class ContractList extends Vue {
  store = useStore();
  docs: Array<any> = [];
  isSigned: boolean = true;
  show: number = 50;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 5;
  search: any = {};
  selected: Array<any> = [];
  mounted() {
    this.fetchData();
  }
  updatePaginate(i: number) {
    this.page = i;
    console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  fetchData() {
    let query = {
      search: {
        $and:[
          {
            name:
            this.search.name!=undefined
            ?{ $regex: this.search.name, $options: '-i' }
            :undefined },
        ]
      },
      limit: this.show,
      skip: this.page * this.show,
      sort: { number: 1 },
    };
    axios.post(`${address}/areas-paginate`, query).then((response) => {
      axios.get(`${address}/areaconditions`).then((response2) => {
        let docs = response.data.docs;
        docs.forEach((element:any,i:number) => {
          docs[i].operationDate = (response2.data.find((el:any)=>el.area==element._id)??{}).operationDate
          docs[i].conditions = (response2.data.find((el:any)=>el.area==element._id)??{}).conditions
          docs[i].isFixed = (docs[i].conditions??[]).find((el:any)=>el.category=="สมทบตามจำนวนคงที่") != undefined
        });
        this.docs = docs;
        
        this.total = response.data.total;
        this.pages = Math.ceil(response.data.total / this.show);
      });
    });
  }
}
