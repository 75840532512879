
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { Watch } from "vue-property-decorator";
@Options({})
export default class ReceiptForm extends Vue {
  store = useStore();
  doc: any = {};
  from: any = {};
  rates: Array<any> = [];
  thailand = require("@/data/thailand.json");
  petition: boolean = false;
  contracts: Array<any> = [];
  address = address;
  provinceSelected: any = {};
  districtSelected: any = {};
  subDistrictSelected: any = {};
  isPrint: boolean = true;
  invoice: any = {};
  vatRate:string ="0.07";

  deleteData(){
    let con = confirm("คุณต้องการลบข้อมูลใช่หรือไม่?")
    if(con==true){
      axios.delete(`${address}/usage/${this.$route.params.id}`).then(response=>{
        this.$router.push('/portal/usage/list')
      })
    }
  }
  mounted() {
    if (this.$route.params.id != undefined) this.getData();
    this.getContracts();
    this.getRates();
  }
  get activeContract() {
    try {
      return this.rates.find((el) => el.code == this.doc.code);
    } catch (error) {
      return {};
    }
  }
  @Watch("doc.cat")
  updateCat() {
    this.getRate();
    // this.doc.rate = this.getRate().rate
    // this.doc.category = this.getRate().category
    // this.doc.categoryType = this.getRate().categoryType
  }

  getData() {
    axios.get(`${address}/usage/${this.$route.params.id}`).then((response) => {
      this.doc = response.data;
      this.doc.cat = response.data.category + "," + response.data.categoryType;
      let query = {
        search: {
          meter: this.doc.meter,
          year: this.doc.year,
          month: this.doc.month,
        },
      };
      this.from = JSON.parse(JSON.stringify(this.doc));
      axios.post(`${address}/invoice-find/`, query).then((response) => {
        this.invoice = response.data;
      });
    });
  }
  getRates() {
    axios.get(`${address}/arearates`).then((response) => {
      this.rates = response.data;
    });
  }
  getContracts() {
    axios.get(`${address}/contracts`).then((response) => {
      this.contracts = response.data;
      console.log(this.contracts);
    });
  }
  submit() {
    if (this.$route.params.id != undefined) this.patchData();
    else this.postData();
  }
  getRate() {
    let rate = 0;
    let category = "";
    let categoryType = "";
    switch (this.doc.cat) {
      case "1,น้ำเสีย":
        rate = (this.activeContract ?? {}).treatment1;
        category = "1";
        categoryType = "น้ำเสีย";
        break;
      case "2,น้ำเสีย":
        rate = (this.activeContract ?? {}).treatment2;
        category = "2";
        categoryType = "น้ำเสีย";
        break;
      case "3,น้ำเสีย":
        rate = (this.activeContract ?? {}).treatment3;
        category = "3";
        categoryType = "น้ำเสีย";
        break;
      case "1,น้ำทิ้ง":
        rate = (this.activeContract ?? {}).effluent1;
        category = "1";
        categoryType = "น้ำทิ้ง";
        break;
      case "2,น้ำทิ้ง":
        rate = (this.activeContract ?? {}).effluent2;
        category = "2";
        categoryType = "น้ำทิ้ง";
        break;
      case "3,น้ำทิ้ง":
        rate = (this.activeContract ?? {}).effluent3;
        category = "3";
        categoryType = "น้ำทิ้ง";
        break;
      case "4,เหมา":
        this.doc.qty = 1;
        category = "4";
        categoryType = "เหมา";
        if ((this.doc.cat = "4,เหมา")) {
          rate = this.doc.rate;
        } else {
        }
        break;
      default:
        break;
    }
    this.doc.rate = rate;
    this.doc.category = category;
    this.doc.categoryType = categoryType;
    if (categoryType == "น้ำเสีย")
      this.doc.calculationType = (this.activeContract ?? {}).treatmentType;
    else if (categoryType == "น้ำทิ้ง")
      this.doc.calculationType = (this.activeContract ?? {}).effluentType;
    else if (categoryType == "น้ำทิ้ง") this.doc.calculationType = "บาท/เดือน";
    return { rate, category, categoryType };
  }
  postData() {
    axios
      .post(`${address}/usage`, {
        ...this.doc,
        vatRate:0.07
      })
      .then((response) => {
        console.log(response);
        alert("สำเร็จ!");
        this.$router.push("/portal/usage/list");
      });
  }
  submitRequest() {
    axios
      .post(`${address}/request`, {
        requestType: "เปลี่ยนข้อมูลผู้ใช้น้ำ",
        note: this.doc.note,
        remark: this.doc.remark,
        fileUrl: this.doc.fileUrl,
        status: "ส่งคำร้อง",
        username:this.store.state.userAccountName,
        priority: "ทั่วไป",
        to: this.doc,
        from: this.from,
        requester:this.store.state.userId
      })
      .then((response) => {
        console.log(response);
        alert("บันทึกคำร้องสำเร็จ!");
        this.$router.push("/portal/usage/list");
      });
  }
  patchData() {
    axios
      .patch(`${address}/usage/${this.$route.params.id}`, {
        ...this.doc,
        vatRate:0.07,
        _id: undefined,
      })
      .then((response) => {
        console.log(response);
        alert("สำเร็จ!");
        this.$router.push("/portal/usage/list");
      });
  }
  updatePostal() {
    let found = this.subDistricts.find(
      (el: any) => el.name.th == this.doc.subDistrict
    ) as any;
    if (found != undefined) this.doc.postal = found.zipcode;
    else this.doc.postal = "";
  }
  fetchData() {
    let query = {
      search: {
        meter: this.doc.meter,
        name: this.doc.name,
        year: this.doc.year,
        month: this.doc.month,
      },
    };
    axios.post(`${address}/invoice-find/`, query).then((response) => {
      this.invoice = response.data;
    });
  }
  get districts() {
    let found = Object.entries(this.thailand)
      .map((el) => el[1])
      .find((el: any) => el.name.th == this.doc.province) as any;
    if (found != undefined)
      return Object.entries((found || {}).amphoes).map((el) => el[1]);
    else return [];
  }
  get subDistricts() {
    let found = this.districts.find(
      (el: any) => el.name.th == this.doc.district
    ) as any;
    if (found != undefined)
      return Object.entries((found || {}).tambons).map((el) => el[1]);
    else return [];
  }
}
