
import address, {filePath} from "@/variable";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
@Options({
  components: {
  },
  props: {
  },
})
export default class AreaPlanList extends Vue {
  store = useStore();
  docs: Array<any> = [];
  filePath = filePath
  isSigned: boolean = true;
  show: number = 50;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 5;
  search: any = {};
  selected: Array<any> = [];
  mounted() {
    this.fetchData();
  }
  getCondition(cond:Array<any>,year:number){
    try {
      return `${cond[year-1].category} - ${(cond[year-1].contributionLimit??0).formatFull()} บาท`
    } catch (error) {
      return {}
    }
  }
  updatePaginate(i: number) {
    this.page = i;
    console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  fetchData() {
    let query = {
      search: {
        $and:[
          {
            name:
            this.search.name!=undefined
            ?{ $regex: this.search.name, $options: '-i' }
            :undefined },
        ]
      },
      limit: this.show,
      skip: this.page * this.show,
      sort: { number: 1 },
    };
    axios.post(`${address}/areas-paginate`, query).then((response) => {
      axios.get(`${address}/areaincomes`).then((response2) => {
        let docs = response.data.docs;
        let docs2 = response2.data as Array<any>;
        axios.get(`${address}/areaconditions`).then(response3=>{
        let docs3 = response3.data as Array<any>;
        docs.forEach((element: any, i: number) => {
          let found1 = docs2.filter((el: any) => {
            return el.area == element._id;
          });
          let found2 = docs3.find((el: any) => {
            return el.area == element._id;
          });
          
          if (found1 != undefined) {
            docs[i].collections = found1;
          } else {
          }
          if (found2 != undefined) {
            docs[i].conditions = found2.conditions;
          } else {
          }
        });
        this.docs = docs;
        this.total = response.data.total;
        this.pages = Math.ceil(response.data.total / this.show);
        })
      });
    });
  }
}
