
import address from "@/variable";
import axios from "axios";
import { Watch } from "vue-property-decorator";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { DateTime } from "luxon"
import { saveAs } from 'file-saver';
@Options({
  components: {},
  props: {},
})
export default class ReceiptList extends Vue {
  store = useStore();
  idx: number = -1;
  id: string = "";
  doc: Array<any> = [];
  selected: Array<string> = [];
  docs: Array<any> = [];
  latest: Array<any> = [];
  loading: boolean = false;
  signature: string = "";
  isSigned: boolean = true;
  isActive: boolean = false;
  search: any = {
    year: 0,
    month: 13,
    cat: 0,
    // year: new Date().getFullYear() + 543,
    // month: new Date().getMonth() + 1,
  };
  sort: any = `{"excelNum":1}`;
  filter: any = {
    isPrint: undefined,
  };
  page: number = 0;
  pages: number = 0;
  show: number = 50;
  limit: number = 5;
  skip: number = 0;
  total: number = 0;
  working: boolean = false;

  totalCount: number = 0;
  totalQty: number = 0;
  totalAmount: number = 0;
  totalPayment: number = 0;
  totalDebt: number = 0;
  ids: Array<string> = [];

  downloadExcel(){
    let query = {
      search:{_id:{$in:this.selected}}
    }
    axios.post(`${address}/receipts-excel`, query,{responseType: 'blob'}).then((response) => {
      // Log somewhat to show that the browser actually exposes the custom HTTP header
      const fileNameHeader = "x-suggested-filename";
      const suggestedFileName = response.headers[fileNameHeader];
      const effectiveFileName = (suggestedFileName === undefined
                  ? "download_report.xlsx"
                  : suggestedFileName);
      console.log("Received header [" + fileNameHeader + "]: " + suggestedFileName
                  + ", effective fileName: " + effectiveFileName);

      saveAs(response.data, effectiveFileName);
    })
  }
  @Watch("search", { deep: true })
  setQuery() {
    this.$router.push({
      path: this.$route.path,
      query: {
        search: JSON.stringify(this.search),
      },
    });
  }
  getQuery() {
    try {
      // this.search = JSON.parse(this.$route.query.search as string);
    } catch (error) {}
  }
  @Watch("filter", { deep: true })
  updateIsPrint() {
    this.fetchData();
  }

  changeActive() {
    this.docs.forEach((el) => {
      el.active = !this.isActive;
    });
  }
  addSignature() {
    this.selected.forEach((el, i) => {
      let foundIndex = this.docs.findIndex((item) => item._id == el);
      if (foundIndex != -1) {
        this.docs[foundIndex].signature = this.signature;
        axios
          .patch(`${address}/receipt/${el}`, { signature: this.signature })
          .then((response) => {
            console.log(response.data);
          });
      }
    });
  }
  calcTreatment(item: any): number {
    if (item.category == 1) return item.qty * 3.0;
    else if (item.category == 2) return item.qty * 3.5;
    else if (item.category == 3) return item.qty * 4.0;
    else return 0;
  }
  calcTax(item: any): number {
    let basisAmount = this.calcTreatment(item) * 0.07;
    return basisAmount || 0;
  }
  sum(item: any): number {
    let sumAmount = 0;
    if (item.calculationType == "บาท/ลบ.ม.") {
      sumAmount = item.qty * item.rate;
    } else {
      sumAmount = item.rate;
    }
    // console.log(item.qty,item.rate,sumAmount,item.calculationType)
    return sumAmount ?? 0;
  }
  mounted() {
    this.getQuery()
    this.getLatest();
    try {
      console.log("search",this.$route.query.searchparam)
      this.search = JSON.parse(this.$route.query.search as string);
      if(this.search != undefined) this.fetchData();
    } catch (error) {
      console.log("error",error)
    }

  }
  getLatest() {
    axios
      .get(`${address}/receipts-information`)
      .then((response) => {
        console.log(response)
        this.latest = response.data[0];
      });
  }
  deleteMany(){
    let userConfirm = confirm("คุณต้องการลบรายการใบเสร็จรับเงิน/ใบกำกับภาษี!");
    if(userConfirm){
      axios.post(`${address}/receipts-delete`,{list:this.selected}).then(response=>{
        this.fetchData()
      })
      .then((response) => {
        alert("ลบข้อมูลแล้ว");
      })
    }
  }
  fetchData() {
    this.loading = true;
    this.docs = [];
    let searchDate;
    try {
      if (this.search.createdAt!=undefined) searchDate = DateTime.fromJSDate(this.search.createdAt);
    } catch (error) {}
    // console.log(this.search.createdAt, searchDate, typeof searchDate, searchDate.toISO())
    let category = null;
    let categoryType = null;
    try {
      category = this.search.cat.split(",")[0];
      categoryType = this.search.cat.split(",")[1];
    } catch (error) {}
    let print;
    if (this.filter.isPrint === true) print = { isPrint: true };
    else if (this.filter.isPrint === false) print = { isPrint: { $ne: true } };
    else print = { isPrint: undefined };
    let sign;
    if (this.filter.isSign === true) sign = { signature: { $exists: true } };
    else if (this.filter.isSign === false)
      sign = { signature: { $exists: false } };
    else sign = { isPrint: undefined };
    let query = {
      search: {
        code: JSON.parse(JSON.stringify(this.search.code)),
        $and: [
          { createdAt:searchDate != undefined ? { $gte: searchDate.startOf('day').toISO() } : undefined, },
          { createdAt:searchDate != undefined ? { $lte: searchDate.endOf('day').toISO() } : undefined, },
          {
            number:
              this.search.numberFrom != undefined
                ? { $gte: this.search.numberFrom }
                : undefined,
          },
          {
            number:
              this.search.numberTo != undefined
                ? { $lte: this.search.numberTo }
                : undefined,
          },
          {
            $or: [
              {
                name:
                  this.search.text != undefined
                    ? { $regex: this.search.text, $options: "-i" }
                    : undefined,
              },
              {
                meter:
                  this.search.text != undefined
                    ? { $regex: this.search.text, $options: "-i" }
                    : undefined,
              },
            ],
          },
          { category: category != undefined ? { $eq: category } : undefined },
          {
            paymentDate:
              this.search.month != undefined
                ? {
                    $gte: DateTime.fromObject({month:this.search.month,year:this.search.year-543})
                      .startOf("month").plus({month:2})
                      .toJSDate(),
                    $lte: DateTime.fromObject({month:this.search.month,year:this.search.year-543})
                      .endOf("month").plus({ month: 2, hours:8 })
                      .toJSDate(),
                  }
                : undefined,
          },
          print,
          sign,
        ],
      },
      limit: this.show,
      skip: this.page*this.show,
      sort: JSON.parse(this.sort),
    };
    console.log('query',query)
    axios.post(`${address}/receipts-paginate`, query).then((response) => {
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);

      this.totalCount = response.data.totalCount;
      this.totalQty = response.data.totalQty;
      this.totalAmount = response.data.totalAmount;
      this.totalPayment = response.data.totalPayment;

      this.totalDebt = response.data.totalDebt;
      this.ids = response.data.ids;
      this.loading = false;
      console.log(response)
    });
  }
  pushItem(ev: any, item: any) {
    let targetValue = ev.target.checked;
    if (targetValue) {
      this.selected.push(item);
    } else {
      let found = this.selected.findIndex((el: any) => el._id == item._id);
      if (found >= 0) {
        this.selected.splice(found, 1);
      }
    }
  }

  selectAll(ev: any) {
    let checked = ev.target.checked;
    if (checked) this.selected = this.ids;
    else this.selected = [];
  }
  signatureRequest(){
    axios.post(`${address}/process/request/receipt`,{list:this.selected, username:this.store.state.userAccountName}).then((response) => {
      alert("ส่งคำขอแล้ว!")
    })
  }

  btnClick() {
    this.store.commit("setReceiptList", this.selected);
    if (this.selected.length != 0) {
      window.open("/portal/receipt/print"), "_blank";
    }
  }
  updatePaginate(i: number) {
    this.page = i;
    console.log(this.page);
    this.fetchData();
  }
  clearPage(){
    if(this.show == 999999999){
      this.page =0;
      this.fetchData();
    }
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }

  toThaiFormat(year: number, month: number) {
    var dtThai = DateTime.fromObject({ year: year - 543, month })
      .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("LLLyy");
    return dtThai;
  }
 getThaiMonth(n: number) {
    switch (n) {
      case 1:
        return "ม.ค.";
        break;
      case 2:
        return "ก.พ.";
        break;
      case 3:
        return "มี.ค.";
        break;
      case 4:
        return "เม.ย.";
        break;
      case 5:
        return "พ.ค.";
        break;
      case 6:
        return "มิ.ย.";
        break;
      case 7:
        return "ก.ค.";
        break;
      case 8:
        return "ส.ค.";
        break;
      case 9:
        return "ก.ย.";
        break;
      case 10:
        return "ต.ค.";
        break;
      case 11:
        return "พ.ย.";
        break;
      case 12:
        return "ธ.ค.";
        break;

      default:
        return ".";
        break;
    }
  }
}
