import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Portal from '../views/Portal.vue'
import Login from '@/views/user/Login.vue'
import Public from '@/views/Public.vue'
import Landing from '@/views/Landing.vue'
import LandingIncome from '@/views/LandingIncome.vue'
import LandingBilling from '@/views/LandingBilling.vue'

import billing from './billing'
import income from './income'
import area from './area'
import account from './account'
import customer from './customer'
import report from './report'
import user from './user'
import demo from './demo'
import ResetForm from '@/views/user/ResetForm.vue'

import incomeReport from './incomeReport'

import Signature from "@/views/debug/Signature.vue"
import Temp from "@/views/debug/Temp.vue"
import Form from '@/views/Krob4/Form.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/debug/signature',
    name: 'Signature',
    component: Signature
  },
  {
    path: '/debug/temp',
    name: 'Temp',
    component: Temp
  },
  {
    path: '/',
    name: 'Home',
    component: Landing
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/krob4',
    name: 'Form',
    component: Form
  },
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   component: Home
  // },
  {
    path: '/Portal',
    name: "Portal",
    component: Portal,
    children: [
      {
        path: 'landing',
        name: 'Landing',
        component: Landing,
      },
      {
        path: 'landing/income',
        name: 'Landing Income',
        component: LandingIncome,
        meta: {
          bc: [
            {
              name: "ระบบจัดเก็บรายได้",
              to: "/portal/landing/income"
            },
          ]
        }
      },
      {
        path: 'landing/billing',
        name: 'Landing Billing',
        component: LandingBilling,
        meta: {
          bc: [
            {
              name: "ระบบบิลลิ่ง",
              to: "/portal/landing/billing"
            },
          ]
        }
      },
      ...billing,
      ...income,
      ...incomeReport,
      ...area,
      // ...customer,
      ...report,
      ...account,
      ...user,
      ...demo
    ]
  },
  {
    path: '/public',
    name: 'Public',
    component: Public,
    children: [
      ...customer,
    ]
  },
  {
    path: '/reset/submit/:uuid',
    name: 'ResetSubmit',
    component: ResetForm
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
