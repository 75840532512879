
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { Watch } from "vue-property-decorator";
import { DateTime } from "luxon";
@Options({})
export default class ConditionForm extends Vue {
  store = useStore();
  area: any = {};
  doc: any = {};
  isExisted: boolean = false;
  copySource: any = {};
  addPercent: any = {};
  addPeriod: any = {};
  DateTime = DateTime;
  dateRange(dt: string | Date, i: number) {
    var dtThai;
    if (typeof dt == "string") {
      dtThai = DateTime.fromISO(dt)
        .reconfigure({ outputCalendar: "buddhist" })
        .setLocale("th");
    } else {
      dtThai = DateTime.fromJSDate(dt)
        .reconfigure({ outputCalendar: "buddhist" })
        .setLocale("th");
    }

    let result = dtThai
      .plus({ years: i - this.doc.operationYear + 1 })
      .toFormat("dd LLL yy");
    result += " - ";
    if (i + 1 == this.doc.contractYear) {
      result += DateTime.fromISO(this.area.contractEnd)
        .reconfigure({ outputCalendar: "buddhist" })
        .setLocale("th")
        .toFormat("dd LLL yy");
    } else {
      result += dtThai
        .plus({ years: i - this.doc.operationYear + 2, days: -1 })
        .toFormat("dd LLL yy");
    }
    return result;
  }
  mounted() {
    if (this.$route.params.id != undefined) this.getData();
  }
  getData() {
    axios.get(`${address}/area/${this.$route.params.id}`).then((response) => {
      this.area = response.data;
      axios
        .get(`${address}/areacondition-by-field/area/${this.$route.params.id}`)
        .then((response) => {
          if (response.data == "") {
            this.doc = {};
            this.doc.name = this.area.prefix + this.area.name;
            this.doc.area = this.area._id;
            this.doc.contractYear = this.area.contractYear;
          } else {
            this.doc = response.data;
            this.doc.name = this.area.prefix + this.area.name;
            this.doc.area = this.area._id;
            this.isExisted = true;
          }
        });
    });
  }
  submit() {
    if (this.isExisted) this.patchData();
    else this.postData();
  }
  postData() {
    axios
      .post(`${address}/areacondition`, {
        ...this.doc,
      })
      .then((response) => {
        console.log(response);
        alert("บันทึกข้อมูลแล้ว!");
      });
  }
  patchData() {
    axios
      .patch(`${address}/areacondition/${this.doc._id}`, {
        ...this.doc,
        _id: undefined,
      })
      .then((response) => {
        console.log(response);
        alert("แก้ไขข้อมูลแล้ว!");
      });
  }

  @Watch("doc.contractYear")
  conditionsArray() {
    if (!Array.isArray(this.doc.conditions)) {
      this.doc.conditions = [];
    }
    this.doc.conditions = this.doc.conditions.slice(0, this.doc.contractYear);
    if (this.doc.contractYear == 0) {
      this.doc.conditions = [];
    } else {
      for (let index = 0; index < this.doc.contractYear; index++) {
        let condition;
        if (this.doc.conditions[index] == undefined)
          condition = { category: "", value: "" };
        else condition = this.doc.conditions[index];
        this.doc.conditions[index] = condition;
      }
    }
  }
  copy() {
    let source = this.copySource;
    let currentAmount: number = 1;
    let currentPercent: number = 1;
    let c = 0;
    currentAmount = this.doc.conditions[source].contributionLimit;
    currentPercent = this.doc.conditions[source].contributionPercent;
    for (let i = source; i < this.doc.conditions.length; i++) {
      if (c == this.addPeriod) {
        currentAmount = parseFloat(
          (currentAmount * (1 + this.addPercent / 100)).toFixed(2)
        );
        currentPercent += this.addPercent
        c = 0;
      }
      let target = {
        ...this.doc.conditions[source],
        contributionLimit: currentAmount,
        contributionPercent: currentPercent,
      };
      this.doc.conditions[i] = Object.assign({}, target);
      c++;
    }
  }
}
