
import address, {filePath} from "@/variable";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import { sort } from "mathjs";
@Options({
  components: {
  },
  props: {
  },
})
export default class CollectionList extends Vue {
  store = useStore();
   docs: Array<any> = [];
  filePath = filePath
  isSigned: boolean = true;
  showDetail:boolean = false;
  show: number = 50;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 5;
  search: any = {};
  loading:boolean = false
  selected: Array<any> = [];
  areas: Array<any> = [];
  get sorted(){
    let sorted = this.docs.sort((a:any,b:any)=>{
      let compare = a.name.localeCompare(b.name)
      return compare
    })
    return sorted
  }
  mounted() {

    console.time("loading")
    console.time("getArea")
    console.time("getCollection")
    this.fetchArea();
    this.fetchData();
  }
  getCondition(cond:Array<any>,year:number){
    try {
      // return `${cond[year-1].category||"ไม่ได้ระบุ"} - ${(cond[year-1].contributionLimit??0).formatFull()} บาท`
      return `${(cond[year-1]||0).category||"ไม่ได้ระบุ"} - ${((cond[year-1]||0).contributionLimit??0).formatFull()} บาท`
    } catch (error) {
      return {}
    }
  }
  updatePaginate(i: number) {
    this.page = i;
    console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  fetchArea() {
    axios
      .get(`${address}/areas`)
      .then((response) => {
          this.areas = response.data
          let TomSelect = require("tom-select")
          this.$nextTick(()=>{
            new TomSelect("#area-select",{
              create: false
            });
          })
        }
      );
  }
  fetchData() {
    this.loading = true
    let query = {
      search:{
        _id:this.search.area,
      },
      // search: {
        // $and:[
        //   {
        //     name:
        //     this.search.name!=undefined
        //     ?{ $regex: this.search.name, $options: '-i' }
        //     :undefined },
        // ]
      // },
      limit: this.show,
      skip: this.page * this.show,
      sort: { number: 1 },
    };
    console.log('query',query)
    axios.post(`${address}/areas-paginate`, query).then((response) => {
      console.timeEnd("getArea")
      let areas = response.data.docs
    console.log('areas.map((el:any)=>el._id)',areas.map((el:any)=>el._id))
      axios.post(`${address}/areacollections-paginate`,{
        search:{
          area: {
            $in:areas.map((el:any)=>el._id)
          }
        },
        limit:20*4,
        skip:0,
      }).then((response2) => {
        console.timeEnd("loading")
        console.timeEnd("getArea")
        console.timeEnd("getCollection")
        this.loading = false
        let docs = response.data.docs;
        let docs2 = response2.data.docs as Array<any>;
        axios.get(`${address}/areaconditions`).then(response3=>{
        let docs3 = response3.data as Array<any>;
        docs.forEach((element: any, i: number) => {
          let found1 = docs2.filter((el: any) => {
            return el.area == element._id;
          });
          let found2 = docs3.find((el: any) => {
            return el.area == element._id;
          });
          
          if (found1 != undefined) {
            docs[i].collections = found1;
          } else {
          }
          if (found2 != undefined) {
            docs[i].conditions = found2.conditions;
          } else {
          }
        });
        this.docs = docs;
        this.total = response.data.total;
        this.pages = Math.ceil(response.data.total / this.show);
        })
      });
    });
  }
}
