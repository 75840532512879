
import { Options, Vue } from 'vue-class-component';
import { useStore } from "vuex";
import { DateTime } from 'luxon'

@Options({})
export default class DemoDebt extends Vue {
  store = useStore();
  debt:Array<any> = []
  count:number = 12*3
  DateTime = DateTime
  mounted(){
    this.generate()
  }
  generate(){
    let today = new Date()
    for (let i = 0; i < this.count; i++) {
      let d = DateTime.fromJSDate(today)
      d = d.minus({ months: i })
      this.debt.push({
        isPaid:false,
        date:d,
        amount:Math.round((((Math.random()*200)+300)/10) * 1)
      })
    }
    for (let i = 3; i < Math.floor(this.count/3); i++) {
      // this.debt[i].isPaid = true
    }
    this.debt[0].isPaid = true
    this.debt[5].isPaid = true
  }
  get display1(){
    let result = ""
    var sum = 0
    var isMiddle = false
    let arr = this.debt.slice().reverse()
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      const next = arr[i+1]??{};
      const prev = arr[i-1]??{};
      if(!item.isPaid){
        let month = new Date(item.date).toThaiMonthShort()
        let year = (new Date(item.date).getFullYear() + 543).toString().substring(2,4)
        sum += item.amount
        if(!item.isPaid){
          if(prev.isPaid){
            result += `${month}${year}`
          }
          else{
            if(!isMiddle){
              if(prev.isPaid!=undefined)
              result += `-`
              isMiddle = true
            }
          }
          if(next.isPaid){
            isMiddle = false
            result += `${month}${year}`
          }
          else{
            if(!isMiddle){
              result += `-`
              isMiddle = true
            }
          }
          if(prev.isPaid == undefined){
            result += `${month}${year}-`
          }
          if(next.isPaid == undefined){
            result += `${month}${year}`
          }
        }
      }
      if(!item.isPaid && next.isPaid){
        result += ` (${(sum??0).formatFull()} บาท) `
        sum = 0
      }
      if(!item.isPaid && (next.isPaid == undefined)){
        result += ` (${(sum??0).formatFull()} บาท) `
        sum = 0
      }
    }
    return result
  }

  get display2(){
    let result = ""
    var sum = 0
    var isMiddle = false
    var isDashed = false
    let arr = this.debt.slice().reverse()
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      const next = arr[i+1]??{};
      const prev = arr[i-1]??{};
      if(!item.isPaid){
        let month = new Date(item.date).toThaiMonthShort()
        let year = (new Date(item.date).getFullYear() + 543).toString().substring(2,4)
        let prevyear = (new Date(prev.date).getFullYear() + 543).toString().substring(2,4)
        let nextyear = (new Date(next.date).getFullYear() + 543).toString().substring(2,4)
        
        if(year == prevyear){
          isMiddle = true
          if(!item.isPaid){
            if(prev.isPaid){
              isMiddle = false
            }
            if(next.isPaid){
              isMiddle = false
            }
          }
          sum += prev.amount
        }
        else{
          isMiddle = false
        }

        if(isMiddle){
          if(!isDashed){
            result += `-`
            isDashed = true
          }
        }
        else{
          result += `${month}${year}`
          isDashed = false
          if(next.isPaid){
            sum += item.amount
            result += ` (${(sum??0).formatFull()} บาท) `
            sum = 0
          }
        }
        
        if(year != nextyear){
          sum += item.amount
          result += `${month}${year}`
          result += ` (${(sum??0).formatFull()} บาท) `
          sum = 0
        }

      }
    }
    return result
  }
}
