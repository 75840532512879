
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon";
import { useStore } from "vuex";

@Options({
  components: {},
  props: {},
})
export default class ReceiptList extends Vue {
  store = useStore();
  docs: Array<any> = [];
  show: number = 50;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 0;
  skip: number = 0;
  loading: boolean = false;
  isSelectedAll: boolean = false;
  sort: any = `{"createdAt":-1}`;
  search: any = {};
  mounted(){
    this.fetchData()
  }
  fetchData() {
      let query = {
        search: {
            $or:[
              {name:this.search.name!=undefined&&this.search.name!=""?{ $regex: this.search.name, $options: "-i" }:undefined},
            ]
          },      
        limit: 999999,
        skip: 0
      };
      axios.post(`${address}/areas-paginate`,query).then((response) => {
        let areas = response.data.docs.map((el:any)=>el._id)
        let query2 = {
          search:{
            area:{$in:areas}
          },
          limit: this.show,
          skip: this.page*this.show,
          populate:"area"
        }
        axios.post(`${address}/arearations-paginate`, query2).then((response) => {
          this.docs = response.data.docs;
          this.total = response.data.total;
          this.pages = Math.ceil(response.data.total / this.show);
        });
      });
  }
  clearSearch(){
    this.search = {}
    this.fetchData();
  }
  deleteData(id:string){
    let con = confirm("คุณต้องการลบข้อมูลใช่หรือไม่?")
    if(con==true){
      axios.delete(`${address}/arearation/${id}`).then(response=>{
        this.fetchData()
      })
    }
  }
  updatePaginate(i: number) {
    this.page = i;
    console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    // let arr = new Array(Math.ceil(this.total/this.show)).fill(0);
    return arr;
  }
}
