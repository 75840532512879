import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import CustomerLogin from '@/views/customer/CustomerLogin.vue'
import CustomerInvoice from '@/views/customer/CustomerInvoice.vue'
import QRCode from '@/views/customer/QRCode.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/customer/qr/v2',
    name: 'QR Code',
    component: QRCode,
  },
  {
    path: 'customer/login',
    name: 'CustomerLogin',
    component: CustomerLogin,
    meta:{
      
      bc:[
        {
          name:"ตรวจสอบยอดค้างชำระ",
          to:"/public/customer/login"
        },
      ]
    }
  },
  {
    path: 'customer/invoice/:code',
    name: 'Customer Invoice',
    component: CustomerInvoice,
    meta:{
      bc:[
        {
          name:"ตรวจสอบยอดค้างชำระ",
          to:"/public/customer/login"
        },
        {
          name:"ตรวจสอบยอดค้างชำระล่าสุด",
          to:"/public/customer/invoice"
        },
      ]
    }
  },
  {
    path: 'customer/invoice',
    name: 'CustomerInvoice Demo',
    component: CustomerInvoice,
    meta:{
      bc:[
        {
          name:"ตรวจสอบยอดค้างชำระล่าสุด",
          to:"/public/customer/invoice"
        },
      ]
    }
  },
]

export default routes