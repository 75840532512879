
import { Options, Vue } from "vue-class-component";
import { watch } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import address from "@/variable";
// const io = require("socket.io-client");

@Options({
  components: {},
  props: {},
})
export default class Navigation extends Vue {
  // socket = io.connect("https://wma.jmandjm.com", { path: '/api/v1/socket.io' });
  store = useStore();
  show = false
  mouseenter(){

  }
  logOut() {
    // this.socket.emit("removeuser", this.store.state.userAccountName);
    axios
      .post(`${address}/user/logout`, {
        username: this.store.state.userAccountName,
      })
      .then((response) => {
        console.log("logged out from API", response.data);
      });
    this.store.commit("logOut");
    localStorage.clear();
    window.localStorage.clear();
  }
}
