
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import { DateTime } from "luxon";
import { Watch } from "vue-property-decorator";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useStore } from "vuex";

@Options({
  components: {},
  props: {},
})
export default class ReceiptList extends Vue {
  store = useStore()
  rates: Array<any> = [];
  docs: Array<any> = [];
  search: any = {
    code:['01-kb'],
    fromDate: DateTime.now().startOf("day").toISO(),
    toDate: DateTime.now().endOf("day").toISO(),
  };
  doc: any = {
    // totalAmount:{ $numberDecimal: 0 },
    debtAmount: { $numberDecimal: 0 },
    paymentAmount: { $numberDecimal: 0 },
  };
  // get yearRange() {
  //   let from = this.search.fromYear;
  //   let to = this.search.toYear;
  //   let result: Array<number> = [];
  //   for (let i = from; i <= to; i++) {
  //     result.push(i);
  //   }
  //   return result;
  // }
  get dateRange(){
    let arr:Array<string> = []
    let current = DateTime.fromJSDate(this.search.fromDate)
    do{
      console.log(current.toISO())
      let year = current.toObject().year! + 543
      let month = current.toObject().month!
      arr.push(`${month}:${year}`)
      current = current.plus({month:1})
    }while(current < DateTime.fromJSDate(this.search.toDate))
    return arr
  }

  downloadExcel() {
    console.log("download excel...");
    let self = this;
    const Excel = require("exceljs");
    var workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet(`รายงาน`);
    let header = ["เรียกเก็บ", "หนี้ค้างชำระสะสม", "ชำระสะสม", "คิด %"];
    worksheet.addRow(header);
    worksheet.addRow([
      (this.getValue(this.doc.totalAmount) * 1.07).formatFull(),
      this.getValue(this.doc.debtAmount).formatFull(),
      this.getValue(this.doc.paymentAmount).formatFull(),
      (
        (this.getValue(this.doc.paymentAmount) * 100 ?? 0) /
        ((this.getValue(this.doc.totalAmount) * 1.07 ?? 0) +
          this.getValue(this.doc.paymentAmount) ?? 0)
      ).formatFull() + "%",
    ]);
    workbook.xlsx.writeBuffer().then(function(data: any) {
      var blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ผลการจัดเก็บค่าบริการ.xlsx");
    });
  }
  mounted() {
    this.getRates();
    // this.postData();
  }
  getRates() {
    axios.get(`${address}/arearates`).then((response) => {
      this.rates = response.data;
      this.search.code = this.rates.map((el) => el.code);
    });
  }
  getValue(obj: any) {
    try {
      return parseFloat(obj.$numberDecimal);
    } catch (error) {
      return 0;
    }
  }
  postData() {
    let query = {
      monthyear: this.dateRange,
      code: JSON.parse(JSON.stringify(this.search.code)),
      limit: 999999,
    };
    axios.post(`${address}/report-billing-receipt`, query).then((response) => {
      this.doc = response.data;
      console.log(response);
      this.initChart();
    });
  }
  print() {
    window.print();
  }
  initChart() {
    var element = this.$refs.chart as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    Chart.plugins.register(ChartDataLabels);
    let self = this;
    var options = {
      tooltips: {
        enabled: true,
      },
      plugins: {
        datalabels: {
          formatter: (value: any, ctx: any) => {
            var percentage = "";
            let datasets = ctx.chart.data.datasets;
            if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
              let sum = datasets[0].data.reduce(
                (a: number, b: number) => a + b,
                0
              );
              percentage = ((value * 100) / sum).toFixed(2) + "%";
              return percentage;
            } else {
              return percentage;
            }
          },
          color: "#fff",
        },
      },
    };
    var myChart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: ["หนี้ค้างสะสม", "ยอดชำระสะสม"],
        datasets: [
          {
            label: "",
            data: [
              (self.getValue(self.doc.totalAmount) ?? 0) * 1.07,
              self.getValue(self.doc.paymentAmount) ?? 0,
            ],
            barThickness: 40,
            backgroundColor: ["red", "#0B655C"],
            fill: false,
            borderColor: "red",
            borderWidth: 1,
          },
        ],
      },
      options: options,
    });
  }
}
