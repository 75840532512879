
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { DateTime } from "luxon";
@Options({
  components: {},
  props: {
    "onUpdate:modelValue": Function,
    modelValue: Object,
    isClear: Boolean,
  },
})
export default class JMIncomeSearch extends Vue {
  modelValue!: any;
  selected: string = "";
  name = "";
  inputType: String = "";
  search: any = {
    year:new Date().getFullYear()+543
  };
  DateTime = DateTime;

  searchEmit(){
    this.$emit("update:modelValue", this.search);
    this.$emit('search')
  }
  mounted(){
    this.search = this.modelValue
  }
  get days() {
    try {
      return DateTime.fromObject({
        month: this.search.month,
        year: this.search.year - 543,
      })
        .endOf("month")
        .toObject().day;
    } catch (error) {
      0
    }
  }

  @Watch("search", { deep: true })
  emitUp() {
    this.$emit("update:modelValue", this.search);
  }
  emitClear() {
    // this.search = {
    //   year:0,
    //   month:13,
    //   cat:0,
    // };
    this.$emit("update:modelValue", this.search);
    this.$emit("clear");
  }
  emitSearchClear() {
    this.search = {};
    this.$emit("update:modelValue", this.search);
    this.$emit("search");
  }
}
