
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import address from "@/variable";
import axios from "axios";
import Excel from "exceljs/dist/es5/exceljs.browser";
import { DateTime } from "luxon";
import Papa from "papaparse";

@Options({
  components: {},
})
export default class PaymentImportKTB extends Vue {
  store = useStore();
  docs: Array<any> = [];
  meter: string = "";
  code: string = "";
  invoiceAmount: string = "";

  importCSV(ev: any) {
    var file = ev.target.files[0];
    let self = this
    Papa.parse(file, {
      complete: function(results) {
        let datas = results.data
        console.log(results);
        let arr:Array<any> = []
        datas.forEach((item:any,i:number) => {
          if(item[0]=="D"){
            arr.push({
              paymentDate:DateTime.fromFormat(item[4],"ddmmyyyy").toJSDate(),
              time:item[5],
              invoiceNumber:item[7],
              name:item[6]
            })
          }
        });
        self.docs = arr
      }
});
  }
  async importExcel(ev:any){
    let file = ev.target.files[0]
    const wb = new Excel.Workbook()
    const book = await wb.xlsx.load(file)
    const sheet = book.getWorksheet("import")
    let arr:Array<any> = []
    sheet.eachRow((row:any,i:number)=>{
      if(i>1){
        console.log(i,row.values)
        arr.push({
          username:row.getCell("A").value,
          email:row.getCell("B").text,
          role:row.getCell("C").value,
        })
      }
    })
  }
  postData() {
    this.docs.forEach((item) => {
      console.log("item");
      axios
        .post(`${address}/payment`, {
          ...item,
          meter: this.meter,
          code: this.code,
          invoiceAmount: this.invoiceAmount,
        })
        .then((response) => {
          console.log(response);
        });
    });
    alert("ส่งข้อมูลไปยังแม่ข่ายแล้ว");
    this.$router.push("/portal/payment/list");
  }
}
