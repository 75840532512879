export { };

declare global {
  interface String {
    capitalize(): String;
    formatComma(): String;
    formatCizitenId(): String;
    formatThai(): String;
  }
  interface Number {
    formatDash(): string;
    formatComma(): string;
    formatFull(): string;
    formatFullABS(): string;
    formatFullABSThai(): string;
    formatMB(): string;
  }
}

String.prototype.formatThai = function () {
  var array = { "1": "๑", "2": "๒", "3": "๓", "4": "๔", "5": "๕", "6": "๖", "7": "๗", "8": "๘", "9": "๙", "0": "๐" };
  var str = this
  for (var val in array) {
    //@ts-ignore
    str = str.split(val).join(array[val]);
  }
  return str;
}
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
String.prototype.formatComma = function () {
  return this.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
String.prototype.formatCizitenId = function () {
  try {
    return this.replace(
      /(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/g,
      "$1-$2-$3-$4-$5"
    );
  } catch (err) {
    return "";
  }
};

Number.prototype.formatComma = function () {
  return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
Number.prototype.formatDash = function () {
  // if(this == -1) return "-"
  return this.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
Number.prototype.formatFull = function () {
  return this.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
Number.prototype.formatFullABS = function () {
  let number = this
  number = Math.abs(number as number)
  return this.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace("-","");
};
Number.prototype.formatFullABSThai = function () {
  let number = this
  number = Math.abs(number as number)
  let result = this.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace("-","");
  return result.formatThai() as string
};
Number.prototype.formatMB = function () {
  let num = this as number
  return (num / 1000000).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
