
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import axios from "axios";
import address from "@/variable";
import contenteditable from "vue-contenteditable";
import { useCurrencyInput } from 'vue-currency-input'
// import { useToast, POSITION } from "vue-toastification";
import CurrencyInput from '@/components/JMMoney.vue'
@Options({
  components: {
    contenteditable,
    CurrencyInput
  },
})
export default class Form extends Vue {
  isLeft: boolean = true;
  isRight: boolean = true;
  year: number = 2564;
  operation: number = 1;
  quarter: number = 1;
  doc: any = {};
  previous: any = {};
  area: any = {};
  conditions: any = {};
  condition: any = {};
  modified: Array<Krob4Config> = [
    {
      name: "รายได้อยู่ที อจน.แล้ว",
      symbol: "+",
      unit: "บาท",
      indent: 2,
    },
    {
      name: "ยอดที่ อปท.พึงชำระสำหรับปีงบประมาณ *year*",
      symbol: "",
      unit: "บาท",
    },
  ];
  loss_equal: Array<Krob4Config> = [
    {
      name: "รวมค่าใช้จ่ายทั้งสิ้นของ อจน. และ  *area*  (C)",
      symbol: "",
      unit: "บาท",
    },
    {
      name: "รวมรายได้จากการจัดเก็บค่าบริการ (A)",
      calc: "-",
      symbol: "-",
      field: "totalIncome,eTotalIncome",
      unit: "บาท",
    },
    {
      name: "คงเหลือค่าใช้จ่ายที่ไม่เพียงพอต่อการบริหารจัดการ ( B = C - A )",
      calc: "minus,1,0",
      symbol: "=",
      unit: "บาท",
    },
    {
      name: "ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (B/๒)",
      calc: "divideConst,2,2",
      symbol: "",
      unit: "บาท",
    },
    {
      name:
        "รายได้จากการจัดเก็บ + ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (A+B/๒)",
      calc: "sum,1,3",
      symbol: "",
      unit: "บาท",
    },
    {
      name: "คืนค่าใช้จ่ายที่ อปท. *area* จ่ายแทน. อจน.ในปีงบประมาณ *year*",
      field: "advanceAmount,eAdvanceAmount",
      symbol: "-",
      unit: "บาท",
    },
    {
      name: "คงเหลือยอด",
      calc: "minus,5,4",
      symbol: "=",
      unit: "บาท",
    },
    {
      name: "ภาษีมูลค่าเพิ่ม (ร้อยละ ๗)",
      symbol: "",
      calc: "vat,6,7",
      unit: "บาท",
    },
  ];
  loss_condition_remainingWMA: Array<Krob4Config> = [
    {
      name: "รวมค่าใช้จ่ายทั้งสิ้นของ อจน. + *area* (C)",
      symbol: "",
      unit: "บาท",
    },
    {
      name: "รวมรายได้จากการจัดเก็บค่าบริการ (A)",
      symbol: "-",
      calc: "-",
      field: "totalIncome,eTotalIncome",
      unit: "บาท",
    },
    {
      name: "คงเหลือค่าใช้จ่ายที่ไม่เพียงพอต่อการบริหารจัดการ ( B = C - A )",
      calc: "minus,0,1",
      symbol: "=",
      unit: "บาท",
    },
    {
      name: "ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (B/๒)",
      calc: "divideConst,2,2",
      symbol: "",
      unit: "บาท",
    },
    {
      name:
        "รายได้จากการจัดเก็บ + ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (A+B/๒)",
      calc: "sum,1,3",
      symbol: "",
      unit: "บาท",
    },
    {
      name: "คืนค่าใช้จ่ายที่ อปท. จ่ายแทน. อจน.ในปีงบประมาณ *year*",
      symbol: "-",
      field: "advanceAmount,eAdvanceAmount",
      unit: "บาท",
    },
    {
      name: "คงเหลือยอดที่ อปท. พึ่งชำระในปีงบประมาณ *year*",
      calc: "minus,5,4",
      symbol: "=",
      unit: "บาท",
    },
    {
      name: "ภาษีมูลค่าเพิ่ม (ร้อยละ ๗)",
      symbol: "",
      calc: "vat,6,7",
      unit: "บาท",
    },
  ];
  loss_condition_remainingEqual: Array<Krob4Config> = [
    {
      name: "รวมค่าใช้จ่ายทั้งสิ้นของ อจน. + *area* (C)",
      symbol: "",
      unit: "บาท",
    },
    {
      name: "รวมรายได้จากการจัดเก็บค่าบริการ (A)",
      symbol: "-",
      calc: "-",
      field: "totalIncome,eTotalIncome",
      unit: "บาท",
    },
    {
      name: "คงเหลือค่าใช้จ่ายที่ไม่เพียงพอต่อการบริหารจัดการ ( B = C - A )",
      calc: "minus,1,0",
      symbol: "=",
      unit: "บาท",
    },
    {
      name: "สัดส่วนเท่าเทียม",
      symbol: "",
      calc: "divideConst,2,2",
      unit: "บาท",
    },
    {
      name: "คืนค่าใช้จ่ายที่ อปท. จ่ายแทน. อจน.ในปีงบประมาณ *year*",
      symbol: "",
      field: "advanceAmount,eAdvanceAmount",
      unit: "บาท",
    },
    {
      name: "คงเหลือยอดที่ อปท.พึงชำระในปีงบประมาณ *year*",
      calc: "minus,4,3",
      symbol: "=",
      unit: "บาท",
    },
    {
      name: "ตามเงื่อนไขไม่เกิน *contributionLimit*",
      calc: "limitOne,5,6",
      symbol: "=",
      unit: "บาท",
    },
    {
      name: "ภาษีมูลค่าเพิ่ม (ร้อยละ ๗)",
      symbol: "",
      calc: "vat,6,7",
      unit: "บาท",
    },
  ];
  profit: Array<Krob4Config> = [
    {
      name: "รวมค่าใช้จ่ายทั้งสิ้นของ อจน. + *area* (C)",
      symbol: "",
      unit: "บาท",
    },
    {
      name: "รวมรายได้จากการจัดเก็บค่าบริการ (A)",
      symbol: "-",
      calc: "-",
      field: "totalIncome,eTotalIncome",
      unit: "บาท",
    },
    {
      name: "คงเหลือค่าใช้จ่ายที่ไม่เพียงพอต่อการบริหารจัดการ ( B = C - A )",
      calc: "minus,0,1",
      symbol: "=",
      unit: "บาท",
    },
    {
      name: "ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (B/๒)",
      symbol: "",
      unit: "บาท",
    },
    {
      name:
        "รายได้จากการจัดเก็บ + ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (A+B/๒)",
      symbol: "",
      unit: "บาท",
    },
    {
      name: "คืนค่าใช้จ่ายที่ อปท. จ่ายแทน. อจน.ในปีงบประมาณ *year*",
      symbol: "-",
      field: "advanceAmount,eAdvanceAmount",
      unit: "บาท",
    },
    {
      name: "คงเหลือยอดที่ อปท. พึ่งชำระในปีงบประมาณ *year*",
      symbol: "=",
      calc: "sum,5,4",
      unit: "บาท",
    },
    {
      name: "ภาษีมูลค่าเพิ่ม (ร้อยละ ๗)",
      symbol: "",
      calc: "vat,5,7",
      unit: "บาท",
    },
  ];
  percent: Array<Krob4Config> = [
    {
      name: "ค่าใช้จ่ายสำหรับปีงบประมาณ *year* ไตรมาสที่ *quarter*",
      symbol: "",
      unit: "บาท",
    },
    {
      name: "คืนค่าใช้จ่ายที่ อปท. จ่ายแทน. อจน.ในปีงบประมาณ *year*",
      symbol: "-",
      field: "totalIncome,eTotalIncome",
      unit: "บาท",
    },
    {
      name: "คงเหลือค่าใช้จ่าย",
      calc: "minus,0,1",
      symbol: "=",
      unit: "บาท",
    },
    {
      name: "คิดค่าใช้จ่ายร้อยละ *contributionPercent*",
      calc: "percent,2",
      symbol: "",
      unit: "บาท",
    },
    {
      name: "ทั้งปีคงเหลือ",
      symbol: "",
      field: "remainingAmount,eRemainingAmount",
      unit: "บาท",
    },
    {
      name: "ยอดที่ อปท. พึงชำระสำหรับปีงบประมาณ *year* ไตรมาสที่ *quarter*",
      symbol: "",
      calc: "limit,3",
      unit: "บาท",
    },
    {
      name: "ภาษีมูลค่าเพิ่ม (ร้อยละ ๗)",
      symbol: "",
      calc: "vat,5,7",
      unit: "บาท",
    },
  ];
  fixed: Array<Krob4Config> = [
    {
      name: "รวมค่าใช้จ่ายทั้งสิ้นของ อจน. และ  *area* (C)",
      symbol: "",
      unit: "บาท",
    },
    {
      name: "รวมรายได้จากการจัดเก็บค่าบริการ (A)",
      symbol: "",
      calc: "-",
      field: "totalIncome,eTotalIncome",
      unit: "บาท",
    },
    {
      name: "คงเหลือค่าใช้จ่ายที่ไม่เพียงพอต่อการบริหารจัดการ ( B = C - A )",
      calc: "minus,0,1",
      symbol: "",
      unit: "บาท",
    },
    {
      name: "คืนค่าใช้จ่ายที่ อปท. จ่ายแทน. อจน.ในปีงบประมาณ *year*",
      symbol: "",
      field: "advanceAmount,eAdvanceAmount",
      unit: "บาท",
    },
    {
      name: "คงเหลือยอด",
      symbol: "",
      calc: "sum,3,2",
      unit: "บาท",
    },
    {
      name: "ภาษีมูลค่าเพิ่ม (ร้อยละ ๗)",
      symbol: "",
      calc: "vat,4,7",
      unit: "บาท",
    },
  ];
  utilities: Array<Krob4Config> = [];
  replaceName(name: string) {
    let result = name
    result = result.replace(/\*area\*/, `${this.area.prefix} ${this.area.name}`);
    result = result.replace(/\*year\*/, `${String(this.year).formatThai()}`);
    result = result.replace(/\*quarter\*/, `${this.quarter}`);
    result = result.replace(/\*contributionPercent\*/, `${this.condition.contributionPercent}`);
    result = result.replace(/\*contributionLimit\*/, `${(this.condition.contributionLimit??0).formatFull()} บาท`);
    return result;
  }

  get calculations(): Array<Krob4Config> {
    let display = this.doc.display
    console.log({display})
    this.doc.category = this.condition.category
    // this.condition.category = "สมทบตามเปอร์เซ็นต์"
    // this.condition.lossType = 2
    // this.condition.contributionPercent = this.condition.contributionPercent??70
    // this.condition.contributionLimit = 300000
    let round = (n: number) => {
      try {
        return parseFloat(n.toFixed(2));
      } catch (error) {
        return 0;
      }
    };
    let abs = (n: number) => {
      try {
        return Math.abs(parseFloat(n.toFixed(2)));
      } catch (error) {
        return 0;
      }
    };
    let original: Array<Krob4Config>
    original = Object.assign([],this.loss_equal) as Array<Krob4Config>;
    if(this.condition.category==="สมทบตามเปอร์เซ็นต์") original = Object.assign([],this.percent) as Array<Krob4Config>;
    if(this.condition.category==="สมทบตามจำนวนคงที่") original = Object.assign([],this.fixed) as Array<Krob4Config>;
    if(this.condition.category==="สมทบตามค่าใช้จ่ายจริง/สัดส่วนเท่าเทียม"){
      console.log((this.doc.totalExpense??0)>=(this.doc.totalIncome??0),this.doc.totalExpense,this.doc.totalIncome)
      if((this.doc.totalExpense??0)>=(this.doc.totalIncome??0)||(this.doc.eRotalExpense??0)>=(this.doc.eTotalIncome??0)){ // ขาดทุน
        console.log("ขาดทุน")
        if(this.condition.lossType===1) original = Object.assign([],this.loss_condition_remainingWMA) as Array<Krob4Config>;
        else if(this.condition.lossType===2) original = Object.assign([],this.loss_condition_remainingEqual) as Array<Krob4Config>;
        else if(this.condition.lossType===3) original = Object.assign([],this.loss_equal) as Array<Krob4Config>;
      } else { // กำไร
        console.log("กำไร")
        original = Object.assign([],this.profit) as Array<Krob4Config>;
      }
    }
    console.log(this.doc.category)
    console.log("regenerated", this.condition.lossType, this.condition.profitType)
    console.table(original)
    let area = Object.assign({}, this.area);
    original[0].value = round(this.doc.totalExpense ?? 0);
    original[0].eValue = round(this.doc.eTotalExpenses ?? 0);
    original.forEach((item, i) => {
      if (item.field !== undefined) {
        item.field1 = item.field?.split(",")[0];
        item.field2 = item.field?.split(",")[1];
        item.value = round(this.doc[item.field1] ?? 0);
        item.eValue = round(this.doc[item.field2] ?? 0);
      }
      if ((item.calc ?? "").search("sum,") !== -1) {
        let a = parseInt(item.calc!.split(",")[1]);
        let b = parseInt(item.calc!.split(",")[2]);
        item.value = round((original[a].value ?? 0) + (original[b].value ?? 0));
        item.eValue = round(
          (original[a].eValue ?? 0) + (original[b].eValue ?? 0)
        );
      } else if ((item.calc ?? "").search("minus,") !== -1) {
        let a = parseInt(item.calc!.split(",")[1]);
        let b = parseInt(item.calc!.split(",")[2]);
        item.value = round((original[a].value ?? 0) - (original[b].value ?? 0));
        item.eValue = round((original[a].eValue ?? 0) - (original[b].eValue ?? 0));
      } else if ((item.calc ?? "").search("divideConst,") !== -1) {
        let a = parseInt(item.calc!.split(",")[1]);
        let b = parseInt(item.calc!.split(",")[2]);
        item.value = round((original[a].value ?? 0) / b);
        item.eValue = round((original[a].eValue ?? 0) / b);
      } else if ((item.calc ?? "").search("vat,") !== -1) {
        let a = parseInt(item.calc!.split(",")[1]);
        let b = parseInt(item.calc!.split(",")[2]);
        item.value = round((original[a].value ?? 0) * (b / 100));
        item.eValue = round((original[a].eValue ?? 0) * (b / 100));
      } else if ((item.calc ?? "").search("percent,") !== -1) {
        let a = parseInt(item.calc!.split(",")[1]);
        let percent = this.condition.contributionPercent??70
        item.value = round((original[a].value ?? 0) * (percent / 100));
        item.eValue = round((original[a].eValue ?? 0) * (percent / 100));
      } else if ((item.calc ?? "").search("limitOne,") !== -1) { // สำหรับ limit กรณีที่เป็นตามเงื่อนไขของสัดส่วนเท่าเทียม
        let a = parseInt(item.calc!.split(",")[1]);
        let b = parseInt(item.calc!.split(",")[2]);
        if(this.doc.display.includes(String(b))){
          if(this.doc.totalExpenses > 0){
            if(a <= (this.condition.contributionLimit??0)){
              item.value = round((this.condition.contributionLimit??0));
            } else item.value = original[a].value
          } else item.value = original[a].value
          if(this.doc.eTotalExpenses > 0){
            if(a <= (this.condition.contributionLimit??0)){
              item.eValue = round((this.condition.contributionLimit??0));
            } else item.eValue = original[a].eValue
          } else item.eValue = original[a].eValue
        } else {
          item.value = original[a].value
          item.eValue = original[a].eValue
        }
      }else if ((item.calc ?? "").search("limit,") !== -1) {// สำหรับ limit กรณีที่เป็นตามเปอร์เซ็นต์ค่าใช้จ่ายแต่ไม่เกิน...
        let a = parseInt(item.calc!.split(",")[1]);
        let currTotalValue = round(this.doc.totalExpenses) + round(this.previous.sumFinal)
        let currTotalEvalue  = round(this.doc.eTotalExpenses) + round(this.previous.sumFinal)
        if(this.doc.totalExpense > 0){
          if(currTotalValue>this.condition.contributionLimit) item.value = round(round(this.condition.contributionLimit) - round(this.previous.sumExpense))
          else item.value = round(this.doc.totalExpenses)
        } else this.doc.remainingAmount = 0
        if(this.doc.eTotalExpenses > 0){
          if(currTotalEvalue>this.condition.contributionLimit) item.eValue = round(round(this.condition.contributionLimit) - round(this.previous.sumeExpense))
          else item.eValue = round(this.doc.eTotalExpenses)
        } else this.doc.eRemainingAmount = 0
      }
    });
    let totalLength = original.length as number;
    let modifications: Array<{ amount: number; eAmount: number }> =
      this.doc.modifications ?? [];
    let modificationSum = modifications
      .map((el) => el.amount)
      .reduce((a, b) => a + (b ?? 0), 0);
    let modificationeSum = modifications
      .map((el) => el.eAmount)
      .reduce((a, b) => a + (b ?? 0), 0);
    this.doc.modificationSum = modificationSum;
    this.doc.modificationeSum = modificationeSum;
    let isVat = false
    if((this.doc.display??[]).includes(String(original.length-1))) isVat = true
    this.doc.contributionAmount =
      (isVat ? (abs(original[totalLength - 1].value ?? 0)) : 0) +
      -1 * abs(original[totalLength - 2].value ?? 0) +
      abs(modificationSum ?? 0);
    this.doc.eContributionAmount =
      (isVat ? (-1 * abs(original[totalLength - 1].eValue ?? 0)) : 0) +
      -1 * abs(original[totalLength - 2].eValue ?? 0) +
      abs(modificationeSum ?? 0);
    return original;
  }
  mounted() {
    this.year = parseInt(this.$route.params.year as string);
    this.quarter = parseInt(this.$route.params.quarter as string);
    this.operation = parseInt(this.$route.params.operation as string);
    this.fetchData();
  }
  async fetchData() {
    let round = (n: number) => {
      try {
        return parseFloat(n.toFixed(2));
      } catch (error) {
        return 0;
      }
    };
    let response0 = await axios.get(
      `${address}/area/${this.$route.params.area}`
    );
    this.area = response0.data;
    let response1 = await axios.post(`${address}/calculation-find`, {
      search: {
        area: this.$route.params.area,
        calendarYear: this.year,
        quarter: this.quarter,
      },
    });
    this.doc = response1.data;
    this.doc.contributionText = this.doc.contributionText.replace(/(\r\n|\n|\r)/gm, "");
    if(!Array.isArray(this.doc.display)) this.doc.display = []
    let response2 = await axios.post(
      `${address}/calculation-quarter-sum/${this.$route.params.area}/${this.year}/${this.quarter}`
    );
    this.previous = response2.data;
    try {
      this.doc.remainingAmount = round(round(this.condition.contributionLimit) - round(this.previous.sumExpense))
      this.doc.eRemainingAmount = round(round(this.condition.contributionLimit) - round(this.previous.sumeExpense))
    } catch (error) {
      
    }

    let response3 = await axios.get(
      `${address}/areaCondition-by-field/area/${this.$route.params.area}`
    );
    this.conditions = response3.data.conditions;
    this.condition = this.conditions[this.operation];
  }

  pushItem(item: any) {
    if (!Array.isArray(this.doc.modifications)) this.doc.modifications = [];
    this.doc.modifications.push({name:"",amount:0,eAmount:0});
    this.$nextTick(()=>{
      this.$forceUpdate()
    })
  }

  submitData() {
    let query = {
      area: this.$route.params.area,
      calendarYear: this.year,
      quarter: this.quarter,
    };
    let prep = {
      area: this.$route.params.area,
      areaCondition: this.conditions._id,
      operationYear: this.operation,
      calendarYear: this.year,
      quarter: this.quarter,
      ...this.doc,
      isKrob4: true,
    };
    axios
      .post(`${address}/calculation-upsert/`, { search: query, doc: prep })
      .then((response) => {
        alert("บันทึกข้อมูลแล้ว!");
        console.log("done!", prep);
      });
  }

  print(){
    window.print()
  }
}
interface Krob4Config {
  name: string;
  field?: string;
  field1?: string;
  field2?: string;
  calc?: string;
  symbol: string;
  unit: string;
  indent?: Number;
  underline?: Boolean;
  value?: number;
  eValue?: number;
}
