
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import address from "@/variable";
// const io = require("socket.io-client");

@Options({})
export default class Login extends Vue {
  store = useStore();
  // socket = io.connect("https://wma.jmandjm.com", { path: '/api/v1/socket.io' });
  invalid: boolean = false;
  username: string = "";
  password: string = "";
  email: string = "";
  userId: string = "";
  checked: boolean = false;
  userTrying = false;
  mounted() {
    let self = this;
    // this.socket.on('users', function(users:Array<any>) {
    //   console.log('users')
    //   console.log(users)
    //   let found = users.find(el=>el.user===self.username)
    //   if(found!=undefined) alert("ชื่อผู้ใช้นี้ลงชื่อเข้าใช้ไว้แล้ว!")
    //   else{
    //     if(self.userTrying){
    //       self.submitForm()
    //     }
    //   }
    // })
  }

  getUsers() {
    axios.get(`${address}/user-list`).then((response) => {
      let list = response.data;
      let i = list.findIndex((el: any) => el.username === this.username);
      console.log(i, list);
      if (i == -1) this.submitForm();
      else
        alert(
          "ไม่สามารถลงชื่อเข้าใช้ได้เนื่องจาก ผู้ใช้ลงชื่อนี้เข้าใช้อยู่ในระบบแล้ว"
        );
    });
  }

  // setUser(){
  //   this.socket.emit("loggedin", this.username);
  // }

  submitForm() {
    axios
      .post(`${address}/user/login/`, {
        username: this.username,
        password: this.password,
      })
      .then((response) => {
        // alert("ข้อมูลถูกต้อง!")
        this.userTrying = false;
        // this.setUser()
        console.log("data", response.data.user.access);
        this.store.commit("setUser", {
          userAccountName: response.data.user.username,
          access: response.data.user.access,
          accessLevel: response.data.user.accessLevel,
          userId: response.data.user._id,
          token: response.data.token,
        });
        console.log(response.data);

        axios
          .post(`${address}/user-keep-alive`, {
            username: this.store.state.userAccountName,
            path: this.$route.path,
          })
          .then((response) => {
            console.log("keep-alive", response.data);
            this.store.commit("updateUserList", response.data);
          });
        if (this.$route.query.path != undefined) {
          this.$router.push(this.$route.query.path as string);
        } else {
          this.$router.push("/portal/landing");
        }
      })
      .catch((error) => {
        console.log(error.response);
        alert("ข้อมูลผู้ใช้ไม่ถูกต้อง");
        this.userTrying = false;
        // if (error.response.status === 401) {
        //   alert("ไม่พบผู้ใช้");
        // }
      });
  }
  resetRequest() {
    axios
      .post(`${address}/reset/request/`, {
        email: this.email,
        username: this.username,
        userId: this.userId,
      })
      .then((response) => {
        alert("ส่งคำขอไปยังอีเมลแล้ว");
      });
  }
}
