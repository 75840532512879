import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Debt from '@/views/demo/Debt.vue'
import InvoicePrint from '@/views/billing/InvoicePrint.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: 'demo/debt',
    name: 'Debt',
    component: Debt
  },
  {
    path: '/demo/print',
    name: 'Invoice Print Demo',
    component: InvoicePrint,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการใบแจ้งหนี้",
          to:"/portal/invoice/list"
        },
        {
          name:"พิมพ์ใบแจ้งหนี้",
          to:"/portal/invoice/print"
        }
      ]
    }
  },
]

export default routes