
import address from "@/variable";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
@Options({})
export default class InvoiceForm extends Vue {
  store = useStore();
  doc: any = {};
  thailand = require("@/data/thailand.json");
  petition: boolean = false;
  contracts: Array<any> = [];
  total:String = ""
  totalVat:String = ""
  totalServiceVat:String = ""
  year:String = ""
  month:String = ""

  deleteData(){
    let con = confirm("คุณต้องการลบข้อมูลใช่หรือไม่?")
    if(con==true){
      axios.delete(`${address}/invoice/${this.$route.params.id}`).then(response=>{
        this.$router.push('/portal/invoice/list')
      })
    }
  }
  mounted() {
    if (this.$route.params.id != undefined) this.getData();
    this.getContracts();
  }
  get activeContract() {
    try {
      return this.contracts.find((el) => el._id == this.doc.area);
    } catch (error) {
      return {};
    }
  }

  getData() {
    axios.get(`${address}/invoice/${this.$route.params.id}`).then((response) => {
      this.doc = response.data;
      this.total = ((this.doc.qty??0) * (this.doc.rate??0)).formatFull()
      this.totalVat = ((this.doc.qty??0) * (this.doc.rate??0) * (this.doc.vatRate??0)).formatFull()
      // this.totalServiceVat = ((this.doc.qty??0) * (this.doc.rate??0) * (1+this.doc.vatRate??0)).formatFull()
      this.totalServiceVat = ((this.doc.qty??0) * (this.doc.rate??0) + (this.doc.vat??0)).formatFull()
      this.year = "ปี " + this.doc.year
      this.month = "เดือน " + this.getThaiMonth(this.doc.month)
    });
  }
  getThaiMonth(n: number) {
    switch (n) {
      case 1:
        return "ม.ค.";
        break;
      case 2:
        return "ก.พ.";
        break;
      case 3:
        return "มี.ค.";
        break;
      case 4:
        return "เม.ย.";
        break;
      case 5:
        return "พ.ค.";
        break;
      case 6:
        return "มิ.ย.";
        break;
      case 7:
        return "ก.ค.";
        break;
      case 8:
        return "ส.ค.";
        break;
      case 9:
        return "ก.ย.";
        break;
      case 10:
        return "ต.ค.";
        break;
      case 11:
        return "พ.ย.";
        break;
      case 12:
        return "ธ.ค.";
        break;

      default:
        return ".";
        break;
    }
  }
  getContracts() {
    axios.get(`${address}/contracts`).then((response) => {
      this.contracts = response.data;
      console.log(this.contracts);
    });
  }
}
