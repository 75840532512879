
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
@Options({
  components: {},
})
export default class JMBreadcrumbs extends Vue {
  list: Array<any> = [];
  @Watch("$route")
  updateRoute() {
    try {
      this.list = this.$route.meta.bc;
    } catch (error) {
      this.list = [];
    }
  }
  mounted() {
    this.updateRoute();
  }
}
