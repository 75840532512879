
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon";
import { useStore } from "vuex";
import { watch } from "@vue/runtime-core";
import { Watch } from "vue-property-decorator";
import { forEach } from "lodash";
import { to } from "mathjs";

@Options({
  components: {},
  props: {},
})
export default class IncomeReport01 extends Vue {
  reportDate: Date = new Date();
  docs: Array<any> = [];
  fade: boolean = true;
  store = useStore();
  areas: Array<any> = [];
  areaincomes: Array<any> = [];
  today: Date = new Date();
  groupCat = ["กลุ่มพื้นที่ขนาดใหญ่","กลุ่มพื้นที่ขนาดเล็ก","กลุ่มพระราชดำริ"]
  get todayFormat() {
    return this.today.toThaiShort();
  }
  get year() {
    // return this.reportDate.getFullYear() + 543;
    return this.reportDate.getFullYear() + 543 + (this.reportDate.getMonth() >= 9 ? 1 : 0);
  }
  get budgetYear() {
    return this.reportDate.getFullYear() + 543 + (this.reportDate.getMonth() >= 9 ? 1 : 0);
  }
  get month() {
    return this.reportDate.getMonth() + 1;
  }

  getData() {
    axios.get(`${address}/areas`).then((response) => {
      this.areas = response.data;
    });
    axios
      .post(`${address}/report-green-yellow`, {
        budgetYear: this.reportDate.getFullYear(),
      })
      .then((response) => {
        this.docs = response.data;
        this.updateValue();
      });
  }

  @Watch("docs", { deep: true })
  updateValue() {
    this.$nextTick(() => {
      let elementsGreen: Array<any> = (document.getElementsByClassName(
        "g"
      ) as unknown) as Array<any>;
      elementsGreen.forEach((el) => {
        if (el.innerHTML === "<span>0.00</span>") el.classList.remove("white");
        else el.classList.add("white");
      });
      let elementsYellow: Array<any> = (document.getElementsByClassName(
        "y"
      ) as unknown) as Array<any>;
      elementsYellow.forEach((el) => {
        if (el.innerHTML === "<span>0.00</span>") el.classList.remove("white");
        else {
          el.classList.add("white");
        }
      });
    });
  }

  get displayReportDay() {
    let getBudgetYear = (dt: DateTime) => {
      let year = dt.toObject().year! + 543;
      if (dt.toObject().month! > 9) {
        year++;
      }
      return year;
    };
    let selected = getBudgetYear(DateTime.fromJSDate(this.reportDate));
    let today = getBudgetYear(DateTime.now());
    let dtThai =  DateTime.fromJSDate(this.reportDate)
    .reconfigure({ outputCalendar: "buddhist" })
      .set({day:30,month:9})
      .setLocale("th")
      .toFormat("d LLLL yyyy");
    let currentThai =  DateTime.now()
    .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("d LLLL yyyy");
    if(selected < today) return dtThai
    else if(selected >= today) return currentThai
    return "ok" + selected + ":" + today;
  }

  getBudgetYear(dt: Date) {
    let offset = 0
    if( dt.getMonth() >= 9) offset = 1
    let year = dt.getFullYear() + 543 + offset;
    return year;
  }

  get yearLong() {
    let dt = DateTime.now()
      .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("yyyy");
    return parseInt(dt);
  }

  sumInco(incomes: Array<any>, m: Array<number>, y: number) {
    try {
      let filtered = incomes.filter(
        (el) =>
          m.find((i) => i == el.month) != undefined &&
          y == el.year &&
          el.isDebt != true
      );
      return filtered
        .map((el) => el.amount)
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return -1;
    }
  }
  getInco(incomes: Array<any>, mo: number, y: number) {
    let m = [mo];
    try {
      let filtered = incomes.filter(
        (el) =>
          m.find((i) => i == el.month) != undefined &&
          y == el.year &&
          el.isDebt != true
      );
      return filtered
        .map((el) => el.amount)
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return -1;
    }
  }
  sumColc(collections: Array<any>, m: Array<number>, y: number) {
    try {
      let filtered = collections.filter((el) => {
        let foundMonth = m.find((mo) => mo == el.month) != undefined;
        let elBudgetYear = el.remarkYear + (el.month >= 10 ? 1 : 0);
        let yBudgetYear = this.year + (this.month >= 10 ? 1 : 0);
        return foundMonth && y == el.year && el.isDebt != true; //||elBudgetYear>yBudgetYear
      });
      return filtered
        .map((el) => el.amount)
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return -1;
    }
  }
  getColc(collections: Array<any>, month: number, y: number) {
    let m = [month];
    try {
      let filtered = collections.filter((el) => {
        let foundMonth = m.find((mo) => mo == el.month) != undefined;
        let elBudgetYear = el.remarkYear + (el.month >= 10 ? 1 : 0);
        let yBudgetYear = this.year + (this.month >= 10 ? 1 : 0);
        return foundMonth && y == el.year && el.isDebt != true; //||elBudgetYear>yBudgetYear
      });
      return filtered
        .map((el) => el.amount)
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return -1;
    }
  }

  // ชุดเงินค้าง
  yearForPrev(m: Array<number>) {
    let min = Math.min(...m);
    if (min >= 10) return this.year - 1;
    else return this.year;
  }

  sumIncoPrev(incomes: Array<any>, m: Array<number>, y: number) {
    try {
      let filtered = incomes.filter(
        (el) =>
          m.find((i) => i == el.month) != undefined && y == el.year && el.isDebt
      );
      return filtered
        .map((el) => el.amount)
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return -1;
    }
  }
  getIncoPrev(incomes: Array<any>, mo: number, y: number) {
    let m = [mo];
    try {
      let filtered = incomes.filter(
        (el) =>
          m.find((i) => i == el.month) != undefined && y == el.year && el.isDebt
      );
      return filtered
        .map((el) => el.amount)
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return -1;
    }
  }

  sumColcPrev(collections: Array<any>, m: Array<number>, y: number) {
    try {
      let filtered = collections.filter((el) => {
        let foundMonth = m.find((mo) => mo == el.month) != undefined;
        return el.isDebt == true && foundMonth && y == el.year;
      });
      let result = filtered
        .map((el) => el.amount ?? 0)
        .reduce((a: number, b: number) => a + b, 0);
      return result;
    } catch (error) {
      return -1;
    }
  }
  getColcPrev(collections: Array<any>, m: number, y: number) {
    try {
      let filtered = collections.filter(
        (el) => el.month == m && el.isDebt == true && y == el.year
      );
      filtered = filtered.filter((el) => el.remarkYear > 2500);
      return filtered
        .map((el) => el.amount ?? 0)
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return 0;
    }
  }

  getCalc(calculations: Array<any>, q: number, y: number) {
    try {
      let found = calculations.find((el) => el.quarter == q);
      return found.contributionAmount;
    } catch (error) {
      return 0;
    }
  }
  getPlan(areaincomes: Array<any>, q: number, y: number) {
    try {
      let found = areaincomes.find((el) => el.quarter == q);
      return found.amount;
    } catch (error) {
      return 0;
    }
  }
  getAreaIncome() {
    axios.get(`${address}/areaincomes`).then((response) => {
      this.areaincomes = response.data;
    });
  }
  mounted() {
    this.getData();
    this.getAreaIncome();
  }
  print() {
    window.print();
  }
}
