
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  components: {},
  props: {},
})
export default class ReceiptList extends Vue {
  store = useStore()
  id:string = ""
  doc:any = {}
  docs: Array<any> = [];
  search: any = {};
  areas: any = [];
  show: number = 50;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 5;
  filePath = address
  mounted() {
    this.fetchData();
    this.fetchArea();
  }

sendRequest(){
    axios
      .post(`${address}/request`, {
        requestType: "แก้ไขใบนำส่ง",
        status: "ส่งคำร้อง",
        priority: "ทั่วไป",
        reason:(this.doc.documentNumber)+(this.doc.subject),
        username:this.store.state.userAccountName,
        to: this.id,
        from: this.id,
        requester:this.store.state.userId
      })
      .then((response) => {
        console.log(response);
        alert("บันทึกคำร้องสำเร็จ!");
      });
}
  getAreaName(id: string) {
    try {
      return this.areas.find((el: any) => el._id == id).name;
    } catch (error) {
      return "ทม. บ้านโป่ง*";
    }
  }

  fetchArea() {
    axios
      .get(`${address}/areas`)
      .then((response) => (this.areas = response.data));
  }
  fetchData() {
    let query = {
      search: {
        // prettier-ignore
        calendarYear:this.search.year != undefined ? { $eq: this.search.year } : undefined,
        // prettier-ignore
        quarter:this.search.quarter != undefined ? { $eq: this.search.quarter } : undefined,
        // prettier-ignore
        area:this.search.area != undefined ? { $eq: this.search.area } : undefined
      },
      limit: this.show,
      skip: this.page*this.show,
    };
    axios.post(`${address}/coverletters-paginate`, query).then((response) => {
      this.docs = response.data.docs;
    });
  }
}
