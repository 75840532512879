
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import address from "@/variable";
import { DateTime } from "luxon";

@Options({})
export default class LoginExample extends Vue {
  store = useStore();
  search: string = "";
  taxId = "0994000203021";
  suffix = "01";
  meter = "12345678901";
  sequence = "64200009";
  option = "1";
  qty = 30.15;
  barcodeData = "";
  qrData = "";
  branch = "00000";
  dueDate = new Date();
  get amount() {
    return this.qty * 3.5;
  }
  mounted() {
    this.regenerate();
  }
  regenerate() {
    let amount = Math.ceil(this.amount * 100).toString();
    // .padStart(10, "0");
    let meter = this.meter;
    // .padStart(18,"0")
    let sequence = this.sequence;
    // .padStart(18,"0")
    let data = `|${this.taxId}${this.suffix}\r\n${meter}\r\n${sequence}\r\n${amount}`;
    this.barcodeData = data;
    this.genBarcode();
    this.getQRCode();
  }
  genBarcode() {
    var JsBarcode = require("jsbarcode");
    JsBarcode("#barcode", this.barcodeData, { format: "CODE128" });
  }
  getQRCode() {
    var QRCode = require("qrcode");
    var canvas = this.$refs.qrcanvas; //document.getElementById('canvas')
    let dueDate = DateTime.fromJSDate(this.dueDate).toFormat("ddLLyyyy");
    let qty = this.qty.toString(); //.padStart(10,"0")
    let amount = Math.ceil(this.amount*100).toString(); //.padStart(10,"0")
    let vat = Math.ceil(this.amount * 0.07 * 100).toString(); //.padStart(10,"0")
    let tax = Math.ceil(this.amount * 0.03 * 100).toString(); //.padStart(10,"0")
    let taxAmount = Math.ceil(this.amount * 1.03 * 100).toString(); //.padStart(10,"0")
    let netAmount = Math.ceil(this.amount * 1.07 * 100).toString(); //.padStart(10,"0")
    let qrData = `|${this.taxId}${this.suffix}\r\n${this.meter}\r\n${this.sequence}\r\n${amount}\r\n`;
    this.qrData = qrData;
    // qrData += `2\r\n`; // Transaction Type
    // qrData += `${dueDate}\r\n`; // DueDate
    // qrData += `${qty}\r\n`; // QTY
    // qrData += `${amount}\r\n`; // Sales Amount
    // qrData += `0700\r\n`; // VAT Rate
    // qrData += `${vat}\r\n`; // VAT Amount
    // qrData += `${this.branch}\r\n`; // Branch
    // qrData += `${this.taxId}\r\n`; // Buyer Tax ID
    // qrData += `${this.branch}\r\n`; // Buyer Branch
    // qrData += `JM AND JM SOLUTION CO., LTD.\r\n`; // Buyer Name
    // qrData += `1111111111\r\n`; // Reference 3
    // qrData += `0039999999999\r\n`; // ProxyID
    // qrData += `1234567890\r\n`; // ProxyType
    // qrData += `${netAmount}\r\n`; // Net Amount
    // qrData += `007\r\n`; // Type Of Income ***
    // qrData += `3\r\n`; // With Holdering Tax
    // qrData += `${tax}\r\n`; // With Holdering Amount
    // qrData += `A`; // With Holdering Amount
    // if (this.option == "1") {
    //   this.qrData = this.barcodeData;
    // } else {
    //   this.qrData = qrData;
    // }

    QRCode.toCanvas(
      canvas,
      this.qrData,
      { errorCorrectionLevel: "H", mode: "alphanumeric" },
      function(error: any) {
        if (error) console.error(error);
        console.log("success!");
      }
    );
  }
}
