
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  components: {},
})
export default class InvoiceImport extends Vue {
  store = useStore();
  docs: Array<any> = [];
  code: string = "";
  year: number = 0;
  month: number = 0;
  category: string = "";
  categoryType: string = "";
  calculationType: string = "";
  rates: Array<any> = [];
  mounted() {
    this.getRates();
  }
  getRates() {
    axios.get(`${address}/arearates`).then((response) => {
      this.rates = response.data;
    });
  }
  get activeContract() {
    try {
      return this.rates.find((el) => el.code == this.code);
    } catch (error) {
      return {};
    }
  }
  postData() {
    this.docs.forEach((el) => {
      axios.post(`${address}/invoice`, el).then((response) => {
        console.log("done!", response.data);
      });
    });
  }
  async importExcel(ev: any) {
    const Excel = require("exceljs/dist/es5/exceljs.browser");
    let self = this;
    console.log(ev);
    let file = ev.target.files[0];
    const workbook = new Excel.Workbook();
    let book = await workbook.xlsx.load(file);
    let sheet = book.getWorksheet("type2");
    console.log(book);
    let count = sheet.rowCount;
    let arr: Array<any> = [];
    var i = 1
    sheet.eachRow(function(row: any, rowNumber: any) {
      let unit = "";
        if (self.categoryType == "น้ำเสีย")
          unit = self.rates.find(((el) => el.code == self.code) ?? {})
            .treatmentType;
        else if (self.categoryType == "น้ำทิ้ง")
          unit = self.rates.find(((el) => el.code == self.code) ?? {})
            .effluentType;
      if (rowNumber >= 4) {
        arr.push({
          no:i,
          numberInit: row.getCell(2).value,
          meter: row.getCell(3).value,
          name: row.getCell(4).value,
          address: row.getCell(5).value,
          debtText: row.getCell(6).value,
          debtAmount: row.getCell(7).value,
          qty: row.getCell(8).value,
          rate: row.getCell(9).value,
          code: self.code,
          year: self.year,
          month: self.month,
          category: self.category,
          categoryType: self.categoryType,
          calculationType: unit,
        });
        i++
      }
    });
    this.docs = arr;
    ev.target.value = null;
  }
}
