
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
var { DateTime } = require("luxon");
@Options({
  components: {},
  props: {},
})
export default class History extends Vue {
  DateTime = DateTime;
  docs: Array<any> = [];
  show: number = 25;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 5;
  mounted() {
    this.fetchData();
  }
  resolveObj(item: any) {
    const _ = require("lodash");
    let from = item.from;
    let to = item.to;
    let result = _.map(to, (value: any, key: any) => {
      let str = "";
      switch (key) {
        case "qty":
          str += `เปลี่ยนแปลงปริมาณ ${from[key]} => ${value}`;
          break;
        case "name":
          str += `เปลี่ยนแปลงชื่อ ${from[key]} => ${value}`;
          break;
        case "year":
          str += `เปลี่ยนแปลงปี ${from[key]} => ${value}`;
          break;
        case "month":
          str += `เปลี่ยนแปลงเดือน ${from[key]} => ${value}`;
          break;
        case "period":
          str += `เปลี่ยนแปลงช่วง ${from[key]} => ${value}`;
          break;

        default:
          str += `เปลี่ยนแปลง ${key} ${from[key]} => ${value}`;
          break;
      }
      if (from[key] != to[key]) {
        return str;
      } else return "*";
    });
    return result;
  }
  updatePaginate(i: number) {
    this.page = i;
    // console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  fetchData() {
    let query = {
      search: {
        documentId: this.$route.params.id,
      },
      limit: this.show,
      skip: this.page * this.show,
      // sort: { number: 1 },
    };
    axios.post(`${address}/histories-paginate`, query).then((response) => {
      console.log(response.data);
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
}
