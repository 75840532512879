import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import UserList from '@/views/user/UserList.vue'
import UserForm from '@/views/user/UserForm.vue'
import ResetForm from '@/views/user/ResetForm.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'user/list',
    name: 'UserList',
    component: UserList,
    meta:{
      bc:[
        {
          name:"จัดการผู้ใช้",
          to:"/portal/landing"
        },
        {
          name:"รายการผู้ใช้งาน",
          to:"user/list"
        },
      ]
    }
  },
  {
    path: 'user/form',
    name: 'UserForm',
    component: UserForm,
    meta:{
      bc:[
        {
          name:"จัดการผู้ใช้",
          to:"/portal/landing"
        },
        {
          name:"รายการผู้ใช้งาน",
          to:"/portal/user/list"
        },
        {
          name:"เพิ่มผู้ใช้งาน",
          to:"user/form"
        },
      ]
    }
  },
  {
    path: 'user/edit/:id',
    name: 'UserEdit',
    component: UserForm,
    meta:{
      bc:[
        {
          name:"จัดการผู้ใช้",
          to:"/portal/landing"
        },
        {
          name:"รายการผู้ใช้งาน",
          to:"/portal/user/list"
        },
        {
          name:"แก้ไขผู้ใช้งาน",
          to:"user/edit/:id"
        },
      ]
    }
  },
  {
    path: '/reset/form',
    name: 'ResetForm',
    component: ResetForm,
    meta:{
      bc:[
        {
          name:"จัดการผู้ใช้",
          to:"/portal/landing"
        },
        {
          name:"รีเซ็ตรหัสผ่านใหม่",
          to:"/reset/form"
        },
      ]
    }
  },
]
export default routes