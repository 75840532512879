
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Quill from "quill";
import { v4 as uuidv4 } from 'uuid';
@Options({
  components: {},
  props:{
    modelValue:String,
    uuid:String,
    id:String
  }
})
export default class AccountList extends Vue {
  uuid!:string
  id!:string
  modelValue!:string
  mounted() {

    this.uuid = uuidv4()
    var toolbarOptions = [
      ["bold", "italic", "underline", "strike"],
      // ["link", "image", "video"],
      // ["blockquote", "code-block",],
      // [{ list: "ordered" }, { list: "bullet" }],
      // [{ script: "sub" }, { script: "super" }], // superscript/subscript
      // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

      // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      // [{ align: [] }]
    ];

    var options = {
      modules: {
        toolbar: toolbarOptions
      },
      imageCompress: {
        quality: 0.45, // default
        maxWidth: 250, // default
        imageType: "image/jpeg", // default
        debug: true // default
      },
      theme: "snow"
    };
    var container = document.getElementById(`editor-${this.uuid}-${this.id}`);
    var editor = new Quill(container!, options);
    editor.on("text-change", () => {
      this.$emit("update:modelValue", editor.root.innerHTML);
    });
    this.updateData()
  }

  updateData() {
    //@ts-ignore
    // document.querySelector(`#editor-${this.uuid} .ql-editor`)!.innerHTML = this.modelValue;
  }
}
