
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon";
import { useStore } from "vuex";

@Options({
  components: {},
  props: {},
})
export default class Krob2List extends Vue {
  store = useStore();
  docs: Array<any> = [];
  calculations: Array<any> = [];
  search:any = {}
  isSigned: boolean = true;
  isActive: boolean = false;
  toggle: boolean = false;
  DateTime = DateTime;
  areas:any = []
  show: number = 50;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 5;
  changeActive() {
    this.docs.forEach((el) => {
      el.active = !this.isActive;
    });
  }
  mounted() {
    this.fetchData();
    this.fetchArea();
  }
  fetchArea() {
    axios
      .get(`${address}/areas`)
      .then((response) => (this.areas = response.data));
  }
  fetchData() {
    let query = {
      search:this.search,
      limit: this.show,
      skip: this.page * this.show,
    }
    axios.post(`${address}/render-calculation-list`,query).then((response) => {
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show)
      axios.post(`${address}/calculations-paginate`,{
        search:{
          area:{$in:this.docs.map(el=>el.area)}
        },
        limit:99999,
        skip:0
      }).then((response) => {
        this.calculations = response.data.docs;
        this.mapping()
      });
    });
  }
  mapping(){
    this.docs.forEach((el,i)=>{
      let found = this.calculations.find(cal=>{
        // console.log(el,cal)
        return cal.area == el.area
        && cal.operationYear == el.j
        && cal.quarter == el.quarter 
      })
      if(found!=undefined){
        console.log("found!")
        this.docs[i].calculation = found
        this.docs[i].modifiedDate = found.createdAt
        this.docs[i].isKrob3 = found.isKrob3
      }
    })
  }
  updatePaginate(i: number) {
    this.page = i;
    console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
}
