
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";

@Options({})
export default class Portal extends Vue {
  store = useStore();
  foundSamePage = "";

  mounted() {
    this.keepAlive();
  }
  get isSamePage() {
    try {
      let filtered = this.store.state.userList.filter(
        (el: any) => el.username != this.store.state.userAccountName
      );
      let found = filtered.find((el: any) => el.path == this.$route.path);
      console.log(found);
      this.foundSamePage = found.username;
      return found != undefined;
    } catch (error) {
      return false;
    }
  }
  @Watch("$route.path")
  updatePath() {
    console.log("updating...");
    axios
      .post(`${address}/user-keep-alive`, {
        username: this.store.state.userAccountName,
        path: this.$route.path,
      })
      .then((response) => {
        console.log("update path", response.data);
        this.store.commit("updateUserList", response.data);
      });
  }
  keepAlive() {
    if(this.$route.path!="/portal/landing"){
      axios
        .post(`${address}/user-keep-alive`, {
          username: this.store.state.userAccountName,
          path: this.$route.path,
        })
        .then((response) => {
          // console.log("keep-alive", response.data);
          this.store.commit("updateUserList", response.data);
        });
    }
    setTimeout(() => {
      this.keepAlive();
    }, 1 * 1000 * 20);
  }
}
