
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon";
import { Watch } from "vue-property-decorator";

@Options({
  components: {},
  props: {
    "onUpdate:modelValue":Function
  },
})
export default class JMIncomeSearch extends Vue {
  search:any = {}
  areas:Array<any> = []
  TomSelect:any

  ts1:any
  ts2:any
  ts3:any
  ts4:any

  @Watch("search",{deep:true})
  updateModel(){
    this.$emit("update:modelValue",this.search)
  }

  clear(){
    this.ts1.clear(true)
    this.ts2.clear(true)
    this.ts3.clear(true)
    this.ts4.clear(true)
    this.$router.push({query: {}});
    this.search={};
    this.$emit('clear')
  }
  mounted() {
    try {
    console.log("search",this.$route.query.searchparam)
    this.search = JSON.parse(this.$route.query.searchparam as string)
    } catch (error) {
      console.log(error)
    }
    // this.search = {
    //   // year:this.$route.query.year!=undefined?parseInt(this.$route.query.year as string):undefined,
    //   // area:this.$route.query.area??undefined,
    //   // quarter:this.$route.query.quarter!=undefined?parseInt(this.$route.query.quarter as string):undefined,
    // }
    
    this.$emit("update:modelValue",this.search)

    this.TomSelect = require("tom-select")
    this.fetchArea();

    this.$nextTick(()=>{

      this.ts1 = new this.TomSelect("#quarter-select",{
        create: false
      });
      this.ts2 = new this.TomSelect("#month-select",{
        create: false
      });
      this.ts3 = new this.TomSelect("#year-select",{
        create: false
      });
    })
  }
  fetchArea() {
    axios
      .get(`${address}/areas`)
      .then((response) => {
          this.areas = response.data
          this.$nextTick(()=>{
            this.ts4 = new this.TomSelect("#area-select",{
              create: false
            });
          })
        }
      );
  }
}
