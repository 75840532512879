
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";
import { useStore } from "vuex";
import { Watch } from "vue-property-decorator";
import { DateTime } from "luxon";
@Options({
  components: {
  },
  props: {
  },
})
export default class TaxReport extends Vue {
  store = useStore()
  docs:Array<any> = [];
  isSigned:boolean = true
  isActive:boolean = false
  search:any={};
  show:number = 999999999;
  total:number = 5;
  pages: number = 0;
  Date: Date = new Date();
  rates: Array<any> = [];
  totalCount: number = 0;
  totalQty: number = 0;
  totalAmount: number = 0;
  totalPayment: number = 0;
  totalDebt: number = 0;
  ids: Array<string> = [];
  loading: boolean = false;
  downloadExcel() {
    console.log("download excel...");
    let self = this;
    const Excel = require("exceljs");
    var workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet(`รายงาน`);
    let header = [
      'ลำดับ',
      'ใบกำกับภาษี-วันเดือนปี',
      'ใบกำกับภาษี-เลขที่',
      'เลขที่ผู้ใช้น้ำ',
      'ชื่อผู้ซื้อสินค้า/ผู้รับบริการ',
      'ชำระเงินค้างรวมภาษี 7%(บาท)',
      'ค่าบริการปัจจุบันรวมภาษี 7%(บาท)',
      'รวมจำนวนเงินตามใบแจ้งหนี้(บาท)',
      'รับเงินจริง(บาท)',
      'ภาษีมูลค่าเพิ่ม',
      'หมายเหตุ'
    ];
    worksheet.addRow(['รายงานภาษีขาย (กรณีมี Vat) ประจำเดือน ' + `${this.getThaiMonth(this.search.month)}${this.search.year}`,...new Array(12).fill(""),new Date().toThaiShort()]);
    worksheet.addRow(['ชื่อผู้ประกอบการ: องค์การจัดการน้ำเสีย ' + `(${(this.rates.find(el=>el.code==this.search.code)??{}).name})`,...new Array(12).fill(""),'สำนักงานใหญ่']);
    worksheet.addRow(['ชื่อสถานประกอบการ: 333 อาคารเล้าเป้งง้วน 1 ชั้น 23 ถนนวิภาวดีรังสิต แขวงจอมพล เขตจตุจักร กรุงเทพฯ 10900 02-273-8530-9',...new Array(12).fill(""),'ประเภท'+this.search.cat]);
    worksheet.addRow(['เลขประจำตัวผู้เสียภาษี: 0994000203021'],...new Array(12).fill(""),'หน่วย [บาท]');
    worksheet.addRow(header);
    this.docs.forEach((item, i) => {
      worksheet.addRow([
        i + 1,
        this.toThaiFormat(item.paymentDate),
        item.sequence,
        item.meter,
        (item.prefix??"") + (item.name??"-"),
        ((item.debtAmount)*item.vat+(item.rate*item.qty)).formatFull(),
        ((item.totalAmount??0)*item.vat+(item.rate*item.qty)).formatFull(),
        (item.invoiceAmount??0).formatFull(),
        (item.paymentAmount).formatFull(),
        (((item.invoiceAmount??0)/1.07)*0.07).formatFull(),
        item.description
      ]);
    });
    workbook.xlsx.writeBuffer().then(function(data: any) {
      var blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ภาษีขาย.xlsx");
    });
  }
  @Watch("search", { deep: true })
  updateReport() {
    console.log("deep");
    if (
      this.search.code != undefined &&
      this.search.cat != undefined &&
      this.search.month != undefined &&
      this.search.year != undefined
    ) {
      console.log("ทำงาน")
      this.fetchData();
    }
  }
  print(){
    window.print();
  }
  sum(item: any): number {
    let sumAmount = 0;
    if (item.calculationType == "บาท/ลบ.ม.") {
      sumAmount = item.qty * item.rate;
    } else {
      sumAmount = item.rate;
    }
    // console.log(item.qty, item.rate, sumAmount, item.calculationType);
    return sumAmount ?? 0;
  }
  mounted(){
    // this.search = {
    //   cat: "2,น้ำเสีย",
    //   code: "01-kb",
    //   month: "2",
    //   year: 2563,
    // };
    this.updateReport()
    // this.fetchData()
    this.getRates()
  }
  getRates(){
    axios.get(`${address}/arearates`).then((response) => {
      this.rates = response.data;
    });
  }

  fetchData(){
    this.loading = true;
    let category = null
    let categoryType = null
    try {
      category = (this.search.cat).split(",")[0]
      categoryType = (this.search.cat).split(",")[1]
    } catch (error) {
    }
    let query = {
      search:{
        $and:[
          { code: this.search.code!=undefined?{ $eq: this.search.code }:undefined },
          { year: this.search.year!=undefined?{ $eq: this.search.year }:undefined },
          { month: this.search.month!=undefined?{ $eq: this.search.month }:undefined },
          { category: category != undefined ? { $eq: category } : undefined },
          // { categoryType:categoryType != undefined ? { $eq: categoryType } : undefined,},
        ],
      },
      limit:999999999,
      skip:0,
      sort:{
        createdAt:"-1"
      }
    }
    axios.post(`${address}/receipts-paginate`,query).then((response) => {
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
      this.loading = false;
      this.totalCount = response.data.totalCount;
      this.totalQty = response.data.totalQty;
      this.totalAmount = response.data.totalAmount;
      this.totalPayment = response.data.totalPayment;
      this.totalDebt = response.data.totalDebt;
      this.ids = response.data.ids;
    });
  }
  toThaiFormat(dt:string){
    var dtThai = DateTime.fromISO(dt)
      .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("ddLLLyy");
    return dtThai
  }
  getThaiMonth(n: string){
      switch (n) {
        case "1":
          return "ม.ค.";
          break;
        case "2":
          return "ก.พ.";
          break;
        case "3":
          return "มี.ค.";
          break;
        case "4":
          return "เม.ย.";
          break;
        case "5":
          return "พ.ค.";
          break;
        case "6":
          return "มิ.ย.";
          break;
        case "7":
          return "ก.ค.";
          break;
        case "8":
          return "ส.ค.";
          break;
        case "9":
          return "ก.ย.";
          break;
        case "10":
          return "ต.ค.";
          break;
        case "11":
          return "พ.ย.";
          break;
        case "12":
          return "ธ.ค.";
          break;

        default:
          return ".";
          break;
      }
  }
  // calcTreatment(item:any):number{
  //   if(item.category == 1) return item.qty * 3.0
  //   else if(item.category == 2) return item.qty * 3.5
  //   else if(item.category == 3) return item.qty * 4.0
  //   else return 0
  // }
  // calcTax(item:any):number{
  //   let basisAmount = this.calcTreatment(item) * 0.07
  //   return (basisAmount||0)
  // }
  // getData() {
  //   axios.get(`${address}/receipts`).then(response=>{
  //     this.docs = response.data
  //   })
  // }

  // changeActive(){
  //   this.docs.forEach(el=>{
  //     el.active = !this.isActive
  //   })
  // }
}
