
import address, { filePath } from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";
import { DateTime } from "luxon";
import { sign } from "mathjs";
import * as _ from "lodash";
import { nextTick } from "@vue/runtime-core";
@Options({})
export default class ReceiptSign extends Vue {
  store = useStore();
  pdfBLOB = "";
  name = "ปนัดดา ภาณุทัต";
  position = "ผู้อำนวยการฝ่ายบริหารการจัดเก็บรายได้";
  fonts: any = {};
  styles: any = {};
  filePath = filePath;
  docs: Array<any> = [];
  printDate: Date = new Date();
  project: any = {};
  withQR: boolean = true;
  withDate: boolean = true;
  url: string = "";
  signature: string = "";
  type1: boolean = true;
  type2: boolean = true;
  type3: boolean = true;
  invoices: Array<any> = [];
  payments: Array<any> = [];
  vision: string =
    "บริหารจัดการน้ำเสียชุมชนให้ได้มาตรฐานและยั่งยืน ภายในปี 2569";
  @Watch("url")
  updateLogo() {
    this.generatePDF("preview");
  }
  @Watch("signature")
  updateSignature() {
    this.generatePDF("preview");
  }
  toThaiFormat(dt: string) {
    var dtThai = DateTime.fromISO(dt)
      .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("ddLLLyy");
    return dtThai;
  }

  sum(item: any): number {
    let sumAmount = 0;
    if (item.calculationType == "บาท/ลบ.ม.") {
      sumAmount = item.qty * item.rate;
    } else {
      sumAmount = item.rate;
    }
    // console.log(item.qty,item.rate,sumAmount,item.calculationType)
    return sumAmount ?? 0;
  }
  mounted() {
    // this.store.commit("setReceiptList", ["602de52023c1cd433687d793"]);
    this.getData();
  }
  getData() {
    let list: Array<any> = this.store.state.receiptList;
    console.log(list);
    axios
      .post(`${address}/process/print/receipt`, {
        limit: 999999999,
        skip: 0,
        list: this.store.state.receiptList,
      })
      .then(async (response) => {
        this.docs = response.data.docs;
        this.generatePDF("preview");
      });
  }
  item = (doc: any, type: string, i: number) => {
    let paymentDate = DateTime.fromISO(doc.paymentDate)
      .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("dd LLL yy");
      console.log("doc.payments",doc)
    let invoiceDate = DateTime.fromISO(doc.paymentDate)
      .minus({month:2})
      .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("LLL yy");
      console.log("doc.payments",doc)
    let offsetMonth: Date = DateTime.fromObject({
      month: doc.month,
      year: doc.year,
    })
      .minus({ month: 2 })
      .toJSDate();
    let result = {
      stack: [
        {
          columns: [
            {
              image: "custom", //default
              width: 70,
              // relativePosition: { x: 17, y: 20 },
            },
            {
              stack: [
                {
                  text: "องค์การจัดการน้ำเสีย (สำนักงานใหญ่)",
                  style: {
                    bold: true,
                  },
                  alignment: "right",
                  fontSize: 12,
                },
                {
                  text: "333 อาคารเล้าเป้งง้วน 1 ชั้น 23",
                  alignment: "right",
                  fontSize: 11,
                },
                {
                  text: "ถนนวิภาวดีรังสิต แขวงจอมพล เขตจตุจักร",
                  alignment: "right",
                  fontSize: 11,
                },
                {
                  text: "กรุงเทพฯ 10900",
                  alignment: "right",
                  fontSize: 11,
                },
                {
                  text: "โทร : 02-273-8530-9",
                  alignment: "right",
                  fontSize: 11,
                },
              ],
              margin: [5, 0, 5, 0],
            },
          ],
        },

        {
          text: [
            { text: `เลขประจำตัวผู้เสียภาษี   ` },
            {
              text: `0 99 4000203021`,
            },
          ],
          alignment: "right",
          margin: [5, 0, 5, 0],
        },
        type == ""
          ? {
              columns: [{ text: `.`, alignment: "right", color: "white" }],
              margin: [5, 0, 5, 0],
            }
          : null,
        {
          text: type,
          alignment: "right",
          margin: [5, 0, 5, 0],
          style: {
            bold: true,
          },
        },
        {
          text: "ใบเสร็จรับเงิน/ใบกำกับภาษี",
          style: {
            bold: true,
          },
          alignment: "right",
          margin: [5, 0, 5, 0],
        },
        {
          layout: "border",
          fontSize: 11,
          table: {
            headerRows: 1,
            widths: ["*", "auto", "auto", "*"],
            body: [
              [
                "เลขที่ใบเสร็จ",
                "เลขที่ผู้ใช้น้ำ",
                "รหัสสายเก็บเงิน",
                "วันที่พิมพ์",
              ],
              [
                { text: `wma-${(doc || {}).sequence ?? "-"}` },
                { text: `${(doc || {}).meter ?? "-"}` },
                // { text: `${(doc || {}).name}` },
                { text: `${(doc || {}).code ?? "-"}` },
                {
                  text: `${new Date(doc.paymentDate).toThaiShort()}`,
                },
              ],
            ],
          },
          alignment: "center",
          margin: [5, 0, 5, 0],
        },
        {
          text: [
            { text: "ชื่อ", style: "bold" },
            {
              text: `\t${(doc || {}).name}`,
            },
          ],
          margin: [5, 10, 10, 0],
          fontSize: 11,
        },
        {
          text: [
            { text: "ที่อยู่", style: "bold" },
            {
              text: `\t${(doc || {}).address ?? "-"}`,
            },
          ],
          margin: [5, 0, 10, 0],
          fontSize: 11,
        },
        {
          layout: {
            hLineColor: function(i: number, node: any) {
              return "#FFFFFF";
            },
            vLineColor: function(i: number, node: any) {
              return "#FFFFFF";
            },
            hLineWidth: function(i: number, node: any) {
              switch (i) {
                case 3:
                case 6:
                  return 1;
                  break;
                case 4:
                  return 2;
                  break;
                default:
                  return 0;
                  break;
              }
            },
          },
          fontSize: 11,
          table: {
            headerRows: 1,
            widths: [150, 40, 40],
            body: [
              [
                {
                  text: [
                    { text: `ชำระยอดค้างเดือน `, style: "bold" },
                    {
                      text: `${doc.debtText}`,
                      fontSize: "8",
                    },
                  ],
                },
                {
                  text: `${(doc.debtAmount ?? 0).formatFull()}`,
                  alignment: "right",
                },
                { text: "บาท", alignment: "right" },
              ],
              [
                { text: "ภาษีมูลค่าเพ่ิม 7%" },
                {
                  text: (doc.debtVat ?? 0).formatFull(),
                  alignment: "right",
                  border: [false, false, false, true],
                  borderColor: ["#ff00ff", "#00ffff", "#ff00ff", "#000000"],
                },
                { text: "บาท", alignment: "right" },
              ],
              [
                {
                  text: [
                    { text: `ปริมาณการใช้น้ำครั้งนี้ `, style: "bold" },
                    {
                      text: `${invoiceDate}`
                    },
                  ],
                },
                {
                  text: `${doc.qty.formatFull()}`,
                  alignment: "right",
                  decorationColor: "grey",
                  border: [false, false, false, true],
                  borderColor: ["#ff00ff", "#00ffff", "#000000", "#000000"],
                },
                { text: "ลบ.ม.", alignment: "right" },
              ],
              [
                { text: "รวมเงินค่าบริการบำบัด" },
                {
                  text: `${((doc.totalAmount??0)).formatFull()}`,
                  alignment: "right",
                  decorationColor: "grey",
                  border: [false, false, false, true],
                  borderColor: ["#ff00ff", "#00ffff", "#ff00ff", "#000000"],
                },
                { text: "บาท", alignment: "right" },
              ],
              [
                { text: "ภาษีมูลค่าเพิ่ม" },
                {
                  text: `${(doc.vat ?? 0).formatFull()}`,
                  alignment: "right",
                },
                { text: "บาท", alignment: "right" },
              ],
              [
                {
                  text: "ค่าบริการบำบัดน้ำเสียได้ชำระ",
                  style: "bold",
                },
                {
                  text: ((doc.totalAmount ?? 0) + (doc.vat ?? 0)+(doc.debtAmount ?? 0)+(doc.debtVat ?? 0)).formatFull(),
                  alignment: "right",
                  border: [false, false, false, true],
                  borderColor: ["#ff00ff", "#00ffff", "#ff00ff", "#000000"],
                },
                { text: "บาท", alignment: "right" },
              ],
              [
                { text: "ชำระเงินจริง" },
                {
                  stack: [
                    {
                      text: ((doc.totalAmount ?? 0) + (doc.vat ?? 0)+(doc.debtAmount ?? 0)+(doc.debtVat ?? 0)).formatFull(),
                      alignment: "right",
                      border: [false, false, false, true],
                      borderColor: ["#ff00ff", "#00ffff", "#ff00ff", "#000000"],
                    },
                    {
                      canvas: [
                        {
                          type: "line",
                          x1: -5,
                          y1: 3,
                          x2: 45,
                          y2: 3,
                          lineWidth: 1,
                        },
                        {
                          type: "line",
                          x1: -5,
                          y1: 5,
                          x2: 45,
                          y2: 5,
                          lineWidth: 1,
                        },
                      ],
                    },
                  ],
                },
                { text: "บาท", alignment: "right" },
              ],
            ],
          },
        },
        {
          text: `(${(doc.paymentAmount).toThaiText()})`,
          margin: [5, 10, 10, 0],
          fontSize: 10,
        },
        type == "ต้นขั้ว"
          ? {
              columns: [{ text: ``, alignment: "right" }],
              margin: [0, 21, 0, 0],
              fontSize: "12",
            }
          : null,
        type == "สำเนา"
          ? {
              columns: [{ text: ``, alignment: "right" }],
              margin: [0, 21, 0, 0],
              fontSize: "12",
            }
          : null,
        type == ""
          ? {
              columns: [
                {
                  text: "",
                },
                {
                  alignment: "center",
                  stack: [
                    "ผู้รับเงิน",
                    "",
                    "..................................",
                    {
                      text: `${this.name} `,
                      alignment: "center",
                    },
                  ],
                },
              ],
              margin: [0, -15, 0, 0],
              fontSize: "11",
            }
          : null,
        type == ""
          ? {
              columns: [
                {
                  text: "",
                },
                {
                  canvas: [
                    {
                      type: "line",
                      x1: 0,
                      y1: 0,
                      x2: 0,
                      y2: 30,
                      lineWidth: 0,
                      color: "#FFF",
                    },
                  ],
                  // relativePosition:{ x:-5,y:-25 }
                },
                {
                  image: "signature",
                  alignment: "center",
                  width: 50,
                  height: 28,
                  relativePosition: { x: -5, y: -30 },
                },
              ],
              margin: [0, -10, 35, 0],
              fontSize: "11",
            }
          : null,
        type == ""
          ? {
              columns: [
                {
                  text: "",
                },
                { text: `( ${this.position} )`, alignment: "center" },
              ],
              margin: [-20, -20, 0, 0],
              fontSize: "11",
            }
          : null,
        {
          columns: [
            { text: `วันที่ชำระเงิน`, style: "bold" },
            {
              text: `\t${new Date(doc.paymentDate).toThaiShort()}`,

              border: [false, false, false, true],
              borderColor: ["#ff00ff", "#00ffff", "#ff00ff", "#000000"],
            },
            { text: `(เอกสารออกเป็นชุด)` },
          ],
          margin: [0, 5, 10, 0],
          fontSize: 11,
        },
        {
          text: [
            {
              text:
                "*กรณีชำระเงินด้วยเช็คและการโอนเงินเข้าบัญชีธนาคารของ อจน. ใบเสร็จรับเงิน",
            },
            {
              text:
                "\r\nจะสมบูรณ์ก็ต่อเมื่อ อจน. ได้เรียกเก็บเงินตามเช็คและการโอนเงินครบถ้วนแล้ว",
            },
            {
              text:
                "\r\n*ใบเสร็จรับเงินฉบับนี้ จะสมบูรณ์ก็ต่อเมื่อมีลายมือชื่อผู้รับเงิน ทั้งนี้จะไม่ ลบล้างหนี้สินที่ค้างชำระอยู่ก่อน",
            },
          ],
          margin: [5, 0, 10, 0],
          fontSize: 11,
        },
        {
          stack: [
            {
              text: "วิสัยทัศน์ อจน.",
              relativePosition: { x: 100, y: 15 },
              fontSize: 11,
            },
            {
              text: this.vision,
              relativePosition: { x: 30, y: 30 },
              fontSize: 11,
            },
            {
              canvas: [
                {
                  type: "rect",
                  w: 220,
                  h: 35,
                  r: 8,
                  x: 15,
                  y: 5,
                },
              ],
              margin: [0, 8, 0, 0],
            },
          ],
        },
        ]
    }
    return result
  }
  generatePDF(mode: string) {
    let array: Array<any> = [{}, {}, {}, {}];
    var pdfMake = require("pdfmake/build/pdfmake.js");
    this.fonts = this.store.getters.getPDFFonts;
    this.styles = this.store.getters.getPDFStyles;

    let count = 0;
    let prepArray: Array<any> = [];
    let prepOuter: Array<any> = [];

    this.docs.forEach((el, i) => {
      if (this.type1) {
        count++;
        prepArray.push(this.item(el, "ต้นขั้ว", i));
      }
      if (this.type2) {
        count++;
        prepArray.push(this.item(el, "สำเนา", i));
      }
      if (this.type3) {
        count++;
        prepArray.push(this.item(el, "", i));
      }
    });

    do {
      prepOuter.push(prepArray.slice(0, 3));
      prepArray.splice(0, 3);
    } while (prepArray.length > 0);

    if (prepOuter[prepOuter.length - 1].length === 1) {
      console.log("เหลืิอ 1 => เติม 2");
      prepOuter[prepOuter.length - 1].push("");
      prepOuter[prepOuter.length - 1].push("");
    } else if (prepOuter[prepOuter.length - 1].length === 2) {
      console.log("เหลืิอ 2 => เติม 1");
      prepOuter[prepOuter.length - 1].push("");
    }
    let signature;
    try {
      signature = this.docs[0].signature;
    } catch (error) {
      console.log("no signature found!", error);
    }
    if (signature == undefined) {
      signature =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=";
    } else {
      if (signature === "")
        signature =
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAZCAYAAADHXotLAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABFBJREFUeNrsWa9zE0EUvhQEjqvDNXV1pA7HxeFIHagmrqgkEpXrX5BGopJK1AWHu4sDldYVldSByuHqQnb43vDN617uR44OGfJmdvZus7f7fu3b7704zo52tKNsVEPbUTHyVm2JvjDt0fPpqoV4dldt9gAGqq6av6kQ/5BBDM3LWrABCxsK8BwWULAHg2pqQvkhGUG8yt8y5ffBN7cFZCz9yPlYvIhBfMuxNcaZYnyIOUPsMd1Cg3TAc0fJ2IFDbkQcsiL0vVU7L1kAw+jxqrWg/Bbe3S0MTcxzAOczDnZQwIHXGoQNc1GiAK/VKZHjPSwz3j4gsePGMIIJ9xOLwXLTY/W+vyGzczAc01gX43N1+Zn+uZq7LQYxJ/wKbQBA1EZk+WvyLMs4ggXCwH9Nj1IuaOPVlxY09iYB4lUBlY2X3FF+c4bfriyGeIG5sSUvMvu8wmkyv39fk0OdEcK7SZjXBP/Mu/DgEA82OWTuGcaegTeWyYThMXQWg5/mGjictE/mEyIILMTvjRSU5dE6C8DFJGTHQocWWLnEPaRzo6Fl3ixhnvCwIN5tPKxDiwvqhSehKfbmNCJJV9Z99nKeqJe4E+qIpacZoPQI8wcZcboglzGQWAX9OZQcKI9vwksPcQd24Xl95Ykyrwte8ia9ghYPaZ0W1mlivxoh1DbAUR19u8ilnkYxjBCDuXnG+Q0YM+2ukMTSGPuExuUCfQrFeMoh6hRuLjDuqXUltPWhwJOcsrfJYS7haHLyquArpvE5Jb9e1ss+r0G6YCDIWCa4AJIKchi8jnUbUKwYcZLAj2sRNragPxn/CWNEBYBHBMcYonfJYbpk7BHeAwr7mfbLG7LmUFglxzctzI8yGiQCxg+Ul/dUdiyKiDLyPYInH6jLNk90GEDREo57WHtMl3tVOVceXeU2yENQTd0LdbR9C0rLQxO6d3oZ7r+k6DCFsl0YpcyqRu6Q9RAkp+KTCj0xxopWoKUycFwweRvh+xpORKDujCJ0oEPsXgZvDQmyuQQzGUenFRwXpOhGxiTxFELXSjp1ErryGKMPeV1VVhLDbHJiOzitc14nzSAyWeJ5BwqV0kEVRzhMUJzE2QF5UgCsnmaYMfqwhEy+aDkjskDtTSsSHcgvRVzXkp8lJoZNUoqPPqRYvKREa0kFRE8lPnKapAQ/U/NtSRl/461J2GwU0hosz8K5XyIfKlkdkpUTR00zrOeSxy8twIP3XlDy6lv2ShVq6vz5M8YjI4SUsYvlA9rMUwqdWgTwLczxNwHt45ZgEF9l/KF6dy0VhD7NddasNyOluwkGkX1EjwsK58MstSy5QI+AMD4jTN1i/AtC1x3aR2D8r1TxjYFujlCHalFOYOZcr9o3PHOucE3JVEvlO7fO/YqypgrWiFT4mRBCEtj8AfLFKlRfI6zekJw6nJk5T/CbeX+bUG+rYO93WM/s+8P5/deE0ev7LLWsHe1oR78EGACEk5cawN+IbgAAAABJRU5ErkJggg==";
    }
    var docDefinition = {
      // watermark:"ฉบับร่าง",
      images: {
        default: this.store.getters.base64Logo,
        example: "https://picsum.photos/200/300",
        custom:
          this.url == ""
            ? this.store.getters.base64Logo
            : this.filePath + "/" + this.url,
        signature: signature,
      },
      pageMargins: [5, 20, 0, 0],

      pageOrientation: "landscape",
      content: [
        prepOuter.map((el, i) => {
          return {
            layout: {
              vLineWidth: function(i: number) {
                return 4;
              },
              vLineColor: function(i: number) {
                return "#FFF";
              },
              hLineColor: function(i: number) {
                return "#FFF";
              },
            },
            table: {
              widths: ["*", "*", "*"],
              heights: 590,
              body: [el],
              // body: [['1','2','3']],
            },
          };
        }),
      ],
    };
    if (mode == "preview") {
      let pdfDocGenerator = pdfMake.createPdf(docDefinition, null, this.fonts);
      pdfDocGenerator.getBlob((blob: any) => {
        let blobURL = URL.createObjectURL(blob);
        this.pdfBLOB = blobURL;
      });
    } else {
      let pdfDocGenerator = pdfMake
        .createPdf(docDefinition, null, this.fonts)
        .download(`ใบเสร็จรับเงิน/ใบกำกับภาษี.pdf`);
    }
  }


//         doc.signature == undefined
//           ? {
//               // svg:'<svg width="200" height="35"><text font-size="30" x="25%" y="50%" transform="translate(1, 25) rotate(-5deg)">ร่าง</text></svg>',
//               svg: `<?xml version="1.0" encoding="utf-8"?>
// <!-- Generator: Adobe Illustrator 25.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
// <svg transform="rotate(-35deg) scale(3)" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
// 	 viewBox="0 0 40 20" style="enable-background:new 0 0 40 20;" xml:space="preserve">
// <g>
// 	<path d="M7.11,14.26c0,0.36-0.13,0.67-0.38,0.92c-0.25,0.25-0.56,0.38-0.92,0.38c-0.33,0-0.62-0.11-0.87-0.33
// 		C4.69,15,4.56,14.73,4.53,14.4c-0.4,0.15-0.8,0.49-1.2,1.02H2.36v-1.33c0-0.32,0.06-0.53,0.17-0.65c-0.11,0-0.19-0.01-0.24-0.02
// 		c-0.63-0.14-0.94-0.52-0.94-1.14c0-0.33,0.12-0.61,0.35-0.84c0.23-0.23,0.51-0.34,0.85-0.34c0.33,0,0.61,0.11,0.84,0.34
// 		c0.23,0.23,0.34,0.51,0.34,0.84c0,0.16-0.05,0.37-0.15,0.64c-0.1,0.27-0.15,0.49-0.15,0.66v0.72c0.18-0.18,0.52-0.41,1.02-0.69
// 		c0.5-0.28,0.85-0.53,1.06-0.74c0.17-0.23,0.26-0.5,0.26-0.8v-1.57c0-0.38-0.18-0.67-0.55-0.87C4.91,9.46,4.53,9.38,4.09,9.38
// 		c-0.48,0-0.88,0.09-1.21,0.27c-0.4,0.22-0.61,0.54-0.65,0.96H1.2c0.17-1.42,1.13-2.13,2.87-2.13c0.75,0,1.37,0.15,1.86,0.44
// 		c0.59,0.36,0.89,0.91,0.89,1.64v1.62c0,0.43-0.13,0.75-0.38,0.96C6.89,13.38,7.11,13.76,7.11,14.26z M3.05,12.28
// 		c0-0.15-0.05-0.27-0.15-0.37c-0.1-0.1-0.22-0.15-0.37-0.15c-0.15,0-0.27,0.05-0.37,0.15c-0.1,0.1-0.15,0.22-0.15,0.37
// 		c0,0.15,0.05,0.27,0.15,0.37c0.1,0.1,0.22,0.15,0.37,0.15c0.15,0,0.27-0.05,0.37-0.15C3,12.55,3.05,12.43,3.05,12.28z M6.36,14.28
// 		c0-0.15-0.05-0.27-0.15-0.38c-0.1-0.1-0.23-0.15-0.38-0.15c-0.15,0-0.27,0.05-0.38,0.15C5.35,14,5.3,14.13,5.3,14.28
// 		c0,0.15,0.05,0.27,0.15,0.38c0.1,0.1,0.23,0.15,0.38,0.15c0.15,0,0.27-0.05,0.38-0.15C6.31,14.55,6.36,14.42,6.36,14.28z"/>
// 	<path d="M14.42,13.88c0,1.12-0.83,1.67-2.48,1.67c-1.66,0-2.49-0.56-2.49-1.67v-2.85c-0.11,0.02-0.21,0.03-0.29,0.03
// 		c-0.36,0-0.67-0.13-0.92-0.38c-0.25-0.26-0.38-0.57-0.38-0.93c0-0.35,0.13-0.65,0.38-0.9C8.49,8.6,8.79,8.47,9.16,8.47
// 		c0.39,0,0.71,0.13,0.96,0.39c0.26,0.26,0.38,0.59,0.38,0.99v4.05c0,0.23,0.15,0.41,0.46,0.55c0.31,0.14,0.63,0.2,0.97,0.2
// 		c0.33,0,0.65-0.07,0.96-0.2c0.31-0.14,0.46-0.32,0.46-0.55V8.61h1.06V13.88z M9.74,9.77c0-0.16-0.06-0.3-0.17-0.41
// 		C9.45,9.25,9.32,9.19,9.16,9.19c-0.16,0-0.3,0.06-0.41,0.17C8.64,9.47,8.58,9.61,8.58,9.77c0,0.16,0.06,0.3,0.17,0.41
// 		c0.11,0.11,0.25,0.17,0.41,0.17c0.16,0,0.3-0.06,0.41-0.17C9.68,10.07,9.74,9.93,9.74,9.77z"/>
// 	<path d="M16.14,4.44c-0.3,1.89-1.32,2.83-3.07,2.83c-0.68,0-1.21-0.14-1.57-0.42c-0.36-0.28-0.55-0.63-0.55-1.07
// 		c0-0.33,0.11-0.61,0.34-0.84c0.23-0.23,0.51-0.34,0.84-0.34c0.35,0,0.64,0.12,0.86,0.35c0.22,0.23,0.33,0.51,0.33,0.83
// 		c0,0.31-0.09,0.54-0.26,0.68c1.18-0.05,1.87-0.72,2.07-2.03H16.14z M12.64,5.79c0-0.15-0.05-0.27-0.15-0.38
// 		c-0.1-0.1-0.23-0.15-0.38-0.15c-0.15,0-0.27,0.05-0.37,0.15c-0.1,0.1-0.15,0.23-0.15,0.38c0,0.15,0.05,0.27,0.15,0.37
// 		c0.1,0.1,0.22,0.15,0.37,0.15c0.15,0,0.27-0.05,0.38-0.15C12.59,6.06,12.64,5.94,12.64,5.79z"/>
// 	<path d="M22.01,13.88c0,1.12-0.83,1.67-2.48,1.67c-1.66,0-2.49-0.56-2.49-1.67v-2.85c-0.11,0.02-0.21,0.03-0.29,0.03
// 		c-0.36,0-0.67-0.13-0.92-0.38c-0.25-0.26-0.38-0.57-0.38-0.93c0-0.35,0.13-0.65,0.38-0.9c0.25-0.25,0.56-0.38,0.92-0.38
// 		c0.39,0,0.71,0.13,0.96,0.39c0.26,0.26,0.38,0.59,0.38,0.99v4.05c0,0.23,0.15,0.41,0.46,0.55c0.31,0.14,0.63,0.2,0.97,0.2
// 		c0.33,0,0.65-0.07,0.96-0.2c0.31-0.14,0.46-0.32,0.46-0.55V8.61h1.06V13.88z M17.34,9.77c0-0.16-0.06-0.3-0.17-0.41
// 		c-0.11-0.11-0.25-0.17-0.41-0.17c-0.16,0-0.3,0.06-0.41,0.17c-0.11,0.11-0.17,0.25-0.17,0.41c0,0.16,0.06,0.3,0.17,0.41
// 		c0.11,0.11,0.25,0.17,0.41,0.17c0.16,0,0.3-0.06,0.41-0.17C17.28,10.07,17.34,9.93,17.34,9.77z"/>
// 	<path d="M27.51,14.28c0,0.36-0.13,0.67-0.38,0.91c-0.26,0.24-0.57,0.37-0.93,0.37c-0.36,0-0.67-0.13-0.93-0.38
// 		c-0.26-0.25-0.38-0.56-0.38-0.92c0-0.36,0.13-0.67,0.38-0.92c0.26-0.25,0.57-0.38,0.93-0.38c0.14,0,0.22,0.01,0.26,0.02v-1.47
// 		c0-0.18-0.38-0.36-1.14-0.54c-0.76-0.18-1.44-0.32-2.06-0.42c0.02-0.63,0.19-1.13,0.5-1.51c0.31-0.38,0.75-0.57,1.33-0.57
// 		c0.24,0,0.57,0.05,0.98,0.16c0.42,0.11,0.71,0.16,0.9,0.16c0.19,0,0.3-0.13,0.32-0.38l0.84,0.22c-0.2,0.69-0.58,1.04-1.13,1.04
// 		c-0.2,0-0.5-0.05-0.88-0.15c-0.38-0.1-0.67-0.15-0.86-0.15c-0.5,0-0.79,0.19-0.85,0.58c0.88,0.18,1.61,0.38,2.21,0.58
// 		c0.6,0.2,0.9,0.56,0.9,1.06V14.28z M26.78,14.26c0-0.16-0.06-0.3-0.17-0.41c-0.11-0.11-0.25-0.17-0.41-0.17
// 		c-0.16,0-0.3,0.06-0.41,0.17c-0.11,0.11-0.17,0.25-0.17,0.41c0,0.16,0.06,0.3,0.17,0.41c0.11,0.11,0.25,0.17,0.41,0.17
// 		c0.16,0,0.3-0.06,0.41-0.17C26.72,14.56,26.78,14.42,26.78,14.26z"/>
// 	<path d="M27.63,7.5h-1.06V5.09h1.06V7.5z"/>
// 	<path d="M33.37,15.42h-1.06v-4.97c0-0.32-0.11-0.58-0.32-0.78c-0.22-0.2-0.51-0.3-0.89-0.3c-0.38,0-0.68,0.13-0.92,0.38
// 		c-0.24,0.25-0.36,0.57-0.38,0.96h-1.02c0.03-0.68,0.26-1.23,0.67-1.63c0.42-0.4,0.96-0.61,1.65-0.61c0.64,0,1.18,0.16,1.61,0.49
// 		c0.44,0.32,0.66,0.82,0.66,1.48V15.42z"/>
// 	<path d="M38.8,15.42h-1.04l-3.19-3.6l0.63-0.73l2.54,2.87v-2.94c-0.03,0.01-0.14,0.02-0.32,0.02c-0.35,0-0.65-0.12-0.9-0.35
// 		c-0.24-0.23-0.37-0.53-0.37-0.9c0-0.36,0.13-0.67,0.38-0.93c0.25-0.26,0.55-0.38,0.89-0.38c0.4,0,0.73,0.13,0.99,0.38
// 		c0.26,0.26,0.39,0.59,0.39,1V15.42z M37.99,9.75c0-0.16-0.06-0.3-0.17-0.41c-0.11-0.11-0.25-0.17-0.41-0.17
// 		c-0.16,0-0.3,0.06-0.41,0.17c-0.11,0.11-0.17,0.25-0.17,0.41c0,0.16,0.06,0.3,0.17,0.41c0.11,0.11,0.25,0.17,0.41,0.17
// 		c0.16,0,0.3-0.06,0.41-0.17C37.94,10.05,37.99,9.91,37.99,9.75z"/>
// </g>
// </svg>
// `,
//               relativePosition: { x: 45, y: -270 },
//             }
//           : null,
//       ],
//     };
//     return result;
//   };
}
