import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import UsageList from '@/views/billing/UsageList.vue'
import UsageForm from '@/views/billing/UsageForm.vue'
import UsageImport from '@/views/billing/UsageImport.vue'
import InvoiceList from '@/views/billing/InvoiceList.vue'
import InvoiceForm from '@/views/billing/InvoiceForm.vue'
import InvoiceImport from '@/views/billing/InvoiceImport.vue'
import InvoicePrint from '@/views/billing/InvoicePrint.vue'
import PaymentList from '@/views/billing/PaymentList.vue'
import PaymentForm from '@/views/billing/PaymentForm.vue'
import PaymentImport from '@/views/billing/PaymentImport.vue'
import PaymentImportKTB from '@/views/billing/PaymentImportKTB.vue'
import ReceiptForm from '@/views/billing/ReceiptForm.vue'
import ReceiptList from '@/views/billing/ReceiptList.vue'
import ReceiptPrint from '@/views/billing/ReceiptPrint.vue'
import BDocumentList from '@/views/billing/BDocumentList.vue'
import CustomerHistory from '@/views/billing/CustomerHistory.vue'
import EditHistory from '@/views/billing/EditHistory.vue'
import Approval from '@/views/billing/Approval.vue'
import ApprovalDetail from '@/views/billing/ApprovalDetail.vue'
import GroupList from '@/views/billing/GroupList.vue'
import GroupForm from '@/views/billing/GroupForm.vue'

import Lodash from "@/views/billing/Lodash.vue"
const routes: Array<RouteRecordRaw> = [
  {
    path: 'billing/lodash',
    name: 'Loadsh',
    component: Lodash,
  },
//สายรหัสเก็บเงิน/อัตราค่าบริการ
  {
    path: 'billing/group/list',
    name: 'GroupList',
    component: GroupList,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"สายรหัสเก็บเงิน/อัตราค่าบริการ",
          to:"/portal/billing/group/list"
        },
      ]
    }
  },

  {
    path: 'billing/group/form/:id',
    name: 'GroupForm',
    component: GroupForm,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"สายรหัสเก็บเงิน/อัตราค่าบริการ",
          to:"/portal/billing/group/list"
        },
        {
          name:"อัตราค่าบริการพื้นที่",
          to:"/portal/billing/group/form"
        }
      ]
    }
  },

//ใบแจ้งหนี้
  {
    path: 'invoice/list',
    name: 'InvoiceList',
    component: InvoiceList,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการใบแจ้งหนี้",
          to:"/portal/invoice/list"
        },
      ]
    }
  },
  {
    path: 'invoice/form/:id',
    name: 'InvoiceForm',
    component: InvoiceForm,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายละเอียดใบแจ้งหนี้",
          to:"/portal/invoice/form/:id"
        },
      ]
    }
  },
  {
    path: 'invoice/import',
    name: 'InvoiceImport',
    component: InvoiceImport,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการใบแจ้งหนี้",
          to:"/portal/invoice/list"
        },
        {
          name:"เพิ่มข้อมูลใบแจ้งหนี้ด้วยไฟล์ Excel",
          to:"/portal/invoice/import"
        }
      ]
    }
  },
  {
    path: 'invoice/print',
    name: 'Invoice Print',
    component: InvoicePrint,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการใบแจ้งหนี้",
          to:"/portal/invoice/list"
        },
        {
          name:"พิมพ์ใบแจ้งหนี้",
          to:"/portal/invoice/print"
        }
      ]
    }
  },

  //ผู้ชำระเงิน
  {
    path: 'payment/list',
    name: 'PaymentList',
    component: PaymentList,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการผู้ชำระเงิน",
          to:"/portal/payment/list"
        },
      ]
    }
  },
  {
    path: 'payment/import',
    name: 'PaymentImport',
    component: PaymentImport,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการผู้ชำระเงิน",
          to:"/portal/payment/list"
        },
        {
          name:"เพิ่มข้อมูลการชำระเงินด้วยไฟล์ Excel",
          to:"/portal/payment/import"
        }
      ]
    }
  },
  {
    path: 'payment/import-ktb',
    name: 'PaymentImportKTB',
    component: PaymentImportKTB,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการผู้ชำระเงิน",
          to:"/portal/payment/list"
        },
        {
          name:"เพิ่มข้อมูลการชำระเงินด้วยไฟล์ Excel (KTB)",
          to:"/portal/payment/import-ktb"
        }
      ]
    }
  },
  {
    path: 'payment/form',
    name: 'PaymentForm',
    component: PaymentForm,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการผู้ชำระเงิน",
          to:"/portal/payment/list"
        },
        {
          name:"ตัดยอดหนี้",
          to:"/portal/payment/form"
        }
      ]
    }
  },
  {
    path: 'payment/edit/:id',
    name: 'PaymentEdit',
    component: PaymentForm,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการผู้ชำระเงิน",
          to:"/portal/payment/list"
        },
        {
          name:"แก้ไขข้อมูลตัดยอดหนี้",
          to:"/portal/payment/form"
        }
      ]
    }
  },

//ใบเสร็จรับเงิน/ใบกำกับภาษี
  {
    path: 'receipt/list',
    name: 'ReceiptList',
    component: ReceiptList,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการใบเสร็จรับเงิน/ใบกำกับภาษี",
          to:"/portal/receipt/list"
        },
      ]
    }
  },
  {
    path: 'receipt/form/:id',
    name: 'ReceiptForm',
    component: ReceiptForm,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายละเอียดใบเสร็จรับเงิน/ใบกำกับภาษี",
          to:"/portal/receipt/form/:id"
        },
      ]
    }
  },
  {
    path: 'receipt/print',
    name: 'ReceiptPrint',
    component: ReceiptPrint,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการใบเสร็จรับเงิน/ใบกำกับภาษี",
          to:"/portal/receipt/list"
        },
        {
          name:"พิมพ์ใบเสร็จ",
          to:"/portal/receipt/print"
        }
      ]
    }
  },
//อัปโหลดเอกสาร
{
  path: 'bdocument/list',
  name: 'BDocumentList',
  component: BDocumentList,
  meta:{
    bc:[
      {
        name:"ระบบบิลลิ่ง",
        to:"/portal/landing/billing"
      },
      {
        name:"รายการเอกสาร",
        to:"/portal/bdocument/list"
      },
    ]
  }
},
//ทะเบียนคุมผู้ใช้บริการ
  {
    path:'usage/list',
    name:"UsageList",
    component:UsageList,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"ทะเบียนคุมผู้ใช้บริการ",
          to:"/portal/usage/list"
        }
      ]
    }
  },
  {
    path:'usage/import',
    name:"UsageImport",
    component:UsageImport,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"ทะเบียนคุมผู้ใช้บริการ",
          to:"/portal/usage/list"
        },
        {
          name:"เพิ่มข้อมูลผู้ใช้บริการด้วยไฟล์ Excel",
          to:"/portal/usage/import"
        }
      ]
    }
  },
  {
    path:'usage/form',
    name:"UsageForm",
    component:UsageForm,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"ทะเบียนคุมผู้ใช้บริการ",
          to:"/portal/usage/list"
        },
        {
          name:"เพิ่มข้อมูลผู้ใช้บริการ",
          to:"/portal/usage/form"
        }
      ]
    }
  },
  {
    path:'usage/edit/:id',
    name:"UsageEdit",
    component:UsageForm,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"ทะเบียนคุมผู้ใช้บริการ",
          to:"/portal/usage/list"
        },
        {
          name:"แก้ไขข้อมูลผู้ใช้บริการ",
          to:"/portal/usage/edit/:id"
        },
      ]
    }
  },
  {
    path:'meter/history/:id',
    name:"CustomerHistory",
    component:CustomerHistory,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการผู้ใช้น้ำทั้งหมด",
          to:"/portal/customer/list"
        },
        {
          name:"ประวัติผู้ใช้น้ำ (รายงานสรุปลูกหนี้)",
          to:"/portal/meter/history/:id"
        },
      ]
    }
  },
  {
    path:'edit/history/:id',
    name:"EditHistory",
    component:EditHistory,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"ทะเบียนคุมผู้ใช้บริการ",
          to:"/portal/usage/list"
        },
        {
          name:"ประวัติการแก้ไข",
          to:"/portal/edit/history/:id"
        },
      ]
    }
  },

//คำขออนุมัติ
  {
    path:'approval/billing',
    name:"Approval Billing",
    component:Approval,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการคำขออนุมัติ",
          to:"/portal/approval"
        }
      ]
    }
  },
  {
    path:'approval/income',
    name:"Approval Income",
    component:Approval,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการคำขออนุมัติ",
          to:"/portal/approval"
        }
      ]
    }
  },
  {
    path:'approval',
    name:"Approval",
    component:Approval,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการคำขออนุมัติ",
          to:"/portal/approval"
        }
      ]
    }
  },
  {
    path:'approval/detail/:id',
    name:"ApprovalDetail",
    component:ApprovalDetail,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการคำขออนุมัติ",
          to:"/portal/approval"
        },
        {
          name:"รายละเอียดคำขออนุมัติ",
          to:"/portal/approval/detail"
        }
      ]
    }
  },
]


export default routes
