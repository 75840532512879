
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import address from "@/variable";
@Options({
  components: {},
  props: {},
})
export default class CodeImport extends Vue {
  docs: Array<any> = [];
  preps: Array<any> = [];
  async importExcel(ev: any) {
    const Excel = require("exceljs/dist/es5/exceljs.browser");
    let file = ev.target.files[0];
    const workbook = new Excel.Workbook();
    let book = await workbook.xlsx.load(file);
    let sheet = book.worksheets[0];
    let count = sheet.rowCount;
    let arr: Array<any> = [];
    let uploadArr: Array<any> = [];

    sheet.eachRow((row: any, index: any) => {
      if (index > 1) {
        arr.push({
          code: row.getCell(1).value,
          name: row.getCell(2).value,
          category: row.getCell(3).value,
        });
      }
    });
    this.docs = arr;
    this.preps = uploadArr;
    ev.target.value = null;
  }

  postData() {
    this.docs.forEach((item) => {
      axios.post(`${address}/costcode`, item).then((response) => {
        alert("บันทึกข้อมูลแล้ว!");
        console.log(response);
      });
    });
    alert("ส่งข้อมูลไปยังแม่ข่ายแล้ว");
  }
}
