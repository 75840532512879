
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon";
import { useStore } from "vuex";
@Options({
  components: {},
  props: {},
})
export default class Krob2List extends Vue {
  store = useStore();
  docs: Array<any> = [];
  calculations: Array<any> = [];
  search: any = {};
  isSigned: boolean = true;
  isActive: boolean = false;
  toggle: boolean = false;
  DateTime = DateTime;
  areas: any = [];
  show: number = 50;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 5;
  parameter: string = "";
  krob2: Array<any> = [];
  mode: string = "";
  id: string = "";
  activeCalculations: Array<any> = [];
  area: any = {};
  downloadExcel(item: any,cat:string) {
    console.log(item)
    axios.get(`${address}/area/${item.area}`).then(arearesponse=>{
      let area = arearesponse.data

      var name = item.calendarYear
      name += item.quarter == 0 ? " รายปี" : " ไตรมาส " + item.quarter
      var fileName = area.name + " - " + name
      axios.get(`${address}/calculation/${item._id}`).then(response=>{
        let doc = response.data

        console.log("download excel...", doc);
        let self = this;
        const Excel = require("exceljs");
        var workbook = new Excel.Workbook();
        var worksheet = workbook.addWorksheet(fileName);
        let header = [
          "รหัสบัญชี",
          "ชื่อบัญชี",
          "ต.ค.",
          "พ.ย.",
          "ธ.ค.",
          "ม.ค.",
          "ก.พ.",
          "มี.ค.",
          "เม.ย.",
          "พ.ค.",
          "มิ.ย.",
          "ก.ค.",
          "ส.ค.",
          "ก.ย.",
          "รวม ",
        ];

        let headerRow = worksheet.addRow(header);
        ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1', 'O1'].map(key => {
            worksheet.getCell(key).fill = {
              type: 'pattern',
              pattern:'solid',
              fgColor:{ argb:'FF666666' }
            };
            worksheet.getCell(key).font = { color: { argb: "FFFFFFFF" } };
        });
        // worksheet.getCell("B1").width = 100
        // worksheet.getRange("A1:O1").font = { color: { argb: "FFFFFFFF" } };
        let arr = cat=='ledgers'?doc.ledgers:doc.endorsedLedgers
        arr.forEach((item:any, i:number) => {
          let code = item.code.slice(0, 6) + "." + item.code.slice(6);
          worksheet.addRow([
            code,item.name,...item.months.map((el:any)=>el.amount),item.months.map((el:any)=>el.amount).reduce((a:number,b:number)=>a+b,0)
          ]);
        });
        worksheet.getColumn('B').width = 60
        workbook.xlsx.writeBuffer().then(function(data: any) {
          var blob = new Blob([data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, fileName + ".xlsx");
        });
      })
    })
  }
  goToForm() {
    let btn = this.$refs.cancelModal as HTMLElement;
    btn.click();
    this.$router.push(
      `/portal/estimation/form/${this.parameter}&mode=${this.mode}`
    );
  }
  goToPDF() {
    let btn = this.$refs.cancelModal2 as HTMLElement;
    btn.click();
    this.$router.push(`/portal/estimation/pdf-id/${this.id}`);
  }
  changeActive() {
    this.docs.forEach((el) => {
      el.active = !this.isActive;
    });
  }
  mounted() {
    this.fetchData();
    this.fetchArea();
  }
  fetchArea() {
    axios
      .get(`${address}/areas`)
      .then((response) => (this.areas = response.data));
  }
  fetchData() {
    let query = {
      search: this.search,
      limit: this.show,
      skip: this.page * this.show,
    };
    console.log("query",query);
    axios.post(`${address}/render-calculation-list`, query).then((response) => {
      console.log(response.data);
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
      axios.post(`${address}/calculations-paginate`,{
        search:{
          area:{$in:this.docs.map(el=>el.area)}
        },
        limit:99999,
        skip:0
      }).then((response) => {
        this.calculations = response.data;
        this.mapping()
      });
    });
  }
  mapping() {
    this.docs.forEach((el, i) => {
      let found = this.calculations.find((cal) => {
        // console.log(el,cal)
        return (
          cal.area == el.area &&
          cal.operationYear == el.j &&
          cal.quarter == el.quarter
        );
      });
      if (found != undefined) {
        console.log("found!");
        this.docs[i].calculation = found;
        this.docs[i].modifiedDate = found.createdAt;
      }
    });
  }
  toThaiFormat(calculations: Array<any>) {
    try {
      let result = new Date(calculations[0].modifiedAt).toThaiShort();
      return result;
    } catch (error) {
      return "-";
    }
  }
  updatePaginate(i: number) {
    this.page = i;
    console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
}
