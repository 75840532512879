
import address, {filePath} from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { Watch } from "vue-property-decorator";
import { DateTime } from "luxon";
@Options({})
export default class AreaPlanForm extends Vue {
  store = useStore();
  area: any = {};
  doc: any = {};
  filePath = filePath
  docs: Array<any> = [];
  isExisted: boolean = false;
  copySource: any = {};
  DateTime = DateTime;
  show: number = 2500;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 5;
  addItem() {
    axios
      .post(`${address}/areaincome`, { area: this.area._id })
      .then((response) => {
        this.docs = response.data.docs;
        this.getData();
        
      });
  }
  updateItems(){
    let count = 0
    if(this.docs.length == 0){
        alert("ไม่พบข้อมูลให้ระบบบันทึก กรุณาตรวจสอบ!");
    }
    this.docs.forEach(el=>{
     axios
      .patch(`${address}/areaincome/${el._id}`, { ...el,area: this.area._id })
      .then((response) => {
        count++
        if(count==this.docs.length){
        //   // this.getData();
        alert("บันทึกข้อมูลแล้ว!");
        this.$router.push('/portal/area/plan/list')
        }
      });
    })
  }
  deleteData(id:string){
    let con = confirm("คุณต้องการลบข้อมูลใช่หรือไม่?")
    if(con==true){
    axios.delete(`${address}/areaincome/${id}`).then(response=>{
      this.getData()
    })
    }
  }
  mounted() {
    if (this.$route.params.id != undefined) this.getData();
  }
  getData() {
    axios.get(`${address}/area/${this.$route.params.id}`).then((response) => {
      this.area = response.data;
      let query = {
        search:{
          area:this.area._id
        },
        limit: this.show,
        skip: this.page * this.show,
        // sort: { number: 1 },
      };
      axios.post(`${address}/areaincomes-paginate`,query).then((response) => {
        console.log(response.data)
        this.docs = response.data.docs;
        let TomSelect = require("tom-select")
        this.$nextTick(()=>{
          this.docs.forEach(el=>{
            new TomSelect("#select-year-"+el._id,{
              create: true
            });
            new TomSelect("#select-quarter-"+el._id,{
              create: true
            });
          })
        })
      });
    });
  }
}
