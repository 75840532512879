
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import axios from "axios"
import address from "@/variable";
import contenteditable from "vue-contenteditable";
// import { useToast, POSITION } from "vue-toastification";

@Options({
  components: {
    contenteditable,
  },
})
export default class Form extends Vue {
  isLeft:boolean = true
  isRight:boolean = true
  modified:Array<Krob4Config> = [
    {
      name:"รายได้อยู่ที อจน.แล้ว",
      symbol:"+",
      unit:"บาท",
      indent:2
    },
    {
      name:"ยอดที่ อปท.พึงชำระสำหรับปีงบประมาณ *year*",
      symbol:"",
      unit:"บาท"
    },
  ]
  equal:Array<Krob4Config> = [
    {
      name:"รวมค่าใช้จ่ายทั้งสิ้นของ อจน. และ  *area*  (C)",
      symbol:"",
      unit:"บาท"
    },
    {
      name:"รวมรายได้จากการจัดเก็บค่าบริการ (A)",
      symbol:"-",
      unit:"บาท"
    },
    {
      name:"คงเหลือค่าใช้จ่ายที่ไม่เพียงพอต่อการบริหารจัดการ ( B = C - A )",
      symbol:"=",
      unit:"บาท"
    },
    {
      name:"ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (B/2)",
      symbol:"",
      unit:"บาท"
    },
    {
      name:"รายได้จากการจัดเก็บ + ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (A+B/2)",
      symbol:"",
      unit:"บาท"
    },
    {
      name:"คืนค่าใช้จ่ายที่ อปท. *area* จ่ายแทน. อจน.ในปีงบประมาณ *year*",
      symbol:"-",
      unit:"บาท"
    },
    {
      name:"คงเหลือยอด",
      symbol:"=",
      unit:"บาท"
    },
    {
      name:"ภาษีมูลค่าเพิ่ม (ร้อยละ 7)",
      symbol:"",
      unit:"บาท"
    },
  ]
  conditionallyPay:Array<Krob4Config> = [
    {
      name:"รวมค่าใช้จ่ายทั้งสิ้นของ อจน. + *area* (C)",
      symbol:"",
      unit:"บาท"
    },
    {
      name:"รวมรายได้จากการจัดเก็บค่าบริการ (A)",
      symbol:"-",
      unit:"บาท"
    },
    {
      name:"คงเหลือค่าใช้จ่ายที่ไม่เพียงพอต่อการบริหารจัดการ ( B = C - A )",
      symbol:"=",
      unit:"บาท"
    },
    {
      name:"ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (B/2)",
      symbol:"",
      unit:"บาท"
    },
    {
      name:"รายได้จากการจัดเก็บ + ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (A+B/2)",
      symbol:"",
      unit:"บาท"
    },
    {
      name:"คืนค่าใช้จ่ายที่ อปท. จ่ายแทน. อจน.ในปีงบประมาณ *year*",
      symbol:"-",
      unit:"บาท"
    },
    {
      name:"คงเหลือยอดที่ อปท. พึ่งชำระในปีงบประมาณ *year*",
      symbol:"=",
      unit:"บาท"
    },
    {
      name:"ภาษีมูลค่าเพิ่ม (ร้อยละ 7)",
      symbol:"",
      unit:"บาท"
    },
  ]
  conditionallyEqual:Array<Krob4Config> = [
    {
      name:"รวมค่าใช้จ่ายทั้งสิ้นของ อจน. + *area* (C)",
      symbol:"",
      unit:"บาท"
    },
    {
      name:"รวมรายได้จากการจัดเก็บค่าบริการ (A)",
      symbol:"-",
      unit:"บาท"
    },
    {
      name:"คงเหลือค่าใช้จ่ายที่ไม่เพียงพอต่อการบริหารจัดการ ( B = C - A )",
      symbol:"=",
      unit:"บาท"
    },
    {
      name:"สัดส่วนเท่าเทียม",
      symbol:"",
      unit:"บาท"
    },
    {
      name:"คืนค่าใช้จ่ายที่ อปท. จ่ายแทน. อจน.ในปีงบประมาณ *year*",
      symbol:"",
      unit:"บาท"
    },
    {
      name:"คงเหลือยอดที่ อปท.พึงชำระในปีงบประมาณ *year*",
      symbol:"",
      unit:"บาท"
    },
    {
      name:"ภาษีมูลค่าเพิ่ม (ร้อยละ 7)",
      symbol:"",
      unit:"บาท"
    },
  ]
  profit:Array<Krob4Config> = [    
    {
      name:"รวมค่าใช้จ่ายทั้งสิ้นของ อจน. + *area* (C)",
      symbol:"",
      unit:"บาท"
    },  
    {
      name:"รวมรายได้จากการจัดเก็บค่าบริการ (A)",
      symbol:"-",
      unit:"บาท"
    }, 
    {
      name:"คงเหลือค่าใช้จ่ายที่ไม่เพียงพอต่อการบริหารจัดการ ( B = C - A )",
      symbol:"=",
      unit:"บาท"
    }, 
    {
      name:"ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (B/2)",
      symbol:"",
      unit:"บาท"
    }, 
    {
      name:"รายได้จากการจัดเก็บ + ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (A+B/2)",
      symbol:"",
      unit:"บาท"
    }, 
    {
      name:"คืนค่าใช้จ่ายที่ อปท. จ่ายแทน. อจน.ในปีงบประมาณ *year*",
      symbol:"-",
      unit:"บาท"
    },
    {
      name:"คงเหลือยอดที่ อปท. พึ่งชำระในปีงบประมาณ *year*",
      symbol:"=",
      unit:"บาท"
    },
    {
      name:"ภาษีมูลค่าเพิ่ม (ร้อยละ 7)",
      symbol:"",
      unit:"บาท"
    },
  ]
  percent:Array<Krob4Config> = [
    {
        name:"คิดค่าใช้จ่ายร้อยละ *contributionPercent*",
        symbol:"",
        unit:"บาท"
    },
    {
        name:"คืนค่าใช้จ่ายที่ อปท. จ่ายแทน. อจน.ในปีงบประมาณ *year*",
        symbol:"",
        unit:"บาท"
    },
    {
        name:"ค่าใช้จ่ายสำหรับปีงบประมาณ *year* ไตรมาสที่ *quarter*",
        symbol:"",
        unit:"บาท"
    },
    {
        name:"คิด *contributionPercent*%",
        symbol:"",
        unit:"บาท"
    },
    {
        name:"ทั้งปีคงเหลือ *remainingInput*",
        symbol:"",
        unit:"บาท"
    },
    {
        name:"ยอดที่ อปท. พึงชำระสำหรับปีงบประมาณ *year* ไตรมาสที่ *quarter*",
        symbol:"",
        unit:"บาท"
    },
    {
        name:"ภาษีมูลค่าเพิ่ม (ร้อยละ 7)",
        symbol:"",
        unit:"บาท"
    },
  ]
  fixed:Array<Krob4Config> = [
    {
        name:"รวมค่าใช้จ่ายทั้งสิ้นของ อจน. และ  *area* (C)",
        symbol:"",
        unit:"บาท"
    },
    {
        name:"รวมรายได้จากการจัดเก็บค่าบริการ (A)",
        symbol:"",
        unit:"บาท"
    },
    {
        name:"คงเหลือค่าใช้จ่ายที่ไม่เพียงพอต่อการบริหารจัดการ ( B = C - A )",
        symbol:"",
        unit:"บาท"
    },
    {
        name:"คืนค่าใช้จ่ายที่ อปท. จ่ายแทน. อจน.ในปีงบประมาณ *year*",
        symbol:"",
        unit:"บาท"
    },
    {
        name:"ยอดที่ อปท. พึงชำระสำหรับปีงบประมาณ *year* ไตรมาสที่ *quarter*",
        symbol:"",
        unit:"บาท"
    },
    {
        name:"ภาษีมูลค่าเพิ่ม (ร้อยละ 7)",
        symbol:"",
        unit:"บาท"
    },
  ]
  utilities:Array<Krob4Config> = [
  ]
}
interface Krob4Config {
  name?: String,
  symbol: String,
  unit?: String,
  indent?: Number,
  underline?: Boolean
}
