
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import { useStore } from "vuex";
import { Watch } from "vue-property-decorator";
import { DateTime } from "luxon";
import math, { multiply, divide } from "mathjs";

@Options({})
export default class ReceiptList extends Vue {
  store = useStore();
  docs: Array<any> = [];
  isSigned: boolean = true;
  isActive: boolean = false;
  search: any = {};
  show: number = 999999999;
  total: number = 5;
  pages: number = 0;
  rates: Array<any> = [];
  loading: boolean = false;
  totalCount: number = 0;
  totalQty: number = 0;
  totalAmount: number = 0;
  totalDebt: number = 0;
  totalPayment: number = 0;
  ids: Array<string> = [];

  EditItems(id:string,field:string, value:number|string) {
    console.log('edit',id);
    let prep:any = {}
    prep[field] = value
    axios.patch(`${address}/receipt/${id}`,prep).then((response) => {
      console.log('receipt',response)
      this.updateReport()
    });
  }
  downloadExcel() {
    console.log("download excel...");
    let self = this;
    const Excel = require("exceljs");
    var workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet(`รายงาน`);
    let header = [
      "ลำดับ",
      "วันที่ชำระ",
      "เลขที่ใบเสร็จ",
      "เลขที่ผู้ใช้น้ำ",
      "ชื่อ-นามสกุล",
      "เดือนที่ค้าง",
      "ชำระยอดเงินที่ค้าง (บาท)",
      "ภาษียอดค้าง",
      "ลบ.ม.",
      "ค่าบริการ",
      "ภาษี",
      "ค่าบริการบวกภาษี",
      "รวมภาษีขาย",
      "รวมเงิน",
      "รับจริง",
      "หมายเหตุ",
    ];
    worksheet.getColumn("B").width = 10;
    worksheet.getColumn("C").width = 16;
    worksheet.getColumn("D").width = 12;
    worksheet.getColumn("E").width = 73;
    worksheet.getColumn("F").width = 23;
    worksheet.getColumn("G").width = 20;
    worksheet.getColumn("H").width = 20;
    worksheet.getColumn("I").width = 20;
    worksheet.getColumn("J").width = 20;
    worksheet.getColumn("K").width = 20;
    worksheet.getColumn("L").width = 20;
    worksheet.getColumn("M").width = 20;
    worksheet.getColumn("N").width = 20;
    worksheet.getColumn("O").width = 20;
    worksheet.getColumn("P").width = 80;
    worksheet.addRow(header);
    this.docs.forEach((item, i) => {
      worksheet.addRow([
        i + 1,
        new Date(item.paymentDate).toThaiShort(),
        "wma-" + item.sequence,
        item.meter,
        (item.prefix ?? "") + (item.name ?? "-"),
        item.debtText,
        item.debtAmount.formatFull(),
        item.debtVat,
        item.qty,
        item.totalAmount.formatFull(),
        item.vat,
        (item.totalAmount + item.vat).formatFull(),
        (item.debtVat + item.vat).formatFull(),
        (
          item.totalAmount+item.vat+item.debtAmount+item.debtVat ??
          0
        ).formatFull(),
        (item.paymentAmount ?? 0).formatFull(),
        item.notes,
      ]);
    });

    worksheet.eachRow({ includeEmpty: true }, (row: any, rn: number) => {
      if (rn >= 1) {
        row.eachCell({ includeEmpty: true }, (cell: any, cn: number) => {
          if (rn>=2 && cn >= 7 && cn <= 15) {
            row.getCell(cn).numFmt = "#,##0.00;[Red]-#,##0.00";
            row.getCell(cn).alignment = {
              vertical: "middle",
              horizontal: "right",
            };
          }
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });
      }
    });
    workbook.xlsx.writeBuffer().then(function(data: any) {
      var blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ทะเบียนคุมใบเสร็จรับเงิน/ใบกำกับภาษี.xlsx");
    });
  }
  cOTax(n: number) {
    try {
      return (n * (7 / 100)).toFixed(2);
    } catch (error) {
      console.log(error);
      return 0;
    }
  }
  cITax(n: number) {
    return 0;
  }

  @Watch("search", { deep: true })
  updateReport() {
    if (
      this.search.code != undefined &&
      this.search.cat != undefined &&
      this.search.month != undefined &&
      this.search.year != undefined
    ) {
      console.log("ทำงาน");
      this.fetchData();
    }
  }
  print() {
    window.print();
  }
  sum(item: any): number {
    let sumAmount = 0;
    if (item.calculationType == "บาท/ลบ.ม.") {
      sumAmount = item.qty * item.rate;
    } else {
      sumAmount = item.rate;
    }
    return sumAmount ?? 0;
  }
  mounted() {
    this.search = {
      // cat: "2,น้ำเสีย",
      // code: "01-kb",
      // month: "2",
      // year: 2563,
    };
    this.updateReport();
    this.getRates();
  }
  getRates() {
    axios.get(`${address}/arearates`).then((response) => {
      this.rates = response.data;
    });
  }
  fetchData() {
    console.log(this.search);
    this.loading = true;
    let category = null;
    let categoryType = null;
    let start = DateTime.fromObject({
      year: this.search.year,
      month: this.search.month,
    })
      .startOf("month")
      .toISO();
    let end = DateTime.fromObject({
      year: this.search.year,
      month: this.search.month,
    })
      .endOf("month")
      .toISO();
    console.log(start, end);
    try {
      category = this.search.cat.split(",")[0];
      categoryType = this.search.cat.split(",")[1];
    } catch (error) {}
    let query = {
      search: {
        $and: [
          {
            code:
              this.search.code != undefined
                ? { $eq: this.search.code }
                : undefined,
          },
          this.search.month != -1
            ? {
                paymentDate: {
                  $gte: DateTime.fromObject({
                    year: this.search.year - 543,
                    month: this.search.month,
                  })
                    .plus({ month: 2 })
                    .startOf("month")
                    .toISO(),
                  $lte: DateTime.fromObject({
                    year: this.search.year - 543,
                    month: this.search.month,
                  })
                    .plus({ month: 2 })
                    .endOf("month")
                    .toISO(),
                },
              }
            : {
                paymentDate: {
                  $gte: DateTime.fromObject({
                    year: this.search.year - 543,
                    month: 1,
                  })
                    .startOf("month")
                    .toISO(),
                  $lte: DateTime.fromObject({
                    year: this.search.year - 543,
                    month: 12,
                  })
                    .endOf("month")
                    .toISO(),
                },
              },
          { category: category != undefined ? { $eq: category } : undefined },
          // {
          //   categoryType:categoryType != undefined ? { $eq: categoryType } : undefined,
          // },
        ],
      },
      limit: 999999,
      skip: 0,
      sort: {
        // paymentDate: 1,
        sequence: 1,
      },
    };
    console.log(query);
    axios.post(`${address}/receipts-paginate`, query).then((response) => {
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
      this.loading = false;

      this.totalCount = response.data.totalCount;
      this.totalQty = response.data.totalQty;
      this.totalAmount = response.data.totalAmount;
      this.totalDebt = response.data.totalDebt;
      this.totalPayment = response.data.totalPayment;
      this.ids = response.data.ids;
    });
  }
  toThaiFormat(dt: string) {
    var dtThai = DateTime.fromISO(dt)
      .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("ddLLLyy");
    return dtThai;
  }
  getThaiMonth(n: string) {
    switch (n) {
      case "1":
        return "ม.ค.";
        break;
      case "2":
        return "ก.พ.";
        break;
      case "3":
        return "มี.ค.";
        break;
      case "4":
        return "เม.ย.";
        break;
      case "5":
        return "พ.ค.";
        break;
      case "6":
        return "มิ.ย.";
        break;
      case "7":
        return "ก.ค.";
        break;
      case "8":
        return "ส.ค.";
        break;
      case "9":
        return "ก.ย.";
        break;
      case "10":
        return "ต.ค.";
        break;
      case "11":
        return "พ.ย.";
        break;
      case "12":
        return "ธ.ค.";
        break;

      default:
        return ".";
        break;
    }
  }
}
