import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import CodeForm from '@/views/account/CodeForm.vue'
import CodeList from '@/views/account/CodeList.vue'
import CodeImport from '@/views/account/CodeImport.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: 'code/form',
    name: 'Code Form',
    component: CodeForm,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการรหัสบัญชี",
          to:"/portal/code/list"
        },
        {
          name:"เพิ่มรหัสบัญชี",
          to:"/portal/code/form"
        },
      ]
    }
  },
  {
    path: 'code/import',
    name: 'Code Import',
    component: CodeImport,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการรหัสบัญชี",
          to:"/portal/code/list"
        },
        {
          name:"นำเข้ารายการรหัสบัญชี",
          to:"/portal/code/import"
        },
      ]
    }
  },
  {
    path: 'code/edit/:id',
    name: 'Code Edit',
    component: CodeForm,
    meta:{
      bc:[
        {
          name:"รายการรหัสบัญชี",
          to:"/portal/code/list"
        },
        {
          name:"แก้ไขรหัสบัญชี",
          to:"/portal/code/edit/:id"
        },
      ]
    }
  },
  {
    path: 'code/list',
    name: 'Code List',
    component: CodeList,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการรหัสบัญชี",
          to:"/portal/code/list"
        },
      ]
    }
  },
]

export default routes