
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon";
import { useStore } from "vuex";
import * as _ from "lodash"

@Options({
  components: {},
  props: {},
})
export default class IncomeReport01 extends Vue {
  docs: Array<any> = [];
  store = useStore();
  areas: Array<any> = [];
  limit:number = 0
  print(){
    window.print();
  }

  get allCollection(){
    let array:Array<any> = []
    this.docs.forEach(doc=>{
      array.push(doc.collections)
    })
    return _.flatten(array)
  }
  get allCalculation(){
    let array:Array<any> = []
    this.docs.forEach(doc=>{
      array.push(doc.calculations)
    })
    return _.flatten(array)
  }
  get filtered(){
    return this.docs.map(el=>{ 
      let collections = el.collections.map((c:any)=>{
        // return "22"
        return { ...c, recordYear:(new Date(c.recordDate).getFullYear()??0)+543, jaiTrong: ((new Date(c.recordDate).getFullYear()??0)+543)===c.year}
      })
      return {area:el.area, contract:el.contract ,collections:collections, calculations:el.calculations, jaiTrongCount:collections.map((cl:any)=>cl.jaiTrong?1:0).reduce((a:number,b:number)=>a+b,0) } 
    })
  }
  get yearLong() {
    let dt = DateTime.now()
      .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("yyyy");
    return parseInt(dt);
  }

  getCalc(calculations: Array<any>, q: number, y: number) {
    try {
      let found = calculations.find((el) => el.calendarYear == y)
      return found.contributionAmount;
    } catch (error) {
      return 0;
    }
  }
  getColc(collections: Array<any>, q: number, y: number) {
    try {
      let found = collections.filter((el) => el.year == y)
      let map = found.map(el=>el.amount??0)
      let reduce = map.reduce((a,b)=>a+b,0)
      return reduce;
    } catch (error) {
      return 0;
    }
  }
  getDate(collections: Array<any>, q: number, y: number) {
    try {
      let found = collections.find((el) => el.year == y)
      return new Date(found.recordDate).toThaiLong()
    } catch (error) {
      return "-"
    }
  }
  getData() {
    axios.get(`${address}/areas`).then((response) => {
      this.areas = response.data;
    });
    axios.get(`${address}/report-income-areamonthly`).then((response) => {
      this.docs = response.data;
    });
  }
  mounted() {
    this.getData();
  }
}
