
import address, {filePath} from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { Watch } from "vue-property-decorator";
import { DateTime } from "luxon";
@Options({})
export default class ConditionForm extends Vue {
  store = useStore();
  area: any = {};
  doc: any = {};
  filePath = filePath
  docs: Array<any> = [];
  isExisted: boolean = false;
  copySource: any = {};
  DateTime = DateTime;
  show: number = 2500;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 5;
  checkMonthYear(){
    this.docs.forEach((el,i)=>{
      let record = typeof el.recordDate == "string" ? DateTime.fromISO(el.recordDate) : DateTime.fromJSDate(el.recordDate)
      
      let compare
      let booked
      if(el.month<10){
        booked = DateTime.fromObject({
          day:1,
          month:el.month,
          year:(el.year??0) - 543
        })
      }else{
        booked = DateTime.fromObject({
          day:1,
          month:el.month,
          year:(el.year??0) - 543 - 1
        })
      }
      let duration = booked.diff(record,'day').toObject()
      if(duration.days! > 0){
        alert(`คำเตือน: รายการที่ ${i+1} เดือนและปีที่จะนำไปแสดงในเขียวเหลืองจะมากกว่าวันที่ชำระเงินไม่ได้`)
        this.docs[i].month = record.toObject().month
        this.docs[i].year = record!.toObject()!.year! + 543
      }
      console.log(i, duration)
    })
  }
  addItem() {
    axios
      .post(`${address}/areacollection`, { area: this.area._id })
      .then((response) => {
        this.docs = response.data.docs;
        this.getData();
        // alert("บันทึกข้อมูลเรียบร้อยแล้ว")
      });
  }
  updateItems(){
    let count = 0
    this.docs.forEach(el=>{
     axios
      .patch(`${address}/areacollection/${el._id}`, { ...el,area: this.area._id })
      .then((response) => {
        count++
        if(count==this.docs.length){
          this.getData();
          alert("บันทึกข้อมูลแล้ว!");
        }
        // this.$router.push('/portal/collection/list')
      });
    })
  }
  deleteData(id:string){
    let con = confirm("คุณต้องการลบข้อมูลใช่หรือไม่?")
    if(con==true){
      axios.delete(`${address}/areacollection/${id}`).then(response=>{
        this.getData()
      })
    }
  }
  deleteUrl(item:any,k:number){
    let con = confirm("คุณต้องการลบข้อมูลใช่หรือไม่?")
    if(con==true){
      item.fileUrl.splice(k,1)
    }
  }
  mounted() {
    if (this.$route.params.id != undefined) this.getData();
  }
  getData() {
    axios.get(`${address}/area/${this.$route.params.id}`).then((response) => {
      this.area = response.data;
      let query = {
        search:{
          area:this.area._id
        },
        limit: this.show,
        skip: this.page * this.show,
        // sort: { number: 1 },
      };
      axios.post(`${address}/areacollections-paginate`,query).then((response) => {
        console.log(response.data)
        this.docs = response.data.docs;
        this.checkMonthYear()
      });
    });
  }
}
