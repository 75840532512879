
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { Watch } from "vue-property-decorator";
import { DateTime, Interval, Duration } from "luxon";
@Options({})
export default class ConditionForm extends Vue {
  store = useStore();
  area: any = {};
  condition: any = {};
  docs: Array<any> = []
  summation:Array<number> = [0,0,0,0,0]
  showDescription:boolean = true
  getAverage(arr: Array<number>): number {
    return arr.reduce((a, b) => a + b, 0) / arr.length;
  }
  dateOffset1(str:string){
    return DateTime.fromISO(str).minus({days:1}).reconfigure({ outputCalendar: "buddhist" }).setLocale("th").toFormat("d LLL yy")
  }
  dateOffset2(str:string){
    return DateTime.fromISO(str).minus({days:2}).reconfigure({ outputCalendar: "buddhist" }).setLocale("th").toFormat("d LLL yy")
  }
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
  }
  fetchData() {
    axios.get(`${address}/report-income-fixed-collection/${this.$route.params.id}`).then((response) => {
      this.docs = response.data.result;
      this.summation = response.data.sum
      this.area = response.data.area
      this.condition = response.data.areaCondition
    });
  }
}
