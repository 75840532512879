
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { Watch } from "vue-property-decorator";
@Options({})
export default class ReceiptForm extends Vue {
  store = useStore();
  doc: any = {};
  areas:Array<any> = []
  mounted() {
    if (this.$route.params.id != undefined) this.fetchData();
    this.fetchAreas()
  }
  submit() {
    if (this.$route.params.id != undefined) this.patchData();
    else this.postData();
  }
  postData() {
    axios
      .post(`${address}/arearation`, this.doc)
      .then((response) => {
        console.log(response);
        alert("บันทึกข้อมูลแล้ว!");
        this.$router.push("/portal/collection/arearation/list");
      });
  }
  patchData() {
    axios
      .patch(`${address}/arearation/${this.$route.params.id}`, this.doc)
      .then((response) => {
        console.log(response);
        alert("แก้ไขข้อมูลแล้ว!");
        this.$router.push("/portal/collection/arearation/list");
      });
  }
  fetchAreas() {
    axios.get(`${address}/areas`).then((response) => {
      this.areas = response.data;
    });
  }
  fetchData() {
    axios.get(`${address}/arearation/${this.$route.params.id}`).then((response) => {
      this.doc = response.data;
    });
  }
}
