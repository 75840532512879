
import address,{filePath} from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";


@Options({
  components: {
  },
  props: {
  },
})
export default class BDocumentList extends Vue {
  store = useStore();
  filePath=filePath
  mounted(){
  }
}
