
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon";
import { useStore } from "vuex";

@Options({
  components: {},
  props: {},
})
export default class IncomeReport05 extends Vue {
  docs: Array<any> = [];
  store = useStore();
  contracts: Array<any> = [];
  estimates: Array<any> = [];
  pVal(area: string, month: number, year: number) {
    try {
      let map = this.estimates
        .filter(
          (el) =>
            el.accountName == area &&
            el.estimateType == "แผน" &&
            el.month == month &&
            el.year == year
        )
        .map((el) => el.amount) || [0];
      return map.reduce((a: number, b: number) => a + b).formatFull();
    } catch (error) {
      return (0).formatFull();
    }
  }
  rVal(area: string, month: number, year: number) {
    try {
      let map = this.estimates
        .filter(
          (el) =>
            el.accountName == area &&
            el.estimateType == "ผล" &&
            el.month == month &&
            el.year == year
        )
        .map((el) => el.amount) || [0];
      return map.reduce((a: number, b: number) => a + b).formatFull();
    } catch (error) {
      return (0).formatFull();
    }
  }
  pSum(area: string, month: number, year: number) {
    return Math.round((Math.random() * 10000000) / 100).formatFull();
  }
  rSum(area: string, month: number, year: number) {
    return Math.round((Math.random() * 10000000) / 100).formatFull(); //`${month}/${year}`
  }
  getData() {
    axios.get(`${address}/contracts`).then((response) => {
      this.contracts = response.data;
    });
    axios.get(`${address}/estimates`).then((response) => {
      this.estimates = response.data;
    });
  }
  mounted() {
    this.getData();
  }

  getTHB(n:number){
    function getRandomArbitrary(min:number, max:number) {
    return Math.random() * (max - min) + min;
}
    return getRandomArbitrary(100000,6000000)
  }
}
