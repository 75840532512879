import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Dashboard from '@/views/income/Dashboard.vue'
import SheetPrint from '@/views/income/SheetPrint.vue'
import AccountImport from '@/views/income/AccountImport.vue'
import Estimation from "@/views/estimate/Estimation.vue"
import EstimationList from "@/views/income/EstimationList.vue"
import EstimationForm from "@/views/income/EstimationForm.vue"
import EstimationPDF from "@/views/income/EstimationPDF.vue"
import CalculationList from "@/views/income/CalculationList.vue"
import CalculationForm from "@/views/income/CalculationForm.vue"
import CalculationPDF from "@/views/income/CalculationPDF.vue"
import SummaryList from "@/views/income/SummaryList.vue"
import SummaryForm from "@/views/income/SummaryForm.vue"
import SummaryFormCustom from "@/views/income/SummaryFormCustom.vue"
import SummaryFormOriginal from "@/views/income/SummaryFormOriginal.vue"
import SummaryPDF from "@/views/income/SummaryPDF.vue"
import SummaryPDFOriginal from "@/views/income/SummaryPDFOriginal.vue"

import CoverLetterList from '@/views/income/CoverLetterList.vue'
import CoverLetterForm from "@/views/income/CoverLetterForm.vue"
import CoverLetterPDF from "@/views/income/CoverLetterPDF.vue"
import EndorsedImport from "@/views/income/EndorsedImport.vue"

import ConditionList from "@/views/income/ConditionList.vue"
import ConditionForm from "@/views/income/ConditionForm.vue"
import ConditionDetail from "@/views/income/ConditionDetail.vue"
import CollectionList from "@/views/income/CollectionList.vue"
import CollectionForm from "@/views/income/CollectionForm.vue"
import CollectionDetail from "@/views/income/CollectionDetail.vue"

import InDocumentList from '@/views/income/InDocumentList.vue'

import RationList from "@/views/income/RationList.vue"
import RationForm from "@/views/income/RationForm.vue"

import AreaPlanForm from "@/views/income/AreaPlanForm.vue"
import AreaPlanList from "@/views/income/AreaPlanList.vue"


const routes: Array<RouteRecordRaw> = [
  //กรอบ2
  {
    path: 'estimation/list',
    name: 'EstimationList',
    component: EstimationList,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการประมาณการค่าใช้จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย",
          to:"/estimation/list"
        },
      ]
    }
  }, 
  {
    path: 'sao/import',
    name: 'EndorsedImport',
    component: EndorsedImport,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการประมาณการค่าใช้จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย",
          to:"/estimation/list"
        },
        {
          name:"เพิ่มข้อมูล สตง.",
          to:"/portal/sao/import"
        },
      ]
    }
  },  
  {
    path: 'estimation/form/:area/:year/:operation/:quarter',
    name: 'EstimationForm',
    component: EstimationForm,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการประมาณการค่าใช้จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย",
          to:"/portal/estimation/list"
        },
        {
          name:"จัดทำกรอบ 2",
          to:"/portal/estimation/form"
        },
      ]
    }
  },  
  {
    path: 'estimation/pdf/:area/:year/:operation/:quarter',
    name: 'EstimationPDF',
    component: EstimationPDF,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการประมาณการค่าใช้จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย",
          to:"/portal/estimation/list"
        },
        {
          name:"PDF กรอบ 2",
          to:"/portal/estimation/pdf"
        },
      ]
    }
  }, 
  {
    path: 'estimation/pdf-id/:id',
    name: 'EstimationPDF by ID',
    component: EstimationPDF,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการประมาณการค่าใช้จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย",
          to:"/portal/estimation/list"
        },
        {
          name:"PDF กรอบ 2",
          to:"/portal/estimation/pdf"
        },
      ]
    }
  },
  
//กรอบ3
  {
    path: 'calculation/list',
    name: 'CalculationList',
    component: CalculationList,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการประมาณการสรุปรายรับ - จ่าย",
          to:"/portal/calculation/list"
        },
      ]
    }
  },  
  {
    path: 'calculation/form/:area/:year/:operation/:quarter',
    name: 'CalculationForm',
    component: CalculationForm,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการประมาณการสรุปรายรับ - จ่าย",
          to:"/portal/calculation/list"
        },
        {
          name:"จัดทำกรอบ 3",
          to:"/portal/calculation/form"
        },
      ]
    }
  },
  {
    path: 'calculation/pdf/:area/:year/:operation/:quarter',
    name: 'CalculationPDF',
    component: CalculationPDF,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการประมาณการสรุปรายรับ - จ่าย",
          to:"/portal/calculation/list"
        },
        {
          name:"PDF กรอบ 3",
          to:"/portal/calculation/pdf"
        },
      ]
    }
  },  

//กรอบ4
  {
    path: 'summary/list',
    name: 'SummaryList',
    component: SummaryList,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการคำนวณยอดเงินสมทบ",
          to:"/summary/list"
        },
      ]
    }
  },  
  {
    path: 'summary/form/:area/:year/:operation/:quarter',
    name: 'SummaryForm Active',
    component: SummaryForm,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการคำนวณยอดเงินสมทบ",
          to:"/portal/summary/list"
        },
        {
          name:"จัดทำกรอบ 4",
          to:"/portal/summary/form"
        },
      ]
    }
  },  
  {
    path: 'summary/form/:area/:year/:operation/:quarter',
    name: 'SummaryFormCustom',
    component: SummaryFormCustom,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการคำนวณยอดเงินสมทบ",
          to:"/portal/summary/list"
        },
        {
          name:"จัดทำกรอบ 4",
          to:"/portal/summary/form"
        },
      ]
    }
  },  
  {
    path: 'summary/form/original/:area/:year/:operation/:quarter',
    name: 'SummaryFormOriginal',
    component: SummaryFormOriginal,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการคำนวณยอดเงินสมทบ",
          to:"/portal/summary/list"
        },
        {
          name:"จัดทำกรอบ 4",
          to:"/portal/summary/form"
        },
      ]
    }
  },  
  {
    path: 'summary/pdf/:area/:year/:operation/:quarter',
    name: 'SummaryPDF',
    component: SummaryPDF,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการคำนวณยอดเงินสมทบ",
          to:"/portal/summary/list"
        },
        {
          name:"PDF กรอบ 4",
          to:"/portal/summary/pdf"
        },
      ]
    }
  },
  {
    path: 'summary/pdf/original/:area/:year/:operation/:quarter',
    name: 'SummaryPDFOriginal',
    component: SummaryPDFOriginal,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการคำนวณยอดเงินสมทบ",
          to:"/portal/summary/list"
        },
        {
          name:"PDF กรอบ 4",
          to:"/portal/summary/pdf"
        },
      ]
    }
  },


//ใบนำส่ง
  {
    path: 'cover/letter/list',
    name: 'CoverLetterList',
    component: CoverLetterList,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการใบนำส่ง/Invoice",
          to:"/cover/letter/list"
        },
      ]
    }
  },
  {
    path: 'combine/form',
    name: 'CoverLetterForm',
    component: CoverLetterForm,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการใบนำส่ง/Invoice",
          to:"/portal/cover/letter/list"
        },
        {
          name:"จัดทำใบนำส่ง/Invoice",
          to:"/portal/combine/form"
        },
      ]
    }
  },  
  {
    path: 'combine/edit/:id',
    name: 'CombineEdit',
    component: CoverLetterForm,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการใบนำส่ง/Invoice",
          to:"/portal/cover/letter/list"
        },
        {
          name:"แก้ไขใบนำส่ง/Invoice",
          to:"/portal/combine/edit/id"
        },
      ]
    }
  },  
  {
    path: 'combine/pdf/:id',
    name: 'CoverLetter PDF',
    component: CoverLetterPDF,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"รายการใบนำส่ง/Invoice",
          to:"/portal/cover/letter/list"
        },
        {
          name:"จัดทำใบนำส่ง/Invoice",
          to:"/portal/combine/pdf/id"
        },
      ]
    }
  },
  // เงื่อนไขสัญญา
  {
    path: 'condition/list',
    name: 'ConditionList',
    component: ConditionList,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"เงื่อนไขสัญญา อปท.",
          to:"/portal/condition/list"
        },
      ]
    }
  },
  {
    path: 'condition/list',
    name: 'ConditionList',
    component: ConditionList,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"เงื่อนไขสัญญา อปท.",
          to:"/portal/condition/list"
        },
      ]
    }
  },
  {
    path: 'condition/edit/:id',
    name: 'ConditionEdit',
    component: ConditionForm,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"เงื่อนไขสัญญา อปท.",
          to:"/portal/condition/list"
        },
        {
          name:"แก้ไขเงื่อนไขตามสัญญา",
          to:"/portal/condition/edit/:id"
        },
      ]
    }
  },
  {
    path: 'condition/detail/:id',
    name: 'ConditionDetail',
    component: ConditionDetail,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"เงื่อนไขสัญญา อปท.",
          to:"/portal/condition/list"
        },
        {
          name:"รายละเอียดค่าใช้จ่าย",
          to:"/portal/condition/detail"
        },
      ]
    }
  },
  // บันทึกการรับเงิน
  {
    path: 'collection/list',
    name: 'CollectionList',
    component: CollectionList,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"บันทึกการรับเงิน",
          to:"/portal/collection/list"
        },
      ]
    }
  },
  {
    path: 'collection/form/:id',
    name: 'CollectionForm',
    component: CollectionForm,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"บันทึกการรับเงิน",
          to:"/portal/collection/list"
        },
        {
          name:"แก้ไขการรับเงิน",
          to:"/portal/collection/form"
        },
      ]
    }
  },


  {
    path: 'collection/arearation/list',
    name: 'RationList',
    component: RationList,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"บันทึกการต้นทุนปันส่วนน้ำ",
          to:"/portal/collection/arearation/list"
        },
      ]
    }
  },
  {
    path: 'collection/arearation/edit/:id',
    name: 'RationEdit',
    component: RationForm,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"บันทึกการต้นทุนปันส่วนน้ำ",
          to:"/portal/collection/arearation/list"
        },
        {
          name:"แก้ไขการต้นทุนปันส่วนน้ำ",
          to:"/portal/collection/arearation/form"
        },
      ]
    }
  },
  {
    path: 'collection/arearation/form',
    name: 'RationForm',
    component: RationForm,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"บันทึกการต้นทุนปันส่วนน้ำ",
          to:"/portal/collection/arearation/list"
        },
        {
          name:"แบบข้อมูลต้นทุนปันส่วนน้ำ",
          to:"/portal/collection/arearation/form"
        },
      ]
    }
  },

  // แผนจัดเก็บรายได้
  {
    path: 'area/plan/list',
    name: 'AreaPlanList',
    component: AreaPlanList,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"แผนการจัดเก็บ",
          to:"/portal/area/plan/list"
        },
      ]
    }
  },
  {
    path: 'area/plan/form/:id',
    name: 'AreaPlanForm',
    component: AreaPlanForm,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"แผนการจัดเก็บ",
          to:"/portal/area/plan/list"
        },
        {
          name:"แก้ไขแผนการจัดเก็บ",
          to:"/portal/area/plan/form"
        },
      ]
    }
  },
//อัปโหลดเอกสาร
{
  path: 'indocument/list',
  name: 'InDocumentList',
  component: InDocumentList,
  meta:{
    bc:[
      {
        name:"ระบบจัดเก็บรายได้",
        to:"/portal/landing/income"
      },
      {
        name:"รายการเอกสาร",
        to:"/portal/indocument/list"
      },
    ]
  }
},
  // {
  //   path: 'collection/arearation/form',
  //   name: 'RationForm',
  //   component: RationForm,
  //   meta:{
  //     bc:[
  //       {
  //         name:"บันทึกการต้นทุนปันส่วนน้ำ",
  //         to:"/portal/collection/arearation/list"
  //       },
  //       {
  //         name:"แบบข้อมูลต้นทุนปันส่วนน้ำ",
  //         to:"/portal/collection/arearation/form"
  //       },
  //     ]
  //   }
  // },
]

export default routes