
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon"

@Options({
  components: {},
  props: {},
})
export default class IncomeReport02 extends Vue {
  docs: Array<any> = [];
  today: Date = new Date();
  get todayFormat(){
    return this.today.toThaiShort()
  }
  getMB(n:number){
    return (Math.random()*100).formatFull()
  }
}
