
import { Options, Vue } from "vue-class-component";
import Navigation from "@/components/Navigation.vue";
import { useStore } from "vuex";
@Options({
  components: {
    Navigation,
  },
  props: {},
})
export default class Landing extends Vue {
  store = useStore();
  mounted() {
  }
}
