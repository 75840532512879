
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon";
import { useStore } from "vuex";
import Excel from "exceljs/dist/es5/exceljs.browser";

@Options({
  components: {},
  props: {},
})
export default class IncomeReport01 extends Vue {
  docs: Array<any> = [];
  conditions:Array<any> = []
  costCodes:Array<any> = []
  store = useStore();
  areas: Array<any> = [];
  preps:Array<any> = []
  year:number =  new Date().getFullYear()+543;
  importStatus:string = "กำลังอ่านข้อมูล..."
  loading: Boolean = false
  print(){
    window.print();
  }
  submitData(){
    this.preps.forEach(prep=>{
      console.log(prep)
    })
  }
  updateStatus(str:string,ctx:any){
    this.importStatus = str
    console.log(str)
    this.$forceUpdate()
  }
  importExcel(ev:any){
    this.loading = true
    let self = this
    console.log("importing...")
    this.updateStatus("กำลังอ่านไฟล์ Excel...",self)
    let file = ev.target.files[0]
    const wb = new Excel.Workbook()
    wb.xlsx.load(file).then((book:any)=>{
      let arr:Array<any> = []
      book.eachSheet(function(worksheet:any, sheetId:any) {
        self.updateStatus("กำลังอ่านชีท " + worksheet.name, self)
        let found = self.conditions.findIndex(c=>{
          return c.code == worksheet.name
        })
        if(found!=-1){
          let months = [0,0,0,0,0,0,0,0,0,0,0,0]
          worksheet.eachRow(function(row: any, rowNumber: any) {
            if (row.getCell(1).value != null&&rowNumber>1) {
              months[0] += parseInt(row.getCell(5).value)||0
              months[1] += parseInt(row.getCell(6).value)||0
              months[2] += parseInt(row.getCell(7).value)||0
              months[3] += parseInt(row.getCell(8).value)||0
              months[4] += parseInt(row.getCell(9).value)||0
              months[5] += parseInt(row.getCell(10).value)||0
              months[6] += parseInt(row.getCell(11).value)||0
              months[7] += parseInt(row.getCell(12).value)||0
              months[8] += parseInt(row.getCell(13).value)||0
              months[9] += parseInt(row.getCell(14).value)||0
              months[10] += parseInt(row.getCell(15).value)||0
              months[11] += parseInt(row.getCell(16).value)||0
              months[12] += parseInt(row.getCell(18).value)||0
            }
          })
          self.conditions[found] = {
            ...self.conditions[found],
            months
          }
          var cond = self.conditions[found]
            let prep:any = {
              area: cond.area,
              areaCondition: cond.areaCondition,
              operationYear: cond.operationYear,
              calendarYear: self.year,
            }
            if(cond.period.search("ปี")!=-1){
              prep.quarter = 0 
              prep.endorsedLedgers = self.importRight(worksheet,0)
              self.preps.push(prep)
            } else {
              for (let i = 0; i < 4; i++) {
                prep.quarter = i 
                prep.endorsedLedgers = self.importRight(worksheet,i)
                self.preps.push(prep)
              }
            }
        }
      })
      this.loading = false
    })
    
  }
  importRight(sheet: any, quarter:number) {
    let self = this;
    let arr: Array<any> = [];
    sheet.eachRow(function(row: any, rowNumber: any) {
      if (row.getCell(1).value != null&&rowNumber>1) {
        arr.push({
          selected: true,
          code: (row.getCell(1).value ?? "").replace(".", ""),
          name: row.getCell(2).value,
          months: self.getAccountByQuarter(row,quarter),
          totalAmount: self.getAccountByQuarter(row,quarter).map(el=>el.amount).reduce((a,b)=>a+b,0),
          category: self.getCategory((row.getCell(1).value ?? "").replace(".", ""))
        });
      }
    })
    return arr
  }


  getCalc(calculations: Array<any>, q: number, y: number) {
    try {
      let found = calculations.find((el) => el.quarter == q)
      return found.contributionAmount;
    } catch (error) {
      return 0;
    }
  }
  getData() {
    axios.get(`${address}/areaconditions`).then((response) => {
      this.areas = response.data;
    });
    axios.post(`${address}/render-areacondition-year-list`, {year:this.year}).then((response) => {
      console.log(response);
      this.conditions = response.data
    });
  }
  mounted() {
    this.getData();
    this.getCostCode();
  }

  getCostCode() {
    axios.get(`${address}/costcodes`).then((response) => {
      this.costCodes = response.data;
    });
  }
  getCategory(acct: string) {
    let found = this.costCodes.find((el) => el.code == acct);
    if (found != undefined) return found.category;
    else return 4;
  }
  getAccountByQuarter(row:any,quarter:number){
    let result = []
    if(quarter == 0 || quarter == 1) result.push({month:10,amount:row.getCell(5).value ?? 0})
    if(quarter == 0 || quarter == 1) result.push({month:11,amount:row.getCell(6).value ?? 0})
    if(quarter == 0 || quarter == 1) result.push({month:12,amount:row.getCell(7).value ?? 0})
    if(quarter == 0 || quarter == 2) result.push({month:1, amount:row.getCell(8).value ?? 0})
    if(quarter == 0 || quarter == 2) result.push({month:2, amount:row.getCell(9).value ?? 0})
    if(quarter == 0 || quarter == 2) result.push({month:3, amount:row.getCell(10).value ?? 0})
    if(quarter == 0 || quarter == 3) result.push({month:4, amount:row.getCell(11).value ?? 0})
    if(quarter == 0 || quarter == 3) result.push({month:5, amount:row.getCell(12).value ?? 0})
    if(quarter == 0 || quarter == 3) result.push({month:6, amount:row.getCell(13).value ?? 0})
    if(quarter == 0 || quarter == 4) result.push({month:7, amount:row.getCell(14).value ?? 0})
    if(quarter == 0 || quarter == 4) result.push({month:8, amount:row.getCell(15).value ?? 0})
    if(quarter == 0 || quarter == 4) result.push({month:9, amount:row.getCell(16).value ?? 0})
    return result
  }
}
