import { createStore, Store } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls";

var ls = new SecureLS({ isCompression: false });

export default createStore({
  state: {
    packageVersion: process.env.VUE_APP_VERSION,
    userAccountName: "",
    userId: "",
    token:"",
    managerId: "",
    userList:[],
    loggedIn: false,
    access:[],
    accessLevel:-1,
    myVariable: "klsjjlsdjk",
    userOnline: 0,
    invoiceList: [],
    invoices: [],
    receiptList: [],
    sectorType:[
      "กองกลาง",
      "กองกฏหมาย",
      "กองธรรมาภิบาลและโครงการพิเศษ",
      "กองประชาสัมพันธ์",
      "กองพัสดุและบริหาร",
      "กองทรัพยากรบุคคล",
      "กองบัญชี",
      "กองการเงิน",
      "กองงบประมาณ",
      "กองนโยบาลและแผน",
      "กองบริหารความเสี่ยงและควบคุมภายใน",
      "กองสารสนเทศและประเมินผล",
      "กองมาตรฐานวิศวกรรม",
      "กองพัฒนาและบริหารโครงการ",
      "กองประสานงานกลาง 1",
      "สำนักงานจัดการนำเสียสาขา 1",
      "กองประสานงานกลาง 2",
      "สำนักงานจัดการนำเสียสาขา 2",
      "กองการจัดเก็บรายได้",
      "กองวิเคราะห์และพัฒนาระบบจัดเก็บรายได้"
    ],
    conditionPercent:{
      name: "สมทบตามจำนวนเปอร์เซนต์",
      children:[
        "คิดค่าใช้จ่ายร้อยละ *contributionPercent*",
        "คืนค่าใช้จ่ายที่ อปท. จ่ายแทน. อจน.ในปีงบประมาณ *year*",
        "ค่าใช้จ่ายสำหรับปีงบประมาณ *year* ไตรมาสที่ *quarter*",
        "คิด *contributionPercent*%",
        "ทั้งปีคงเหลือ *remainingInput*",
        "ยอดที่ อปท. พึงชำระสำหรับปีงบประมาณ *year* ไตรมาสที่ *quarter*" ,
        "ภาษีมูลค่าเพิ่ม (ร้อยละ 7)"
      ],
      symbols:[
        '','','','','','','','','',
      ]
    },
    conditionFixed:
      {
        name: "สมทบตามจำนวนคงที่",
        children: [
          "รวมค่าใช้จ่ายทั้งสิ้นของ อจน. และ  *area* (C)",
          "รวมรายได้จากการจัดเก็บค่าบริการ (A)",
          "คงเหลือค่าใช้จ่ายที่ไม่เพียงพอต่อการบริหารจัดการ ( B = C - A )",
          "คืนค่าใช้จ่ายที่ อปท. จ่ายแทน. อจน.ในปีงบประมาณ *year*",
          "ยอดที่ อปท. พึงชำระสำหรับปีงบประมาณ *year* ไตรมาสที่ *quarter*" ,
          "ภาษีมูลค่าเพิ่ม (ร้อยละ 7)"
        ],
        symbols:[
          '','','','','','','','',
        ]
      },
      conditionProfitType1:
      {
        name: "แบบกำไร-กันไว้",
        children: [
          "รวมรายได้จากการจัดเก็บค่าบริการ (A)",
          "รวมค่าใช้จ่ายทั้งสิ้นของ อจน. + *area* (C)",
          "คงเหลือกำไรหลังหักค่าใช้จ่าย (B = A - C)",
          "หัก กำไรสุทธิจากการดำเนินงานร้อยละ.  (D = B/100*30)",
          "คงเหลือกำไร",
          "คงเหลือกำไรแบ่งในสัดส่วนที่เท่าเทียมกัน (E = (B - D)/2)",
          "รายได้จากการจัดเก็บ - กำไรแบ่งในสัดส่วนที่เท่าเทียมกัน (F = A - E)",
          "คืนค่าใช้จ่ายที่ อปท.จ่ายแทน อจน.ในปีงบประมาณ *year* ไตรมาสที่ *quarter*",
          "คงเหลือที่ อปท. พึงชำระสำหรับปีงบประมาณ *year* ไตรมาสที่ *quarter*",
          "ภาษีมูลค่าเพิ่ม (ร้อยละ 7)"
        ],
        symbols:[
          '','-','=','','','','','-','=','',
        ]
      },
      conditionProfitType2:
      {
        name: "แบบกำไร-สัดส่วนเท่าเทียม",
        children: [
          "รวมค่าใช้จ่ายทั้งสิ้นของ อจน. และ  *area* (C)",
          "รวมรายได้จากการจัดเก็บค่าบริการ (A)",
          "คงเหลือค่าใช้จ่ายที่ไม่เพียงพอต่อการบริหารจัดการ ( B = C - A )",
          "ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (B/2)",
          "รายได้จากการจัดเก็บ + ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (A+B/2)",
          "คืนค่าใช้จ่ายที่ อปท. จ่ายแทน. อจน.ในปีงบประมาณ *year*",
          "คงเหลือยอดที่ อปท. พึ่งชำระในปีงบประมาณ *year*",
          "ภาษีมูลค่าเพิ่ม (ร้อยละ 7)"
        ],
        symbols:[
          '','-','=','','','-','=',''
        ]
      },
      conditionLossType1:
      {
        name: "ขาดทุน-ตามเงื่อนไข ที่เหลืออจน.จ่าย",
        children: [
          "รวมค่าใช้จ่ายทั้งสิ้นของ อจน. + *area* (C)",
          "รวมรายได้จากการจัดเก็บค่าบริการ (A)",
          "คงเหลือค่าใช้จ่ายที่ไม่เพียงพอต่อการบริหารจัดการ ( B = C - A )",
          "ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (B/2)",
          "รายได้จากการจัดเก็บ + ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (A+B/2)",
          "คืนค่าใช้จ่ายที่ อปท. จ่ายแทน. อจน.ในปีงบประมาณ *year*",
          "คงเหลือยอดที่ อปท. พึ่งชำระในปีงบประมาณ *year* ",
          "ภาษีมูลค่าเพิ่ม (ร้อยละ 7)"
        ],
        symbols:[
          '','-','=','','','-','=',''
        ]
      },
      conditionLossType2:
      {
        name: "ขาดทุน-ตามเงื่อนไข สัดส่วนเท่าเทียม",
        children: [
          "รวมค่าใช้จ่ายทั้งสิ้นของ อจน. และ  *area*  (C)",
          "รวมรายได้จากการจัดเก็บค่าบริการ (A)",
          "คงเหลือค่าใช้จ่ายที่ไม่เพียงพอต่อการบริหารจัดการ ( B = C - A )",
          "สัดส่วนเท่าเทียม",
          "เงื่อนไขสมทบค่าใช้จ่าย/ในสัดส่วนเท่าเทียมกันไม่เกิน *contributionLimit* บาท จึงปรับเป็น",
          "คืนค่าใช้จ่ายที่ อปท. จ่ายแทน. อจน.ในปีงบประมาณ *year*",
          "คงเหลือยอดที่ อปท.พึงชำระในปีงบประมาณ *year*",
          "ภาษีมูลค่าเพิ่ม (ร้อยละ 7)"
        ],
        symbols:[
          '','-','=','','','',
        ]
      },
      conditionLossType3:
      {
        name: "ขาดทุน-สัดส่วนเท่าเทียม",
        children: [
          "รวมค่าใช้จ่ายทั้งสิ้นของ อจน. และ  *area*  (C)",
          "รวมรายได้จากการจัดเก็บค่าบริการ (A)",
          "คงเหลือค่าใช้จ่ายที่ไม่เพียงพอต่อการบริหารจัดการ ( B = C - A )",
          "ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (B/2)",
          "รายได้จากการจัดเก็บ + ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (A+B/2)",
          "คืนค่าใช้จ่ายที่ อปท. *area* จ่ายแทน. อจน.ในปีงบประมาณ *year*",
          // "คงเหลือยอดที่ อปท. *area* พึ่งชำระในปีงบประมาณ *year*"
          "คงเหลือยอด",
          "ภาษีมูลค่าเพิ่ม (ร้อยละ 7)"
        ],
        symbols:[
          '','-','=','','','-','=',''
        ]
      },
    PDFFonts: {
      Roboto: {
        normal: " https://inco.wma.go.th/api/v1/static/sarabun_regular.ttf",
        bold: " https://inco.wma.go.th/api/v1/static/sarabun_bold.ttf",
        italics: " https://inco.wma.go.th/api/v1/static/sarabun_italic.ttf",
        bolditalics:
          " https://inco.wma.go.th/api/v1/static/sarabun_bolditalic.ttf",
      },
    },
    PDFStyles: {
      pagetitle: {
        fontSize: 26,
        bold: true,
      },
      header: {
        fontSize: 16,
        bold: true,
      },
      title: {
        fontSize: 16,
        bold: true,
      },
      subtitle: {
        fontSize: 12,
        color: "#555555",
      },
      body: {
        fontSize: 12,
      },
      bold: {
        bold: true,
      },
      boldunderline: {
        decoration: "underline",
        bold: true,
        decorationStyle: "solid",
        decorationColor: "black",
      },
      underline: {
        decoration: "underline",
        decorationStyle: "dotted",
        decorationColor: "black",
      },
      underlineblank: {
        decoration: "underline",
        decorationStyle: "dotted",
        decorationColor: "black",
        color: "#FFFFFF",
      },
      blank: {
        decoration: "underline",
        decorationStyle: "dotted",
        decorationColor: "#FFFFFF",
        color: "#FFFFFF",
      },
      signature: {
        fontSize: 20,
        bold: true,
        decoration: "overline",
        decorationStyle: "solid",
        decorationColor: "black",
      },
    },
    pdfSquare: {
      canvas: [
        {
          type: "polyline",
          lineWidth: 1,
          closePath: true,
          points: [
            { x: 0, y: 0 },
            { x: 0, y: 10 },
            { x: 10, y: 10 },
            { x: 10, y: 0 },
          ],
        },
      ],
    },
    pdfCircle: {
      canvas: [
        {
          type: "ellipse",
          x: 0,
          y: 0,
          r1: 8,
          r2: 8,
          lineWidth: 1,
          closePath: true,
        },
      ],
    },
    base64Logo: require("./logo").default,
    base64Water: require("./water").default

  },
  getters: {
    version: (state) => {
      return state.packageVersion
    },
    getPDFFonts: (state) => {
      return state.PDFFonts;
    },
    getPDFStyles: (state) => {
      return state.PDFStyles;
    },
    getPDFSquare: (state) => {
      return state.pdfSquare;
    },
    getPDFCircle: (state) => {
      return state.pdfCircle;
    },
    base64Logo: (state) => {
      return state.base64Logo;
    },
    base64Water: (state) => {
      return state.base64Water;
    },
    isAdmin: (state) => {
      let isAdmin = false;
      if(state.accessLevel == 100 || state.accessLevel == 10){
        isAdmin = true;
      } else {
        isAdmin = false;
      }
      return isAdmin
    }
  },
  mutations: {
    setInvoiceList: (state, value) => {
      state.invoiceList = value
      state.invoices = value
    },
    setReceiptList: (state, value) => {
      state.receiptList = value
    },
    setUser(state, user) {
      console.log("ผู้ใช้ลงชื่อเข้าใช้และบันทึกบน store แล้ว")
      console.log(user)
      state.userAccountName = user.userAccountName;
      state.loggedIn = true;
      state.accessLevel = user.accessLevel
      state.access = user.access
      state.userId = user.userId
      state.token = user.token
      console.log(state)
    },
    logOut(state) {
      state.userAccountName = "";
      state.loggedIn = false;
      localStorage.clear()
      window.localStorage.clear()
    },
    setUserOnline(state, count) {
      state.userOnline = count
    },
    updateUserList(state,list){
      state.userList = list
    }
  },
  actions: {

  },
  modules: {
  },
  plugins: [createPersistedState(
    {
      key:"JMJMWMA201WF120210625",
      paths: ["loggedIn", "userId", "userAccountName", "invoiceList", "invoices", "receiptList", "access", "token"],
      // reducer (val) {
      //   if(val.loggedIn === null) { // val.user.token (your user token for example)
      //     return {}
      //   }
      //   return val
      // },
      overwrite:false,
      // storage: {
      //   getItem: (key:any) => {
      //     try {
      //       ls.get(key)
      //     } catch (error) {
      //       console.log(error)
      //     }
      //   },
      //   setItem: (key:any, value:any) => ls.set(key, value),
      //   removeItem: (key:any) => ls.remove(key),
      // },
    },
  )],
})