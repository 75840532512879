
import { Options, Vue } from "vue-class-component";
import { watch } from "vue";

@Options({
  components: {},
  props: {
    modelValue: Number,
    label: String,
    readonly: Boolean,
    "onUpdate:modelValue":Function,
    onChange:Function
  },
})

export default class JMPaginateSelect extends Vue {
  value:number = 50;
  modelValue!:number;
  mounted(){
    this.value=this.modelValue!=undefined?this.modelValue:25
  }
  emitUp(){
    this.$emit("update:modelValue",this.value)
    this.$emit("change")
  }
}

