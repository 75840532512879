
import { Options, Vue } from "vue-class-component";
import Navigation from "@/components/Navigation.vue";
import address from "./variable";
import { useStore } from "vuex";
import axios from "axios";
// const io = require("socket.io-client");
@Options({
  components: {
    Navigation,
  },
  props: {},
})
export default class MyApp extends Vue {
  store = useStore();
  // socket = io.connect("https://wma.jmandjm.com", { path: '/api/v1/socket.io' });
  mounted() {
    let self = this;
    document.title = `ระบบการจัดเก็บรายได้ อจน. ${this.store.getters.version}`;
    // this.socket.emit("connected", {
    //   user: "Test",
    // });
    // this.socket.on('userCount', function(msg:any) {
    //   self.store.commit("setUserOnline",msg+1)
    // })

    axios.interceptors.request.use((config) => {
      const token = this.store.state.token;
      config.headers.Authorization = `JWT ${token}`;
      return config;
    });
  }
}
