
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  components: {},
  props: {},
})
export default class RateGroupList extends Vue {
  store = useStore();
  docs: Array<any> = [];
  isSigned: boolean = true;
  show: number = 999999999;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 0;
  search: any = {};
  selected: Array<any> = [];
  mounted() {
    this.fetchData();
  }
  updatePaginate(i: number) {
    this.page = i;
    console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  fetchData() {
    let query = {
      search: {
        $and:[
          { name: this.search.name!=undefined?{ $regex: this.search.name, $options: '-i' }:undefined },
        ]
      },
      limit: 999999999,
      skip: 0,
    };
    axios.post(`${address}/areas-paginate`, query).then((response) => {
      axios.get(`${address}/arearates`).then((response2) => {
        let docs = response.data.docs;
        let docs2 = response2.data as Array<any>;
        docs.forEach((element: any, i: number) => {
          let found = docs2.find((el: any) => {
            return el.name == element.name;
          });
          if (found != undefined) {
            docs[i].code = found.code;
            docs[i].path="form/"+element._id;


            docs[i].treatment1 = found.treatment1
            docs[i].treatment2 = found.treatment2
            docs[i].treatment3 = found.treatment3

            docs[i].effluent1 = found.effluent1
            docs[i].effluent2 = found.effluent2
            docs[i].effluent3 = found.effluent3

            docs[i].treatmentType = found.treatmentType
            docs[i].effluentType = found.effluentType

          } else {
            docs[i].code = "กรุณาเพิ่มข้อมูล";
            docs[i].treatment1 = docs[i].treatment2 = docs[i].treatment3 =
              "กรุณาเพิ่มข้อมูล";
            docs[i].effluent1 = docs[i].effluent2 = docs[i].effluent3 =
              "กรุณาเพิ่มข้อมูล";

            docs[i].path="form/"+element._id;
          }
        });
        this.docs = docs;
        this.total = response.data.total;
        this.pages = Math.ceil(response.data.total / this.show);
      });
    });
  }
}
