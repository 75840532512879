
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import address from "@/variable"
@Options({})
export default class CustomerForm extends Vue {
  store = useStore();
  doc:any ={
    paymentDate:new Date()
  };
  thailand = require("@/data/thailand.json");
  address = address;
  provinceSelected: any = {};
  districtSelected: any = {};
  subDistrictSelected: any = {};
  contracts: Array<any> = [];
  rates: Array<any> = []

  deleteData(){
    let con = confirm("คุณต้องการลบข้อมูลใช่หรือไม่?")
    if(con==true){
      axios.delete(`${address}/payment/${this.$route.params.id}`).then(response=>{
        this.$router.push('/portal/payment/list')
      })
    }
  }
  checkNumber(){
    if(this.doc.invoiceNumber.length==10){
      axios.get(`${address}/invoice-by-field/sequence/${this.doc.invoiceNumber}`).then(response=>{
        console.log(response.data)
        let prep = response.data
        delete prep.sequence
        delete prep.createdAt
        this.doc = {...prep,...this.doc,process:true}
      })
      // axios.post(`${address}/report-debt-payment/`,{invoice:this.doc.invoiceNumber}).then(response=>{
      //   console.log(response.data)
      //   let data = response.data
      //   this.doc = data
      //   this.doc.rate = data.rate
      //   this.doc.code = data.code
      //   this.doc.qty = data.qty
      //   this.doc.meter = data.meter
      //   this.doc.name = data.name
      //   this.doc.address = data.address
      //   this.doc.category = data.category
      //   this.doc.categoryType = data.categoryType
      //   this.doc.month = data.debtText
      //   this.doc.invoiceAmount = (data.qty*data.rate)+this.calcTax(data)
      //   this.doc.debtText = data.debtText
      //   this.doc.debtAmount = data.debtAmount
      //   this.doc.calculationType = data.calculationType
      //   this.doc.invoiceNumber = data.sequence
      //   this.doc.invoice = data._id
      //   this.doc.paidDate = new Date()
      //   this.doc.method="keyin"
      //   this.doc._id = undefined
      // })
    }
  }
  getData() {
    axios.get(`${address}/payment/${this.$route.params.id}`).then((response) => {
      this.doc = response.data;
    });
  }
  onSubmit(){
    console.log("posting...")
    axios.post(`${address}/payment`, this.doc).then(response=>{
      console.log(response)
      alert("สำเร็จ!")
      this.$router.push('/portal/payment/list')
    });
  }

  calcTax(item: any): number {
    let basisAmount = item.qty * item.rate * item.vatRate;
    return basisAmount || 0;
  }
  mounted() {
    // this.getData();
    if (this.$route.params.id != undefined) this.getData();
    this.getRates();
  }
  updatePostal() {
    let found = this.subDistricts.find(
      (el: any) => el.name.th == this.doc.subDistrict
    ) as any;
    if (found != undefined) this.doc.postal = found.zipcode;
    else this.doc.postal = "";
  }
  get districts() {
    let found = Object.entries(this.thailand)
      .map((el) => el[1])
      .find((el: any) => el.name.th == this.doc.province) as any;
    if (found != undefined)
      return Object.entries((found || {}).amphoes).map((el) => el[1]);
    else return [];
  }
  get subDistricts() {
    let found = this.districts.find(
      (el: any) => el.name.th == this.doc.district
    ) as any;
    if (found != undefined)
      return Object.entries((found || {}).tambons).map((el) => el[1]);
    else return [];
  }
  patchData() {
    axios
      .patch(`${address}/payment/${this.$route.params.id}`, {
        ...this.doc,
        _id: undefined,
      })
      .then((response) => {
        console.log(response);
        alert("สำเร็จ!");
        this.$router.push('/portal/payment/list')
      });
  }
  getContracts() {
    axios.get(`${address}/contracts`).then((response) => {
      this.contracts = response.data;
      console.log(this.contracts);
    });
  }
  getRates(){
    axios.get(`${address}/arearates`).then((response) => {
      this.rates = response.data;
    });
  }
  submit() {
    if (this.$route.params.id != undefined) this.patchData();
    else this.getData();
  }
}
