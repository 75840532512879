
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import * as _ from "lodash";

@Options({
  components: {},
  props: {},
})
export default class Krob3Form extends Vue {
  _ = _;
  docs: Array<any> = [];
  ledgers: Array<any> = [];
  area: any = {};
  areaRation: any = {};
  doc: any = {
    allocationPerUnit: 0,
    allocationCost: 0,
    treatmentQty: 0,
    wmaExpenses: 0,
    areaExpenses: 0,
    totalExpenses: 0,
    totalIncome: 0,
    expenses: [],

    eAllocationPerUnit: 0,
    eAllocationCost: 0,
    eTreatmentQty: 0,
    eWmaExpenses: 0,
    eAreaExpenses: 0,
    eTotalExpenses: 0,
    eTotalIncome: 0,
  };
  areaCondition: any = {
    conditions: [],
  };
  isSigned: boolean = true;
  isActive: boolean = false;
  year: number = -1;
  quarter: number = -1;
  operation: number = 0;
  endorsedLedgers: Array<any> = [];
  condition: any = {};
  union: Array<any> = [];
  showOnlyNonZeroes = false;
  categories: Array<string> = [
    "เงินเดือน (ผจก.,พนง.ลูกจ้าง)",
    "ค่าซ่อมแซมและบำรุงรักษาระบบบำบัดน้ำเสีย",
    "ค่าสาธารณูปโภค",
    "ค่าใช้จ่ายอื่นๆ",
  ];

  @Watch("endorsedLedgers", { deep: true })
  @Watch("ledgers", { deep: true })
  updateCategory() {
    let getData = (cat: string) => {
      return _.chain([
        ...ledgers.filter((el) => el.category == cat),
        ...endorsed.filter((el) => el.category == cat),
      ])
        .groupBy("code")
        .map((items, item) => {
          return {
            code: item,
            left: items.find((el) => el.side == "left"),
            right: items.find((el) => el.side == "right"),
            category:
              items.find((el) => el.side == "left") != undefined
                ? items.find((el) => el.side == "left").category
                : items.find((el) => el.side == "right").category,
            name:
              items.find((el) => el.side == "left") != undefined
                ? items.find((el) => el.side == "left").name
                : items.find((el) => el.side == "right").name,
          };
        })
        .value();
    };
    var ledgers = _.chain(this.doc.ledgers)
      .map((el) => {
        return { ...el, side: "left", months: undefined };
      })
      .value();
    var endorsed = _.chain(this.endorsedLedgers)
      .map((el) => {
        return { ...el, side: "right", months: undefined };
      })
      .value();
    let u1 = getData("1");
    let u2 = getData("2");
    let u3 = getData("3");
    let u4 = getData("4");
    this.union = [u1, u2, u3, u4];

    this.$forceUpdate();
  }

  changeActive() {
    this.docs.forEach((el) => {
      el.active = !this.isActive;
    });
  }
  get ConditionCat() {
    try {
      return this.areaCondition.conditions[
        parseInt(this.$route.params.operation as string)
      ].category;
    } catch (error) {}
  }
  get isEndorsed() {
    try {
      return this.doc.endorsedLedgers.length > 0;
    } catch (error) {
      return false;
    }
  }
  // fetchAreaRation() {
  //   axios
  //     .get(`${address}/areaRation-by-field/area/${this.$route.params.area}`)
  //     .then((response) => (this.areaRation = response.data));
  //   }
  updateDoc() {
    this.updateCategory()
    this.doc.allocationCost =
      (this.doc.treatmentQty ?? 0) * this.doc.allocationPerUnit;
    this.doc.eAllocationCost =
      (this.doc.eTreatmentQty ?? 0) * this.doc.eAllocationPerUnit;
    try {
      let unionSum = ((_.chain([...this.union[0],...this.union[1],...this.union[2],...this.union[3]]).map('left.totalAmount')).reduce((sum,n)=>sum+(n??0),0).value()??0)
      // let union = (_.chain([
      //       ...this.union[0],
      //       ...this.union[1],
      //       ...this.union[2],
      //       ...this.union[3],
      //     ]).map("left.totalAmount")
      //       .reduce((sum, n) => sum + (n ?? 0), 0)
      //       .value() ?? 0);
      this.doc.wma = (unionSum??0)
      this.doc.wmaExpenses = (this.doc.allocationCost||0) + (unionSum??0);
      // this.docs.map((el) => el.totalAmount ?? 0).reduce((a, b) => a + b, 0);
      console.log("this.doc.wmaExpenses", this.doc.wmaExpenses, unionSum, this.doc.allocationCost||0)
    } catch (error) {
      console.log("wmaExpenses Error", error);
    }
    try {
      if (this.doc.endorsedLedgers != undefined) {
        this.doc.eWma = (_.chain([
            ...this.union[0],
            ...this.union[1],
            ...this.union[2],
            ...this.union[3],
          ])
            .map("right.totalAmount")
            .reduce((sum, n) => sum + (n ?? 0), 0)
            .value() ?? 0);
        this.doc.eWmaExpenses =
          (this.doc.eAllocationCost||0) +
          (_.chain([
            ...this.union[0],
            ...this.union[1],
            ...this.union[2],
            ...this.union[3],
          ])
            .map("right.totalAmount")
            .reduce((sum, n) => sum + (n ?? 0), 0)
            .value() ?? 0);
        // this.endorsedLedgers.map((el) => el.totalAmount ?? 0).reduce((a, b) => a + b, 0);
      } else {
      }
    } catch (error) {
      console.log("eWmaExpenses Error", error);
    }

    let sum = 0;
    for (let index = 1; index < 10; index++) {
      try {
        sum += this.doc[`areaCost${index.toString().padStart(2, "0")}`] ?? 0;
      } catch (error) {}
    }
    let eSum = 0;
    for (let index = 1; index < 10; index++) {
      try {
        eSum += this.doc[`eAreaCost${index.toString().padStart(2, "0")}`] ?? 0;
      } catch (error) {}
    }

    let income = 0;
    for (let index = 1; index < 10; index++) {
      try {
        income += this.doc[`income${index.toString().padStart(2, "0")}`] ?? 0;
      } catch (error) {}
    }
    let eIncome = 0;
    for (let index = 1; index < 10; index++) {
      try {
        eIncome += this.doc[`eIncome${index.toString().padStart(2, "0")}`] ?? 0;
      } catch (error) {}
    }
    try {
      this.doc.expenses.forEach((elem: any) => {
        sum += elem.amount ?? 0;
        eSum += elem.eAmount ?? 0;
      });
    } catch (error) {}
    this.doc.areaExpenses = sum;
    this.doc.eAreaExpenses = eSum;
    this.doc.wmaExpenses = parseFloat((this.doc.wmaExpenses ?? 0).toFixed(2));
    console.log("ConditionCat", this.ConditionCat);
    console.log("union", this.union);
    if (this.ConditionCat == "สมทบตามเปอร์เซ็นต์") {
      try {
        this.doc.totalExpenses =
          _.chain([
            ...this.union[0],
            ...this.union[1],
            ...this.union[2],
            ...this.union[3],
          ])
            .map("left.totalAmount")
            .reduce((sum, n) => sum + (n ?? 0), 0)
            .value() ?? 0;
        this.doc.eTotalExpenses =
          _.chain([
            ...this.union[0],
            ...this.union[1],
            ...this.union[2],
            ...this.union[3],
          ])
            .map("right.totalAmount")
            .reduce((sum, n) => sum + (n ?? 0), 0)
            .value() ?? 0;
      } catch (error) {
        console.log("สมทบตามเปอร์เซ็น error", error);
      }
      // this.doc.totalExpenses = (this.docs.filter(el=>el.selected).map(el=>el.totalAmount??0).reduce((a,b)=>a+b,0)),
      // this.doc.eTotalExpenses = (this.endorsedLedgers.filter(el=>el.selected).map(el=>el.totalAmount??0).reduce((a,b)=>a+b,0))
    } else {
      this.doc.totalExpenses = parseFloat(
        (this.doc.areaExpenses + this.doc.wmaExpenses).toFixed(2)
      );
      this.doc.eTotalExpenses = this.doc.eAreaExpenses + this.doc.eWmaExpenses;
    }
    this.doc.totalIncome = income;
    this.doc.eTotalIncome = eIncome;
  }

  mounted() {
    this.year = parseInt(this.$route.params.year as string);
    this.quarter = parseInt(this.$route.params.quarter as string);
    this.operation = parseInt(this.$route.params.operation as string);
    this.fetchArea();
    this.fetchData();
    // this.fetchAreaRation();
  }
  fetchData() {
    axios
      .post(`${address}/calculation-find`, {
        search: {
          area: this.$route.params.area,
          calendarYear: this.year,
          quarter: this.quarter,
        },
        sort: "-createdAt",
      })
      .then((response) => {
        axios
          .post(`${address}/areaRation-find`, {
            search: {
              area: this.$route.params.area,
              year: this.$route.params.year,
            },
          })
          .then((response) => {
            console.log("area", response);
            this.areaRation = response.data;
            this.doc.allocationPerUnit = response.data.rate;
            this.doc.eAllocationPerUnit = response.data.rate;
          });
        if (response.data != "") {
          this.docs = response.data.ledgers;
          this.ledgers = response.data.ledgers;
          this.endorsedLedgers = response.data.endorsedLedgers;
          this.doc = response.data;
          this.updateDoc();
        } else {
          axios
            .post(`${address}/areaRation-find`, {
              search: {
                area: this.$route.params.area,
                year: this.$route.params.year,
              },
            })
            .then((response) => {
              console.log("area", response);
              this.areaRation = response.data;
              this.doc.allocationPerUnit = response.data.rate;
              this.doc.eAllocationPerUnit = response.data.rate;
              this.updateDoc();
            });
        }
      });
  }
  fetchArea() {
    axios
      .get(`${address}/area/${this.$route.params.area}`)
      .then((response) => (this.area = response.data));
    axios
      .get(`${address}/areaCondition-by-field/area/${this.$route.params.area}`)
      .then((response) => (this.areaCondition = response.data));
  }
  submitData() {
    let query = {
      area: this.area._id,
      // area:this.$route.params.area,
      calendarYear: this.year,
      quarter: this.quarter,
    };
    let prep = {
      area: this.area._id,
      // area:this.$route.params.area,
      areaCondition: this.areaCondition._id,
      operationYear: this.operation,
      calendarYear: this.year,
      quarter: this.quarter,
      ledgers: this.docs,
      ...this.doc,
      isKrob3: true,
      _id: undefined,
    };

    console.log("prep", prep);

    axios
      .post(`${address}/calculation-upsert/`, { search: query, doc: prep })
      .then((response) => {
        alert("บันทึกข้อมูลแล้ว!");
        console.log("done!");
      });
  }

  get combined() {
    return this.docs.filter((el) => el.selected);
  }

  get category1() {
    return this.docs.filter((el) => el.selected && el.category == 1);
  }
  get category2() {
    return this.docs.filter((el) => el.selected && el.category == 2);
  }
  get category3() {
    return this.docs.filter((el) => el.selected && el.category == 3);
  }
  get category4() {
    return this.docs.filter((el) => el.selected && el.category == 4);
  }
  get eCategory1() {
    if (this.endorsedLedgers != undefined) {
      return this.endorsedLedgers.filter(
        (el) => el.selected && el.category == 1
      );
    }
  }
  get eCategory2() {
    if (this.endorsedLedgers != undefined) {
      return this.endorsedLedgers.filter(
        (el) => el.selected && el.category == 2
      );
    }
  }
  get eCategory3() {
    if (this.endorsedLedgers != undefined) {
      return this.endorsedLedgers.filter(
        (el) => el.selected && el.category == 3
      );
    }
  }
  get eCategory4() {
    if (this.endorsedLedgers != undefined) {
      return this.endorsedLedgers.filter(
        (el) => el.selected && el.category == 4
      );
    }
  }

  pushItem(key: any) {
    if (!Array.isArray(this.doc[key])) this.doc[key] = [];
    this.doc[key].push({ name: "", value: 0 });
  }
}
