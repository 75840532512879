
import { Options, Vue } from "vue-class-component";
// import { Iupload } from "@/interface";
import axios from "axios";
import address,{filePath} from "@/variable";

@Options({
  components: {},
  props: {
    modelValue: Array,
    folder: String,
    label: String,
  },
})
export default class JMUpload extends Vue {
  modelValue!:Array<string>
  folder!: string;
  label!: string;
  uploadPercentage: number = 0;
  address = address
  filePath=filePath
  isDetail:boolean = false;
  mounted(){
    if(this.$route.path.search("detail/")!=-1) this.isDetail = true
  }
  deleteItem(idx:number){
    let items = this.modelValue
    items.splice(idx,1)
    this.$emit("update:modelValue", items);
  }
  submitfile(ev: InputEvent) {
    let myinput = this.$refs[`myfile`] as HTMLInputElement;
    var file = myinput!.files![0];
    var fileSize = file.size / 1024;
    console.log("ขนาดไฟล์" + fileSize);
    if (fileSize > 250 * 1024) {
      alert("ไฟล์มีขนาดมากกว่า 250 MB กรุณาเลือกไฟล์ใหม่");
    } else {
      const self = this;
      const formData = new FormData();
      formData.set("name", `${this.folder}`);
      formData.append("file", file);
      axios
        .post(`${address}/upload/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function(progressEvent: ProgressEvent) {
            self.uploadPercentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          }.bind(self),
        })
        .then(function(response: any) {
          let newObj = self.modelValue
          if(!Array.isArray(newObj)) newObj = []
          if(newObj.length>=4){
            alert("คุณสามารถอัพโหลดได้ 4 เท่านั้น")
          }
          else{
          newObj.push(response.data.path)
          
          self.$emit("update:modelValue", newObj);
          console.log(response);
          }
        })
        .catch(function(err: Error) {
          console.log(err);
        });
    }
  }
}
