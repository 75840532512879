
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import address from "@/variable";
import sparkline from "@fnando/sparkline";
@Options({})
export default class CustomerList extends Vue {
  store = useStore();
  search: any = {
    category:undefined
  };
  docs: Array<any> = [];
  page: number = 0;
  pages: number = 0;
  show: number = 50;
  limit: number = 5;
  skip: number = 0;
  total: number = 0;
  loading: boolean = false;
  downloadExcel() {
    console.log("download excel...");
    let self = this;
    const Excel = require("exceljs");
    var workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet(`รายงาน`);
    let header = [
      "ลำดับ",
      "เลขที่ผู้ใช้น้ำ",
      "ชื่อ",
      "ที่อยู่",
      "ปริมาณการใช้น้ำ (ลบ.ม.)",
      "ยอดชำระทั้งสิ้น (บาท)",
    ];
    worksheet.addRow(header);
    this.docs.forEach((item, i) => {
      worksheet.addRow([
        i + 1,
        item.meter,
        (item.prefix ?? "") + (item.name ?? "-"),
        item.address,
        (item.qty ?? 0).formatFull(),
        (item.amount ?? 0).formatFull(),
      ]);
    });
    workbook.xlsx.writeBuffer().then(function(data: any) {
      var blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "รายการผู้ใช้น้ำ.xlsx");
    });
  }
  updatePaginate(i: number) {
    this.page = i;
    // console.log(this.page);
    this.fetchData();
  }
  print() {
    window.print();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  clearPage() {
    if (this.show == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }
  fetchData() {
    this.loading = true;
    this.docs = [];
    function findClosest(target: any, tagName: any) {
      if (target.tagName === tagName) {
        return target;
      }

      while ((target = target.parentNode)) {
        if (target.tagName === tagName) {
          break;
        }
      }

      return target;
    }
    let query = {
      search: {
        category:this.search.category,
        $or: [
          {
            name:
              this.search.name != undefined && this.search.name != ""
                ? { $regex: this.search.name, $options: "-i" }
                : undefined,
          },
          {
            meter:
              this.search.name != undefined && this.search.name != ""
                ? { $regex: this.search.name, $options: "-i" }
                : undefined,
          },
        ],
      },
      limit: this.show,
      skip: this.page * this.show,
    };
    axios.post(`${address}/render-customer-list`, query).then((response) => {
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
      this.$nextTick(() => {
        this.loading = false;
        this.docs.forEach((el) => {
          let sorted = el.history;
          sorted.sort(function(a: any, b: any) {
            return a.year + a.month * 0.01 - (b.year + b.month * 0.01);
          });
          let spk = this.$refs["spk-" + el._id] as SVGSVGElement;
          var options = {
            onmousemove(event: any, datapoint: any) {
              var svg = findClosest(event.target, "svg");
              var tooltip = spk.nextElementSibling as any;
              tooltip.hidden = false;
              tooltip.textContent = `${datapoint.month.toThaiMonth()}${
                datapoint.year
              }: ${datapoint.value.toFixed(2)} ฿`;
              tooltip.style.top = `${event.offsetY}px`;
              tooltip.style.left = `${event.offsetX + 20}px`;
            },

            onmouseout() {
              var svg = findClosest(event!.target, "svg");
              var tooltip = svg.nextElementSibling;

              tooltip.hidden = true;
            },
          };
          sparkline(spk, el.history, options);
        });
      });
    });
  }
  mounted() {
    this.fetchData();
  }
  clearSearch() {
    this.search = {};
    this.fetchData();
  }
}
