
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
@Options({})
export default class ResetForm extends Vue {
  store = useStore();
  doc: any = {};
  mounted() {
    if (this.$route.params.id != undefined) this.getData();
  }
  getData() {
    axios.get(`${address}/user/${this.$route.params.id}`).then((response) => {
      this.doc = response.data;
    });
  }
  submitData() {
    if (this.doc.password.length > 6) {
      if (this.doc.password == this.doc.checkPassword) {
        axios.post(`${address}/reset/submit`, {...this.doc,uuid:this.$route.params.uuid}).then((response) => {
          console.log(response);
          alert("สำเร็จ!");
        });
      } else {
        alert("รหัสผ่านไม่ตรงกัน!");
      }
    } else {
      alert("รหัสผ่านต้องมากกว่า 6 ตัวหนังสือขึ้นไปเท่านั้น!");
    }
  }
}
