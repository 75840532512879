
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon";
import { useStore } from "vuex";
import { saveAs } from "file-saver";
import { createPopper } from "@popperjs/core";
import { Watch } from "vue-property-decorator";
@Options({
  components: {},
  props: {},
})
export default class UsageList extends Vue {
  store = useStore();
  docs: Array<any> = [];
  latest: Array<any> = [];
  invoices: Array<any> = [];
  isSigned: boolean = true;
  working: boolean = false;
  show: number = 50;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 5;
  totalCount: number = 0;
  totalQty: number = 0;
  ids: Array<string> = [];
  isPrint: boolean = true;
  loading: boolean = false;
  isSelectedAll: boolean = false;
  sort: any = `{"no":1}`;
  invoiceDate = new Date();
  // search: any = {
  //   year: 2564,
  //   month: 1,
  //   cat: 0,
  // };
  search: any = {
    year: 0,
    month: 13,
    cat: 0,
  };
  selected: Array<any> = [];

  // @Watch("search", { deep: true })
  setQuery() {
    this.$router.push({
      path: this.$route.path,
      query: {
        search: JSON.stringify(this.search),
      },
    });
  }
  getQuery() {
    try {
      // this.search = JSON.parse(this.$route.query.search as string);
    } catch (error) {}
  }
  showModal() {
    let modal = this.$refs.modal as HTMLElement;
    modal.click();
  }
  mounted() {
    this.getQuery()
    this.getLatest();
    try {
      console.log("search", this.$route.query.searchparam);
      this.search = JSON.parse(this.$route.query.search as string);
      if(this.search != undefined) this.fetchData();
    } catch (error) {
      console.log("error", error);
    }
  }
  downloadExcel() {
    let query = {
      search: { _id: { $in: this.selected } },
    };
    axios
      .post(`${address}/usages-excel`, query, { responseType: "blob" })
      .then((response) => {
        // Log somewhat to show that the browser actually exposes the custom HTTP header
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
          suggestedFileName === undefined
            ? "download_report.xlsx"
            : suggestedFileName;
        console.log(
          "Received header [" +
            fileNameHeader +
            "]: " +
            suggestedFileName +
            ", effective fileName: " +
            effectiveFileName
        );

        saveAs(response.data, effectiveFileName);
      });
  }
  selectAll(ev: any) {
    let checked = ev.target.checked;
    if (checked) this.selected = this.ids;
    else this.selected = [];
  }

  updatePaginate(i: number) {
    this.page = i;
    console.log(this.page);
    this.fetchData();
  }

  clearPage() {
    if (this.show == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }

  deleteMany() {
    let userConfirm = confirm("คุณต้องการลบรายการผู้ใช้น้ำ!");
    if (userConfirm) {
      axios
        .post(`${address}/usages-delete`, { list: this.selected })
        .then((response) => {
          this.fetchData();
        })
        .then((response) => {
          alert("ลบข้อมูลแล้ว");
        });
    }
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    // let arr = new Array(Math.ceil(this.total/this.show)).fill(0);
    return arr;
  }
  postData() {
    axios
      .post(`${address}/process/create/invoice`, {
        list: this.selected,
        requester: this.store.state.userId,
        approver: this.store.state.managerId,
        invoiceDate: this.invoiceDate,
      })
      .then((response) => {
        alert("ส่งข้อมูลเพื่อจัดทำใบแจ้งหนี้แล้ว");
        let btn = this.$refs.close as HTMLElement;
        btn.click();
        this.$router.push("/portal/invoice/list");
      })
      .catch((error) => {
        alert("พบข้อผิดพลาดบนเครื่องแม่ข่าย " + error);
      });
  }
  getLatest() {
    axios.get(`${address}/usages-information`).then((response) => {
      console.log(response);
      this.latest = response.data[0];
    });
  }
  fetchData() {
    this.loading = true;
    this.docs = [];
    this.totalCount = 0;
    this.totalQty = 0;
    let category = undefined;
    let categoryType = undefined;
    try {
      if (this.search.cat != "") {
        category = this.search.cat.split(",")[0];
        categoryType = this.search.cat.split(",")[1];
      }
    } catch (error) {
      console.log(error);
      category = undefined;
      categoryType = undefined;
    }
    let query: any = {
      search: {
        category: this.search.cat != undefined ? category : undefined,
        code: JSON.parse(JSON.stringify(this.search.code)),
        // categoryType: this.search.cat != undefined ? categoryType : undefined,
        $and: [
          {
            number:
              this.search.numberFrom != undefined
                ? { $gte: this.search.numberFrom }
                : undefined,
          },
          {
            number:
              this.search.numberTo != undefined
                ? { $lte: this.search.numberTo }
                : undefined,
          },
          {
            $or: [
              {
                name:
                  this.search.text != undefined
                    ? { $regex: this.search.text, $options: "-i" }
                    : undefined,
              },
              {
                meter:
                  this.search.text != undefined
                    ? { $regex: this.search.text, $options: "-i" }
                    : undefined,
              },
            ],
          },
          {
            year:
              this.search.year != undefined
                ? { $eq: this.search.year }
                : undefined,
          },
          {
            month:
              this.search.month != undefined
                ? { $eq: this.search.month }
                : undefined,
          },
        ],
      },
      limit: this.show,
      skip: this.page * this.show,
      sort: JSON.parse(this.sort),
    };
    console.log(query);
    axios.post(`${address}/usages-paginate`, query).then((response) => {
      this.docs = response.data.docs;
      // console.log(this.docs);
      this.total = response.data.total;
      this.totalCount = response.data.totalCount;
      this.totalQty = response.data.totalQty;
      this.ids = response.data.ids;
      this.pages = Math.ceil(response.data.total / this.show);
      this.selected = [];
      this.isSelectedAll = false;
      this.loading = false;
    });
  }
  getThaiMonth(n: number) {
    switch (n) {
      case 1:
        return "ม.ค.";
        break;
      case 2:
        return "ก.พ.";
        break;
      case 3:
        return "มี.ค.";
        break;
      case 4:
        return "เม.ย.";
        break;
      case 5:
        return "พ.ค.";
        break;
      case 6:
        return "มิ.ย.";
        break;
      case 7:
        return "ก.ค.";
        break;
      case 8:
        return "ส.ค.";
        break;
      case 9:
        return "ก.ย.";
        break;
      case 10:
        return "ต.ค.";
        break;
      case 11:
        return "พ.ย.";
        break;
      case 12:
        return "ธ.ค.";
        break;

      default:
        return ".";
        break;
    }
  }
}
