
import component from "*.vue";
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator"
import * as _ from "lodash"

@Options({
  components: {},
  props: {},
})
export default class Krob2Form extends Vue {
  _ = _
  event: any;
  ledgers: Array<any> = [];
  endorsedLedgers: Array<any> = [];
  costCodes: Array<any> = [];
  area: any = {};
  areaCondition: any = {
    conditions: [],
  };
  isSigned: boolean = true;
  isActive: boolean = false;
  isExisted: boolean = false;
  id: string = "";
  areaId: string = "";
  selected: boolean = true;
  year: number = -1;
  quarter: number = -1;
  operation: number = 0;
  newText: string = "";
  oldText: string = "";
  union:Array<any> = []
  showOnlyNonZeroes = false
  categories:Array<string> = [
    "เงินเดือน (ผจก.,พนง.ลูกจ้าง)",
    "ค่าซ่อมแซมและบำรุงรักษาระบบบำบัดน้ำเสีย",
    "ค่าสาธารณูปโภค",
    "ค่าใช้จ่ายอื่นๆ"
  ]
  downloadExcel() {

      var name = this.year
      var fileName = this.area.name + " - " + name
      const _numFormat = `_(* #,##0.00_);_(* - #,##0.00_);"-";`

        let self = this;
        const Excel = require("exceljs");
        var workbook = new Excel.Workbook();
        var worksheet = workbook.addWorksheet(`กรอบ 2`);

        worksheet.addRow(["ค่าใช้จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย"])
        worksheet.addRow([(this.area.prefix??'')+(this.area.name??'')])
        let text1 = "ปีงบประมาณ " + this.year
        let text2 = ""
        text2 += this.areaCondition.conditions[this.operation].category + "/" 
        text2 += (this.areaCondition.conditions[this.operation].contributionLimit??0).formatFull() + "/" 
        text2 += this.areaCondition.conditions[this.operation].period+ "/" 
        text2 += this.areaCondition.conditions[this.operation].calculation
        if(this.quarter!=0) text1 += " ไตรมาส " + this.quarter
        worksheet.addRow(["ประจำ " + this.year + text1])
        worksheet.addRow([text2])
        worksheet.mergeCells('A1:C1');
        worksheet.mergeCells('A2:C2');
        worksheet.mergeCells('A3:C3');
        worksheet.mergeCells('A4:C4');
        worksheet.getCell("A1").alignment = { horizontal: "center" };
        worksheet.getCell("A2").alignment = { horizontal: "center" };
        worksheet.getCell("A3").alignment = { horizontal: "center" };
        worksheet.getCell("A4").alignment = { horizontal: "center" };
        let count = 0
        this.categories.forEach((category:any, i:number) => {
          worksheet.addRow([
            (i+1) + " " + category
          ]);
          this.union[i].forEach((item:any, i:number) => {
            worksheet.addRow([
              "    "+item.name,((item.left??{}).totalAmount??0),((item.right??{}).totalAmount??0)
            ]);
          })
          count++
        });
        worksheet.addRow([
          "รวมค่าใช้จ่ายจากการดำเนินงานขององค์การจัดการน้ำเสียสำหรับ" + text1,
          ((_.chain([...this.union[0],...this.union[1],...this.union[2],...this.union[3]]).map('left.totalAmount')).reduce((sum,n)=>sum+(n??0),0).value()??0),
          ((_.chain([...this.union[0],...this.union[1],...this.union[2],...this.union[3]]).map('right.totalAmount')).reduce((sum,n)=>sum+(n??0),0).value()??0)
        ])

        for (let i = 6; i < 200; i++) {
          worksheet.getRow(i).getCell(2).numFmt = _numFormat
          worksheet.getRow(i).getCell(3).numFmt = _numFormat
        }
        
        worksheet.getColumn('A').width = 60
        worksheet.getColumn('B').width = 30
        worksheet.getColumn('C').width = 30
        workbook.xlsx.writeBuffer().then(function(data: any) {
          var blob = new Blob([data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, fileName + ".xlsx");
        });
  }


  async importLeft(ev: any) {
    const Excel = require("exceljs/dist/es5/exceljs.browser");
    let self = this;
    let file = ev.target.files[0]
    const workbook = new Excel.Workbook();
    let book = await workbook.xlsx.load(file);
    let sheet = book.worksheets[0];
    let arr: Array<any> = [];
    sheet.eachRow(function(row: any, rowNumber: any) {
      if (row.getCell(1).value != null&&rowNumber>1) {
        let code = (row.getCell(1).value ?? "")
        if(typeof code === "string") code = code.replace(".", "")
        else code = code.toString().replace(".", "")
        let found = self.costCodes.find(el=>el.code==code)
        if(found != undefined) 
          arr.push({
            selected: found!=undefined?true:false,
            code: code, 
            name: found.name,//row.getCell(2).value,
            months: self.getAccountByQuarter(row,self.quarter),
            totalAmount: self.getAccountByQuarter(row,self.quarter).map(el=>el.amount).reduce((a,b)=>a+b,0),
            category: self.getCategory(code)
        });
      }
    })
    this.ledgers = arr
  }

  async importRight(ev: any) {
    const Excel = require("exceljs/dist/es5/exceljs.browser");
    let self = this;
    let file = ev.target.files[0]
    const workbook = new Excel.Workbook();
    let book = await workbook.xlsx.load(file);
    let sheet = book.worksheets[0];
    let arr: Array<any> = [];
    sheet.eachRow(function(row: any, rowNumber: any) {
      if (row.getCell(1).value != null&&rowNumber>1) {
        let code = (row.getCell(1).value ?? "")
        if(typeof code === "string") code = code.replace(".", "")
        else code = code.toString().replace(".", "")
        let found = self.costCodes.find(el=>el.code==code)
        if(found != undefined)
          arr.push({
            selected: found!=undefined?true:false,
            code: code, 
            name: found.name,//row.getCell(2).value,
            months: self.getAccountByQuarter(row,self.quarter),
            totalAmount: self.getAccountByQuarter(row,self.quarter).map(el=>el.amount).reduce((a,b)=>a+b,0),
            category: self.getCategory(code)
          });
      }
    })
    console.log(arr)
    this.endorsedLedgers = arr
  }

  getCostCode() {
    axios.get(`${address}/costcodes`).then((response) => {
      this.costCodes = response.data;
    });
  }

  getMatchCode(code: string) {
    try {
      return (
        this.endorsedLedgers.find((el: any) => el.code == code).totalAmount ?? 0
      );
    } catch (error) {
      return 0;
    }
  }

  mounted() {
    window.scrollTo(0,0);
    this.year = parseInt(this.$route.params.year as string);
    this.quarter = parseInt(this.$route.params.quarter as string);
    this.operation = parseInt(this.$route.params.operation as string);
    this.areaId = this.$route.params.area as string;
    this.fetchArea();
    this.fetchData();
    this.getCostCode();
  }
  fetchData() {
    if (this.$route.query.mode == "new") {

    } else {
      let query = {
        search: {
          area: this.areaId,
          calendarYear: this.year,
          quarter: this.quarter,
        },
        sort:{
          createdAt:-1 // "-createdAt"
        }
      };
      console.log(query);
      axios.post(`${address}/calculation-find`, query).then((response) => {
        if (response.data != "") {
          this.isExisted = true;
          this.id = response.data._id;
          console.log(response.data);
          this.ledgers = response.data.ledgers;
          this.endorsedLedgers = response.data.endorsedLedgers;
          this.newText = response.data.newText;
          this.oldText = response.data.oldText;
          this.updateCategory()
        } else {
          this.isExisted = false;
        }
      });
    }
  }

  @Watch("endorsedLedgers",{deep:true})
  @Watch("ledgers",{deep:true})
  updateCategory(){
    let getData = (cat:string) => {
      return _.chain([...ledgers.filter(el=>el.category==cat),...endorsed.filter(el=>el.category==cat)]).groupBy('code').map((items,item)=>{
        return {
          code:item,
          left:items.find(el=>el.side=="left"),
          right:items.find(el=>el.side=="right"),
          category:items.find(el=>el.side=="left")!=undefined?items.find(el=>el.side=="left").category:items.find(el=>el.side=="right").category,
          name:items.find(el=>el.side=="left")!=undefined?items.find(el=>el.side=="left").name:items.find(el=>el.side=="right").name
        }
      }).value()
    }
    var ledgers = _.chain(this.ledgers).map(el=>{ return {...el,side:'left',months:undefined}}).value()
    var endorsed = _.chain(this.endorsedLedgers).map(el=>{ return {...el,side:'right',months:undefined}}).value()
    let u1 = getData("1")
    let u2 = getData("2")
    let u3 = getData("3")
    let u4 = getData("4")
    this.union = [u1,u2,u3,u4]

    this.$forceUpdate()
  }
  fetchArea() {
    axios
      .get(`${address}/area/${this.$route.params.area}`)
      .then((response) => (this.area = response.data));
    axios
      .get(`${address}/areaCondition-by-field/area/${this.$route.params.area}`)
      .then((response) => (this.areaCondition = response.data));
  }
  submitData() {
    let prep = {
      area: this.area._id,
      areaCondition: this.areaCondition._id,
      operationYear: this.operation,
      calendarYear: this.year,
      quarter: this.quarter,
      ledgers: this.ledgers,
      endorsedLedgers: this.endorsedLedgers,
      newText: this.newText,
      oldText: this.oldText,
    };
    if (this.$route.query.mode == "new") {
      console.log("ใหม่");
      axios.post(`${address}/calculation/`, prep).then((response) => {
        alert("บันทึกข้อมูลแล้ว!");
        console.log("done!");
        this.$router.push('/portal/estimation/list')
      });
    } else {
      console.log("เก่า");
      axios
        .patch(`${address}/calculation/${this.id}`, prep)
        .then((response) => {
          alert("แก้ไขข้อมูลแล้ว!");
          console.log("done!");
          this.$router.push('/portal/estimation/list')
        });
    }
  }
  getCategory(acct: string) {
    let found = this.costCodes.find((el) => el.code == acct);
    if (found != undefined) return found.category;
    else return -1;
  }
  getAccountByQuarter(row:any,quarter:number){
    let result = []
    if(quarter == 0 || quarter == 1) result.push({month:10,amount:row.getCell(5).value ?? 0})
    if(quarter == 0 || quarter == 1) result.push({month:11,amount:row.getCell(6).value ?? 0})
    if(quarter == 0 || quarter == 1) result.push({month:12,amount:row.getCell(7).value ?? 0})
    if(quarter == 0 || quarter == 2) result.push({month:1, amount:row.getCell(8).value ?? 0})
    if(quarter == 0 || quarter == 2) result.push({month:2, amount:row.getCell(9).value ?? 0})
    if(quarter == 0 || quarter == 2) result.push({month:3, amount:row.getCell(10).value ?? 0})
    if(quarter == 0 || quarter == 3) result.push({month:4, amount:row.getCell(11).value ?? 0})
    if(quarter == 0 || quarter == 3) result.push({month:5, amount:row.getCell(12).value ?? 0})
    if(quarter == 0 || quarter == 3) result.push({month:6, amount:row.getCell(13).value ?? 0})
    if(quarter == 0 || quarter == 4) result.push({month:7, amount:row.getCell(14).value ?? 0})
    if(quarter == 0 || quarter == 4) result.push({month:8, amount:row.getCell(15).value ?? 0})
    if(quarter == 0 || quarter == 4) result.push({month:9, amount:row.getCell(16).value ?? 0})
    return result
  }
}
