
import { Options, Vue } from "vue-class-component";
import axios from "axios";
import address from "@/variable";
import { DateTime } from "luxon";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";

@Options({
  components: {},
  props: {},
})
export default class ReceiptList extends Vue {
  store = useStore()
  docs: Array<any> = [];
  doc: any = {};
  isSigned: boolean = true;
  isActive: boolean = false;
  loading: boolean = false;
  search: any = {};
  show: number = 999999999;
  total: number = 5;
  pages: number = 0;
  rates: Array<any> = [];

  totalCount: number = 0;
  totalQty: number = 0;
  totalAmount: number = 0;
  totalDebt: number = 0;
  ids: Array<string> = [];

  EditItems(id:string,field:string, value:number|string) {
    console.log('edit',id);
    let prep:any = {}
    prep[field] = value
    axios.patch(`${address}/invoice/${id}`,prep).then((response) => {
      console.log('invoice',response)
      this.updateReport()
    });
  }
  downloadExcel() {
    console.log("download excel...");
    let self = this;
    const Excel = require("exceljs");
    var workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet(`รายงาน`);
    let header = [
      "ลำดับ",
      "เลขที่ใบแจ้งหนี้",
      "เลขที่ผู้ใช้น้ำ(ใหม่)",
      "ชื่อ",
      "ที่อยู่",
      "เดือนที่ค้างชำระ",
      "เงินคงค้าง(บาท)",
      "ปริมาณการใช้น้ำ",
      "ค่าบำบัดน้ำเสีย",
      "รวมเงิน(บาท)",
      "ภาษี",
      "ค่าบริการบำบัดน้ำเสีย",
      "รวมเงินชำระทั้งสิ้น(บาท)",
    ];
    worksheet.addRow(header);
    this.docs.forEach((item, i) => {
      worksheet.addRow([
        i + 1,
        item.sequence,
        item.meter,
        (item.prefix ?? "") + (item.name ?? "-"),
        item.address,
        (item.debtText ?? "").length == 0 ? "-" : item.debtText,
        (item.debtAmount ?? 0).formatFull(),
        (item.qty ?? 0).formatFull(),
        item.category,
        this.sum(item).formatFull(),
        (this.sum(item) * item.vatRate).formatFull(),
        item.totalAmount.formatFull(),
        item.invoiceAmount.formatFull(),
      ]);
    });
    workbook.xlsx.writeBuffer().then(function(data: any) {
      var blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ทะเบียนคุมใบแจ้งหนี้.xlsx");
    });
  }
  @Watch("search", { deep: true })
  updateReport() {
    if (
      this.search.code != undefined &&
      this.search.cat != undefined &&
      this.search.month != undefined &&
      this.search.year != undefined
    ) {
      this.fetchData();
    }
  }
  print() {
    window.print();
  }
  mounted() {
    // this.search = {
    //   cat: "2,น้ำเสีย",
    //   code: "01-kb",
    //   month: "2",
    //   year: 2563,
    // };
    this.getRates();
  }
  sum(item: any): number {
    let sumAmount = 0;
    if (item.calculationType == "บาท/ลบ.ม.") {
      sumAmount = item.qty * item.rate;
    } else {
      sumAmount = item.rate;
    }
    return sumAmount ?? 0;
  }
  getRates() {
    axios.get(`${address}/arearates`).then((response) => {
      this.rates = response.data;
    });
  }
  fetchData() {
    this.loading = true;
    this.docs = [];
    let category = null;
    let categoryType = null;
    try {
      category = this.search.cat.split(",")[0];
      categoryType = this.search.cat.split(",")[1];
    } catch (error) {}
    let query = {
      search: {
        $and: [
          {
            code:
              this.search.code != undefined
                ? { $eq: this.search.code }
                : undefined,
          },
          this.search.month!=-1?{
            $and:[
              {year:{ $eq: this.search.year }},
              {month:this.search.month}
            ]
          }:{
            //@prettier-ignore
            $or:[
              // { month: 10, year:this.search.year-1 },
              { month: 11, year:this.search.year-1 },
              { month: 12, year:this.search.year-1 },
              { month: 1, year:this.search.year },
              { month: 2, year:this.search.year },
              { month: 3, year:this.search.year },
              { month: 4, year:this.search.year },
              { month: 5, year:this.search.year },
              { month: 6, year:this.search.year },
              { month: 7, year:this.search.year },
              { month: 8, year:this.search.year },
              { month: 9, year:this.search.year },
              { month: 10, year:this.search.year },
            ]
          },
          // {
          //   year:
          //     this.search.year != undefined
          //       ? { $eq: this.search.year }
          //       : undefined,
          // },
          // {
          //   month:
          //     this.search.month != undefined
          //       ? { $eq: this.search.month }
          //       : undefined,
          // },
          { category: category != undefined ? { $eq: category } : undefined },
          // {
          //   categoryType:
          //     categoryType != undefined ? { $eq: categoryType } : undefined,
          // },
        ],
      },
      limit: 999999999,
      skip: 0,
      // sort: { createdAt: 1 },
      sort: { sequence: 1 },
    };
    axios.post(`${address}/invoices-paginate`, query).then((response) => {
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);

      this.loading = false;

      this.totalCount = response.data.totalCount;
      this.totalQty = response.data.totalQty;
      this.totalAmount = response.data.totalAmount;
      this.totalDebt = response.data.totalDebt;
      this.ids = response.data.ids;
    });
  }
  getThaiMonth(n: string) {
    switch (n) {
      case "1":
        return "ม.ค.";
        break;
      case "2":
        return "ก.พ.";
        break;
      case "3":
        return "มี.ค.";
        break;
      case "4":
        return "เม.ย.";
        break;
      case "5":
        return "พ.ค.";
        break;
      case "6":
        return "มิ.ย.";
        break;
      case "7":
        return "ก.ค.";
        break;
      case "8":
        return "ส.ค.";
        break;
      case "9":
        return "ก.ย.";
        break;
      case "10":
        return "ต.ค.";
        break;
      case "11":
        return "พ.ย.";
        break;
      case "12":
        return "ธ.ค.";
        break;

      default:
        return ".";
        break;
    }
  }
}
