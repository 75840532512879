import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AreaForm from '@/views/area/AreaForm.vue'
import AreaList from '@/views/area/AreaList.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: 'area/form',
    name: 'AreaForm',
    component: AreaForm,
    meta:{
      bc:[
        {
          name:"พื้นที่ อปท.",
          to:"/portal/landing"
        },
        {
          name:"รายการพื้นที่องค์การปกครองส่วนท้องถิ่น (อปท.)",
          to:"/portal/area/list"
        },
        {
          name:"เพิ่ม อปท.",
          to:"/portal/area/form"
        },
      ]
    }
  },
  {
    path: 'area/edit/:id',
    name: 'AreaEdit',
    component: AreaForm,
    meta:{
      bc:[
        {
          name:"พื้นที่ อปท.",
          to:"/portal/landing"
        },
        {
          name:"รายการพื้นที่องค์การปกครองส่วนท้องถิ่น (อปท.)",
          to:"/portal/area/list"
        },
        {
          name:"แก้ไข อปท.",
          to:"/portal/area/edit/:id"
        },
      ]
    }
  },
  {
    path: 'area/list',
    name: 'AreaList',
    component: AreaList,
    meta:{
      bc:[
        {
          name:"พื้นที่ อปท.",
          to:"/portal/landing"
        },
        {
          name:"รายการพื้นที่องค์การปกครองส่วนท้องถิ่น (อปท.)",
          to:"/portal/area/list"
        },
      ]
    }
  },
]

export default routes