
import address from "@/variable";
import axios from "axios";
import { DateTime } from "luxon";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";
import PDF from "@/pdf";
import { DocumentCreator } from "@/docx";
import { Packer } from "docx";
@Options({})
export default class AlbumExample extends Vue {
  store = useStore();
  pdfBLOB = "";
  fonts: any = {};
  styles: any = {};
  address = address
  filePath = address
  doc: any = {
    documentNumber: "มท.",
    subject: "ยังไม่ระบุ",
    to: "ยังไม่ระบุ",
    issueDate: new Date(),
    notifyTo: "พิจารณาอนุมัติ ขอขอบคุณมา ณ โอกาส นี้",
    content: `ตามหนังสือที่อ้างถึง องค์การจัดการน้ําเสีย ได้แจ้งยอดเงินสมทบค่าใช้จ่ายในการ ....`,
    // content: `ตามหนังสือที่อ้างถึง องค์การจัดการน้ําเสีย ได้แจ้งยอดเงินสมทบค่าใช้จ่ายในการ .... \r\n\r\n\tจึงเรียนมาเพื่อโปรดพิจารณา ขอขอบคุณมา ณ โอกาส นี้`,
    approverName: "นายวิรัตน์  ภมรานนท์",
    approverPosition:
      "รองผู้อำนวยการปฏิบัติการ ปฏิบัติการแทน\r\nผู้อำนวยการองค์การจัดการน้ำเสีย",
    attachmentsName: [],
    calculation: {
      ledgers: [],
    },
    showLeft: true,
    showRight: false,
  };
  area: any = {};
  areaCondition: any = {};
  calculation: any = {
    ledgers: [],
  };
  calculations: Array<any> = [];
  areas: Array<any> = [];
  docs: Array<any> = [];
  project: any = {};
  selectedArea: string = "";
  withQR: boolean = true;
  withDate: boolean = true;
  timer: any;
  interval = 800;
  count = 0;
  isCustom: boolean = true;
  TomSelect:any
  ts1:any
  doc2:Array<any> = []
  generate(): void {
    let peeNai = this.calculation.operationYear - 1;
    let category = "ไม่ระบุ";
    try {
      category = this.areaCondition.conditions[peeNai].category;
    } catch (error) {
      console.log(error);
    }
    if(this.area.name != "แสนสุข") {
      this.isCustom = false
    } else {
      this.isCustom = true
    }
    const documentCreator = new DocumentCreator();
    const doc = documentCreator.create(
      this.area,
      this.doc,
      this.calculation,
      this.areaCondition,
      this.store.state,
      category,
      this.doc.showLeft,
      this.doc.showRight,
      this.isCustom
    );

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "ใบนำส่ง.docx");
      console.log("Document created successfully");
    });
  }
  deleteUrl(item:any,k:number){
    let con = confirm("คุณต้องการลบข้อมูลใช่หรือไม่?")
    if(con==true){
      item.documents.splice(k,1)
    }
  }
  // @Watch("doc",{deep:true})
  updateSignature() {
    let self = this;
    clearTimeout(this.timer);
    this.timer = setTimeout(doneTyping, self.interval);
    function doneTyping() {
      self.generatePDF("preview");
    }
  }

  chooseSignature() {
    const preview = this.$refs.signatureImage as HTMLImageElement;
    let myinput = this.$refs.signatureInput as HTMLInputElement;
    const file = myinput!.files![0];
    const reader = new FileReader();
    let self = this;
    reader.addEventListener(
      "load",
      function() {
        preview.src = reader.result as string;
        // console.log(reader.result);
        self.doc.signature = reader.result as string;
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  }
  mounted() {
    this.fetchAreas();
    this.TomSelect = require("tom-select")
    axios
      .post(`${address}/calculation-quarter-sum/${this.$route.params.area}/${this.calculation.calendarYear}/${this.calculation.quarter}`)
      .then(async (response) => {
        this.doc2 = response.data
      })
  }
  fetchCoverLetter() {
    if (this.$route.params.id != undefined) {
      axios
        .get(`${address}/coverletter/${this.$route.params.id}`)
        .then((response) => {
          this.doc = response.data;
          this.selectedArea = this.doc.area;
          // this.calculation = this.doc.calculation
          console.log("fetchcover", this.calculation);
          if (this.doc.approverName == undefined)
            this.doc.approverName = "นายวิรัตน์  ภมรานนท์";
          if (this.doc.approverPosition == undefined)
            this.doc.approverPosition =
              "รองผู้อำนวยการปฏิบัติการ ปฏิบัติการแทน\r\nผู้อำนวยการองค์การจัดการน้ำเสีย";
          this.fetchArea();
        });
    }
  }
  saveFinal() {
    if (this.$route.params.id != undefined)
      axios
        .patch(`${address}/coverletter/${this.$route.params.id}`, {
          ...this.doc,
          calculation: this.calculation,
          calculationId: this.calculation._id,
          operationYear: this.calculation.operationYear,
          calendarYear: this.calculation.calendarYear,
          quarter: this.calculation.quarter,
          // area: this.area._id,
          // area: this.selectedArea,
          areaCondition: this.areaCondition,
          isPrint: true,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
        });
    else
      axios
        .post(`${address}/coverletter`, {
          ...this.doc,
          calculation: this.calculation,
          calculationId: this.calculation._id,
          operationYear: this.calculation.operationYear,
          calendarYear: this.calculation.calendarYear,
          quarter: this.calculation.quarter,
          //area: this.area._id,
          // area: this.selectedArea,
          areaCondition: this.areaCondition,
          isPrint: true,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
        });
  }
  submitData() {
    if (this.$route.params.id != undefined) {
      let prep = {
        ...this.doc,
        calculation: this.calculation,
        calculationId: this.calculation._id,
        operationYear: this.calculation.operationYear,
        calendarYear: this.calculation.calendarYear,
        quarter: this.calculation.quarter,
        // area: this.area._id,
        // area: this.selectedArea,
        areaCondition: this.areaCondition,
      };
      axios
        .patch(`${address}/coverletter/${this.$route.params.id}`, prep)
        .then((response) => {
          console.log(this.doc);
          alert("บันทึกข้อมูลแล้ว");
        });
    } else
      axios
        .post(`${address}/coverletter`, {
          ...this.doc,
          calculation: this.calculation,
          calculationId: this.calculation._id,
          operationYear: this.calculation.operationYear,
          calendarYear: this.calculation.calendarYear,
          quarter: this.calculation.quarter,
          // area: this.area._id,
          // area: this.selectedArea,
          areaCondition: this.areaCondition,
        })
        .then((response) => {
          alert("บันทึกข้อมูลแล้ว");
        });
  }
  tomSelectArea: any;
  fetchAreas() {
    axios.get(`${address}/areas`).then((response) => {
      this.areas = response.data;
      try {
        this.selectedArea = this.areas[1]._id;
      } catch (error) {
        console.log("no area");
      }
      this.fetchArea();
      this.fetchCoverLetter();
    });
  }
  fetchCalcalation() {
    axios
      .post(`${address}/calculations-paginate`, {
        search: {
          area: this.area._id,
          isKrob4: true,
        },
        limit: 9999,
        skip: 0,
      })
      .then((response) => {
        if (response.data != "") {
          this.calculations = response.data.docs;
          this.count += 1;
          console.log("original calculation", this.calculations);
          this.reallyGeneratePDF();
        }
      });
  }

  fetchArea() {
    axios.get(`${address}/area/${this.doc.area}`).then((response) => {
      this.area = response.data;
      this.fetchCalcalation();
      if (this.$route.params.id == undefined) {
        this.generatePDF("preview");
      }
      this.$nextTick(()=>{
        this.ts1 = new this.TomSelect("#area-select",{
          create: false
        });
      })
      axios
        .get(`${address}/areaCondition-by-field/area/${this.doc.area}`)
        .then((response) => {
          this.areaCondition = response.data;
          this.doc.subject = `แจ้งยอดเงินสมทบค่าใช้จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย ${(this
            .area.prefix ?? "") + (this.area.name ?? "")}`;
          this.doc.to = `นายกเทศมนตรี  ${(this.area.prefix ?? "") +
            (this.area.name ?? "")}`;
          this.area.contractNumber = `หนังสือ ${(
            (this.area ?? {}).contractNumber ?? ""
          ).formatThai()}`;
          this.doc.showAttachment = true;
          this.doc.showRef = true;
          this.generatePDF("preview");
          this.count += 1;
          this.reallyGeneratePDF();
        });
    });

  }

  reallyGeneratePDF() {
    if (this.count == 2) {
      this.generatePDF("preview");
    }
    setTimeout(() => {
      if (this.count < 2) {
        this.generatePDF("preview");
      }
    }, 3000);
  }

  async generatePDF(mode: string) {
    // alert(JSON.stringify(this.doc))
    console.log(1,this.calculation.contributionAmount)
    let resultArray: Array<any> = [];
    let peeNai = this.calculation.operationYear - 1;
    let category = "ไม่ระบุ";
    try {
      category = this.areaCondition.conditions[peeNai].category;
    } catch (error) {
      console.log(error);
    }
    try {
      resultArray.push(PDF.getCover(this.area, this.doc, this.calculation));
    } catch (error) {}
    console.log(2,this.calculation.contributionAmount)
    try {
      resultArray.push(
        PDF.getKrob3(
          this.area,
          this.calculation,
          category,
          this.doc.showLeft,
          this.doc.showRight
        )
      );
    } catch (error) {
      console.log("krob3", error);
    }
    console.log(3,this.calculation.contributionAmount)

    try {
      let temp = JSON.parse(JSON.stringify(this.calculation)) // หลังจาก QTN ดำเนิน PDF เสร็จสิ้นสามารถลบได้และกลับไปใช้ตัวแปร this.calculation ตรงๆ
      // alert(JSON.stringify(this.area))
      if(this.area._id == "605c62fc402bbc642fd1d96e") {
      resultArray.push(
        PDF.getKrob4Custom(
          this.area,
          temp,
          this.calculation,
          this.areaCondition,
          parseInt(this.calculation.operationYear as string) || 0,
          this.store.state,
          this.doc.showLeft,
          this.doc.showRight,
          true
        )
      );
      } else {
      resultArray.push(
        PDF.getKrob4Original(
          this.area,
          temp,
          this.doc2,
          this.areaCondition,
          parseInt(this.calculation.operationYear as string) || 0,
          this.store.state,
          this.doc.showLeft,
          this.doc.showRight,
          true
        )
      );
      }
      // resultArray.push(
      //   this.area.name=="แสนสุข" ? 
      //   PDF.getKrob4Custom(
      //     this.area,
      //     temp,
      //     this.areaCondition,
      //     parseInt(this.calculation.operationYear as string) || 0,
      //     this.store.state,
      //     this.doc.showLeft,
      //     this.doc.showRight,
      //     true
      //   ) :
      //   PDF.getKrob4Original(
      //     this.area,
      //     temp,
      //     this.areaCondition,
      //     parseInt(this.calculation.operationYear as string) || 0,
      //     this.store.state,
      //     this.doc.showLeft,
      //     this.doc.showRight,
      //     true
      //   )
      // );

    } catch (error) {
      console.log("krob4", error);
    }
    console.log(4,this.calculation.contributionAmount)
    try {
      resultArray.push(
        PDF.getKrob2(
          this.area,
          this.calculation,
          this.doc.showLeft,
          this.doc.showRight,
          true
        )
      );
    } catch (error) {
      console.log("krob2", error);
    }
    console.log(5,this.calculation.contributionAmount)
    var pdfMake = require("pdfmake/build/pdfmake.js");
    this.fonts = this.store.getters.getPDFFonts;
    this.styles = this.store.getters.getPDFStyles;
    var docDefinition = {
      pageSize: "A4",
      pageMargins: [40, 100, 40, 20],
      header: PDF.getHeader,

      images: {
        default: this.store.getters.base64Logo,
        signature:
          this.doc.signature == "" || this.doc.signature == undefined
            ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
            : this.doc.signature,
      },
      content: resultArray,
      styles: this.styles,
      watermark: !this.doc.isPrint
        ? {
            text: "ฉบับร่าง",
            angle: "-30",
            color: "#3760A0",
            opacity: 0.15,
            bold: true,
            italics: false,
          }
        : null,
      footer: (currentPage: number, pageCount: number, pageSize: any) => {
        let text = `${
          this.doc.documentNumber
        } (${currentPage
          .toString()
          .formatThai()} จาก ${pageCount.toString().formatThai()})`;
        return {
          text: text,
          alignment: "right",
          marginRight: 10,
          italics: true,
          fontSize: 10,
          relativePosition: { x: 0, y: -10 },
        };
      },
      defaultStyle: {
        fontSize: "16",
        lineHeight: 0.9,
      },
    };
    if (mode == "preview") {
      let pdfDocGenerator = pdfMake.createPdf(docDefinition, null, this.fonts);
      pdfDocGenerator.getBlob((blob: any) => {
        let blobURL = URL.createObjectURL(blob);
        this.pdfBLOB = blobURL;
      });
    } else {
      let pdfDocGenerator = pdfMake
        .createPdf(docDefinition, null, this.fonts)
        .download(`ใบนำส่ง.pdf`);
    }
  }
}
