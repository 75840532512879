import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import InvoiceReport from '@/views/report/InvoiceReport.vue'
import ReceiptReport from '@/views/report/ReceiptReport.vue'
import TaxReport from '@/views/report/TaxReport.vue'
import BalanceReport from '@/views/report/BalanceReport.vue'
import RequestReport from '@/views/report/RequestReport.vue'
import BillingDashboard from '@/views/report/BillingDashboard.vue'
import CustomerList from '@/views/billing/CustomerList.vue'
import IncomeResult from '@/views/report/IncomeResult.vue'
import ReceiptNoHistory from '@/views/billing/ReceiptNoHistory.vue'



const routes: Array<RouteRecordRaw> = [
//รายงานระบบบิลลิ่ง
 {
    path: 'dashboard',
    name: 'Dashboard',
    component: BillingDashboard,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"Dashboard",
          to:"dashboard"
        },
      ]
    }
  },
  {
    path: 'customer/list',
    name: 'CustomerList',
    component: CustomerList,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการผู้ใช้น้ำทั้งหมด",
          to:"/customer/list"
        },
      ]
    }
  },
  {
    path: 'invoice/report',
    name: 'InvoiceReport',
    component: InvoiceReport,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"ทะเบียนคุมใบแจ้งหนี้",
          to:"/invoice/report"
        },
      ]
    }
  },
  {
    path: 'receipt/report',
    name: 'ReceiptReport',
    component: ReceiptReport,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"ทะเบียนคุมใบเสร็จรับเงิน/ใบกำกับภาษี",
          to:"/receipt/report"
        },
      ]
    }
  },
  {
    path: 'balance/report',
    name: 'BalanceReport',
    component: BalanceReport,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายงานตัดยอดหนี้",
          to:"/balance/report"
        },
      ]
    }
  },
  {
    path: 'tax/report',
    name: 'TaxReport',
    component: TaxReport,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายงานภาษีขาย",
          to:"/tax/report"
        },
      ]
    }
  },
  {
    path: 'request/report',
    name: 'RequestReport',
    component: RequestReport,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"ทะเบียนคุมใบคำร้อง",
          to:"/request/report"
        },
      ]
    }
  },
  
  {
    path: 'income/result/',
    name: 'IncomeResult',
    component: IncomeResult,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"ผลการจัดเก็บค่าบริการ",
          to:"/income/result/"
        },
      ]
    }
  },
  {
    path: 'receipt/no/history',
    name: 'ReceiptNoHistory',
    component: ReceiptNoHistory,
    meta:{
      bc:[
        {
          name:"ระบบบิลลิ่ง",
          to:"/portal/landing/billing"
        },
        {
          name:"รายการประวัติเลขที่ใบเสร็จ",
          to:"/receipt/no/history"
        },
      ]
    }
  },

]

export default routes