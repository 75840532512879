import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Dashboard from '@/views/income/Dashboard.vue'
import IncomeStatus from '@/views/report/IncomeStatus.vue'
import ReportAnnualCollection from '@/views/incomeReport/ReportAnnualCollection.vue'
import ReportAnnualIncome from '@/views/incomeReport/ReportAnnualIncome.vue'
import ReportOverallIncome from '@/views/incomeReport/ReportOverallIncome.vue'
import ReportOverallCollection from '@/views/incomeReport/ReportOverallCollection.vue'

import ReportRisk from '@/views/incomeReport/ReportRisk.vue'
import ReportCollectionStatus from '@/views/incomeReport/ReportCollectionStatus.vue'
import ReportCollectionStatistic from '@/views/incomeReport/ReportCollectionStatistic.vue'
import ReportComparePlanResult from '@/views/incomeReport/ReportComparePlanResult.vue'

import ReportAreaAnnualCollection from '@/views/incomeReport/ReportAreaAnnualCollection.vue'
import ReportAreaMonthlyCollection from '@/views/incomeReport/ReportAreaMonthlyCollection.vue'
import RequestReport from '@/views/incomeReport/RequestReport.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: 'income/dashboard',
    name: 'Income Dashboard',
    component: Dashboard,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"Dashboard",
          to:"income/dashboard"
        },
      ]
    }
  },
  {
    path: 'income/status',
    name: 'IncomeStatus',
    component: IncomeStatus
  },
  
  {
    path: 'income-report/risk',
    name: 'ReportRisk',
    component: ReportRisk,
    meta:{
      bc:[
        {
          name:"สรุปผล ปัญหา อุปสรรค/แนวทางแก้ไข",
          to:"/portal/income/dashboard"
        },
      ]
    }
  },
  {
    path: 'income-report/collectionstatus',
    name: 'ReportCollectionStatus',
    component: ReportCollectionStatus,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"สรุปสถานะการจัดเก็บรายได้",
          to:"/portal/income/dashboard"
        },
      ]
    }
  },
  {
    path: 'income-report/collectionstatistic',
    name: 'ReportCollectionStatistic',
    component: ReportCollectionStatistic,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"สถิติผลการจัดเก็บรายได้ที่ผ่านมา",
          to:"/portal/income/dashboard"
        },
      ]
    }
  },
  {
    path: 'income-report/compareplanresult',
    name: 'ReportComparePlanResult',
    component: ReportComparePlanResult,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"เปรียบเทียบแผน/ผลรายเดือน",
          to:"/portal/income/dashboard"
        },
      ]
    }
  },
  {
    path: 'income-report/areamonthly',
    name: 'ReportAreaMonthlyCollection',
    component: ReportAreaMonthlyCollection,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"ตารางแสดงแผนผลการจัดเก็บรายได้ค่าบริหารจัดการระบบบำบัดนำเสียประจำปีงบประมาณ",
          to:"/portal/cost/list"
        },
      ]
    }
  },
  {
    path: 'income-report/areaannual',
    name: 'ReportAreaAnnualCollection',
    component: ReportAreaAnnualCollection,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
        {
          name:"ตารางแสดงแผนผลการเรียกเรียกเก็บรายได้ที่ผ่านมา",
          to:"/portal/cost/list"
        },
      ]
    }
  },



  {
    path: 'income-report/annual/collection',
    name: 'ReportAnnualCollection',
    component: ReportAnnualCollection,
    meta:{
      bc:[
        {
          name:"แผนการจัดเก็บรายได้ประจำปีงบประมาณ",
          to:"/portal/cost/list"
        },
      ]
    }
  },
  {
    path: 'income-report/annual/income',
    name: 'ReportAnnualIncome',
    component: ReportAnnualIncome,
    meta:{
      bc:[
        {
          name:"รายได้ประจำปีงบประมาณ",
          to:"/portal/cost/list"
        },
      ]
    }
  },
  {
    path: 'income-report/overall/income',
    name: 'ReportOverallIncome',
    component: ReportOverallIncome,
    meta:{
      bc:[
        {
          name:"ภาพรวมรายได้ ณ วันที่",
          to:"/portal/cost/list"
        },
      ]
    }
  },
  {
    path: 'income-report/overall/collection',
    name: 'ReportOverallCollection',
    component: ReportOverallCollection,
    meta:{
      bc:[
        {
          name:"ตารางแผน/ผลการเรียกเรียกเก็บรายได้ที่ผ่านมา",
          to:"/portal/cost/list"
        },
      ]
    }
  },
  {
    path: 'income/report',
    name: 'RequestReport 2',
    component: RequestReport,
    meta:{
      bc:[
        {
          name:"ระบบจัดเก็บรายได้",
          to:"/portal/landing/income"
        },
      ]
    }
  },
]

export default routes