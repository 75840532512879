
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import address from "@/variable"
@Options({
  components: {
  },
  props: {
  },
})
export default class ContractList extends Vue {
  store = useStore();
  docs: Array<any> =[];
  show: number = 99999;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 0;
  search: any = {};
  updatePaginate(i: number) {
    this.page = i;
    console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
   fetchData(){
    let query ={
      search: {
        $or:[
          { code: this.search.text!=undefined?{ $regex: this.search.text, $options: '-i' }:undefined },
          { name: this.search.text!=undefined?{ $regex: this.search.text, $options: '-i' }:undefined },
        ]
      },
      limit: this.show,
      skip: this.page*this.show,
      sort:{
        category:1,
        code:1,
      }
    };
    axios.post(`${address}/costcodes-paginate`, query).then((response) => {
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
      console.log(this.search.code)
    });
  }
  mounted() {
    this.fetchData();
  }
}
