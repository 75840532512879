
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import address, {filePath} from "@/variable"
import Sortable from "sortablejs";
import draggable from 'vuedraggable'
@Options({
  components: {
    draggable
  },
  props: {
  },
})
export default class ContractList extends Vue {
  store = useStore();
  filePath = filePath;
  docs: Array<any> =[];
  show: number = 9999;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 5;
  search: any = {};

  saveOrder(){
    this.docs.forEach((element,index)=>{
      element.order = index + 1;
    })
    this.docs.forEach((element,index)=>{
      axios.patch(`${address}/area/${element._id}`,{
        order: element.order
      }).then(res=>{
        console.log(res.data);
      })
    })
  }

  fetchData() {
    let query = {
      search: {
        $and:[
          {
            name:
            this.search.name!=undefined
            ?{ $regex: this.search.name, $options: '-i' }
            :undefined },
        ]
      },
      limit: this.show,
      skip: this.page * this.show,
      sort:{ order: 1, createdAt: -1 },
      // sort: { number: 1 },
    };
    console.log(query)
    axios.post(`${address}/areas-paginate`, query).then((response) => {
      console.log(response.data)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }

  convertToThaiDate(data: string) {
    let newDate = new Date(data);
    let thaiString = newDate.toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    if (thaiString == "Invalid Date") return "-";
    else return thaiString;
  }
  mounted() {
    this.fetchData();
  }
  updatePaginate(i: number) {
    this.page = i;
    console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
}
