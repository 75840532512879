
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    'onUpdate:modelValue':Function,
    nomargin: Boolean,
    modelValue: String,
    placeholder: String,
    dataset: Array,
    selected: String,
    value: String,
    icon: String,
    label: String,
  },
})
export default class JMSelect extends Vue {
  dataset!: Array<string>;
  icon!: string;
  label!: string;
  value!: string;
  modelValue!: string;
  selected: string = "";
  isActive: boolean = false;
  mounted() {
    this.selected = this.modelValue;
  }
  updated() {
    this.selected = this.modelValue;
  }
  clicked() {
    this.isActive = !this.isActive;
  }
  selectedItem = (ev:any) => {
    console.log(ev.target.value)
    this.$emit("update:modelValue", ev.target.value);
    this.$emit("change",ev.target.value)
  };
}
