
import { Options, Vue } from "vue-class-component";
import Axios from "axios";
import address from "@/variable";

@Options({
  components: {},
  props: {
    modelValue: Array,
    folder: String,
    label: String,
  },
})
export default class JMUpload extends Vue {
  folder!: string;
  label!: string;
  uploadPercentage: number = 0;

  submitfile(ev: InputEvent) {
    let myinput = this.$refs[`myfile`] as HTMLInputElement;
    var file = myinput!.files![0];
    var fileSize = file.size / 1024;
    console.log("ขนาดไฟล์" + fileSize);
    if (fileSize > 250 * 1024) {
      alert("ไฟล์มีขนาดมากกว่า 250 MB กรุณาเลือกไฟล์ใหม่");
    } else {
      const self = this;
      const formData = new FormData();
      formData.set("name", `${this.folder}`);
      formData.append("file", file);
      Axios.post(`${address}/upload/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function(progressEvent: ProgressEvent) {
          self.uploadPercentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        }.bind(self),
      })
        .then(function(response: any) {
          self.$emit("update:modelValue", response.data.path);
          self.$emit("change", response.data.path);
          console.log(response);
        })
        .catch(function(err: Error) {
          console.log(err);
        });
    }
  }
}
