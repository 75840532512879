
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";
import axios from "axios";
import address from "@/variable";
import * as _ from "lodash";
import { findIndex } from "lodash";
@Options({})
export default class CustomerList extends Vue {
  store = useStore();
  docs: Array<any> = [];
  doc: any = {};
  id: string = "";
  tags: Array<string> = ["Invoice","Receipt",];
  get sorted() {
    let filterTags = this.docs.filter((doc) => {
      let index = this.tags.findIndex(tag=>tag==doc.name)
      return index != -1
    })
    let filter = filterTags.filter((o) => {
      return o.year > 2500;
    });
    let map = filter.map((o) => {
      return {
        ...o,
        sortby: `${o.name}${o.year}`,
      };
    });
    let sort = _.sortBy(map, "sortby");
    return sort;
  }
  updateTags(tag:string){
    let foundIndex = this.tags.findIndex(item => {
      return item == tag 
    })
    if(foundIndex != -1){
      this.tags.splice(foundIndex,1)
    }
    else{
      this.tags.push(tag)
    }
    // console.log(tag)
  }
  nextSequence(){
    var num:Number = ((this.doc.sequence??0)+1)
    if(this.doc.name==="Receipt")
      return num.toString().padStart(6,"0")
    else
      return num.toString().padStart(7,"0")
  }

  @Watch("id")
  getData() {
    axios.get(`${address}/counter/${this.id}`).then((response) => {
      this.doc = response.data;
    });
  }
  patchCounter() {
    let con = confirm("คุณต้องการแก้ไขตัวรันเลขใช่หรือไม่?");
    if (con == true) {
      axios
        .patch(`${address}/counter/${this.id}`, this.doc)
        .then((response) => {
          this.fetchData();
        });
    }
  }

  getCount(meter: string, sequence: number) {}

  resetData(id: string) {
    let con = confirm("คุณต้องการรีเซ็ตเลขใช่หรือไม่?");
    if (con == true) {
      axios
        .patch(`${address}/counter/${id}`, { sequence: this.doc.sequence })
        .then((response) => {
          this.fetchData();
        });
    }
  }
  fetchData() {
    axios.get(`${address}/counters`).then((response) => {
      console.log(response.data);
      this.docs = response.data;
    });
  }
  mounted() {
    this.fetchData();
  }
}
