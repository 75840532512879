
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";

@Options({
  components: {
  },
  props: {
  },
})
export default class ReceiptList extends Vue {
  store = useStore()
  docs:Array<any> = [];
  isSigned:boolean = true
  isActive:boolean = false
  mounted(){
    // this.getData();
    this.getData();
  }
  getData() {
    axios.get(`${address}/users`).then(response=>{
      this.docs = response.data
    })
  }

  changeActive(){
    this.docs.forEach(el=>{
      el.active = !this.isActive
    })
  }
}
