import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import { DateTime } from "luxon";

import "@popperjs/core";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "@/extensions/date.extension";
import "@/extensions/string.extension";
import "@/extensions/number.extension";
import JMCalendar from "@/components/JMCalendar.vue";
import JMUpload from "@/components/JMUpload.vue";
import JMUploads from "@/components/JMUploads.vue";
import JMBreadcrumbs from "@/components/JMBreadcrumbs.vue";
import JMInput from "@/components/JMInput.vue";
import JMNumber from "@/components/JMNumber.vue";
import JMNumberLite from "@/components/JMNumberLite.vue";
import JMPaginateSelect from "@/components/JMPaginateSelect.vue";
import JMSign from "@/components/JMSign.vue";
import JMUser from "@/components/JMUser.vue";
import JMSelect from "@/components/JMSelect.vue";
import JMBillingsearch from "@/components/JMBillingsearch.vue";
import JMIncomeSearch from "@/components/JMIncomeSearch.vue";
import JMIncomeStepSearch from "@/components/JMIncomeStepSearch.vue";
import JMRichText from "@/components/JMRichText.vue";
import JMSign2 from "@/components/JMSign2.vue";
import StepBilling from "@/components/StepBilling.vue";
import StepIncome from "@/components/StepIncome.vue";

import NotLogin from "@/components/NotLogin.vue";
import NoAccess from "@/components/NoAccess.vue";

import Krob4Header from "@/components/Krob4Header.vue";

// import money from 'v-money3'

const app = createApp(App)
  .use(store)
  .use(router)
  

app.component("JMCalendar", JMCalendar);
app.component("JMUpload", JMUpload);
app.component("JMUploads", JMUploads);
app.component("JMBreadcrumbs", JMBreadcrumbs);
app.component("JMInput", JMInput);
app.component("JMNumber", JMNumber);
app.component("JMNumberLite", JMNumberLite);
app.component("JMPaginateSelect", JMPaginateSelect);
app.component("JMSign", JMSign);
app.component("JMUser", JMUser);
app.component("JMSelect", JMSelect);
app.component("JMBillingsearch", JMBillingsearch);
app.component("JMIncomeSearch", JMIncomeSearch);
app.component("JMIncomeStepSearch", JMIncomeStepSearch);
app.component("JMRichText", JMRichText);
app.component("JMSign2", JMSign2);
app.component("StepBilling", StepBilling);
app.component("StepIncome", StepIncome);
app.component("NotLogin", NotLogin);
app.component("NoAccess", NoAccess);
app.component("Krob4Header", Krob4Header);


app.mount("#app");
