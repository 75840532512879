
import address from "@/variable";
import axios from "axios";
import { DateTime } from "luxon";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";
import PDF from "@/pdf";
@Options({})
export default class AlbumExample extends Vue {
  store = useStore();
  pdfBLOB = "";
  fonts: any = {};
  styles: any = {};
  doc: any = {
    documentNumber: "มท.",
    subject: "",
    issueDate: new Date(),
    content: `ตามหนังสือที่อ้างถึง องค์การจัดการน้ําเสีย ได้แจ้งยอดเงินสมทบค่าใช้จ่ายในการ .... \r\n\r\n\tจึงเรียนมาเพื่อโปรดพิจารณา ขอขอบคุณมา ณ โอกาส นี้`,
    approverName: "นายวิรัตน์  ภมรานนท์",
    approverPosition:
      "รองผู้อำนวยการปฏิบัติการ ปฏิบัติการแทน\r\nผู้อำนวยการองค์การจัดการน้ำเสีย",
    attachmentsName: [],
    calculation: {
      ledgers: [],
    },
  };
  area: any = {};
  areaCondition: any = {};
  calculation: any = {
    ledgers: [],
  };
  calculations: Array<any> = [];
  areas: Array<any> = [];
  docs: Array<any> = [];
  project: any = {};
  selectedArea: string = "";
  withQR: boolean = true;
  withDate: boolean = true;

  @Watch("doc.signature")
  updateSignature() {
    this.generatePDF("preview");
  }
  mounted() {
    this.fetchAreas();
  }
  fetchAreas() {
    axios.get(`${address}/areas`).then((response) => {
      this.areas = response.data;
      this.selectedArea = this.areas[1]._id;
      this.fetchArea();
      this.fetchData();
    });
  }
  fetchData() {
    console.log(`${address}/coverletter/${this.$route.params.id}`);
    axios
      .get(`${address}/coverletter/${this.$route.params.id}`)
      .then((response) => {
        if (response.data != "") {
          this.doc = response.data;
          this.calculation = response.data.calculation;
          console.log("calculation", this.calculation);
          this.generatePDF("preview");
        }
      });
  }

  fetchArea() {
    axios.get(`${address}/area/${this.selectedArea}`).then((response) => {
      this.area = response.data;
      axios
        .get(`${address}/areaCondition-by-field/area/${this.selectedArea}`)
        .then((response) => {
          this.areaCondition = response.data;
          this.doc.subject = `แจ้งยอดเงินสมทบค่าใช้จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย ${this.area.prefix+this.area.name}`;
          this.doc.to = `นายกเทศมนตรี ${this.area.prefix+this.area.name}`;
          // this.getData();
        });
    });
  }

  async generatePDF(mode: string) {
    let mm = (u: number) => {
      return u / 0.352777778;
    };
    var pdfMake = require("pdfmake/build/pdfmake.js");
    this.fonts = this.store.getters.getPDFFonts;
    this.styles = this.store.getters.getPDFStyles;
    console.log("this.areaCondition", this.areaCondition)
    var docDefinition = {
      pageSize: "A4",
      pageMargins: [40, 100, 40, 20],
      header: PDF.getHeader,

      images: {
        default: this.store.getters.base64Logo,
        signature:
          this.doc.signature == "" || this.doc.signature == undefined
            ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
            : this.doc.signature,
      },
      content: [
        PDF.getCover(this.area, this.doc, this.calculation),
        PDF.getKrob3(this.area, this.calculation),
        this.area.name=="แสนสุข"?PDF.getKrob4Custom(this.area, this.doc, this.calculation, this.areaCondition):PDF.getKrob4Original(this.area, this.doc, this.calculation, this.areaCondition),
        PDF.getKrob2(this.area, this.calculation,false),
      ],
      styles: this.styles,
      // watermark: {
      //   text: "ฉบับร่าง",
      //   angle: "-30",
      //   color: "#3760A0",
      //   opacity: 0.15,
      //   bold: true,
      //   italics: false,
      // },
      footer: (currentPage: number, pageCount: number, pageSize: any) => {
        let text = `${
          this.doc.documentNumber
        } (${currentPage
          .toString()
          .formatThai()} จาก ${pageCount.toString().formatThai()})`;
        return {
          text: text,
          alignment: "right",
          marginRight: 10,
          italics: true,
          fontSize: 10,
          relativePosition: { x: 0, y: -10 },
        };
      },
      defaultStyle: {
        fontSize: "16",
        lineHeight: 0.9,
      },
    };
    if (mode == "preview") {
      let pdfDocGenerator = pdfMake.createPdf(docDefinition, null, this.fonts);
      pdfDocGenerator.getBlob((blob: any) => {
        let blobURL = URL.createObjectURL(blob);
        this.pdfBLOB = blobURL;
      });
    } else {
      let pdfDocGenerator = pdfMake
        .createPdf(docDefinition, null, this.fonts)
        .download(`ใบนำส่ง.pdf`);
    }
  }
}
