
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import address from "@/variable"
import { Watch } from "vue-property-decorator";
@Options({})
export default class CustomerForm extends Vue {
  store = useStore();
  doc:any ={};
  
  updateInvoiceAmount(){
    let amount:number =  this.doc.rate * this.doc.qty
    let vatAmount:number = amount * parseFloat((1+(this.doc.vatRate??0.07)))
    // let vatAmount:number = amount + this.doc.vat
    console.log(typeof amount, typeof parseFloat((1+(this.doc.vatRate??0.07))))
    this.doc.invoiceAmount = parseFloat(vatAmount.toFixed(2))
  }
  deleteData(){
    let con = confirm("คุณต้องการลบข้อมูลใช่หรือไม่?")
    if(con==true){
      axios.delete(`${address}/receipt/${this.$route.params.id}`).then(response=>{
        this.$router.push('/portal/receipt/list')
      })
    }
  }
  getData() {
    axios.get(`${address}/receipt/${this.$route.params.id}`).then((response) => {
      this.doc = response.data;
      this.doc.paymentAmount = this.doc.paymentAmount
      this.doc.invoiceAmount = this.doc.invoiceAmount
      this.doc.debtAmount = this.doc.debtAmount
      
    });
  }
  postData(){
    if(this.$route.params.id!=undefined){
    axios.patch(`${address}/receipt/${this.$route.params.id}`, this.doc).then(response=>{
      console.log(response)
      alert("แก้ไขข้อมูลแล้ว!")
      this.$router.push('/portal/receipt/list')
    });
    }else{
    axios.post(`${address}/receipt`, this.doc).then(response=>{
      console.log(response)
      alert("บันทึกข้อมูลแล้ว!")
      this.$router.push('/portal/receipt/list')
    });
    }
  }
  mounted() {
    this.getData();
  }
}
