
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import address from "@/variable";
import axios from "axios";
import Excel from "exceljs/dist/es5/exceljs.browser";
import { DateTime } from "luxon";

@Options({
  components: {},
})
export default class PaymentImport extends Vue {
  store = useStore();
  docs: Array<any> = [];
  meter: string = "";
  code: string = "";
  invoiceAmount: string = "";
  year: number = 0;
  month: number = 0;
  meterDatas: Array<any> = [];
  paidSum:number = 0;
  active:number = -1
  percent = 0.0
  uploadPercent = 0.0
  duplicateCount = 0
  original:any = {}
  get totalInvoiceAmount(){
    try {
    let map = this.sorted.map(el=>Number(el.invoiceAmount??0))
    let reduce = map.reduce((a,b)=>a+b,0)
    return reduce
    } catch (error) {
      return 0
    }
  }
  get totalAmount(){
    try {
    let map = this.sorted.map(el=>Number(el.paymentAmount??0))
    let reduce = map.reduce((a,b)=>a+b,0)
    return reduce
    } catch (error) {
      return 0
    }
  }
  get totalCount(){
    try {
    return this.sorted.length
    } catch (error) {
      return 0
    }
  }
  get sorted(){
    let docs = this.docs
    docs = docs.map((doc)=>({...doc, yearMonth:parseInt(`${doc.invoiceYear}${String(doc.invoiceMonth).padStart(2,'0')}`)}))
    docs = docs.sort((a,b)=>a.excelNum-b.excelNum||parseInt(a.yearMonth)-parseInt(b.yearMonth))
    // docs = docs.sort((a,b)=>a.excelNum-b.excelNum)
    return docs
  }
  get summation() {
    let result = this.meterDatas
      .filter(el=>el.isChecked)
      .map((el) => (el.totalAmount))
      .reduce((a: number, b: number) => a + b, 0);
      result = result*100
      result = Math.floor(result)
      result = result/100
    return result
  }
  checkDuplicate(item:any){
    // console.log(item,this.sorted)
    try {
    let filter = this.sorted.filter(el=>el.meter===item.meter&&el.year===item.year&&el.month===item.month)
    return filter.length>1
    } catch (error) {
      return false
    }
  }
  async importExcel(ev: any) {
    this.duplicateCount = 0
    const Excel = require("exceljs/dist/es5/exceljs.browser");
    let self = this;
    console.log(ev);
    let file = ev.target.files[0];
    const workbook = new Excel.Workbook();
    let book = await workbook.xlsx.load(file);
    let sheet = book.getWorksheet("Sheet1");
    let count = sheet.rowCount;
    let arr: Array<any> = [];
    let result: Array<any> = [];
    let promises: Array<Promise<any>> = [];

    sheet.eachRow(function(row: any, rowNumber: any) {
      if (rowNumber > 1) {
        console.log(row.getCell(2));
        console.log(row.getCell(2).value instanceof Date);
        let time = DateTime.fromJSDate(row.getCell(2).value)
          .plus({ hours: -7 })
          .toFormat("HHmm");
        let timeInt = parseInt(time);
        arr.push({
          receiptSequence: row.getCell("M").value,
          paymentDate: row.getCell("A").value,
          meter: row.getCell("B").value,
          name: row.getCell("C").value,
          address: row.getCell("D").value,
          paymentAmount: row.getCell("E").value,
          year: row.getCell("F").value,
          month: row.getCell("G").value,
          calculationType: row.getCell("H").value,
          excelNum:rowNumber,
          isNextStage:false
        });
      }
    });
    let countFind = 1
    for(const el of arr){
      let data:any = {}
      self.percent = (countFind++/arr.length)*100
      try {
        let invoiceResponse = await axios.post(`${address}/invoice-find`,{
          search: {
            meter: el.meter,
            year: el.year,
            month: el.month,
          }
        })
        data = invoiceResponse.data
      } catch (error) {
        
      }
      result.push({
        ...el,
        ...data,
        invoiceAmount: data.totalAmount,
        invoiceNumber:data.sequence,
        invoiceYear:data.year,
        invoiceMonth:data.month,
      })
    }
    this.docs = result;
  }
  getDataForMeter(meter: string) {
    console.log("getting for meter")
    let query: any = {
      search: {
        meter: meter,
        isPaid: false,
      },
      limit: 200,
      skip: 0,
      sort:{
        sequence:-1
      }
    };
    axios.post(`${address}/invoices-paginate-light`, query).then((response) => {
      this.meterDatas = response.data.docs;
    });
  }
  insertRecord(){
    let original = this.original//this.docs[this.active]
    let originalPayment = original.paymentAmount
    let foundIndex = this.docs.findIndex(doc=>doc._id===original._id)
    // original.paymentAmount = original.invoiceAmount
    let filter = this.meterDatas.filter(el=>el.isChecked)
    let newRecords = filter.map(el=>{
      return {
        ...el,
        invoiceMonth:el.month,
        invoiceYear:el.year,
        excelNum:original.excelNum,
        paymentDate: DateTime.fromJSDate(original.paymentDate).plus({hours:7}).toJSDate(),
        meter: original.meter,
        invoiceNumber: el.sequence,
        address: el.address,
        name: el.name,
        paymentAmount: el.totalAmount,
        invoiceAmount: el.totalAmount,
        calculationType: el.calculationType,
        isNextStage: false,
        done: true,
      }
    })
    let totalNewRecord = newRecords.map(el=>el.paymentAmount).reduce((a,b)=>a+b,0)
    // let lastPaymentAount = original.paymentAmount - totalNewRecord
    // newRecords[newRecords.length - 1].paymentAmount = newRecords[newRecords.length - 1].paymentAmount + lastPaymentAount
    if(typeof original.invoiceNumber === 'undefined') {
      try { this.docs.splice(foundIndex,1)} catch (error) { }
      let upper = Object.assign([],newRecords) as Array<any>
      let upperRecord = upper.map(el=>el.paymentAmount).reduce((a,b)=>a+b,0)
      console.log("calc", originalPayment,upperRecord,originalPayment - upperRecord, newRecords)
      newRecords[newRecords.length-1].paymentAmount += parseFloat((originalPayment - upperRecord).toFixed(2))
    } else {
      this.docs[foundIndex].paymentAmount = originalPayment - totalNewRecord // ใช้ไม่ได้ กรณีที่ไม่ได้เลือกเดือนมา
    }
    this.docs.push(...newRecords)
    
    //@ts-ignore
    this.$refs.close.click()
  }

  async postData() {
    let countUpload = 1
    this.uploadPercent = 0.0
    for(const item of this.sorted){
      console.log(item)
      try {
        this.uploadPercent = (countUpload++/this.sorted.length)*100
        
        let response = await axios.post(`${address}/payment-upsert`, item)
      //   console.log(response.data)
      } catch (error) {
        console.log("error!", error);
      }
    }
    alert("ส่งข้อมูลไปยังแม่ข่ายแล้ว" + `${countUpload-1}/${this.sorted.length} รายการ`);
    // this.sorted.forEach((item) => {
    //   console.log("item", item);
    //   axios.post(`${address}/payment-upsert`, item).then((response) => {
    //     console.log(response);
    //   });
    // });
    // this.$router.push("/portal/payment/list");
  }
}
