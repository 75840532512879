
import address from "@/variable";
import { beforeWrite } from "@popperjs/core";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";
import * as _ from "lodash"
import { forEach } from "lodash";

@Options({
  components: {},
  props: {},
})
export default class Krob4Form extends Vue {
  store = useStore()
  doc:any = {}
  originalDoc:any = {}
  area:any = {}
  conditions:any = {}
  condition:any = {}
  calculation:Array<any> =  new Array(30)
  calculation1:Array<any> = new Array(30)
  calculation2:Array<any> = new Array(30)
  year:number = 2564
  operation:number = 1
  quarter: number = 1
  debug:string = ""
  percentResult:Array<any> = []
  oldeContributionAmount:Array<any> = []
  oldContributionAmount:Array<any> = []
  doc2:any = {
    lean:[]
  }
  get sortedDebug (){
    return this.doc2.lean
      .sort((a:any,b:any)=>a.quarter-b.quarter)
      .map((d2:any)=>({calendarYear:d2.calendarYear,quarter:d2.quarter,contributionAmount:d2.contributionAmount,eContributionAmount:d2.eContributionAmount}))
  }

  get finalPayment(){
    let remaining = (this.condition.contributionLimit??0) - (this.doc2.sumFinal) - ((this.doc.totalExpenses??0) * ((this.condition.contributionPercent??100)/100))
    let eRemaining = (this.condition.contributionLimit??0) - (this.doc2.sumFinal) - ((this.doc.eTotalExpenses??0) * ((this.condition.contributionPercent??100)/100))
    if(remaining < 0){
      remaining = 0
    }
    if(eRemaining < 0){
      eRemaining = 0
    }
    this.doc.remaining = this.isEndorsed?0:remaining
    this.doc.eRemaining = eRemaining
    return {
      remaining,
      eRemaining
    }
  }

  updateCondition(){
    this.condition.category = this.debug
    this.categoryCalculation()
  }
  
  get isVat(){
    let result = this.calculation1[this.calculation1.length-1]
    if(this.doc.isVat){
       this.calculation1[this.calculation1.length-1] = this.calculation1[this.calculation1.length-2] * 0.07
       result =  this.calculation1[this.calculation1.length-2] * 1.07
    } 
    let sum = 0;
    (this.doc.modifications??[]).forEach((mod:any) => {
      if(mod.symbol == "+") sum += mod.amount??0
      else if(mod.symbol == "-") sum -= mod.amount??0
    })
    this.doc.contributionAmount = result + sum
    return this.doc.contributionAmount
  }
  get iseVat(){
    if(this.finalPayment.eRemaining<=0) this.calculation2[this.calculation2.length-2] = (this.condition.contributionLimit??0) - (this.doc2.sumFinal)
    let result = this.calculation2[this.calculation2.length-2]
    if(this.doc.isVat){
      this.calculation2[this.calculation2.length-1] = this.calculation2[this.calculation2.length-2] * 0.07
      result =  result * 1.07
    } 
    let eSum = 0;
    (this.doc.modifications??[]).forEach((mod:any) => {
      if(mod.symbol == "+") eSum += mod.eAmount??0
      else if(mod.symbol == "-") eSum -= mod.eAmount??0
    })
    this.doc.eContributionAmount = result + eSum
    return this.doc.eContributionAmount
  }

// (this.condition.contributionLimit??0) - (this.doc2.sumFinal)
  checkModified(){
    console.log(this.doc.modifications)
    if((this.doc.modifications??[]).length>0){
      let sum = 0
      let eSum = 0
      let pSum = 0
      let peSum = 0
      this.doc.modifications.forEach((mod:any) => {
        if(mod.symbol == "+"){
          sum += mod.amount??0
          eSum += mod.eAmount??0
        }
        else if(mod.symbol == "-"){
          sum -= mod.amount??0
          eSum -= mod.eAmount??0
        }
      });
      console.log(sum,eSum)
      this.doc.contributionAmount = sum + this.calculation1[this.calculation1.length-1] // was this.originalDoc
      this.doc.eContributionAmount = eSum + this.calculation2[this.calculation2.length-1] // was this.originalDoc
    }else{
      if(this.condition.category == 'สมทบตามเปอร์เซ็นต์'){
        this.doc.contributionAmount = (this.calculation1[this.calculation1.length-1]??0) - (this.doc.advanceAmount??0) // was this.originalDoc
        this.doc.eContributionAmount = (this.calculation2[this.calculation2.length-1]??0) - (this.doc.eAdvanceAmount??0)  // was this.originalDoc
      }
    }
  }

  get isEndorsed(){
    try {
      return this.doc.endorsedLedgers.length>0
    } catch (error) {
      return false
    }
  }
  categoryCalculation(){
    console.log("cat",this.condition.category)
    switch(this.condition.category) {
      case 'สมทบตามเปอร์เซ็นต์':
        console.log("welp",this.doc.contributionAmount)
        let q = this.$route.params.quarter
        // let limit = q=='0'?this.condition.contributionLimit:this.condition.contributionLimit/4
        // if(limit < 1) limit = 9E10
        let a = (this.doc.wmaExpenses)*((this.condition.contributionPercent)/100)
        // if(a > limit) this.doc.contributionAmount = limit
        this.doc.contributionAmount = a 
        this.doc.contributionAmount = (this.doc.contributionAmount??0) -  (this.doc.advanceAmount??0)
        this.calculation1 = [
          this.condition.contributionPercent,
          this.doc.advanceAmount,
          this.doc.wmaExpenses,
          this.doc.wmaExpenses * ((this.condition.contributionPercent)/100),
          0,
          this.doc.contributionAmount,
          0,
        ]
        let b = (this.doc.eWmaExpenses)*((this.condition.contributionPercent)/100)
        // console.log(limit)
        // if(b > limit) this.doc.eContributionAmount = limit
        this.doc.eContributionAmount = b 
        this.doc.eContributionAmount = (this.doc.eContributionAmount??0) -  (this.doc.eAdvanceAmount??0)
        this.calculation2 = [
          this.condition.contributionPercent,
          this.doc.eAdvanceAmount,
          this.doc.eWmaExpenses,
          this.doc.eWmaExpenses * ((this.condition.contributionPercent)/100),
          0,
          this.doc.eContributionAmount,
          0,
        ]
        this.checkModified()
      break;
      case 'สมทบตามจำนวนคงที่':
        this.calculation1 = [
          this.doc.totalExpenses,
          this.doc.totalIncome,
          this.doc.totalExpenses - this.doc.totalIncome,
          this.doc.contributionAmount = (this.doc.totalExpenses - this.doc.totalIncome).formatFull(),
        ]
        this.calculation2 = [
          this.doc.eTotalExpenses,
          this.doc.eTotalIncome,
          this.doc.eTotalExpenses - this.doc.eTotalIncome,
          this.doc.eContributionAmount = (this.doc.eTotalExpenses - this.doc.eTotalIncome).formatFull(),
        ]
        this.checkModified()
      break;
      case 'สมทบตามค่าใช้จ่ายจริง/สัดส่วนเท่าเทียม':
        let income = 0
        let expense = 0
        if(this.doc.ledgers.length == 0){
          income = this.doc.eTotalIncome
          expense = this.doc.eTotalExpenses
        } else {
          income =  this.doc.totalIncome
          expense = this.doc.totalExpenses
        }
        let isProfit = (income - expense) > 0 //(this.doc.totalIncome-this.doc.totalExpenses)>0
        // if(isProfit) alert("กำไร")
        // else alert("ขาดทุน")
        if(isProfit){
          switch (this.condition.profitType) {
            case 1:
              this.doc.contributionAmount = ((this.doc.totalIncome - (((this.doc.totalIncome - this.doc.totalExpenses)-((this.doc.totalIncome - this.doc.totalExpenses) / (100*30)))/2))-(this.doc.advanceAmount??0))
              this.doc.contributionAmount = Math.abs(parseFloat((this.doc.contributionAmount??0).toFixed(2))) - (this.doc.advanceAmount??0)
              this.doc.contributionAmount = Math.abs(parseFloat((this.doc.contributionAmount??0).toFixed(2)))
              this.calculation1 = [
                this.doc.totalIncome,
                this.doc.totalExpenses,
                this.doc.totalIncome - this.doc.totalExpenses,
                (this.doc.totalIncome - this.doc.totalExpenses) / (100*30),
                (this.doc.totalIncome - this.doc.totalExpenses)-((this.doc.totalIncome - this.doc.totalExpenses) / (100*30)),
                ((this.doc.totalIncome - this.doc.totalExpenses)-((this.doc.totalIncome - this.doc.totalExpenses) / (100*30)))/2,
                this.doc.totalIncome - (((this.doc.totalIncome - this.doc.totalExpenses)-((this.doc.totalIncome - this.doc.totalExpenses) / (100*30)))/2),
                this.doc.advanceAmount,
                (this.doc.totalIncome - (((this.doc.totalIncome - this.doc.totalExpenses)-((this.doc.totalIncome - this.doc.totalExpenses) / (100*30)))/2))-(this.doc.advanceAmount??0),
                this.doc.contributionAmount
              ]
              this.doc.eContributionAmount = ((this.doc.eTotalIncome - (((this.doc.eTotalIncome - this.doc.eTotalExpenses)-((this.doc.eTotalIncome - this.doc.eTotalExpenses) / (100*30)))/2))-(this.doc.eAdvanceAmount??0))
              this.doc.eContributionAmount = Math.abs(parseFloat((this.doc.eContributionAmount??0).toFixed(2))) - (this.doc.eAdvanceAmount??0)
              this.doc.eContributionAmount = Math.abs(parseFloat((this.doc.eContributionAmount??0).toFixed(2)))
              this.calculation2 = [
                this.doc.eTotalIncome,
                this.doc.eTotalExpenses,
                this.doc.eTotalIncome - this.doc.eTotalExpenses,
                (this.doc.eTotalIncome - this.doc.eTotalExpenses) / (100*30),
                (this.doc.eTotalIncome - this.doc.eTotalExpenses)-((this.doc.eTotalIncome - this.doc.eTotalExpenses) / (100*30)),
                ((this.doc.eTotalIncome - this.doc.eTotalExpenses)-((this.doc.eTotalIncome - this.doc.eTotalExpenses) / (100*30)))/2,
                this.doc.eTotalIncome - (((this.doc.eTotalIncome - this.doc.eTotalExpenses)-((this.doc.eTotalIncome - this.doc.eTotalExpenses) / (100*30)))/2),
                this.doc.eAdvanceAmount,
                (this.doc.eTotalIncome - (((this.doc.eTotalIncome - this.doc.eTotalExpenses)-((this.doc.eTotalIncome - this.doc.eTotalExpenses) / (100*30)))/2))-(this.doc.eAdvanceAmount??0),
                this.doc.eContributionAmount
              ]
              this.checkModified()
            break;
            case 2:
              this.doc.contributionAmount = (this.doc.totalIncome+((this.doc.totalExpenses - this.doc.totalIncome) / 2))
              this.doc.contributionAmount = Math.abs(parseFloat((this.doc.contributionAmount??0).toFixed(2))) - (this.doc.advanceAmount??0)
              this.doc.contributionAmount = Math.abs(parseFloat((this.doc.contributionAmount??0).toFixed(2)))
              this.calculation1 = [
                this.doc.totalExpenses,
                this.doc.totalIncome,
                this.doc.totalExpenses - this.doc.totalIncome ,
                (this.doc.totalExpenses - this.doc.totalIncome) / 2,
                this.doc.totalIncome+((this.doc.totalExpenses - this.doc.totalIncome) / 2),
                this.doc.advanceAmount,
                this.doc.totalIncome+((this.doc.totalExpenses - this.doc.totalIncome) / 2)-0,
                this.doc.contributionAmount
              ]
              this.doc.eContributionAmount = (this.doc.eTotalIncome+((this.doc.eTotalExpenses - this.doc.eTotalIncome) / 2))
              this.doc.eContributionAmount = Math.abs(parseFloat((this.doc.eContributionAmount??0).toFixed(2))) - (this.doc.eAdvanceAmount??0)
              this.doc.eContributionAmount = Math.abs(parseFloat((this.doc.eContributionAmount??0).toFixed(2)))
              this.calculation2 = [
              this.doc.eTotalExpenses,
              this.doc.eTotalIncome,
              this.doc.eTotalExpenses - this.doc.eTotalIncome,
              (this.doc.eTotalExpenses - this.doc.eTotalIncome) / 2,
              this.doc.eTotalIncome+((this.doc.eTotalExpenses - this.doc.eTotalIncome) / 2),
              this.doc.eAdvanceAmount,
              this.doc.eTotalIncome+((this.doc.eTotalExpenses - this.doc.eTotalIncome) / 2)-(this.doc.eAdvanceAmount??0),
              ]
              this.checkModified()
              break;
          }
        }else{
          let percent = 100
          if(this.condition.contributionPercent==0||this.condition.contributionPercent==undefined) percent = 100
          else percent = this.condition.contributionPercent
          switch (this.condition.lossType) {
            case 1:
              this.doc.contributionAmount = (this.doc.totalIncome+((this.doc.totalExpenses - this.doc.totalIncome) / 2))
              this.doc.contributionAmount = Math.abs(parseFloat((this.doc.contributionAmount??0).toFixed(2))) - (this.doc.advanceAmount??0)
              this.doc.contributionAmount = Math.abs(parseFloat((this.doc.contributionAmount??0).toFixed(2)))
              this.doc.contributionAmount = this.doc.contributionAmount
              this.calculation1 = [
                this.doc.totalExpenses,
                this.doc.totalIncome,
                this.doc.totalExpenses - this.doc.totalIncome,
                (this.doc.totalExpenses - this.doc.totalIncome) / 2,
                this.doc.totalIncome+((this.doc.totalExpenses - this.doc.totalIncome) / 2),
                this.doc.advanceAmount,
                (this.doc.totalIncome+((this.doc.totalExpenses - this.doc.totalIncome) / 2))-0,
                this.doc.contributionAmount,
                0
              ]
              this.doc.eContributionAmount = (this.doc.eTotalIncome+((this.doc.eTotalExpenses - this.doc.eTotalIncome) / 2))
              this.doc.eContributionAmount = Math.abs(parseFloat((this.doc.eContributionAmount??0).toFixed(2))) - (this.doc.eAdvanceAmount??0)
              this.doc.eContributionAmount = Math.abs(parseFloat((this.doc.eContributionAmount??0).toFixed(2)))
              this.doc.eContributionAmount = this.doc.eContributionAmount
              this.calculation2 = [
                this.doc.eTotalExpenses,
                this.doc.eTotalIncome,
                this.doc.eTotalExpenses - this.doc.eTotalIncome,
                (this.doc.eTotalExpenses - this.doc.eTotalIncome) / 2,
                this.doc.eTotalIncome+((this.doc.eTotalExpenses - this.doc.eTotalIncome) / 2),
                this.doc.eAdvanceAmount,
                this.doc.eContributionAmount,
                0
              ]
              this.checkModified()

            break;
            case 2:
              this.doc.contributionAmount = (this.doc.totalExpenses - this.doc.totalIncome) / 2 * (percent/100)
              this.doc.contributionAmount = (this.doc.contributionAmount>this.condition.contributionLimit) ? this.condition.contributionLimit : this.doc.contributionAmount
              this.doc.contributionAmount = Math.abs(parseFloat((this.doc.contributionAmount??0).toFixed(2))) - (this.doc.advanceAmount??0)
              this.doc.contributionAmount = Math.abs(parseFloat((this.doc.contributionAmount??0).toFixed(2)))
              this.calculation1 = [
                this.doc.totalExpenses,
                this.doc.totalIncome,
                this.doc.totalExpenses - this.doc.totalIncome,
                (this.doc.totalExpenses - this.doc.totalIncome) / 2,
                this.condition.contributionLimit,
                this.condition.contributionLimit,
                this.doc.contributionAmount,
                0
              ]
              this.doc.eContributionAmount = (this.doc.eTotalExpenses - this.doc.eTotalIncome) / 2 * (percent/100)
              this.doc.eContributionAmount = (this.doc.eContributionAmount>this.condition.contributionLimit) ? this.condition.contributionLimit : this.doc.eContributionAmount
              this.doc.eContributionAmount = Math.abs(parseFloat((this.doc.eContributionAmount??0).toFixed(2))) - (this.doc.eAdvanceAmount??0)
              this.doc.eContributionAmount = Math.abs(parseFloat((this.doc.eContributionAmount??0).toFixed(2)))
              this.calculation2 = [
                this.doc.eTotalExpenses,
                this.doc.eTotalIncome,
                this.doc.eTotalExpenses - this.doc.eTotalIncome,
                (this.doc.eTotalExpenses - this.doc.eTotalIncome) / 2,
                this.condition.contributionLimit,
                this.condition.contributionLimit,
                this.doc.eContributionAmount,
                0
              ]
              this.checkModified()

            break;
            case 3:
              this.doc.contributionAmount =  (this.doc.totalIncome + ((this.doc.totalExpenses - this.doc.totalIncome) / 2))*(((this.condition.contributionPercent==0||this.condition.contributionPercent==undefined)?100:this.condition.contributionPercent)/100),
              this.doc.contributionAmount = Math.abs(parseFloat((this.doc.contributionAmount??0).toFixed(2))) - (this.doc.advanceAmount??0)
              this.doc.contributionAmount = Math.abs(parseFloat((this.doc.contributionAmount??0).toFixed(2)))
              
              this.doc.contributionAmount = this.doc.contributionAmount
              this.calculation1 = [
                this.doc.totalExpenses,
                this.doc.totalIncome,
                this.doc.totalExpenses - this.doc.totalIncome,
                (this.doc.totalExpenses - this.doc.totalIncome) / 2,
                this.doc.totalIncome + ((this.doc.totalExpenses - this.doc.totalIncome) / 2),
                this.doc.advanceAmount,
                this.doc.contributionAmount,
                0
              ]
              this.doc.eContributionAmount = (this.doc.eTotalIncome + ((this.doc.eTotalExpenses - this.doc.eTotalIncome) / 2))*(((this.condition.contributionPercent==0||this.condition.contributionPercent==undefined)?100:this.condition.contributionPercent)/100)
              this.doc.eContributionAmount = Math.abs(parseFloat((this.doc.eContributionAmount??0).toFixed(2))) - (this.doc.eAdvanceAmount??0)
              this.doc.eContributionAmount = Math.abs(parseFloat((this.doc.eContributionAmount??0).toFixed(2)))
              this.doc.eContributionAmount = this.doc.eContributionAmount

              this.calculation2 = [
                this.doc.eTotalExpenses,
                this.doc.eTotalIncome,
                this.doc.eTotalExpenses - this.doc.eTotalIncome,
                (this.doc.eTotalExpenses - this.doc.eTotalIncome) / 2,
                this.doc.eTotalIncome + ((this.doc.eTotalExpenses - this.doc.eTotalIncome) / 2),
                this.doc.eAdvanceAmount,
                this.doc.eContributionAmount,
                0
              ]
              this.checkModified()

              break;
          }
        }
    }
    // Delete Me
    /*
    if(this.doc.isVat){
      // this.calculation1[this.calculation1.length-1] = this.calculation1[(this.calculation1.length-1)] * 1.07
      // this.calculation2[this.calculation2.length-1] = this.calculation2[(this.calculation2.length-1)] * 1.07
      this.calculation1[this.calculation1.length] = this.calculation1[(this.calculation1.length-1)] * 0.07
      this.calculation2[this.calculation2.length] = this.calculation2[(this.calculation2.length-1)] * 0.07
    }
    */
  }
  get calOldeContributionAmount() {
    let result = (this.condition.contributionLimit - (this.oldeContributionAmount.reduce((a,b) => a+b ,0))).formatFull()
    return result
  }
  get calOldContributionAmount() {
    let result = (this.condition.contributionLimit - (this.oldContributionAmount.reduce((a,b) => a+b ,0)== undefined ? 0 : 0)).formatFull()
    return result
  }
  get category(){
    switch(this.condition.category) {
      case 'สมทบตามเปอร์เซ็นต์':
        return this.store.state.conditionPercent
      case 'สมทบตามจำนวนคงที่':
        return this.store.state.conditionFixed
      case 'สมทบตามค่าใช้จ่ายจริง/สัดส่วนเท่าเทียม':
        let isProfit = this.doc.totalIncome-this.doc.totalExpenses>0
        if(isProfit){
          switch (this.condition.profitType) {
            case 1:
              return this.store.state.conditionProfitType1
            case 2:
              return this.store.state.conditionProfitType2
          }
        }else{
          switch (this.condition.lossType) {
            case 1:
              return this.store.state.conditionLossType1
            case 2:
              return this.store.state.conditionLossType2
            case 3:
              return this.store.state.conditionLossType3
          }
        }
    }
    return []
  }
  mounted(){
    this.year = parseInt(this.$route.params.year as string);
    this.quarter = parseInt(this.$route.params.quarter as string);
    this.operation = parseInt(this.$route.params.operation as string);
    this.fetchArea()
    this.fetchCondition()
    switch (this.quarter) {
      case 2:
        this.percentResult = [
          {
          quarter:this.quarter-1
          },
          {
          quarter:this.quarter
          }
        ]
      break;
      case 3:
        this.percentResult = [
          {
          quarter:this.quarter-2
          },
          {
          quarter:this.quarter-1
          },
          {
          quarter:this.quarter
          }
        ]
      break;
      case 4:
        this.percentResult = [
          {
          quarter:this.quarter-3
          },
          {
          quarter:this.quarter-2
          },
          {
          quarter:this.quarter-1
          },
          {
          quarter:this.quarter
          }
        ]
      break;
    }
  }
  fetchDoc(){
    axios
      .post(`${address}/calculation-find`, {
        search: {
          area: this.$route.params.area,
          calendarYear: this.year,
          quarter:this.quarter
        },
      })
      .then(async (response) => {
        if (response.data == "") console.log("ใบใหม่!")
        else {
          this.doc = response.data
          this.originalDoc = Object.assign({},this.doc)
          this.$nextTick(()=>{
            this.categoryCalculation()
          })
        }
      })
    axios
      .post(`${address}/calculation-quarter-sum/${this.$route.params.area}/${this.year}/${this.quarter}`)
      .then(async (response) => {
        this.doc2 = response.data
      })
    // this.percentResult.forEach((el:any) => {
    //   console.log('oldquarter',el)
    //   // calculation-quarter-sum/60640c7ac426854f543f15ba/2564/4
    //   axios
    //     .post(`${address}/calculation-quarter-sum`, {
    //       search: {
    //         area: this.$route.params.area,
    //         calendarYear: this.year,
    //         quarter:el.quarter
    //       },
    //     })
    //     .then(async (response) => {
    //       this.oldeContributionAmount.push(response.data.eContributionAmount)
    //       this.oldContributionAmount.push(response.data.ContributionAmount)
    //     })
    // })
  }
  fetchArea(){
    axios
      .get(`${address}/area/${this.$route.params.area}`)
      .then(async (response) => {
        this.area = response.data
      })
  }
  fetchCondition(){
    axios
      .get(`${address}/areaCondition-by-field/area/${this.$route.params.area}`)
      .then(async (response) => {
        this.conditions = response.data.conditions
        this.condition = this.conditions[this.operation]
        this.fetchDoc()
      })
  }
  submitData() {
    let query = {
      area: this.$route.params.area,
      calendarYear: this.year,
      quarter: this.quarter,
    };
    let prep = {
      area: this.$route.params.area,
      areaCondition: this.conditions._id,
      operationYear: this.operation,
      calendarYear: this.year,
      quarter: this.quarter,
      ...this.doc,
      isKrob4: true,
    };
    axios
      .post(`${address}/calculation-upsert/`, { search: query, doc: prep })
      .then((response) => {
        alert("บันทึกข้อมูลแล้ว!");
        console.log("done!",prep);
      });
  }
  pushItem(item:any){
    if(!Array.isArray(this.doc.modifications)) this.doc.modifications = []
    this.doc.modifications.push(item)
    this.categoryCalculation()
    console.log("push")
  }
}
