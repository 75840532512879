
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon";
import { useStore } from "vuex";
import * as _ from "lodash";

@Options({
  components: {},
  props: {},
})
export default class ReceiptList extends Vue {
  store = useStore();
  docs: Array<any> = ["doc", "cat", "girrafe"];
  result: any = {};
  selected: Array<any> = [];
  show = 20;
  page = 0;
  mounted() {
    this.fetchData();
  }
  fetchData() {
    this.docs = [];
    let query = {
      search: {
        meter: "1545423",
      },
      limit: this.show,
      skip: this.page * this.show,
    };
    console.log(query);
    axios.post(`${address}/invoices-paginate`, query).then((response) => {
      this.docs = response.data.docs;
      // this.result = this.docs
      //@ts-ignore
      // this.result = _.groupBy(this.docs,'isPaid')
      // this.result = _.map(this.result,(items,item)=>{
      //   return {
      //     tookbai:items,
      //     yeakduay:item
      //   }
      // })

      this.result = _.chain(this.docs)
        .groupBy("isPaid").map((arr,el)=>el)
        // .map((items, item) => {
        //   return {
        //     // tookbai: items,
        //     yeakduay: item,
        //   };
        // });
    });
  }
}
