
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";
import JMUpload from "@/components/JMUpload.vue";
import axios from "axios";
import address, { filePath } from "@/variable";

@Options({
  components: {
    JMUpload,
  },
})
export default class ContractForm extends Vue {
  store = useStore();
  filePath = filePath;
  saving: boolean = false;
  doc: any = {};
  docs: any = {};
  conditions: Array<any> = [];
  address = address;
  thailand = require("@/data/thailand.json");
  provinceSelected: any = {};
  districtSelected: any = {};
  subDistrictSelected: any = {};

  deleteData(){
    let con = confirm("คุณต้องการลบข้อมูลใช่หรือไม่?")
    if(con==true){
      axios.delete(`${address}/area/${this.$route.params.id}`).then(response=>{
        this.$router.push('/portal/area/list')
      })
    }
  }
  
  
  updatePostal() {
    let found = this.subDistricts.find(
      (el: any) => el.name.th == this.doc.subDistrict
    ) as any;
    if (found != undefined) this.doc.postal = found.zipcode;
    else this.doc.postal = "";
  }
  get districts() {
    let found = Object.entries(this.thailand)
      .map((el) => el[1])
      .find((el: any) => el.name.th == this.doc.province) as any;
    if (found != undefined)
      return Object.entries((found || {}).amphoes).map((el) => el[1]);
    else return [];
  }
  get subDistricts() {
    let found = this.districts.find(
      (el: any) => el.name.th == this.doc.district
    ) as any;
    if (found != undefined)
      return Object.entries((found || {}).tambons).map((el) => el[1]);
    else return [];
  }
  getData() {
    axios.get(`${address}/area/${this.$route.params.id}`).then((response) => {
      this.doc = response.data;
    });
  }
  submit() {
    if (this.$route.params.id != undefined) this.patchData();
    else this.postData();
  }
  postData() {
    axios.post(`${address}/area`, {
      ...this.doc,
      createdAt:new Date()
      }).then((response) => {
      console.log(response);
      alert("สำเร็จ!");
    });
  }
  patchData() {
    this.saving = true;
    axios
      .patch(`${address}/area/${this.$route.params.id}`, {
        ...this.doc,
        _id: undefined,
      })
      .then((response) => {
        console.log(response);
        this.saving = false;
        alert("สำเร็จ!");
      });
  }
  updateTambon(){


    let TomSelect = require("tom-select")
    this.$nextTick(()=>{

      new TomSelect("#tambon-select",{
        create: true
      });
    })
  }
  mounted() {
    if (this.$route.params.id != undefined) this.getData();
  }
}
