
import { Options, Vue  } from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Options({
  components: {},
  props: {
    modelValue: [String, Number, Array],
    label: String,
    inputType: String,
    icon: String,
    placeholder: String,
    value: String,
    allowed: String,
    isDisabled: Boolean,
    nomargin: Boolean,
    white: Boolean,
    withDelete: Boolean,
    suffix: String,
    "onUpdate:modelValue": Function,
  },
  
})
export default class JMInput extends Vue {
  label!: string;
  inputType!: string;
  icon!: string;
  placeholder!: string;
  modelValue!: number;
  allowed!: string;
  isDisabled!: boolean;
  nomargin!: boolean;
  white!: boolean;
  withDelete!: boolean;
  suffix!: string;
  inputData: any = 0;
  componentKey: number = 0;
  overlayData: string = "";
  isError: boolean = false;

  @Watch("modelValue")
  updateValue() {
    this.inputData = this.addCommas(this.modelValue);
  }
  @Watch("inputData")
  resolveNaN(){
    if(Number.isNaN(this.inputData)) this.inputData = 0
    if(this.inputData=="NaN") this.inputData = 0
  }

  mounted() {
    this.inputData = this.addCommas(this.modelValue);
  }
  emitup = (event: any) => {
    let value = event.target.value.replace(/[^0-9.]/g, "");
    this.$emit("update:modelValue", parseFloat(value));
    this.$emit("change")
    this.inputData = this.addCommas(value);
  };
  addCommas(x: number) {
    try {
      var inp = parseFloat((x||0).toFixed(2))
      var parts = inp.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    } catch (error) {
      return x
    }
  }
  DeleteData(index: number) {
    let self = this;
    this.$emit("change", index);
  }
}
