
import address from "@/variable";
import { beforeWrite } from "@popperjs/core";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";
import * as _ from "lodash"

@Options({
  components: {},
  props: {},
})
export default class Krob4Form extends Vue {
  store = useStore()
  doc:any = {}
  originalDoc:any = {}
  area:any = {}
  conditions:any = {}
  condition:any = {}
  year:number = 2564
  operation:number = 1
  quarter: number = 1
  manuals:Array<any> = [
    {
      order: 0,
      enable: true,
      name: "รวมรายได้จากการจัดเก็บ",
      type: "pull",
      calculation: "plus",
      value: 0,
      eValue: 0,
    },
    {
      order: 1,
      enable: true,
      name: "รวมค่าใช้จ่ายทั้งสิ้นของ อจน.+**area**",
      type: "pull",
      calculation: "minus",
      value: 0,
      eValue: 0,
    },
    {
      order: 2,
      enable: true,
      name: "คงเหลือกำไรหลังหักค่าใช้จ่าย",
      type: "calculation",
      calculation: "subtract,0,1",
      value: -1,
      eValue: -1,
    },
    {
      order: 3,
      enable: true,
      name: "หัก กำไรสุทธิจากการดำเนินงานร้อยละ",
      type: "calculation",
      calculation: "percent,2,C30",
      value: -1,
      eValue: -1,
    },
    {
      order: 4,
      enable: true,
      name: "คงเหลือกำไร",
      type: "calculation",
      calculation: "subtract,2,3",
      value: -1,
      eValue: -1,
    },
    {
      order: 5,
      enable: true,
      name: "คงเหลือกำไรแบ่งในสัดส่วนที่เท่าเทียมกัน",
      type: "input",
      calculation: "divide,4,C2",
      value: -1,
      eValue: -1,
    },
    {
      order: 6,
      enable: true,
      name: "รายได้จากการจัดเก็บ - ครึ่งหนึ่งของสัดส่วนที่ไม่เพียงพอฯ",
      type: "input",
      calculation: "subtract,0,5",
      value: -1,
      eValue: -1,
    },
    {
      order: 7,
      enable: true,
      name: "คืนค่าใช้จ่ายที่ อปท.จ่ายแทน อจน.ในปีงบประมาณ **year** ไตรมาส **quarter**",
      type: "input",
      calculation: "minus",
      value: 0,
      eValue: 0,
    },
    {
      order: 8,
      enable: true,
      name: "คงเหลือ",
      type: "calculation",
      calculation: "subtract,6,7",
      value: -1,
      eValue: -1,
    },
    {
      order: 9,
      enable: true,
      name: "ภาษีมูลค่าเพิ่ม 7%",
      type: "calculation",
      calculation: "vat,8,C7",
      value: -1,
      eValue: -1,
    },
    // {
    //   order: 10,
    //   enable: true,
    //   name: "รวมภาษีมูลค่าเพิ่ม 7%",
    //   type: "calculation",
    //   calculation: "vat,6,C107",
    //   value: -1,
    //   eValue: -1,
    // },
  ]

  calculate1(data:any){
    if(data.type==="pull") {console.log(JSON.stringify(data)); return data.value; }
    else if(data.type==="calculation"||data.type==="input"){
      let split = data.calculation.split(",")
      let method = split[0]
      let result = -99
      console.log(JSON.stringify(data))
      switch (method) {
        case 'add':
          if (data.enable == false) {
            result = 0
            this.manuals[data.order].value = result
          } else {
            result = this.manuals[parseInt(split[1])].value + this.manuals[parseInt(split[2])].value
            result = parseFloat(result.toFixed(2))
            this.manuals[data.order].value = result

          }
          return result
          break;
        case 'subtract':
          if (data.enable == false) {
            result = 0
            this.manuals[data.order].value = result
          } else {
            result = this.manuals[parseInt(split[1])].value - this.manuals[parseInt(split[2])].value
            result = parseFloat(result.toFixed(2))
            this.manuals[data.order].value = result

          }
          return result
          break;
        case 'divide':
          if (data.enable == false) {
            result = this.manuals[parseInt(split[1])].value
            this.manuals[data.order].value = result
          } else {
            result = this.manuals[parseInt(split[1])].value / parseInt(split[2].replace("C",""))
            result = parseFloat(result.toFixed(2))
            this.manuals[data.order].value = result
          }
          return result
          break;
        case 'percent':
          if (data.enable == false) {
            result = 0
            this.manuals[data.order].value = result
          } else {
            result = this.manuals[parseInt(split[1])].value * (parseInt(split[2].replace("C",""))/100)
            result = parseFloat(result.toFixed(2))
            this.manuals[data.order].value = result
          }
          return result
          break;
        case 'vat':
          if (data.enable == false) {
            result = 0
            this.manuals[data.order].value = result
          } else {
            result = this.manuals[parseInt(split[1])].value * (parseInt(split[2].replace("C",""))/100)
            result = parseFloat(result.toFixed(2))
            this.manuals[data.order].value = result
          }
          return result
          break;
      }
    }
    return -2
  }
  calculate2(data:any){
    if(data.type==="pull") {console.log(JSON.stringify(data)); return data.eValue; }
    else if(data.type==="calculation"||data.type==="input"){
      let split = data.calculation.split(",")
      let method = split[0]
      let result = -99
      console.log(JSON.stringify(data))
      switch (method) {
        case 'add':
          if (data.enable == false) {
            result = 0
            this.manuals[data.order].eValue = result
          } else {
            result = this.manuals[parseInt(split[1])].eValue + this.manuals[parseInt(split[2])].eValue
            result = parseFloat(result.toFixed(2))
            this.manuals[data.order].eValue = result
          }
          return result
          break;
        case 'subtract':
          if (data.enable == false) {
            result = 0
            this.manuals[data.order].eValue = result
          } else {
            result = this.manuals[parseInt(split[1])].eValue - this.manuals[parseInt(split[2])].eValue
            result = parseFloat(result.toFixed(2))
            this.manuals[data.order].eValue = result

          }
          return result
          break;
        case 'divide':
          if (data.enable == false) {
            result = this.manuals[parseInt(split[1])].eValue
            this.manuals[data.order].eValue = result
          } else {
            result = this.manuals[parseInt(split[1])].eValue / parseInt(split[2].replace("C",""))
            result = parseFloat(result.toFixed(2))
            this.manuals[data.order].eValue = result
          }
          return result
          break;
        case 'percent':
          if (data.enable == false) {
            result = 0
            this.manuals[data.order].eValue = result
          } else {
            result = this.manuals[parseInt(split[1])].eValue * (parseInt(split[2].replace("C",""))/100)
            result = parseFloat(result.toFixed(2))
            this.manuals[data.order].eValue = result
          }
          return result
          break;
        case 'vat':
          if (data.enable == false) {
            result = 0
            this.manuals[data.order].eValue = result
          } else {
            result = this.manuals[parseInt(split[1])].eValue * (parseInt(split[2].replace("C",""))/100)
            result = parseFloat(result.toFixed(2))
            this.manuals[data.order].eValue = result
          }
          return result
          break;
      }
    }
    return -2
  }

  @Watch('doc.modifications', {deep:true})
  @Watch('manuals', {deep:true})
  calculationFinalValue() {
    // this.manuals[10].enable = this.manuals[9].enable
    this.manuals[9].enable
    let sum = 0;
    let eSum = 0;
    (this.doc.modifications??[]).forEach((mod:any) => {
      if(this.doc.ledgers.length>0){
        if(mod.symbol == "+") sum += mod.amount??0
        else if(mod.symbol == "-") sum -= mod.amount??0
      }
      if(this.doc.endorsedLedgers.length>0){
        if(mod.symbol == "+") eSum += mod.amount??0
        else if(mod.symbol == "-") eSum -= mod.amount??0
      }
    })
    if(this.manuals[9].enable){
      this.doc.contributionAmount = ((this.manuals[8].value)*1.07) + sum
      this.doc.eContributionAmount = ((this.manuals[8].eValue)*1.07) + eSum
    } else {
      this.doc.contributionAmount = this.manuals[8].value + sum
      this.doc.eContributionAmount = this.manuals[8].eValue + eSum
    }
  }
  get isEndorsed(){
    try {
      return this.doc.endorsedLedgers.length>0
    } catch (error) {
      return false
    }
  }

  resolveItemName(name:string){
    return name
      .replace("**area**",(this.area.prefix??'')+(this.area.name??''))
      .replace("**year**",this.year.toString())
      .replace("**quarter**",'ไตรมาสที่ ' + this.quarter.toString())
      .replace("ไตรมาสที่ 0","")
  }
  mounted(){
    this.year = parseInt(this.$route.params.year as string);
    this.quarter = parseInt(this.$route.params.quarter as string);
    this.operation = parseInt(this.$route.params.operation as string);

    this.fetchArea()
    this.fetchCondition()
  }
  
  fetchDoc(){
    axios
      .post(`${address}/calculation-find`, {
        search: {
          area: this.$route.params.area,
          calendarYear: this.year,
          quarter: this.quarter,
        },
      })
      .then(async (response) => {
        if (response.data == ""){
          // ใบใหม่
          this.getInitCalcuation()
        }
        else {
          // บันทึกไว้แล้ว
          this.doc = response.data
          this.originalDoc = Object.assign({},this.doc)
          this.getInitCalcuation()
          try {
            if(this.doc.manuals.length > 0){
              this.manuals = this.doc.manuals
            }
          } catch (error) {
            
          }
        }
      })
  }

  getInitCalcuation(){
    this.manuals[3].calculation = "percent,2,C"+this.condition.contributionPercent
    this.manuals[0].value = this.doc.totalIncome
    this.manuals[1].value = this.doc.totalExpenses
    if(this.isEndorsed){
      this.manuals[0].eValue = this.doc.eTotalIncome
      this.manuals[1].eValue = this.doc.eTotalExpenses
    } 
  }

  fetchArea(){
    axios
      .get(`${address}/area/${this.$route.params.area}`)
      .then(async (response) => {
        this.area = response.data
      })
  }
  fetchCondition(){
    axios
      .get(`${address}/areaCondition-by-field/area/${this.$route.params.area}`)
      .then(async (response) => {
        this.conditions = response.data.conditions
        this.condition = this.conditions[this.operation]
        this.fetchDoc()
      })
  }
  submitData() {
    let query = {
      area: this.$route.params.area,
      calendarYear: this.year,
      quarter: this.quarter,
    };
    let prep = {
      area: this.$route.params.area,
      areaCondition: this.conditions._id,
      operationYear: this.operation,
      calendarYear: this.year,
      quarter: this.quarter,
      ...this.doc,
      isKrob4: true,
      manuals: this.manuals
    };
    axios
      .post(`${address}/calculation-upsert/`, { search: query, doc: prep })
      .then((response) => {
        alert("บันทึกข้อมูลแล้ว!");
        console.log("done!",prep);
      });
  }
  pushItem(item:any){
    if(!Array.isArray(this.doc.modifications)) this.doc.modifications = []
    this.doc.modifications.push(item)
    console.log("push")
  }
}
