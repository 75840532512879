
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon";

@Options({
  components: {},
  props: {},
})
export default class ReportRisk extends Vue {
  docs: Array<any> = [];
  areas: Array<any> = [];
  calculations: Array<any> = [];
  area: string = "";
  problem: string = "";
  problemType: string = "";
  solution: string = "";
  mounted() {
    this.fetchData();
    this.fetchArea();
  }
  get filtered(){
    return this.docs.filter(el=>el.active)
  }
  pushToServer() {
    axios
      .post(`${address}/risk`, {
        area: this.areas.find(el=>el._id==this.area).name,
        areaId: this.area,
        problemType: this.problemType,
        problem: this.problem,
        solution: this.solution,
        active:true
      })
      .then((response) => {
        alert("บันทึกข้อมูลแล้ว!");
        this.fetchData()
        this.problemType = ""
        this.problem = ""
        this.solution = ""
      });
  }
  hideItem(id:string) {
    axios
      .patch(`${address}/risk/${id}`, {
        active:false
      })
      .then((response) => {
        this.fetchData()
      });
  }

  fetchArea() {
    axios
      .get(`${address}/areas`)
      .then((response) => (this.areas = response.data));
  }

  fetchData() {
    axios.get(`${address}/risks`).then((response) => {
      this.docs = response.data;
    });
  }
}
