
import address, { filePath } from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
@Options({})
export default class InvoiceList extends Vue {
  store = useStore();
  // docs: Array<any> =[];
  signature:string = "";
  selected:any
  doc: Array<any> = [];
  docs: Array<any> = new Array(5).fill({ active: false });
  address = address;
  filePath = filePath;
  show: number = 50;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 5;
  mounted() {
    this.fetchData();
  }

  downloadExcel(){
    let query = {
      // search:{_id:{$in:this.selected}}
    }
    axios.post(`${address}/requests-excel`, query,{responseType: 'blob'}).then((response) => {
      // Log somewhat to show that the browser actually exposes the custom HTTP header
      const fileNameHeader = "x-suggested-filename";
      const suggestedFileName = response.headers[fileNameHeader];
      const effectiveFileName = (suggestedFileName === undefined
                  ? "download_report.xlsx"
                  : suggestedFileName);
      console.log("Received header [" + fileNameHeader + "]: " + suggestedFileName
                  + ", effective fileName: " + effectiveFileName);

      saveAs(response.data, effectiveFileName);
    })
  }
  // updateStatus(item: any, status: string) {
  //   if (status == "อนุมัติ") {
  //     axios
  //       .patch(`${address}/request/${item._id}`, { status: status })
  //       .then((response) => {
  //         axios
  //           .patch(`${address}/usage/${item.from._id}`, {
  //             ...item.to,
  //             _id: undefined,
  //           })
  //           .then((response) => {
  //             alert("สำเร็จ!");
  //             this.fetchData();
  //           });
  //       });
  //   } else {
  //     axios
  //       .patch(`${address}/request/${item._id}`, { status: status })
  //       .then((response) => {
  //         alert("สำเร็จ!");
  //         this.fetchData();
  //       });
  //   }
  // }
  fetchData() {
    let query = {
      search: { requestType: {$nin:["พิมพ์ใบเสร็จ","เปลี่ยนข้อมูลผู้ใช้น้ำ"]} },
      //แก้ไขใบนำส่ง
      limit: 25,
      skip: 0,
      sort: { createdAt: -1 },
      populate: "requester",
    };
    console.log(query);
    axios.post(`${address}/requests-paginate`, query).then((response) => {
      this.docs = response.data.docs
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
}
