
import address from "@/variable";
import axios from "axios";
import { DateTime } from "luxon";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";

import PDF from "@/pdf";

@Options({})
export default class CalculationPDF extends Vue {
  store = useStore();
  docs: Array<any> = [];
  costCodes:Array<any> = []
  pdfBLOB = "";
  fonts: any = {};
  styles: any = {};
  doc: any = {
    ledgers:[]
    
  }
  areas:Array<any> = []
  area:any = {}
  calculations:Array<any> = []
  areaCondition:any = {}
  year: number = -1;
  quarter: number = -1;
  operation: number = 0;
  isLeft:boolean = true
  isRight:boolean = true
  mounted() {
    this.year = parseInt(this.$route.params.year as string);
    this.quarter = parseInt(this.$route.params.quarter as string);
    this.operation = parseInt(this.$route.params.operation as string);
    this.fetchArea();
    this.fetchData();
    this.getCostCode()
  }
  get ConditionCat(){
    try {
      return this.areaCondition.conditions[parseInt(this.$route.params.operation as string)].category
    } catch (error) {
      
    }
  }
  fetchData(){
    axios.post(`${address}/calculation-find`,{
      search:{
        area:this.$route.params.area,
        calendarYear:this.year,
        quarter:this.quarter,
      }
    }).then(response=>{
      if(response.data!=""){
        this.docs = response.data
        this.generatePDF("preview")
      }
    })
  }
  fetchArea() {
    axios
      .get(`${address}/area/${this.$route.params.area}`)
      .then((response) => (this.area = response.data));
    axios
      .get(`${address}/areaCondition-by-field/area/${this.$route.params.area}`)
      .then((response) => (this.areaCondition = response.data));
  }

  getCostCode() {
    axios.get(`${address}/costcodes`).then(response=>{
      this.costCodes = response.data
    })
  }

  async generatePDF(mode: string) {
    let mm = (u: number) => {
      return u / 0.352777778;
    };
    var pdfMake = require("pdfmake/build/pdfmake.js");
    this.fonts = this.store.getters.getPDFFonts;
    this.styles = this.store.getters.getPDFStyles;
    let result:any = PDF.getKrob3(this.area,this.docs,this.ConditionCat,this.isLeft,this.isRight)

    var docDefinition = {
      pageSize: "A4",
      pageMargins: [40, 100, 40, 20],
      header:PDF.getHeader(this.doc._id),
      content: [
        result,
        ],
      styles: this.styles,
      // watermark: { text: 'ฉบับร่าง', angle:"-30",  color: "#3760A0", opacity: 0.15, bold: true, italics: false },
      footer: (currentPage: number, pageCount: number, pageSize: any) => {
        let text = `สรุปรายรับ - จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย (${currentPage.toString().formatThai()} จาก ${pageCount.toString().formatThai()})`
        return {
          text: text,
          alignment: "right",
          marginRight: 10,
          italics:true,
          fontSize:10,
          relativePosition:{x:0,y:-10}
        };
      },
      defaultStyle: {
        fontSize: "16",
        lineHeight: 0.9,
      },
    };
    if (mode == "preview") {
      let pdfDocGenerator = pdfMake.createPdf(docDefinition, null, this.fonts);
      pdfDocGenerator.getBlob((blob: any) => {
        let blobURL = URL.createObjectURL(blob);
        this.pdfBLOB = blobURL;
      });
    } else {
      let pdfDocGenerator = pdfMake
        .createPdf(docDefinition, null, this.fonts)
        .download(`ใบนำส่ง.pdf`);
    }
  }
}
