
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon";
import Excel from "exceljs";

@Options({
  components: {},
  props: {},
})
export default class ReceiptList extends Vue {
  docs: Array<any> = [];
  invoices: Array<any> = [];
  receipts: Array<any> = [];
  search: any = {};
  newMeter: string = "";
  selected: Array<any> = [];
  DateTime = DateTime;
  patchData() {
    if (this.newMeter === "") {
      alert("คุณยังไม่ได้กรอกเลขที่ผู้ใช้น้ำใหม่!");
      return;
    }
    axios
      .post(`${address}/process/change/meter/`, {
        newMeter: this.newMeter,
        oldMeter: this.$route.params.id,
      })
      .then((response) => {
        console.log(response);
        alert("สำเร็จ");
        this.$router.push(`/portal/meter/history/${this.newMeter}`);
        this.fetchData();
      });
  }

  updateIsPaid(id: string, seq: string) {
    let input = prompt("กรุณาระบุหมายเลขใบเสร็จ", "-");
    let found = this.receipts.find((r) => r.sequence === input) ?? {};
    console.log(input, found);
    let receipts = [input];
    axios
      .patch(`${address}/invoice/${id}`, { receipts, isPaid: true })
      .then((response) => {
        console.log({ invoices: [seq] });
        axios
          .patch(`${address}/receipt/${found.id}`, {
            invoice: id,
            isPaid: true,
          })
          .then((response2) => {
            this.fetchData();
          })
          .catch((error) => {
            console.log(error);
          });
      });
  }
  updateIsNotPaid(id: string) {
    axios
      .patch(`${address}/invoice/${id}`, { isPaid: false, receipts:[] })
      .then((response) => {
        this.fetchData();
      });
  }

  get filtered() {
    let result = this.docs;
    if (this.search.year != undefined)
      result = result.filter((el) => {
        return el.year == this.search.year;
      });
    if (this.search.month != undefined)
      result = result.filter((el) => {
        return el.month == this.search.month;
      });
    if (this.search.isPaid == true)
      result = result.filter((el) => {
        return this.findReceipt(el) != undefined;
      });
    if (this.search.isPaid == false)
      result = result.filter((el) => {
        return this.findReceipt(el) == undefined;
      });
    if (this.search.text != "" && this.search.text != undefined)
      result = result.filter((el) => {
        return el.sequence.search(this.search.text) != -1;
      });
    // result = result.sort((a:any,b:any)=>(parseInt(a.sequence)??0)-(parseInt(b.sequence)??0))
    result = result.map((el) => {
      return {
        ...el,
        r0: this.findReceipt(el).paymentAmount ?? 0,
        r1: new Date(this.findReceipt(el).paymentDate ?? "-").toThaiShort(),
        r2: this.findReceipt(el).sequence ?? "-",
        r3: this.findReceipt(el).sequence == undefined ? 0 : el.totalAmount,
        r4: this.findReceipt(el).paymentAmount ?? 0,
        r5: 0,
      };
    });
    var remain = 0;
    var cummulative = 0;
    result.forEach((res, i) => {
      const element = result[i];
      cummulative += parseFloat((element.totalAmount ?? 0).toFixed(2));
      element.r5 = parseFloat(cummulative.toFixed(2));
      try {
        if (result[i + 1].r4 != 0) cummulative = 0; //parseFloat((element.totalAmount ?? 0).toFixed(2))
      } catch (error) {
        cummulative = element.totalAmount ?? 0;
      }
      try {
        if (element.r4 == 0) {
          element.r0 = result[i - 1].r0;
          if (element.r0 >= element.r5) {
            element.r1 = result[i - 1].r1;
            element.r2 = result[i - 1].r2;
            // element.isPaid = true
            element.r3 = result[i].totalAmount;
            // element.r3 = result[i-1].totalAmount
            // element.r4 = result[i-1].r4
          }
        } else {
          if (result[i - 1].r1 === result[i + 0].r1) element.r4 = 0;
          // element.r0 =  result[i-1].r0
        }
      } catch (error) {}
    });
    return result;
  }

  toThaiFormat(year: number, month: number) {
    var dtThai = DateTime.fromObject({ year: year - 543, month })
      .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("LLLyy");
    return dtThai;
  }
  findReceipt(item: any) {
    // console.log(item.year,this.receipts)
    try {
      const result = this.receipts.find((el) => {
        return item.receipts.findIndex((r: string) => r == el.sequence) > -1;
      });
      if (result != undefined) return result;
      else return {};
    } catch (error) {
      return {};
    }
  }
  mounted() {
    this.fetchData();
  }
  fetchData() {
    this.docs = [];
    this.receipts = [];
    let query = {
      search: { meter: this.$route.params.id },
      limit: 999999,
      skip: 0,
      sort: {
        sequence: -1,
      },
    };
    axios.post(`${address}/invoices-paginate`, query).then((response) => {
      this.docs = response.data.docs;
      let query = {
        search: { meter: this.$route.params.id },
        limit: 999999,
        skip: 0,
        sort: {
          sequence: -1,
        },
      };
      axios.post(`${address}/receipts-paginate`, query).then((receipts) => {
        this.receipts = receipts.data.docs;
      });
    });
  }
  downloadExcel() {
    console.log("download excel...");
    let self = this;
    // const Excel = require("exceljs");
    var workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet(`ประวัติผู้ใช้น้ำ`);

    worksheet.getColumn("B").width = 15;
    worksheet.getColumn("C").width = 15;
    worksheet.getColumn("D").width = 15;
    worksheet.getColumn("E").width = 15;
    worksheet.getColumn("F").width = 15;
    worksheet.getColumn("G").width = 15;
    worksheet.getColumn("H").width = 15;
    worksheet.getColumn("I").width = 15;
    worksheet.getColumn("J").width = 15;
    worksheet.getColumn("K").width = 15;
    worksheet.getColumn("L").width = 15;
    worksheet.getColumn("M").width = 15;
    worksheet.getColumn("N").width = 15;
    worksheet.getColumn("O").width = 15;
    worksheet.getColumn("P").width = 15;
    worksheet.getColumn("Q").width = 15;
    worksheet.addRow([""]);
    worksheet.addRow([
      "เลขที่ผู้ใช้น้ำ",
      this.$route.params.id,
      (this.docs[0] ?? {}).name,
      null,
      "จำนวนใบแจ้งหนี้",
      (this.docs.length ?? 0).formatComma(),
      "ยอดคงค้าง",
      (
        (this.docs.map((el) => el.totalAmount).reduce((a, b) => a + b, 0) ??
          0) -
        (this.filtered
          .filter((el) => el.isPaid)
          .map((el) => el.totalAmount)
          .reduce((a, b) => a + b, 0) ?? 0)
      ).formatFull(),
      "จำนวนใบเสร็จรับเงิน",
      (this.receipts.length ?? 0).formatComma(),
      "ปริมาณการใช้น้ำ",
      (
        this.docs.map((el) => el.qty).reduce((a, b) => a + b, 0) ?? 0
      ).formatFull(),
      "จำนวนค่าบริการ",
      (
        this.docs.map((el) => el.totalAmount).reduce((a, b) => a + b, 0) ?? 0
      ).formatFull(),
      "ภาษีมูลค่าเพิ่ม",
      (
        this.docs.map((el) => el.vat).reduce((a, b) => a + b, 0) ?? 0
      ).formatFull(),
      "ชำระค่าบริการ(ตามใบแจ้งหนี้)",
      (
        this.filtered
          .filter((el) => el.isPaid)
          .map((el) => el.totalAmount)
          .reduce((a, b) => a + b, 0) ?? 0
      ).formatFull(),
      "ชำระค่าบริการ(ยอดที่จ่ายจริง)",
      (
        this.receipts
          .map((el) => el.paymentAmount)
          .reduce((a, b) => a + b, 0) ?? 0
      ).formatFull(),
    ]);
    worksheet.addRow([""]);
    worksheet.addRow([
      "ค้างชำระ",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "ชำระจริง",
      "",
      "",
      "",
    ]);
    worksheet.lastRow?.eachCell((cell, cn) => {
      cell.style = {
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF0E4B8D" },
        },
        font: { color: { argb: "FFFFFFFF" } },
      };
    });
    worksheet.addRow([
      "ลำดับ",
      "หมายเลขใบแจ้งหนี้",
      "ประจำเดือน",
      "ปริมาณการใช้น้ำ (ลบ.ม.)",
      "อัตราค่าบำบัด",
      "รวมเงิน",
      "ภาษี (บาท)",
      "ค่าบริการบำบัดน้ำเสีย",
      "วันที่ชำระ",
      "หมายเลขใบเสร็จ",
      "ชำระทั้งสิ้น",
      "จำนวนเงินที่รับจริง",
    ]);
    worksheet.lastRow?.eachCell((cell, cn) => {
      cell.style = {
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF0E4B8D" },
        },
        font: { color: { argb: "FFFFFFFF" } },
      };
    });
    this.filtered.forEach((item, i) => {
      let res = 0
      if(item.r3 == 0) res = item.r4
      else item.r4 == 0 ? res = item.r0 : res = item.r4
      worksheet.addRow([
        i + 1,
        item.sequence,
        this.toThaiFormat(item.year, item.month),
        (item.qty ?? 0).formatFull(),
        (item.rate ?? 0).formatFull(),
        ((item.qty ?? 0) * (item.rate ?? 0)).formatFull(),
        item.vat.formatFull(),
        item.totalAmount.formatFull(),
        item.r1,
        item.r2,
        item.r3,
        res,
      ]);
    });
    worksheet.addRow([""]);

    worksheet.addRow([
      "ลำดับ",
      "วันที่ชำระ",
      "เลขที่ใบเสร็จ",
      "เลขที่ผู้ใช้น้ำ",
      "ชื่อ-นามสกุล",
      "เดือนที่ค้าง\r\n(เดือน)",
      "ชำระยอดเงินที่ค้าง (บาท)",
      "ภาษียอดค้าง",
      "ลบ.ม.",
      "ค่าบริการ",
      "ภาษี",
      "ค่าบริการบวกภาษี",
      "รวมภาษีขาย",
      "รวมเงิน\r\bตามใบแจ้งหนี้",
      "รับจริง",
      "หมายเหตุ",
    ]);
    worksheet.lastRow?.eachCell((cell, cn) => {
      cell.style = {
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FF0E4B8D" },
        },
        font: { color: { argb: "FFFFFFFF" } },
      };
    });
    this.receipts.forEach((item, i) => {
      worksheet.addRow([
        i + 1,
        new Date(item.paymentDate).toThaiShort(),
        item.sequence,
        item.meter,
        (item.prefix ?? "") + (item.name ?? ""),
        item.debtText,
        item.debtAmount,
        item.debtVat,
        item.qty,
        item.totalAmount,
        item.vat,
        item.debtVat + item.vat,
        item.debtAmount + item.totalAmount + item.taxDebt + item.vat ?? 0,
        item.invoiceAmount,
        item.paymentAmount,
        item.description,
      ]);
      worksheet.lastRow?.eachCell((cell, cn) => {
        if (cn === 7)
          cell.style = {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFA500" },
            },
            font: { color: { argb: "FF000000" } },
          };
        if (cn === 8)
          cell.style = {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFA500" },
            },
            font: { color: { argb: "FF000000" } },
          };
        if (cn === 12)
          cell.style = {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF9ACD32" },
            },
            font: { color: { argb: "FF000000" } },
          };
      });
    });
    workbook.xlsx.writeBuffer().then(function(data: any) {
      var blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `ประวัติผู้ใช้น้ำ_${self.$route.params.id}.xlsx`);
    });
  }

  display1(debt: Array<any>) {
    let debtText = "";
    var debtAmount = 0;
    var isMiddle = false;
    let arr = debt.slice().reverse() as Array<any>;
    for (let i = 0; i < arr.length; i++) {
      let current = DateTime.fromObject({
        year: arr[i].year - 543,
        month: arr[i].month,
        day: 5,
      });
      if (i == 0) {
        debtText += current
          .reconfigure({ outputCalendar: "buddhist" })
          .setLocale("th")
          .toFormat("LLLyy");
        let next = DateTime.fromObject({
          year: ((arr[i + 1] ?? {}).year ?? 2600) - 543,
          month: (arr[i + 1] ?? {}).month ?? 13,
          day: 5,
        });
        const diffNext = current.diff(next, "months").toObject().months;
        if (diffNext == -1) debtText += "-";
        else debtText += "/";
      } else if (i == arr.length - 1) {
        debtText += current
          .reconfigure({ outputCalendar: "buddhist" })
          .setLocale("th")
          .toFormat("LLLyy");
      } else {
        let last = DateTime.fromObject({
          year: arr[i - 1].year - 543,
          month: arr[i - 1].month,
          day: 5,
        });
        let next = DateTime.fromObject({
          year: ((arr[i + 1] ?? {}).year ?? 2600) - 543,
          month: (arr[i + 1] ?? {}).month ?? 13,
          day: 5,
        });
        const diffLast = current.diff(last, "months").toObject().months;
        const diffNext = current.diff(next, "months").toObject().months;
        if (diffLast == 1) isMiddle = true;
        else if (diffNext == -1) isMiddle = true;
        else isMiddle = false;
        if (isMiddle) {
          if (debtText.slice(-1) != "-") debtText += "-";
        } else {
          debtText += current
            .reconfigure({ outputCalendar: "buddhist" })
            .setLocale("th")
            .toFormat("LLLyy");
          debtText += "/";
          debtText += next
            .reconfigure({ outputCalendar: "buddhist" })
            .setLocale("th")
            .toFormat("LLLyy");
        }
      }

      let amt = arr[i].rate * arr[i].qty * 100;
      let res = parseFloat((amt * (1 + arr[i].vatRate)).toFixed(2));
      debtAmount += res / 100;
    }
    return {
      debtText,
      debtAmount,
    };
  }
  display0(invoices: Array<any>) {
    let debts = invoices.filter(
      (invoice) => invoice.isPaid === false && invoice.totalAmount > 0
    );
    let mapDebts = debts.map((debt) => ({
      month: debt.month,
      year: debt.year,
      yearMonth: parseInt(String(debt.year) + String(debt.month).padStart(2, "0")),
    }));
    let sortDebts = mapDebts.sort((a, b) => a.yearMonth - b.yearMonth);
    let debtText: Array<any> = [];
    let arrayDebtText: Array<any> = [];
    let latest: any = {};
    let maxYear = Math.max(...sortDebts.map(el=>el.year))
    let maxYearMonth = Math.max(...sortDebts.map(el=>el.yearMonth))
    for (const [i, debt] of sortDebts.entries()) {
      let current = DateTime.fromObject({
        year: debt.year - 543,
        month: debt.month,
        day: 10,
      });
      let formatDate = current
        .reconfigure({ outputCalendar: "buddhist" })
        .setLocale("th")
        .toFormat("LLLyy");
      let gap = (latest.yearMonth ?? 0) - (debt.yearMonth ?? 0)
      // console.log('latest.yearMonth',debt.yearMonth)
      debtText.push({
        text: formatDate,
        latest:latest.yearMonth,
        yearMonth: debt.yearMonth,
        gap
      });
      latest = debt;
    }
    for (const [i, debt] of debtText.entries()) {
      if (debt.yearMonth === maxYearMonth){
        if(debt.gap===-1){
          arrayDebtText.push({ text: "-" });
        }
      }
      if (debt.gap === -1) {
        if(debtText.length-1 === i){
        arrayDebtText.push({ text: debt.text });}
        else {
          arrayDebtText.push({ text: "-" });
        }
        try {
          if (debtText[i + 1].gap !== -1)
            arrayDebtText.push({ text: debt.text });
        } catch (error) { }
      } else {
        arrayDebtText.push({ text: debt.text });
      }
    }
    let finalDebtAmount = debts.reduce(
      (acc, debt) => acc + debt.totalAmount,
      0
    );
    let finalDebtText = arrayDebtText
      .map((el) => el.text)
      .join("/")
      // .join(arrayDebtText.length==2?"-":"/")
      .replace(/\/-(.*?)([ก-เ])/g, "-$2");
    return {
      debtAmount: finalDebtAmount,
      debtText: finalDebtText,
      original: debtText,
      // debts,
      arrayDebtText,
      maxYear,
      maxYearMonth
    };
  }
}
