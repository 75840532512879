
import address, { filePath } from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import * as _ from "lodash"
@Options({})
export default class ApprovalDetail extends Vue {

  receipts:Array<any> = []
  totalCount: number = 0;
  totalQty: number = 0;
  // totalAmount: number = 0;
  totalPayment: number = 0;
  totalDebt: number = 0;
  print(){
    window.print();
  }

  selectedPaymentDate: string = "";

  get totalAmount():number{
    let reduce = this.filteredReceipts.map(el=>el.paymentAmount??0).reduce((a,b)=>a+b,0)
    return reduce
  }
  get allPaymentDates(){
    return _.uniq(this.receipts.map(rc=>rc.paymentDate))
  }

  get filteredReceipts(): Array<any>{
    try {
      if(this.selectedPaymentDate!='')
        return this.receipts.filter(rc=>rc.paymentDate===this.selectedPaymentDate)
      else this.receipts
    } catch (error) {
      return []
    }
    return []
  }
  mounted(){
    this.fetchData()
    if(this.$route.query.dt!==undefined){
      this.selectedPaymentDate = this.$route.query.dt as string
    }
  }
  fetchData() {
      axios.get(`${address}/request/${this.$route.params.id}`).then((response) => {
        this.getReceipt(response.data.list)
      });
    }
  getReceipt(list:Array<string>){
    let query = {
      search:{
        _id:{
          $in:list
        }
      },
      limit:9999,
      skip:0
    }
    axios
      .post(`${address}/receipts-paginate`, query)
      .then((response) => {
        this.receipts = response.data.docs

        this.totalCount = response.data.totalCount;
        this.totalQty = response.data.totalQty;
        // this.totalAmount = response.data.totalAmount;
        this.totalPayment = response.data.totalPayment;

        this.totalDebt = response.data.totalDebt;
      });    
  }
}

