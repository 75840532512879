
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import contenteditable from "vue-contenteditable";

@Options({
  components: {
    contenteditable,
  },
  props:{
    name:String,
    modelValue:Object,
    "update:modelValue":Function
  },
})
export default class Krob4Header extends Vue {
  message = "";
  name!:string;
  doc:any = {}
  docInside:any = {}
  modelValue!:any

  mounted(){
    this.docInside = this.modelValue??{}
  }
  updated(){
    this.docInside = this.modelValue??{}
  }

  emitUp(){
    this.$emit("update:modelValue", this.docInside);
    this.$emit("change", this.docInside);
  }
}
