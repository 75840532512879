
import address from "@/variable";
import axios from "axios";
import { DateTime } from "luxon";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";

import PDF from "@/pdf";

@Options({})
export default class CalculationPDF extends Vue {
  store = useStore();
  docs: any = {}
  costCodes:Array<any> = []
  pdfBLOB = "";
  fonts: any = {};
  styles: any = {};
  doc: any = {}
  areas:Array<any> = []
  area:any = {}
  calculations:Array<any> = []
  areaCondition:any = {}
  year: number = -1;
  quarter: number = -1;
  operation: number = 0;
  isLeft:boolean = true
  isRight:boolean = true
  debug:string = ""
  condition:any = {
    category:"",
    lossType:1,
    profitType:1
  }
  doc2:Array<any> = []
  setProfit(){
    this.docs.totalExpenses = 10000
    this.docs.totalIncome = 12000
    this.updateCondition()
  }
  setLoss(){
    this.docs.totalExpenses = 10000
    this.docs.totalIncome = 8000
    this.updateCondition()
  }
  updateCondition(){
    // this.condition.category = this.debug
    this.areaCondition.conditions[this.operation].category = this.debug
    this.docs.advanceAmount = 7777
    this.docs.contributionAmount = 9999
    this.docs.eAdvanceAmount = 7777
    this.docs.eContributionAmount = 9999
    this.areaCondition.conditions[this.operation].contributionPercent = 20
    this.areaCondition.conditions[this.operation].contributionLimit = 10000
    this.areaCondition.conditions[this.operation].lossType = this.condition.lossType
    this.areaCondition.conditions[this.operation].profitType = this.condition.profitType
    this.doc.advanceAmount = 20
    console.log(this.debug)
    this.generatePDF("preview")
  }

  mounted() {
    this.year = parseInt(this.$route.params.year as string);
    this.quarter = parseInt(this.$route.params.quarter as string);
    this.operation = parseInt(this.$route.params.operation as string);
    this.fetchArea();
    this.fetchData();
    this.getCostCode()
  }
  get ConditionCat(){
    try {
      return this.areaCondition.conditions[parseInt(this.$route.params.operation as string)].category
      } catch (error) {
    }
  }
  // get Category() {
  // let test = "สมทบตามค่าใช้จ่ายจริง/สัดส่วนเท่าเทียมกัน"
  //   switch(this.ConditionCat) {
  //     case 'สมทบตามเปอร์เซ็นต์':
  //       return this.store.state.conditionPercent
  //       break;
  //     case 'สมทบตามจำนวนคงที่':
  //       return this.store.state.conditionFixed
  //       break;
  //     case 'สมทบตามค่าใช้จ่ายจริง/สัดส่วนเท่าเทียม':
  //       if( this.condition.totalIncome >= this.condition.totalExpenses)
  //         if(this.condition.profitType == 1)
  //         return this.store.state.conditionProfitType1
  //         if(this.condition.profitType == 2)
  //         return this.store.state.conditionProfitType2
  //       else( this.condition.totalIncome < this.condition.totalExpenses)
  //         if(this.condition.lossType == 1)
  //         return this.store.state.conditionLossType1
  //         if(this.condition.lossType == 2)
  //         return this.store.state.conditionLossType2
  //         if(this.condition.lossType == 3)
  //         return this.store.state.conditionLossType3
  //       break;
  //   }
  // }
  fetchData(){
    axios.post(`${address}/calculation-find`,{
      search:{
        area:this.$route.params.area,
        calendarYear:this.year,
        quarter:this.quarter,
      }
    }).then(response=>{
      if(response.data!=""){
        this.docs = response.data
        axios.get(`${address}/areaCondition-by-field/area/${this.$route.params.area}`).then(response2=>{
          this.areaCondition = response2.data
          this.generatePDF("preview")
        })
      }
    })
  }
  get isEndorsed(){
    try {
      return this.doc.endorsedLedgers.length>0
    } catch (error) {
      return false
    }
  }
  fetchArea() {
    axios
      .get(`${address}/area/${this.$route.params.area}`)
      .then((response) => (this.area = response.data));
    axios
      .get(`${address}/areaCondition-by-field/area/${this.$route.params.area}`)
      .then((response) => (this.areaCondition = response.data));
    axios
      .post(`${address}/calculation-quarter-sum/${this.$route.params.area}/${this.year}/${this.quarter}`)
      .then(async (response) => {
        this.doc2 = response.data
      })
  }

  getCostCode() {
    axios.get(`${address}/costcodes`).then(response=>{
      this.costCodes = response.data
    })
  }

  async generatePDF(mode: string) {
    let mm = (u: number) => {
      return u / 0.352777778;
    };
    var pdfMake = require("pdfmake/build/pdfmake.js");
    this.fonts = this.store.getters.getPDFFonts;
    this.styles = this.store.getters.getPDFStyles;
    
    var docDefinition = {
      pageSize: "A4",
      pageMargins: [40, 100, 40, 20],
      header:PDF.getHeader(this.doc._id),
      content: [
        PDF.getKrob4Original(this.area,this.docs,this.doc2,this.areaCondition,parseInt(this.$route.params.operation as string)||0,this.store.state,this.isLeft,this.isRight),
        ],
      styles: this.styles,
      // watermark: { text: 'ฉบับร่าง', angle:"-30",  color: "#3760A0", opacity: 0.15, bold: true, italics: false },
      footer: (currentPage: number, pageCount: number, pageSize: any) => {
        let text = `สรุปรายรับ - จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย (${currentPage.toString().formatThai()} จาก ${pageCount.toString().formatThai()})`
        return {
          text: text,
          alignment: "right",
          marginRight: 20,
          italics:true,
          fontSize:10,
          relativePosition:{x:0,y:-10}
        };
      },
      defaultStyle: {
        fontSize: "16",
        lineHeight: 0.9,
      },
    };
    if (mode == "preview") {
      let pdfDocGenerator = pdfMake.createPdf(docDefinition, null, this.fonts);
      pdfDocGenerator.getBlob((blob: any) => {
        let blobURL = URL.createObjectURL(blob);
        this.pdfBLOB = blobURL;
      });
    } else {
      let pdfDocGenerator = pdfMake
        .createPdf(docDefinition, null, this.fonts)
        .download(`ใบนำส่ง.pdf`);
    }
  }
}
