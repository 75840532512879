
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
@Options({})
export default class RateGroupForm extends Vue {
  store = useStore();
  area: any = {};
  doc: any = {};
  isExisted: boolean = false;
  mounted() {
    if (this.$route.params.id != undefined) this.getData();
  }
  getData() {
    axios.get(`${address}/area/${this.$route.params.id}`).then((response) => {
      this.area = response.data;

      axios
        .get(`${address}/arearate-by-field/area/${this.$route.params.id}`)
        .then((response) => {
          if (response.data == "") {
            this.doc = {};
            this.doc.name = this.area.name;
            this.doc.area = this.area._id;
          } else {
            this.doc = response.data;
            this.doc.name = this.area.name;
            this.doc.area = this.area._id;
            this.isExisted = true;
          }
        });
    });
  }
  submit() {
    if (this.isExisted) this.patchData();
    else this.postData();
  }
  postData() {
    axios
      .post(`${address}/arearate`, {
        ...this.doc,
      })
      .then((response) => {
        console.log(response);
        alert("สำเร็จ!");
        this.$router.push("/portal/billing/group/list");
      });
  }
  patchData() {
    axios
      .patch(`${address}/arearate/${this.doc._id}`, {
        ...this.doc,
        _id: undefined,
      })
      .then((response) => {
        console.log(response);
        alert("สำเร็จ!");
        this.$router.push("/portal/billing/group/list");
      });
  }
}
