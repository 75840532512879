
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
@Options({
  components: {},
  props: {},
})
export default class Signature extends Vue {
  store = useStore();
  fonts: any = {};
  styles: any = {};
  htmldata = "";
  pdfBLOB = "";
  signature = "";
  mounted() {
    this.generatePDF;
  }

  chooseSignature() {
    const preview = this.$refs.signatureImage as HTMLImageElement;
    let myinput = this.$refs.signatureInput as HTMLInputElement;
    const file = myinput!.files![0];
    const reader = new FileReader();
    let self = this
    reader.addEventListener(
      "load",
      function() {
        preview.src = reader.result as string;
        console.log(reader.result);
        self.signature = reader.result as string
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  }
  async generatePDF(mode: string) {
    var htmlToPdfmake = require("html-to-pdfmake");
    var html = htmlToPdfmake(this.htmldata);
    var pdfMake = require("pdfmake/build/pdfmake.js");
    this.fonts = this.store.getters.getPDFFonts;
    this.styles = this.store.getters.getPDFStyles;
    var docDefinition = {
      pageSize: "A4",
      pageOrientation: "portrait",
      header: {
        text: "",
        alignment: "right",
        marginRight: 10,
        marginTop: 10,
      },
      content: [
        {
          text:
            "ทดสอบ HTML",
          alignment: "center",
          bold: true,
        },
        html,
        {image:this.signature, width:120, height:120}
      ],
    };

    let pdfDocGenerator = pdfMake.createPdf(docDefinition, null, this.fonts);
    pdfDocGenerator.getBlob((blob: any) => {
      let blobURL = URL.createObjectURL(blob);
      this.pdfBLOB = blobURL;
    });
  }
}
