
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import Excel from "exceljs"

@Options({
  components: {},
})
export default class UsageImport extends Vue {
  store = useStore();
  docs: Array<any> = [];
  doc: any ={};
  rates: Array<any> = []
  year: number = 0;
  month: number = 0;
  code: string = "";
  area: string = "";
  category: string = "";
  thailand = require("@/data/thailand.json");
  select: string = "";
  debugging = false
  total = 0;
  current = 0;
  percent = 0;
  mounted(){
    this.getRates()
    if(this.$route.query.debug=="true") this.debugging = true
  }

  get activeContract() {
    try {
      return this.rates.find((el) => el.code == this.code);
    } catch (error) {
      return {};
    }
  }
  async importExcel(ev: any) {
    let self = this;
    console.log(ev);

    // let file = ev.target.files[0];
    //@ts-ignore
    let file = this.$refs.file.files[0]
    const workbook = new Excel.Workbook();
    let book = await workbook.xlsx.load(file);
    let sheet = book.worksheets[0];
    let count = sheet.rowCount;
    let arr: Array<any> = [];
    let no = 1
    self.total = sheet.rowCount
    sheet.eachRow(function(row: any, rowNumber: any) {
      self.current = rowNumber
      self.percent = (self.current / self.total) * 100
      let t1 = ""
      let rate = 0
      if (rowNumber > 1 && row.getCell(2).value!=null){
        if(row.getCell(5).value == "น้ำเสีย") t1 = "treatment"
        else if(row.getCell(5).value == "น้ำทิ้ง") t1 = "effluent"

        if(row.getCell(8).value == "บาท/เดือน") rate = row.getCell(7).value
        else rate = self.activeContract[t1+row.getCell(4).value]

        let debtAmount = row.getCell("K").value==null?undefined:row.getCell("K").value
        let debtText = row.getCell("L").value==null?undefined:row.getCell("L").value
        arr.push({
          no:no,
          code:self.activeContract.code,
          invoiceSequence: row.getCell("M").value,
          meter: row.getCell(1).text,
          name: row.getCell(2).value,
          address: row.getCell(3).value,
          category: row.getCell(4).value,
          categoryType: row.getCell(5).value,
          qty: row.getCell(6).value,
          calculationType: row.getCell(8).value??"บาท/ลบ.ม.",
          vatRate:0.07,
          rate: rate,//row.getCell(6).value,
          year:self.year,
          month:self.month,
          debtAmount,
          debtText,
          notes:"problem-batch"
        });}
        no++
    });
    this.docs = arr;
    ev.target.value = null;
  }

  getRates() {
    axios.get(`${address}/arearates`).then((response) => {
      this.rates = response.data;
    });
  }
  postData() {

    for(const item of this.docs){
      axios
        .post(`${address}/usage-upsert`, item)
        .then((response) => {
          console.log(response);
        });
    }
    alert("ส่งข้อมูลไปยังแม่ข่ายแล้ว");
    this.$router.push("/portal/usage/list");
  }
}
