
import address from "@/variable";
import axios from "axios";
import { create } from "lodash";
import { DateTime } from "luxon";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";
import { saveAs } from 'file-saver';
@Options({
  components: {},
  props: {},
})
export default class InvoiceList extends Vue {
  store = useStore();
  docs: Array<any> = [];
  latest: Array<any> = [];
  counter: Array<any> = [];
  doc: Array<any> = [];
  selected: Array<string> = [];
  signature: string = "";
  search: any = {
    year: 0,
    month: 13,
    cat: 0,
  };
  filter: any = {
    isPrint: undefined,
  };
  sort: any = `{"no":1}`;
  isActive: boolean = false;
  loading: boolean = false;
  page: number = 0;
  pages: number = 0;
  show: number = 50;
  limit: number = 5;
  skip: number = 0;
  total: number = 0;
  working: boolean = false;
  calculationType: string = "";
  isPrint: boolean = true;
  status: boolean = true;
  totalCount: number = 0;
  totalQty: number = 0;
  totalAmount: number = 0;
  totalDebt: number = 0;
  ids: Array<string> = [];
  tasks: Array<any> = []


  downloadExcel(){
    console.log("download excel...");
    let self = this;
    const Excel = require("exceljs");
    var workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet(`รายงาน`);
    let header = [
      "ลำดับ",
      "เลขที่ใบแจ้งหนี้",
      "เลขที่ผู้ใช้น้ำ(ใหม่)",
      "ชื่อ",
      "ที่อยู่",
      "เดือนที่ค้างชำระ",
      "เงินคงค้าง(บาท)",
      "ปริมาณการใช้น้ำ",
      "ค่าบำบัดน้ำเสีย",
      "รวมเงิน(บาท)",
      "ภาษี",
      "ค่าบริการบำบัดน้ำเสีย",
      "รวมเงินชำระทั้งสิ้น(บาท)",
    ];
    worksheet.addRow(header);
    let newDocs:Array<any> =  Object.assign([],this.docs)
    if(this.selected != []) {
      newDocs = newDocs.filter(doc=>{
      return this.selected.includes(doc._id)
    })
    }
    newDocs.forEach((item, i) => {
      worksheet.addRow([
        i + 1,
        item.sequence,
        item.meter,
        (item.prefix ?? "") + (item.name ?? "-"),
        item.address,
        (item.debtText ?? "").length == 0 ? "-" : item.debtText,
        (item.debtAmount ?? 0).formatFull(),
        (item.qty ?? 0).formatFull(),
        item.category,
        this.sum(item).formatFull(),
        (this.sum(item) * item.vatRate).formatFull(),
        item.totalAmount.formatFull(),
        item.invoiceAmount.formatFull(),
      ]);
    });
    workbook.xlsx.writeBuffer().then(function(data: any) {
      var blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "รายการใบแจ้งหนี้.xlsx");
    });

    // let query = {
    //   search:{_id:{$in:this.selected}}
    // }
    // axios.post(`${address}/invoices-excel`, query,{responseType: 'blob'}).then((response) => {
    //   // Log somewhat to show that the browser actually exposes the custom HTTP header
    //   const fileNameHeader = "x-suggested-filename";
    //   const suggestedFileName = response.headers[fileNameHeader];
    //   const effectiveFileName = (suggestedFileName === undefined
    //               ? "download_report.xlsx"
    //               : suggestedFileName);
    //   console.log("Received header [" + fileNameHeader + "]: " + suggestedFileName
    //               + ", effective fileName: " + effectiveFileName);

    //   saveAs(response.data, effectiveFileName);
    // })
  }
  @Watch("search", { deep: true })
  setQuery() {
    this.$router.push({
      path: this.$route.path,
      query: {
        search: JSON.stringify(this.search),
      },
    });
  }
  getQuery() {
    try {
      // this.search = JSON.parse(this.$route.query.search as string);
    } catch (error) {}
  }
  @Watch("filter.isPrint")
  updateIsPrint() {
    this.fetchData();
  }
  changeActive() {
    this.docs.forEach((el) => {
      el.active = !this.isActive;
    });
  }
  sum(item: any): number {
    let sumAmount = 0;
    if(item.calculationType == "บาท/ลบ.ม.") sumAmount = item.qty * item.rate;
    else if(item.calculationType == "บาท/เดือน") sumAmount = item.rate;
    else sumAmount = item.rate;
      
    
    return sumAmount ?? 0;
  }
  mounted() {
    this.getLatest();
    this.getCounter();
    this.getQuery()
    this.fetchTask()
    try {
      console.log("search",this.$route.query.searchparam)
      this.search = JSON.parse(this.$route.query.search as string)
      if(this.search != undefined) this.fetchData();
    } catch (error) {
      console.log("error",error)
    }
  }
  clearPage(){
    if(this.show == 999999999){
      this.page =0;
      this.fetchData();
    }
  }
  deleteMany(){
    let userConfirm = confirm(`กรุณากลับไป reset เลขก่อนทำการ Import ใหม่ `);
    if(userConfirm){
      axios.post(`${address}/invoices-delete`,{list:this.selected}).then(response=>{
        this.fetchData()
      })
      .then((response) => {
        alert("ลบข้อมูลแล้ว");
      })
    }
  }
  updatePaginate(i: number) {
    this.page = i;
    // console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  getLatest() {
    axios
      .get(`${address}/invoices-information`)
      .then((response) => {
        console.log(response)
        this.latest = response.data[0];
      });
  }
  getCounter() {
    axios
      .get(`${address}/counters`)
      .then((response) => {
        console.log(response)
        this.counter = response.data;
        // this.counter.forEach((element: any,i : number) => {
        //   element.name == "Invoice"
        //   // element.year == this.search.year
        // })
      // result.filter(el => { return el.name == "Invoice" })
      // return result
      });
  }
  fetchTask() {
    const query = {
      search:{
        name:"สร้างใบแจ้งหนี้จากทะเบียนคุมผู้ใช้บริการ",
      },
      limit:3,
      skip:0,
      sort:{createdAt:-1}
    }
    axios
      .post(`${address}/tasks-paginate`, query).then((response) => {
        this.tasks = response.data.docs
        if(this.tasks.find(task=>task.status=="started")){
          setTimeout(() => {
            this.fetchTask()
          }, 2000);
        }
      })
  }
  fetchData() {
    this.loading = true;
    let searchDate;
    try {
      if (this.search.invoiceDate!=undefined) searchDate = DateTime.fromJSDate(this.search.invoiceDate);
    } catch (error) {}
    //console.log(this.search.createdAt, searchDate, typeof searchDate, searchDate.toISO())
    let category = null;
    let categoryType = null;
    try {
      category = this.search.cat.split(",")[0];
      categoryType = this.search.cat.split(",")[1];
    } catch (error) {}
    let query:any = {
      search: {
        code: JSON.parse(JSON.stringify(this.search.code)),
        $and: [
          { invoiceDate:searchDate != undefined ? { $gte: searchDate.startOf('day').toISO() } : undefined, },
          { invoiceDate:searchDate != undefined ? { $lte: searchDate.endOf('day').toISO() } : undefined, },
          {
            number:
              this.search.numberFrom != undefined
                ? { $gte: this.search.numberFrom }
                : undefined,
          },
          {
            number:
              this.search.numberTo != undefined
                ? { $lte: this.search.numberTo }
                : undefined,
          },
          {
            $or: [
              {
                name:
                  this.search.text != undefined
                    ? { $regex: this.search.text, $options: "-i" }
                    : undefined,
              },
              {
                meter:
                  this.search.text != undefined
                    ? { $regex: this.search.text, $options: "-i" }
                    : undefined,
              },
            ],
          },
          {
            invoiceAmount: this.search.unPaid
          },
          {
            category: category != undefined ? { $eq: category } : undefined,
          },
          // {
          //   categoryType:
          //     categoryType != undefined ? { $eq: categoryType } : undefined,
          // },
          {
            year:
              this.search.year != undefined
                ? { $eq: this.search.year }
                : undefined,
          },
          {
            month:
              this.search.month != undefined
                ? { $eq: this.search.month }
                : undefined,
          },
          {
            isPrint: this.filter.isPrint,
          },
          {
            printDate:
              this.search.printDate != undefined
                ? { $eq: this.search.printDate }
                : undefined,
          },
        ],
      },
      limit: 999999999,
      skip: this.page * this.show,
      sort: JSON.parse(this.sort),
    };
    if(this.search.unPaid>0){
      query.search = {
        invoiceAmount:this.search.unPaid,
        isPaid:false
      }
    }
    console.log(JSON.stringify(query.search))
    axios.post(`${address}/invoices-paginate`, query).then((response) => {
      this.loading = false;
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);

      this.totalCount = response.data.totalCount;
      this.totalQty = response.data.totalQty;
      this.totalAmount = response.data.totalAmount;
      this.totalDebt = response.data.totalDebt;
      this.ids = response.data.ids;

      let list = this.docs.map((el: any) => el._id);
    });
  }
  selectAll(ev: any) {
    let checked = ev.target.checked;
    if (checked) this.selected = this.ids;
    else this.selected = [];
  }
  btnClick() {
    this.store.commit("setInvoiceList", this.selected);
    if (this.selected.length != 0) {
      window.open(`/portal/invoice/print?searchparam=${JSON.stringify(this.search)}`), "_blank";
    }
  }
  getThaiMonth(n: number) {
    switch (n) {
      case 1:
        return "ม.ค.";
        break;
      case 2:
        return "ก.พ.";
        break;
      case 3:
        return "มี.ค.";
        break;
      case 4:
        return "เม.ย.";
        break;
      case 5:
        return "พ.ค.";
        break;
      case 6:
        return "มิ.ย.";
        break;
      case 7:
        return "ก.ค.";
        break;
      case 8:
        return "ส.ค.";
        break;
      case 9:
        return "ก.ย.";
        break;
      case 10:
        return "ต.ค.";
        break;
      case 11:
        return "พ.ย.";
        break;
      case 12:
        return "ธ.ค.";
        break;

      default:
        return ".";
        break;
    }
  }
}
