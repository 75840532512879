
import address, { filePath } from "@/variable";
import axios from "axios";
import { ECDH } from "crypto";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import * as _ from "lodash"
@Options({})
export default class InvoiceList extends Vue {
  store = useStore();
  // docs: Array<any> =[];
  signature:string = "";
  selected:any = {}
  receipts:Array<any> = []
  doc: Array<any> = [];
  docs: Array<any> = []
  address = address;
  filePath = filePath;
  show: number = 50;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 5;

  totalCount: number = 0;
  totalQty: number = 0;
  totalAmount: number = 0;
  totalPayment: number = 0;
  totalDebt: number = 0;

  selectedPaymentDate: string = "";

  id:string = ""
  get filteredReceipts(){
    try {
      if(this.selectedPaymentDate!='')
        return this.receipts.filter(rc=>rc.paymentDate===this.selectedPaymentDate)
      else this.receipts
    } catch (error) {
      
    }
  }
  get allPaymentDates(){
    return _.uniq(this.receipts.map(rc=>rc.paymentDate))
  }
  goToForm(){
    // alert("ไปเด้!")
    // let btn = this.$refs.cancelModal as HTMLElement
    // btn.click()
    this.store.commit("setReceiptList", this.receipts.map(el=>el._id));
    if (this.selected.length != 0) {
      window.open("/portal/receipt/print"), "_blank";
    }
    // this.$router.push(`/portal/approval/detail/${this.id}`)
  }
  goToRequest(){
    let btn = this.$refs.cancelModal as HTMLElement
    btn.click()
    this.$router.push(`/portal/approval/detail/${this.id}?dt=${this.selectedPaymentDate}`)
  }
  mounted() {
    this.fetchData();
  }
  print(){
    window.print();
  }
  getReceipt(list:Array<string>){
    let query = {
      search:{
        _id:{
          $in:list
        }
      },
      limit:999,
      skip:0
    }
    axios
      .post(`${address}/receipts-paginate`, query)
      .then((response) => {
        this.receipts = response.data.docs

        this.totalCount = response.data.totalCount;
        this.totalQty = response.data.totalQty;
        this.totalAmount = response.data.totalAmount;
        this.totalPayment = response.data.totalPayment;

        this.totalDebt = response.data.totalDebt;
      });    
  }
  approveReceipt(item: any, status: string) {
    axios
      .post(`${address}/process/approve/receipt`, { ...item, id: item._id })
      .then((response) => {
        alert("สำเร็จ!");
        this.fetchData();
      });    
  }
  signReceipt(item: any, status: string) {
    axios
      .post(`${address}/process/sign/receipt`, { ...item, id: item._id,signature:this.signature })
      .then((response) => {
        alert("สำเร็จ!");
        this.fetchData();
      });
  }
  editCoverLetter(item: any, status: string) {
    axios
      .patch(`${address}/request/${item._id}`, { status: status })
      .then((response) => {
      axios
        .patch(`${address}/coverletter/${item.to}`, { isPrint:false })
        .then((response) => {
          this.fetchData();
          alert("สำเร็จ!");
        });
    });
  }
  updateStatus(item: any, status: string) {
    if (status == "อนุมัติ") {
      axios
        .patch(`${address}/request/${item._id}`, { status: status })
        .then((response) => {
          axios
            .patch(`${address}/usage/${item.from._id}`, {
              ...item.to,
              _id: undefined,
            })
            .then((response) => {
              alert("สำเร็จ!");
              this.fetchData();
            });
        });
    } else {
      axios
        .patch(`${address}/request/${item._id}`, { status: status })
        .then((response) => {
          alert("สำเร็จ!");
          this.fetchData();
        });
    }
  }

  chooseSignature() {
    const preview = this.$refs.signatureImage as HTMLImageElement;
    let myinput = this.$refs.signatureInput as HTMLInputElement;
    const file = myinput!.files![0];
    const reader = new FileReader();
    let self = this
    reader.addEventListener(
      "load",
      function() {
        preview.src = reader.result as string;
        console.log(reader.result);
        self.signature = reader.result as string
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }
  }
  fetchData() {
    let query = {
      search: this.$route.name=="Approval Income"?{requestType:"แก้ไขใบนำส่ง"}:{requestType:{$ne:"แก้ไขใบนำส่ง"}},
      limit: 25,
      skip: 0,
      sort: { createdAt: -1 },
      populate: "requester",
    };
    console.log(query);
    axios.post(`${address}/requests-paginate`, query).then((response) => {
      console.log(response.data)
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
    });
  }
}
