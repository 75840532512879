
import address, { filePath } from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";
import { DateTime } from "luxon";
import { add } from "lodash";
@Options({})
export default class AlbumExample extends Vue {
  store = useStore();
  pdfBLOB = "";
  filePath = filePath;
  fonts: any = {};
  styles: any = {};
  page: number = 0;
  pages: number = 0;
  show: number = 999999;
  limit: number = 5;
  total: number = 0;
  skip: number = 0;
  docs: Array<any> = [];
  url: string = "";
  Date: Date = new Date();
  project: any = {};
  withQR: boolean = true;
  withDate: boolean = true;
  qrcode: boolean = true;
  barcode: boolean = false;
  invoiceDate: Date = new Date();
  taxId = "0994000203021";
  suffix = "01";
  @Watch("url")
  updateLogo() {
    this.generatePDF("preview");
  }
  calcTreatment(doc: any): number {
    if (doc.category == 1) return doc.qty * 3.0;
    else if (doc.category == 2) return doc.qty * 3.5;
    else if (doc.category == 3) return doc.qty * 4.0;
    else return 0;
  }
  calcTax(doc: any): number {
    let basisAmount = doc.rate * doc.qty * 0.07;
    return basisAmount || 0;
  }
  sum(doc: any): number {
    let sumAmount = 0;
    if (doc.calculationType == "บาท/ลบ.ม.") {
      sumAmount = doc.qty * doc.rate;
    } else {
      sumAmount = doc.rate;
    }
    return sumAmount ?? 0;
  }
  updateInvoiceDate() {
    this.docs.forEach((el) => {
      // axios.patch(`${address}/invoice/${el._id}`,{invoiceDate:this.invoiceDate}).then(response=>{
      //   console.log(response.data)
      // })
    });
    axios
      .post(`${address}/invoice-date`, {
        list: this.docs.map((el) => el._id),
        dt: this.invoiceDate,
      })
      .then((response) => {
        console.log(response.data);
      });
    this.generatePDF("preview");
  }
  mounted() {
    if (this.$route.name == "Invoice Print Demo") {
      this.store.commit("setInvoiceList", [
        "60bdce199a9b19bff705bfa3",
        "60bdcd8f9a9b19bff705bfa0",
        "60bdcd8f9a9b19bff705bf9f",
        "60bde79f10e6bc9a46c76af0",
      ]);
    }
    // var JsBarcode = require("jsbarcode");
    // JsBarcode("#barcode", "wma.jmandjm.com");
    this.getData();
    this.updateInvoiceDate();
  }
  getData() {
    var JsBarcode = require("jsbarcode");
    let list: Array<any> = this.store.state.invoiceList;
    axios
      .post(`${address}/process/print/invoice`, {
        limit: this.show,
        skip: this.page * this.show,
        list: list,
        sort: {
          no: 1,
          // sequence:1,
        },
      })
      .then((response) => {
        const list = response.data.docs;
        const isOdd = list.length % 2 != 0;
        const half = Math.ceil(list.length / 2);
        const firstHalf = list.slice(0, half);
        const secondHalf = list.slice(isOdd ? -(half - 1) : -half);
        let output = [];
        for (var i = 0; i < half; i++) {
          if (firstHalf[i] != undefined) output.push(firstHalf[i]);
          if (secondHalf[i] != undefined) output.push(secondHalf[i]);
        }
        this.docs = output;
        this.total = response.data.total;
        this.pages = Math.ceil(response.data.total / this.show);
        this.$nextTick(() => {
          this.docs.forEach((item) => {
            let invoiceAmount = (item.invoiceAmount * 100).toFixed(0);
            JsBarcode(
              "#barcode" + item._id,
              `|${this.taxId}${this.suffix}\r\n${item.meter}\r\n${item.sequence}\r\n${invoiceAmount}`
            );
          });
          this.generatePDF("preview");
        });
        // this.$nextTick(() => {
        //   this.generatePDF("preview");
        // });
        // this.generatePDF("preview")
      });

    list.forEach((element) => {
      if (element.isPrint == false)
        axios
          .patch(`${address}/invoice/${element}`, {
            isNextStage: true,
            isPrint: true,
          })
          .then((response) => {
            console.log(element + " - printed");
          });
    });
  }
  async generatePDF(mode: string) {
    var QRCode = require("qrcode");
    let mm = (u: number) => {
      return u / 0.352777778;
    };
    var pdfMake = require("pdfmake/build/pdfmake.js");
    this.fonts = this.store.getters.getPDFFonts;
    this.styles = this.store.getters.getPDFStyles;

    let oneReceipt = (doc: any, type: string, i: number) => {
      var canvas = this.$refs[doc._id] as any;
      var dataURL = canvas!.toDataURL();
      // var dataURL = ""
      let thisMonth = DateTime.fromObject({
        day: 5,
        month: doc.month,
        year: doc.year - 543,
      })
        .reconfigure({ outputCalendar: "buddhist" })
        .setLocale("th")
        .toFormat("LLLyy");
      let one = {
        pageSize: "A4",
        pageOrientation: "landscape",
        margin: type=="ใบแจ้งหนี้"?[10, 0, 0,0]:[5,0,0,7],
        stack: [
          {
            columns: [
              {
                image: "custom", //default
                width: 50,
                margin: [10, 2, 0, 7],
                // margin: [10, 9, 0, 7],
              },
              {
                margin: [22, 2, 0, 0],
                // margin: [20, 8, 0, 0],
                stack: [
                  [
                    {
                      text: "องค์การจัดการน้ำเสีย (สำนักงานใหญ่)",
                      style: "bold",
                      alignment: "left",
                    },
                    {
                      text: "333 อาคารเล้าเป้งง้วน 1 ชั้น 23 ถนนวิภาวดีรังสิต",
                      alignment: "left",
                    },
                    {
                      text:
                        " แขวงจอมพล เขตจตุจักร กรุงเทพฯ 10900 02-273-8530-9",
                      alignment: "left",
                    },
                    {
                      columns: [
                        { text: `เลขประจำตัวผู้เสียภาษี` },
                        {
                          text: `0 99 4000203021`,
                          alignment: "left",
                        },
                        {
                          text: `${type}`,
                          style: "bold",
                          fontSize: "8",
                          alignment: "right",
                          margin: [0, 0, 10, 0],
                        },
                      ],
                      alignment: "left",
                    },
                    {
                      columns: [
                        { text: `วันที่ออกใบแจ้งหนี้` },
                        {
                          text: this.invoiceDate.toThaiShort(),
                          alignment: "right",
                          margin: [0, 0, 10, 0],
                        },
                      ],
                    },
                  ],
                ],
              },
            ],
          },
          {
            layout: "border", // optional
            table: {
              widths: ["*", "*", "*", "*"],
              body: [
                [
                  "ใบแจ้งหนี้เลขที่",
                  "เลขที่ผู้ใช้น้ำ",
                  "ประเภทลูกค้า",
                  "ประจำเดือน",
                ],
                [
                  { text: `${(doc || {}).sequence}` },
                  { text: `${(doc || {}).meter}` },
                  { text: `${(doc || {}).category}` + ` (${(doc || {}).categoryType})`,},
                  { text: thisMonth,},
                ],
              ],
            },
            margin:[3, 0, 8, 1],
            // margin: [5, 0, 8, 1],
            alignment: "center",
          },
          
          {
            text: [
              { text: `ชื่อ`, style: "bold" },
              {
                text: `\t${(doc || {}).name}`,
                fontSize: "9",
              },
            ],
            alignment: "left",
            margin: [5, 0, 0, 0],
          },
          {
            text: [
              { text: `ที่อยู่   `, style: "bold" },
              {
                text: `${(doc || {}).address}`,
                fontSize: "9",
              },
            ],
            alignment: "left",
            margin: [5, 0, 0, 0],
          },
          {
            layout: {
              hLineColor: function(i: number, node: any) {
                return "#FFFFFF";
              },
              vLineColor: function(i: number, node: any) {
                return "#FFFFFF";
              },
              hLineWidth: function(i: number, node: any) {
                switch (i) {
                  case 3:
                  case 6:
                  case 7:
                  case 8:
                    return 1;
                    break;
                  case 4:
                    return 2;
                    break;
                  default:
                    return 0;
                    break;
                }
              },
            },
            table: {
              widths: ["*", 45, 25],
              heights: 4,
              body: [
                [
                  {
                    text: [
                      { text: `ยอดค้างชำระยกมาตั้งแต่ `, style: "bold" },
                      {
                        text: `${doc.debtText}`,
                        fontSize: "7",
                      },
                    ],
                  },
                  {
                    text: `${((doc || {}).debtAmount || 0).formatFull()}`,
                    alignment: "right",
                  },
                  { text: "บาท", alignment: "right" },
                ],
                [
                  {
                    text: `ปริมาณการใช้น้ำครั้งนี้ ${thisMonth}`,
                    style: "bold",
                  },

                  {
                    text: `${((doc || {}).qty || 0).formatFull()}`,
                    alignment: "right",
                  },
                  { text: "ลบ.ม.", alignment: "right" },
                ],
                [
                  "ค่าบำบัดน้ำเสียตามประเภทผู้ใช้น้ำ (ลบ.ม.)",
                  {
                    text: `${doc.rate.formatFull()}`,
                    alignment: "right",
                    border: [false, false, false, true],
                    borderColor: ["#ff00ff", "#00ffff", "#000000", "#000000"],
                  },
                  { text: "บาท", alignment: "right" },
                ],
                [
                  { text: "รวมเงิน", style: "bold" },
                  {
                    // text: `${doc.totalAmount.formatFull() || {} || 0}`,
                    text:doc.calculationType="บาท/เดือน"?`${(doc.rate??0).formatFull() || {} || 0}`:`${((doc.qty) * (doc.rate)).formatFull() || {} || 0}`,
                    alignment: "right",
                    border: [false, false, false, true],
                    borderColor: ["#ff00ff", "#00ffff", "#000000", "#000000"],
                  },
                  { text: "บาท", alignment: "right" },
                ],
                [
                  "ภาษีมูลค่าเพิ่ม",
                  {
                    text: `${(doc.vat ?? 0).formatFull() || {} || 0}`,
                    alignment: "right",
                  },
                  { text: "บาท", alignment: "right" },
                ],
                [
                  {
                    text: "ค่าบริการบำบัดน้ำเสียที่ต้องชำระ",
                    style: "bold",
                  },
                  {
                    text: `${(((doc.totalAmount) ?? 0) 
                    ).formatFull() ||
                      {} ||
                      0}`,
                    alignment: "right",
                    border: [false, false, false, true],
                    borderColor: ["#ff00ff", "#00ffff", "#000000", "#000000"],
                  },
                  { text: "บาท", alignment: "right" },
                ],
                [
                  "รวมเงินที่ต้องชำระทั้งสิ้น",
                  {
                    stack: [
                      {
                        text: `${(doc.invoiceAmount ?? 0).formatFull() ||
                          {} ||
                          0}`,
                        alignment: "right",
                        border: [false, false, false, true],
                        borderColor: "#000000",
                      },
                      {
                        canvas: [
                          {
                            type: "line",
                            x1: -5,
                            y1: 3,
                            x2: 50,
                            y2: 3,
                            lineWidth: 1,
                          },
                          {
                            type: "line",
                            x1: -5,
                            y1: 5,
                            x2: 50,
                            y2: 5,
                            lineWidth: 1,
                          },
                        ],
                      },
                    ],
                  },
                  { text: "บาท", alignment: "right" },
                ],
              ],
            },
            margin: [0, 0, 8, 0],
          },
          type == "ต้นขั้ว"
            ? {
                columns: [
                  {
                    text: `วันที่รับเงิน....................................................`,
                  },
                  { text: `(เอกสารออกเป็นชุด)`, alignment: "right" },
                ],
                margin: [5, 0, 10, 0],
              }
            : null,
          type == "ใบรับเงินชั่วคราว"
            ? {
                text: [
                  {
                    text: `(เอกสารออกเป็นชุด) ได้รับเงินถูกต้องแล้ว......................   พนักงานเก็บเงิน วันที่......./........./.......`,
                  },
                ],
                alignment: "left",
                margin: [5, 0, 0, 0],
              }
            : null,
          type == "ใบแจ้งหนี้"
            ? {
                text: [
                  {
                    text: `(เอกสารออกเป็นชุด) โปรดชำระเงินภายในวันที่ 25 `,
                  },
                  {
                    text: new Date(this.invoiceDate)
                      .toThaiShort()
                      .toString()
                      .substring(2, 13),
                    alignment: "right",
                    margin: [0, 0, 3, 0],
                  },
                ],
                alignment: "left",
                margin: [5, 0, 0, 10],
              }
            : null,
          type == "ต้นขั้ว"
            ? {
                text: [
                  {
                    text: `รับจริง.................................บาท`,
                  },
                ],
                alignment: "left",
                margin: [5, 0, 0, 0],
              }
            : null,
          type == "ใบรับเงินชั่วคราว"
            ? {
                text: [
                  {
                    text: `รับจริง.................................บาท`,
                  },
                ],
                alignment: "left",
                margin: [5, 0, 0, 0],
              }
            : null,
          this.qrcode
            ? {
                columns: [
                  { image: dataURL,
                    fit: [180, 50],
                  },
                  { qr: `|${this.taxId}${this.suffix}\r\n${doc.meter}\r\n${
                      doc.sequence
                    }\r\n${(doc.invoiceAmount * 100).toFixed(0)}\r\n`,
                    fit: "45",
                    width: 45,
                    margin: [0, -3, 0, 0],
                  },
                ],
                // margin: [30, 0, 20, 3],
                margin: [20,-1, 5, 5],
                pageBreak: (i+1)%2==0?"after":null,
              }
            : {
                text: "",
                margin: [0, 0, 0, 35],
                pageBreak: (i+1)%2==0?"after":null,
              },
        ],
      };
      return one;
    };
    var docDefinition = {
      images: {
        default: this.store.getters.base64Logo,
        example: "https://picsum.photos/200/300",
        custom:
          this.url == ""
            ? this.store.getters.base64Logo
            : this.filePath + "/" + this.url,
        custom1:
          this.url == ""
            ? this.store.getters.base64Water
            : this.filePath + "/" + this.url,
      },
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [0, 14, 0, 0],
      content: [
        {
          layout: {
            hLineStyle: function(i: number, node: any) {
              return { dash: { length: 4, space: 2 } };
            },
            vLineStyle: function(i: number, node: any) {
              return { dash: { length: 4, space: 2 } };
            },
            hLineColor: function(i: number, node: any) {
              return "#FFFFFF";
            },
            vLineColor: function(i: number, node: any) {
              return "#FFFFFF";
            },
          },
          table: {
            widths: ["*", "*", "*"],
            // fontSize: "5",
            // heights: [mm(210 / 2)],//[mm(207.5 / 2), mm(207.5 / 2), mm(207.5 / 2), mm(207.5 / 2)],
            body: [
              ...this.docs.map((el, i) => {
                return [
                  oneReceipt(el || {}, "ต้นขั้ว", i),
                  oneReceipt(el || {}, "ใบรับเงินชั่วคราว", i),
                  oneReceipt(el || {}, "ใบแจ้งหนี้", i),
                ];
              }),
            ],
          },
        },
      ],
      styles: this.styles,
      defaultStyle: {
        fontSize: "9",
        lineHeight: 0.8,
      },
    };
    if (mode == "preview") {
      let pdfDocGenerator = pdfMake.createPdf(docDefinition, null, this.fonts);
      pdfDocGenerator.getBlob((blob: any) => {
        let blobURL = URL.createObjectURL(blob);
        this.pdfBLOB = blobURL;
      });
    } else {
      let pdfDocGenerator = pdfMake
        .createPdf(docDefinition, null, this.fonts)
        .download(`ใบแจ้งหนี้.pdf`);
    }
  }
}
