
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
@Options({})
export default class ReceiptForm extends Vue {
  store = useStore();
  doc: any = {
    access:[]
  };

  deleteData(){
    let con = confirm("คุณต้องการลบข้อมูลใช่หรือไม่?")
    if(con==true){
      axios.delete(`${address}/user/${this.$route.params.id}`).then(response=>{
        this.$router.push('/portal/user/list')
      })
    }
  }
  selectAllBilling(ev:any){
    let arr = [
      "ทะเบียนคุมผู้ใช้บริการ",
      "ใบแจ้งหนี้",
      "ผู้ชำระเงิน",
      "คำขออนุมัติ",
      "สายรหัสเก็บเงิน",
      "ทะเบียนคุมใบคำร้อง",
      "ทะเบียนคุมใบเสร็จรับเงิน/ใบกำกับภาษี",
      "ทะเบียนคุมใบแจ้งหนี้",
      "รายงานตัดยอดหนี้",
      "รายการผู้ใช้น้ำ",
      "Dashboard",
      "ผลการจัดเก็บค่าบริการ",
      "รายงานภาษีขาย",
      "เทมเพลตเอกสารบิลลิ่ง",
      "ใบเสร็จรับเงิน/กำกับภาษี"
    ]
    if(ev.target.checked){
      arr.forEach(el=>{
        let found = this.doc.access.find((access:any)=>el===access)
        if(found==undefined) this.doc.access.push(el)
      })
    }else{
      arr.forEach(el=>{
        let found = this.doc.access.findIndex((access:any,i:number)=>el===access)
        if(found!=-1) this.doc.access.splice(found,1)
      })
    }
    // console.log(ev.target.checked)
    // if(ev.target!.checked)
  }

  selectAllIncome(ev:any){
    let arr = [
      "ค่าใช้จ่าย (กรอบ 2)",
      "สรุปรายรับ - รายจ่าย (กรอบ 3)",
      "ใบนำส่ง/Invoice",
      "แผนการจัดเก็บรายได้ประจำปีงบประมาณ",
      "Dashboard",
      "คำนวณยอดเงินสมทบ (กรอบ 4)",
      "คำขออนุมัติ",
      "ต้นทุนปันส่วนน้ำ",
      "รหัสบัญชี",
      "บันทึกการรับเงิน อปท.",
      "เงื่อนไขสัญญา อปท.",
      "ตารางแผน/ผลการจัดเก็บรายได้ที่ผ่านมา",
      "เปรียบเทียบแผน/ผลรายเดือน",
      "สรุปสถานะการจัดเก็บรายได้",
      "สถิติผลการจัดเก็บรายได้ที่ผ่านมา",
      "แผนการจัดเก็บรายได้"
    ]
    if(ev.target.checked){
      arr.forEach(el=>{
        let found = this.doc.access.find((access:any)=>el===access)
        if(found==undefined) this.doc.access.push(el)
      })
    }else{
      arr.forEach(el=>{
        let found = this.doc.access.findIndex((access:any,i:number)=>el===access)
        if(found!=-1) this.doc.access.splice(found,1)
      })
    }
  }


  mounted() {
    if (this.$route.params.id != undefined) this.getData();
  }
  getData() {
    axios.get(`${address}/user/${this.$route.params.id}`).then((response) => {
      this.doc = response.data;
      if(!Array.isArray(this.doc.access)) this.doc.access = []
    });
  }
  submit() {
    if (this.$route.params.id != undefined) this.patchData();
    else this.postData();
  }
  postData() {
    if (this.doc.password.length > 6) {
      if (this.doc.password == this.doc.checkPassword) {
        axios.post(`${address}/user`, this.doc).then((response) => {
          console.log(response);
          alert("สำเร็จ!");
        });
      } else {
        alert("รหัสผ่านไม่ตรงกัน!");
      }
    } else {
      alert("รหัสผ่านต้องมากกว่า 6 ตัวหนังสือขึ้นไปเท่านั้น!");
    }
  }
  patchData() {
    axios
      .patch(`${address}/user/${this.$route.params.id}`, {
        ...this.doc,
        _id: undefined,
      })
      .then((response) => {
        console.log(response);
        alert("สำเร็จ!");
      });
  }
}
