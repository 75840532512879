import { DateTime } from "luxon"
import * as _ from "lodash"
import axios from "axios"

let updateCategory = (ledgerLeft: Array<any>, ledgerRight: Array<any>) => {
  let getData = (cat: string) => {
    return _.chain([...ledgers.filter(el => el.category == cat), ...endorsed.filter(el => el.category == cat)]).groupBy('code').map((items, item) => {
      return {
        code: item,
        left: items.find(el => el.side == "left"),
        right: items.find(el => el.side == "right"),
        category: items.find(el => el.side == "left") != undefined ? items.find(el => el.side == "left").category : items.find(el => el.side == "right").category,
        name: items.find(el => el.side == "left") != undefined ? items.find(el => el.side == "left").name : items.find(el => el.side == "right").name
      }
    }).value()
  }
  var ledgers = _.chain(ledgerLeft).map(el => { return { ...el, side: 'left', months: undefined } }).value()
  var endorsed = _.chain(ledgerRight).map(el => { return { ...el, side: 'right', months: undefined } }).value()
  let u1 = getData("1")
  let u2 = getData("2")
  let u3 = getData("3")
  let u4 = getData("4")
  var union = [u1, u2, u3, u4]

  return union
}

let getCover = (area: any, doc: any, calculation: any) => {
  var htmlToPdfmake = require("html-to-pdfmake");
  var html = htmlToPdfmake(doc.content);
  let dt = doc.issueDate ? DateTime.now() : DateTime.fromISO(doc.issueDate)
  let dateFormat = dt
    .reconfigure({ outputCalendar: "buddhist" })
    .setLocale("th")
    .toFormat("LLLL yyyy").formatThai()
  let calc = calculation == undefined ? {} : calculation
  let calendarYear = (calc.calendarYear ?? new Date().getFullYear() + 543).toString() ?? ""
  let quarter = (calc.quarter ?? 1).toString() ?? ""
  console.log(calc)
  return {
    stack: [
      { text: `ที่ ${doc.documentNumber}` },
      { text: dateFormat, alignment: 'center' },
      {
        columns: [
          { text: `เรื่อง`, width: 45, marginRight: 10 },
          { text: `${doc.subject}` },
        ],
        marginTop: 10
      }, {
        columns: [
          { text: `เรียน`, width: 45, marginRight: 10 },
          { text: `${doc.to}` },
        ],
        marginTop: 10
      }, {
        columns: [
          { text: `${doc.showRef == false ? "" : 'อ้างถึง'}`, width: 45, marginRight: 10 },
          { text: `${doc.showRef == false ? "" : `หนังสือ ${(area.contractNumber ?? "").formatThai()}`}` },
        ],
        marginTop: doc.showRef == false ? 0 : 10
      }, {
        columns: [
          { text: `${doc.showAttachment == false ? "" : 'สิ่งที่ส่งมาด้วย'}`, width: 75, marginRight: 10 },
          {
            stack: [
              { text: `${doc.showAttachment == false ? "" : [`เอกสารการคำนวณ ${(calendarYear).formatThai()}`, quarter != 0 && quarter != undefined ? `ไตรมาส ${(quarter).formatThai()}` : " "]}` },
              (doc.attachmentsName ?? []).map((el: any, i: number) => {
                return { text: `${doc.showAttachment == false ? "" : `${(i + 1).toString().formatThai()}) ${el}`}` }
              })
            ]
          },
        ],
        marginTop: doc.showAttachment == false ? 0 : 10
      },
      {
        text: html,
        preserveLeadingSpaces: true,
        alignment: 'justify',
        marginTop: 10
      },
      {
        text: `จึงเรียนมาเพื่อโปรด${(doc.notifyTo ?? "").formatThai()}`,
        preserveLeadingSpaces: true,
        alignment: 'justify',
        marginTop: 10
      },
      {
        columns: [
          "",
          {
            stack: [
              { text: "ขอแสดงความนับถือ", alignment: "center" },
              {
                image: "signature",
                width: 100,
                height: 50,
                alignment: "center"
              },
              { text: `(${doc.approverName})`, alignment: "center" },
              { text: `${doc.approverPosition}`, alignment: "center" },
            ]
          }
        ],
        marginTop: 20
      },
      { text: "ฝ่ายบริการการจัดเก็บรายได้" },
      { text: "กองการจัดเก็บรายได้" },
      { text: "โทร. ๐ ๒๒๗๓ ๘๕๗๔" },
      { text: "โทรสาร ๐ ๒๒๗๓ ๘๕๘๐" },
      {
        absolutePosition: { x: 40, y: 775 },
        stack: [
          { text: "๓๓๓ อาคารเล้าเป้งง้วน ๑ ชั้น ๒๓ ถนนวิภาวดีรังสิต แขวงจอมพล เขตจตุจักร กทม. ๑๐๙๐๐ โทรศัพท์ ๐ ๒๒๗๓ ๘๕๓๐-๙ โทรสาร ๐๒๒๗๓ ๘๕๗๗-๙", fontSize: 13, lineHeight: 0.7 },
          {
            canvas: [
              {
                type: "line",
                x1: 0,
                y1: 5,
                x2: 515,
                y2: 5,
                lineColor: "#3760A0",
                lineWidth: 1,
              },
            ],
          },
          { text: "333 LAO PENG NGUAN TOWER 1 Fl. 23 VIBHAVADI-RANGSIT Rd., JOMPHON, CHATUJAK, BANGKOK 10900 Tel. O 2273 8530-9 FAX O 2273 8577-9", fontSize: 12, lineHeight: 0.7 },
        ]
      }
    ],
    pageBreak: "after",
  };
};


let getKrob2 = (area: any, doc: any, isLeft?: boolean, isRight?: boolean, pageBreak?: boolean) => {
  let ledgers = doc.ledgers.filter((el: any) => el.selected)
  let eLedgers = (doc.endorsedLedgers ?? []).filter((el: any) => el.selected)
  let union = updateCategory(ledgers, eLedgers)
  let categories: Array<string> = [
    "เงินเดือน (ผจก.,พนง.ลูกจ้าง)",
    "ค่าซ่อมแซมและบำรุงรักษาระบบบำบัดน้ำเสีย",
    "ค่าสาธารณูปโภค",
    "ค่าใช้จ่ายอื่นๆ"
  ]
  let result: Array<any> = []
  let result2: Array<any> = []
  console.log(area)
  let grandTotalLeft = 0
  let grandTotalRight = 0
  let colWidth = 0
  let isLeftRight = false
  let titleprefix = ""
  if (isLeft && isRight) {
    isLeftRight = true
    colWidth = 80
    titleprefix = ""
  }
  if (isRight && !isLeft) titleprefix = ""
  union.forEach((un: Array<any>, i: number) => {
    result.push([
      { text: `${(i + 1).toString().formatThai()} ` + categories[i], colSpan: 3, bold: true, pageBreak: ((i + 1) == 3) ? "before" : null },
      "",
      "",
      isLeft ? { text: `${doc.oldText}`, alignment: 'center', border: isLeftRight ? [true, true, true, true] : [false, false, false, false], fillColor: isLeftRight ? "#84D0DC" : "#FFFFFF" } : "",
      isRight ? { text: `${doc.newText}`, alignment: 'center', border: isLeftRight ? [true, true, true, true] : [false, false, false, false], fillColor: isLeftRight ? "#84D0DC" : "#FFFFFF" } : "",
      ""])
    let totalLeft = 0
    let totalRight = 0
    un.forEach(el => {
      totalLeft += (el.left ?? {}).totalAmount ?? 0
      totalRight += (el.right ?? {}).totalAmount ?? 0
      result.push([
        "",
        `${el.name}`,
        "",
        {
          text: isLeft ? ((el.left ?? {}).totalAmount ?? 0).formatFull().formatThai() : "",
          alignment: 'right',
          border: isLeftRight ? [true, true, true, true] : [false, false, false, false]
        },
        {
          text: isRight ? ((el.right ?? {}).totalAmount ?? 0).formatFull().formatThai() : "",
          alignment: 'right',
          border: isLeftRight ? [true, true, true, true] : [false, false, false, false]
        },
        "บาท"])
    })

    result.push(["", "รวม", "", {
      text: isLeft ? totalLeft.formatFull().formatThai() : "",
      alignment: 'right',
      border: isLeftRight ? [true, true, true, true] : [false, false, false, false]
    }, {
        text: isRight ? totalRight.formatFull().formatThai() : "",
        alignment: 'right',
        border: isLeftRight ? [true, true, true, true] : [false, false, false, false]
      }, "บาท"])
    result.push(
      isLeftRight ?
        ["", "", "", "", "", ""] : ["", "", "", singleLine(), "", ""]
    )
    grandTotalLeft += totalLeft
    grandTotalRight += totalRight
  })
  result2.push(["", { text: `รวมค่าใช้จ่ายจากการดำเนินงานขององค์การจัดการน้ำเสีย` }, "", {
    text: isLeft ?
      ((_.chain([...union[0], ...union[1], ...union[2], ...union[3]]).map('left.totalAmount')).reduce((sum, n) => sum + (n ?? 0), 0).value() ?? 0).formatFull().formatThai()
      : "",
    alignment: 'right',
    border: isLeftRight ? [true, true, true, true] : [false, false, false, false]
  },
    {
      text: isRight ?
        ((_.chain([...union[0], ...union[1], ...union[2], ...union[3]]).map('right.totalAmount')).reduce((sum, n) => sum + (n ?? 0), 0).value() ?? 0).formatFull().formatThai()
        : "",
      alignment: 'right',
      border: isLeftRight ? [true, true, true, true] : [false, false, false, false]
    },
    "บาท"])
  result2.push(
    isLeftRight ?
      ["", ((doc.quarter != 0) ? `(ไตรมาส ${doc.quarter.toString().formatThai()})` : "") + ` สำหรับปีงบประมาณ ${(doc.calendarYear ?? 0).toString().formatThai()}`, "", "", "", ""] : ["", ((doc.quarter != 0) ? `(ไตรมาส ${doc.quarter.toString().formatThai()})` : "") + `สำหรับปีงบประมาณ ${(doc.calendarYear ?? 0).toString().formatThai()}`, "", doubleLine(), "", ""]
  )
  return {
    stack: [
      { text: "ค่าใช้จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย " + area.prefix + area.name + titleprefix, alignment: "center", bold: true, pageBreak: pageBreak ? "before" : undefined },
      {
        text: [
          `ประจำปี ${(doc.calendarYear ?? 0).toString().formatThai()} `,
          doc.quarter != 0 && doc.quarter != undefined ? ` ไตรมาสที่ ${doc.quarter.toString().formatThai()} ` : " ",
          // getQuarterRange(doc.calendarYear ?? new Date().getFullYear(), doc.quarter),
          getQuarterRange(doc.quarter == 1 ? doc.calendarYear - 1 ?? new Date().getFullYear() : doc.calendarYear ?? new Date().getFullYear(), doc.quarter)
        ], alignment: "center", marginBottom: 10, bold: true
      },
      {
        layout: {
          defaultBorder: false,
        },
        table: {
          widths: [10, isLeftRight ? 285 : 365, "auto", isLeft ? 80 : 0, isRight ? 80 : 0, "auto"],
          body: [
            ...result,
            ...result2,
            // ["", { text: (`รวมค่าใช้จ่ายสำหรับปีงบประมาณ ${(doc.calendarYear ?? 0).toString().formatThai()}`) + ((doc.quarter!=0)?`ไตรมาสที่ ${(doc.quarter??0).toString().formatThai()}`:"") }, "",
            // { text: isLeft?((grandTotalLeft??0).formatFull().formatThai()):"" , alignment: "right",border:isLeftRight?[true,true,true,true]:[false,false,false,false]},
            // { text: isRight?((grandTotalRight??0).formatFull().formatThai()):"" , alignment: "right",border:isLeftRight?[true,true,true,true]:[false,false,false,false]},"บาท"],
            // isLeftRight?
            //  ["","","","","",""]:["","","",doubleLine(),"",""]
          ]
        }
      },
      // {
      //   text:`รวมค่าใช้จ่ายจากการดำเนินงานขององค์การจัดการน้ำเสียสำหรับปีงบประมาณ ${(doc.calendarYear ?? 0).toString().formatThai()}` + ((doc.quarter!=0)?`(ไตรมาส ${doc.quarter.toString().formatThai()}`:"")
      // },
      // {
      //   layout: {
      // 	  defaultBorder: false,
      //   },
      //   table: {
      //     widths: [10, "*", "auto", isLeft?80:0, isRight?80:0, "auto"],
      //     body: [
      //       ...result2,
      //     ]
      //   }
      // }
    ],
  };
}

let getKrob2Backup = (area: any, doc: any, isOldNew?: boolean, isRight?: boolean) => {
  let ledgers = doc.ledgers.filter((el: any) => el.selected)
  let eLedgers = (doc.endorsedLedgers ?? []).filter((el: any) => el.selected)
  let union = updateCategory(ledgers, eLedgers)
  let categories: Array<string> = [
    "เงินเดือน (ผจก.,พนง.ลูกจ้าง)",
    "ค่าซ่อมแซมและบำรุงรักษาระบบบำบัดน้ำเสีย",
    "ค่าสาธารณูปโภค",
    "ค่าใช้จ่ายอื่นๆ"
  ]
  let result: Array<any> = []
  console.log(area)
  let grandTotalLeft = 0
  let grandTotalRight = 0
  union.forEach((un: Array<any>, i: number) => {
    let arr1 = [{ text: `${(i + 1).toString().formatThai()} ` + categories[i], colSpan: 3, bold: true }, "", "", isOldNew ? { text: `${doc.oldText}`, alignment: 'center', border: isOldNew ? [true, true, true, true] : [false, false, false, false], fillColor: "#84D0DC" } : "", isOldNew ? { text: `${doc.newText}`, alignment: 'center', border: isOldNew ? [true, true, true, true] : [false, false, false, false], fillColor: "#84D0DC" } : "", ""]
    result.push(arr1)
    let totalLeft = 0
    let totalRight = 0
    un.forEach(el => {
      totalLeft += (el.left ?? {}).totalAmount ?? 0
      totalRight += (el.right ?? {}).totalAmount ?? 0
      result.push([
        "",
        { text: `\t\t${el.name}`, preserveLeadings: true },
        "",
        {
          text: ((el.left ?? {}).totalAmount ?? 0).formatFull().formatThai(),
          alignment: 'right',
          border: isOldNew ? [true, true, true, true] : [false, false, false, false]
        },
        {
          text: isOldNew ? ((el.right ?? {}).totalAmount ?? 0).formatFull().formatThai() : "",
          alignment: 'right',
          border: isOldNew ? [true, true, true, true] : [false, false, false, false]
        },
        "บาท"])
    })
    result.push(["", "รวม", "", {
      text: totalLeft.formatFull().formatThai(),
      alignment: 'right',
      border: isOldNew ? [true, true, true, true] : [false, false, false, false]
    }, {
        text: isOldNew ? totalRight.formatFull().formatThai() : "",
        alignment: 'right',
        border: isOldNew ? [true, true, true, true] : [false, false, false, false]
      }, "บาท"])
    result.push(
      isOldNew ?
        ["", "", "", "", "", ""] : ["", "", "", singleLine(), "", ""]
    )
    grandTotalLeft += totalLeft
    grandTotalRight += totalRight
  }),

    console.log(result)

  return {
    stack: [
      { text: "ค่าใช้จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย " + area.prefix + area.name, alignment: "center", bold: true },
      {
        text: [
          `ประจำปี ${(doc.calendarYear ?? 0).toString().formatThai()} `,
          doc.quarter != 0 && doc.quarter != undefined ? ` ไตรมาสที่ ${doc.quarter.toString().formatThai()} ` : " ",
          getQuarterRange(doc.calendarYear ?? new Date().getFullYear(), doc.quarter),
        ], alignment: "center", marginBottom: 10, bold: true
      },
      {
        layout: {
          defaultBorder: false,
        },
        table: {
          widths: [10, "*", "auto", 80, isOldNew ? 80 : 0, "auto"],
          body: [
            ...result,
            ["", { text: (`รวมค่าใช้จ่ายสำหรับปีงบประมาณ ${(doc.calendarYear ?? 0).toString().formatThai()}`) + ((doc.quarter != 0) ? `ไตรมาสที่ ${(doc.quarter ?? 0).toString().formatThai()}` : "") }, "", {
              text: (grandTotalLeft ?? 0).formatFull().formatThai(), alignment: "right", border: isOldNew ? [true, true, true, true] : [false, false, false, false]
            },
              isOldNew ? { text: (grandTotalRight ?? 0).formatFull().formatThai(), alignment: "right", border: isOldNew ? [true, true, true, true] : [false, false, false, false] } : "", "บาท"],
            isOldNew ?
              ["", "", "", "", "", ""] : ["", "", "", doubleLine(), "", ""]
          ]
        }
      }
    ],
  };
};

let getKrob2Type2 = (area: any, doc: any) => {
  let ledgers = doc.ledgers.filter((el: any) => el.selected)
  let eLedgers = doc.endorsedLedgers.filter((el: any) => el.selected)
  let category1: Array<any> = (ledgers ?? []).filter((el: any) => el.category == 1)
  let category2: Array<any> = (ledgers ?? []).filter((el: any) => el.category == 2)
  let category3: Array<any> = (ledgers ?? []).filter((el: any) => el.category == 3)
  let category4: Array<any> = (ledgers ?? []).filter((el: any) => el.category == 4)
  let eCategory1: Array<any> = (eLedgers ?? []).filter((el: any) => el.category == 1)
  let eCategory2: Array<any> = (eLedgers ?? []).filter((el: any) => el.category == 2)
  let eCategory3: Array<any> = (eLedgers ?? []).filter((el: any) => el.category == 3)
  let eCategory4: Array<any> = (eLedgers ?? []).filter((el: any) => el.category == 4)
  return {
    stack: [
      { text: "ค่าใช้จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย " + area.prefix + area.name, alignment: "center", bold: true },
      {
        text: [
          `ประจำปี ${(doc.calendarYear ?? 0).toString().formatThai()} `,
          doc.quarter != 0 && doc.quarter != undefined ? ` ไตรมาสที่ ${doc.quarter.toString().formatThai()} ` : " ",
          getQuarterRange(doc.calendarYear ?? new Date().getFullYear(), doc.quarter),
        ], alignment: "center", marginBottom: 10, bold: true
      },
      {
        layout: 'noBorders',
        table: {
          widths: [10, "*", "auto", 70, 70,],
          body: [
            [{ text: "๑ เงินเดือน (ผจก.,พนง.ลูกจ้าง)", colSpan: 5, bold: true }, "", "", "", ""],
            ["", "", "", { text: "เก่า", alignment: "center" }, { text: "ใหม่", alignment: "center" }],
            ...category1.map((el: any, i: number) => {
              // return ["", `๑.${(i + 1).toString().formatThai()} ${el.name}`, "", { text: el.totalAmount == 0 ? "-" : ((el.totalAmount ?? 0).formatFull().formatThai()), alignment: "right" }, "บาท"]
              return ["", `${el.name}`, "", { text: el.totalAmount == 0 ? "-" : ((el.totalAmount ?? 0).formatFull().formatThai()), alignment: "right" }, { text: eCategory1[i].totalAmount == 0 ? "-" : ((eCategory1[i].totalAmount ?? 0).formatFull().formatThai()), alignment: "right" }]
            }),
            ["", { text: "\t\t\t\tรวม", preserveLeadingSpaces: true }, "", { text: category1.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(), alignment: "right", bold: true }, { text: eCategory1.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(), alignment: "right", bold: true }],
            ["", "", "", singleLineType2(), singleLineType2()],
            [{ text: "๒ ค่าซ่อมแซมและบำรุงรักษาระบบบำบัดน้ำเสีย", colSpan: 5, bold: true }, "", "", "", ""],
            ...category2.map((el: any, i: number) => {
              // return ["", `๒.${(i + 1).toString().formatThai()} ${el.name}`, "", { text: el.totalAmount == 0 ? "-" : ((el.totalAmount ?? 0).formatFull().formatThai()), alignment: "right" }, "บาท"]
              return ["", `${el.name}`, "", { text: el.totalAmount == 0 ? "-" : ((el.totalAmount ?? 0).formatFull().formatThai()), alignment: "right" }, { text: eCategory2[i].totalAmount == 0 ? "-" : ((eCategory2[i].totalAmount ?? 0).formatFull().formatThai()), alignment: "right" }]
            }),
            ["", { text: "\t\t\t\tรวม", preserveLeadingSpaces: true }, "", { text: category2.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(), alignment: "right", bold: true }, { text: eCategory2.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(), alignment: "right", bold: true }],
            ["", "", "", singleLineType2(), singleLineType2()],
            [{ text: "๓ ค่าสาธารณูปโภค", colSpan: 5, bold: true }, "", "", "", ""],
            ...category3.map((el: any, i: number) => {
              // return ["", `๓.${(i + 1).toString().formatThai()} ${el.name}`, "", { text: el.totalAmount == 0 ? "-" : ((el.totalAmount ?? 0).formatFull().formatThai()), alignment: "right" }, "บาท"]
              return ["", `${el.name}`, "", { text: el.totalAmount == 0 ? "-" : ((el.totalAmount ?? 0).formatFull().formatThai()), alignment: "right" }, { text: eCategory3[i].totalAmount == 0 ? "-" : ((eCategory3[i].totalAmount ?? 0).formatFull().formatThai()), alignment: "right" }]
            }),
            ["", { text: "\t\t\t\tรวม", preserveLeadingSpaces: true }, "", { text: category3.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(), alignment: "right", bold: true }, { text: eCategory3.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(), alignment: "right", bold: true }],
            ["", "", "", singleLineType2(), singleLineType2()],
            [{ text: "๔ ค่าใช้จ่ายอื่นๆ", colSpan: 5, bold: true }, "", "", "", ""],
            ...category4.map((el: any, i: number) => {
              // return ["", `๔.${(i + 1).toString().formatThai()} ${el.name}`, "", { text: el.totalAmount == 0 ? "-" : ((el.totalAmount ?? 0).formatFull().formatThai()), alignment: "right" }, "บาท"]
              return ["", `${el.name}`, "", { text: el.totalAmount == 0 ? "-" : ((el.totalAmount ?? 0).formatFull().formatThai()), alignment: "right" }, { text: eCategory4[i].totalAmount == 0 ? "-" : ((eCategory4[i].totalAmount ?? 0).formatFull().formatThai()), alignment: "right" }]
            }),
            ["", { text: "\t\t\t\tรวม", preserveLeadingSpaces: true }, "", { text: category4.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(), alignment: "right", bold: true }, { text: eCategory4.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(), alignment: "right", bold: true }],
            ["", "", "", singleLineType2(), singleLineType2()],
            ["", { text: `รวมค่าใช้จ่ายสำหรับปีงบประมาณ ${(doc.calendarYear ?? 0).toString().formatThai()} ไตรมาสที่ ${(doc.quarter ?? 0).toString().formatThai()}` }, "",
              { text: ledgers.filter((el: any) => el.selected).map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(), alignment: "right", bold: true },
              { text: eLedgers.filter((el: any) => el.selected).map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(), alignment: "right", bold: true }
            ],
            ["", "", "", doubleLineType2(), doubleLineType2()],
          ]
        }
      }
    ],
  };
};
let getKrob3 = (area: any, doc: any, condition?: string, isLeft?: boolean, isRight?: boolean, pageBreak?: boolean) => {
  let isEndorsed = false
  let isLeftRight = false
  let grandTotalLeft = 0
  let grandTotalRight = 0
  let titleprefix = "(ก่อน สตง. รับรอง)"
  if (isLeft && isRight) {
    isLeftRight = true
    titleprefix = ""
  }
  try {
    if ((doc.endorsedLedgers ?? []).length > 0) isEndorsed = true
  } catch (error) {
  }
  let ledgers = doc.ledgers.filter((el: any) => el.selected)
  let eLedgers = (doc.endorsedLedgers ?? []).filter((el: any) => el.selected)
  let union = updateCategory(ledgers, eLedgers)
  let categories: Array<string> = [
    "เงินเดือน (ผจก.,พนง.ลูกจ้าง)",
    "ค่าซ่อมแซมและบำรุงรักษาระบบบำบัดน้ำเสีย",
    "ค่าสาธารณูปโภค",
    "ค่าใช้จ่ายอื่นๆ"
  ]
  let result: Array<any> = []
  result.push([
    "",
    "",
    "",
    isLeft ? { text: `${doc.oldText}`, alignment: 'center', border: isLeftRight ? [true, true, true, true] : [false, false, false, false], fillColor: isLeftRight ? "#84D0DC" : "#FFFFFF" } : "",
    isRight ? { text: `${doc.newText}`, alignment: 'center', border: isLeftRight ? [true, true, true, true] : [false, false, false, false], fillColor: isLeftRight ? "#84D0DC" : "#FFFFFF" } : "",
    ""])
  union.forEach((un: Array<any>, i: number) => {
    let totalLeft = 0
    let totalRight = 0
    un.forEach(el => {
      totalLeft += (el.left ?? {}).totalAmount ?? 0
      totalRight += (el.right ?? {}).totalAmount ?? 0
    })

    result.push(["",
      { text: `${(i + 1).toString().formatThai()} ` + categories[i], colSpan: 2, bold: false },
      "",
      {
        text: isLeft ? (totalLeft ?? 0).formatFull().formatThai() : "",
        alignment: 'right',
        border: isLeftRight ? [true, true, true, true] : [false, false, false, false]
      },
      {
        text: isRight ? (totalRight ?? 0).formatFull().formatThai() : "",
        alignment: 'right',
        border: isLeftRight ? [true, true, true, true] : [false, false, false, false]
      }, "บาท"])
    if (!isLeftRight) {
      ["", "", "", singleLine(), "", ""]
    }
    grandTotalLeft += totalLeft
    grandTotalRight += totalRight
  })
  result.push([
    "",
    { text: `รวมค่าใช้จ่ายในการดำเนินการขององค์กรการจัดการน้ำเสีย ` },
    "",
    { text: isLeft ? ((grandTotalLeft ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] },
    { text: isRight ? ((grandTotalRight ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] },
    "บาท"
  ],
    isLeftRight ?
      ["", "", "", "", "", ""] : ["", "", "", singleLine(), "", ""],
  )

  let expenses: Array<any> = []
  // try {
  //   expenses = (doc.expenses ?? []).map((el: any) => {
  //     return ["", el.name, "+", { text: (el.amount ?? 0).formatFull().formatThai(), alignment: "right" }, "บาท"]
  //   })
  // } catch (error) {
  //   console.log(error)
  // }
  try {
    expenses = (doc.expenses ?? []).map((el: any) => {
      // if ((doc.endorsedLedgers ?? []).length > 0)
        return ["", { text: `\t${el.name}`, preserveLeadingSpaces: true }, "+", { text: isLeft ? ((el.amount ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, { text: isRight ? ((el.eAmount ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, "บาท"]
      // else
      //   return ["", { text: `\t${el.name}`, preserveLeadingSpaces: true }, "+", { text: (el.amount ?? 0).formatFull().formatThai(), alignment: "right" }, "บาท"]
    })
  } catch (error) {
    console.log(error)
  }

  // expenses = [["", "", "+", { text: (9).formatFull().formatThai(), alignment: "right" }, "บาท"]]
  // expenses = [["", {text: expenses.map((el:any)=>el.name)}, "+", { text: expenses.map((el: any) => el.amount ?? 0), alignment: "right" }, "บาท"]]

  let category1: Array<any> = (ledgers ?? []).filter((el: any) => el.category == "1")
  let category2: Array<any> = (ledgers ?? []).filter((el: any) => el.category == "2")
  let category3: Array<any> = (ledgers ?? []).filter((el: any) => el.category == "3")
  let category4: Array<any> = (ledgers ?? []).filter((el: any) => el.category == "4")
  let eCategory1: Array<any> = (eLedgers ?? []).filter((el: any) => el.category == 1)
  let eCategory2: Array<any> = (eLedgers ?? []).filter((el: any) => el.category == 2)
  let eCategory3: Array<any> = (eLedgers ?? []).filter((el: any) => el.category == 3)
  let eCategory4: Array<any> = (eLedgers ?? []).filter((el: any) => el.category == 4)
  let withBorder = new Array(4).fill(true)
  let noBorder = new Array(4).fill(false)
  // let upperPart:Array<any> = [
  //     [{ text: "ค่าใช้จ่ายในการดำเนินงานขององค์การจัดการน้ำเสีย", colSpan: 5, decoration: "underline"}, "", "", "", "",""],
  //     ["","","",isEndorsed?{text:`${doc.oldText}`,alignment:"center",border:withBorder,bold:true,fillColor:"#84D0DC"}:"",isEndorsed?{ text:`${doc.newText}`,alignment:"center",border:withBorder,bold:true,fillColor:"#84D0DC"}:"",""],
  //     ["", "๑ เงินเดือน", "", { text: category1.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(),alignment: "right",border:isEndorsed?withBorder:noBorder },isEndorsed?{ text: eCategory1.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(), alignment: "right" ,border:withBorder}:"","บาท"],
  //     ["", "๒ ค่าซ่อมแซมและบำรุงรักษาระบบบำบัดน้ำเสีย", "+", { text: category2.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(),alignment: "right",border:isEndorsed?withBorder:noBorder},isEndorsed?{ text: eCategory2.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(), alignment: "right",border:withBorder }:"","บาท"],
  //     ["", "๓ ค่าสาธารณูปโภคอื่นๆ", "+", { text: category3.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(),alignment: "right",border:isEndorsed?withBorder:noBorder},  isEndorsed?{text:eCategory3.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(), alignment: "right",border:withBorder }:"","บาท"],
  //     ["", "๔ ค่าใช้จ่ายอื่นๆ", "+", { text: category4.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(),alignment: "right",border:isEndorsed?withBorder:noBorder},  isEndorsed?{text:eCategory4.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(), alignment: "right",border:withBorder}:"","บาท"],
  //     ["", "", "", isEndorsed?"":singleLine(),"",""],
  //     [{text:`รวมค่าใช้จ่ายในการดำเนินการขององค์กรการจัดการน้ำเสีย`,colSpan: 2}, "", "=", { text: doc.ledgers.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(), alignment: "right",border:isEndorsed?withBorder:noBorder},isEndorsed?{ text: eLedgers.map((el: any) => el.totalAmount ?? 0).reduce((a: number, b: number) => a + b, 0).formatFull().formatThai(), alignment: "right",border:withBorder }:"","บาท"],
  //     ["", "", "", isEndorsed?"":doubleLine(),"",""]
  //   ]
  let middlePart: Array<any> = [
    ["", "", "", "", "", ""],//["", "", "", "", "", isEndorsed?{ text: "-", color: "white" }:""],
    ["", { text: `\tปริมาณน้ำบำบัดได้ในปีงบประมาณ ${(doc.calendarYear ?? 0).toString().formatThai()}`, preserveLeadingSpaces: true }, "", { text: isLeft ? ((doc.treatmentQty ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, { text: isRight ? ((doc.eTreatmentQty ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, "ลบ.ม."],
    ["", { text: `\tต้นทุนปันส่วนต่อหน่วย`, preserveLeadingSpaces: true }, "x", { text: isLeft ? ((doc.allocationPerUnit ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, { text: isRight ? ((doc.eAllocationPerUnit ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, "บาท/ลบ.ม."],
    isLeftRight ?
      ["", "", "", "", "", ""] : ["", "", "", singleLine(), "", ""],
    ["", { text: `\tค่าใช้จ่ายปันส่วนตามปริมาณน้ำ`, preserveLeadingSpaces: true }, "=", { text: isLeft ? ((doc.allocationCost ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, { text: isRight ? ((doc.eAllocationCost ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, "บาท"],
    isLeftRight ?
      ["", "", "", "", "", ""] : ["", "", "", singleLine(), "", ""],
    ["", { text: `\tรวมค่าใช้จ่ายทั้งสิ้นของ อจน.`, preserveLeadingSpaces: true }, "", { text: isLeft ? ((doc.wmaExpenses ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, { text: isRight ? ((doc.eWmaExpenses ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, "บาท"],
    isLeftRight ?
      ["", "", "", "", "", ""] : ["", "", "", doubleLine(), "", ""],
    [{ text: "ค่าใช้จ่ายในการดำเนินงานของ  " + area.prefix + area.name, colSpan: 5, decoration: "underline" }, "", "", "", "", ""],
    ["", `ค่าใช้จ่ายในการดำเนินงานของ ` + area.prefix + area.name, "", "", "", ""],
    // ["", `ค่าใช้จ่ายในการดำเนินงานของ 1` + area.prefix + area.name, "", { text: isLeft?((doc.areaCost01 ?? 0).formatFull().formatThai()):"", alignment: "right" ,border:isLeftRight?[true,true,true,true]:[false,false,false,false]},{ text: isRight?((doc.eAreaCost01 ?? 0).formatFull().formatThai()):"", alignment: "right",border:isLeftRight?[true,true,true,true]:[false,false,false,false] } ,"บาท"],
    ["", { text: `\tค่าใช้จ่ายในการจัดเก็บค่าบริการ`, preserveLeadingSpaces: true }, "", { text: isLeft ? ((doc.areaCost02 ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, { text: isRight ? ((doc.eAreaCost02 ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, "บาท"],
    ["", { text: `\tค่าสื่อประชาสัมพันธ์`, preserveLeadingSpaces: true }, "+", { text: isLeft ? ((doc.areaCost03 ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, { text: isRight ? ((doc.eAreaCost03 ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, "บาท"],
    ...expenses,
    isLeftRight ?
      ["", "", "", "", "", ""] : ["", "", "", singleLine(), "", ""],
    ["", `รวมค่าใช้จ่ายทั้งสิ้นของ ` + area.prefix + area.name, "=", { text: isLeft ? (((doc.areaExpenses) ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, { text: isRight ? (((doc.eAreaExpenses) ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, "บาท"],
    isLeftRight ?
      ["", "", "", "", "", ""] : ["", "", "", doubleLine(), "", ""],
    [{ text: `รวมค่าใช้จ่ายทั้งสิ้นของ อจน. และ ` + area.prefix + area.name, colSpan: 2 }, "", "=", { text: isLeft ? (((doc.wmaExpenses ?? 0) + (doc.areaExpenses ?? 0)).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, { text: isRight ? (((doc.eWmaExpenses ?? 0) + (doc.eAreaExpenses ?? 0)).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, "บาท"],
    isLeftRight ?
      ["", "", "", "", "", ""] : ["", "", "", doubleLine(), "", ""],
  ]
  let lowerPart: Array<any> = [
    [{ text: `รายได้ในการดำเนินงานของ ` + area.prefix + area.name, colSpan: 5, decoration: "underline" }, "", "", "", "", ""],
    ["", { text: `รายได้จากการจัดเก็บค่าบริการ (บำบัดน้ำเสีย,เชื่อมท่อ,ฯลฯ) ของ` + area.prefix + area.name, colSpan: 3 }, "", "", "", ""],
    ["", `รายได้จากการจัดเก็บค่าบริการ `, "", { text: isLeft ? ((doc.income01 ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, { text: isRight ? ((doc.eIncome01 ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, "บาท"],
    ["", `รายได้จากการต่อท่อเชื่อม `, "+", { text: isLeft ? ((doc.income02 ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, { text: isRight ? ((doc.eIncome02 ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, "บาท"],
    isLeftRight ?
      ["", "", "", "", "", ""] : ["", "", "", singleLine(), "", ""],
    [{ text: `รวมรายได้จากการจัดเก็บค่าบริการ`, colSpan: 2 }, "", "=", { text: isLeft ? ((doc.totalIncome ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, { text: isRight ? ((doc.eTotalIncome ?? 0).formatFull().formatThai()) : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }, "บาท"],
    isLeftRight ?
      ["", "", "", "", "", ""] : ["", "", "", doubleLine(), "", ""],
  ]
  // condition
  // alert(condition)
  return {
    stack: [
      { text: "สรุปรายรับ - จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย", alignment: "center", bold: true },
      { text: area.prefix + area.name, alignment: "center", bold: true },
      {
        text: [
          `ประจำปี ${(doc.calendarYear ?? 0).toString().formatThai()} `,
          doc.quarter != 0 && doc.quarter != undefined ? ` ไตรมาสที่ ${doc.quarter.toString().formatThai()} ` : " ",
          getQuarterRange(doc.quarter == 1 ? doc.calendarYear - 1 ?? new Date().getFullYear() : doc.calendarYear ?? new Date().getFullYear(), doc.quarter)
        ], alignment: "center", marginBottom: 10, bold: true
      },
      {
        layout: { defaultBorder: false },
        table: {
          widths: [3, "*", "auto", (isLeft || isLeftRight) ? 80 : 0, (isRight || isLeftRight) ? 80 : 0, 50],
          body: [
            // ...upperPart,
            ...result,

            ...((condition != "สมทบตามเปอร์เซ็นต์"&&condition != undefined) ? middlePart : []),
            ...((condition != "สมทบตามเปอร์เซ็นต์"&&condition != undefined) ? lowerPart : [])
          ]
        },
      }
    ],
  };
};

let address = require("../variable").default
//แสนสุข//
let getKrob4Custom = (area: any, doc: any, RCalculation: any, areaCondition?: any, operation?: number, state?: any, isLeft?: boolean, isRight?: boolean, pageBreak?: boolean) => {
  let calculation = JSON.parse(JSON.stringify(RCalculation))
  let underline: Array<Array<Boolean>> = []
  let condition = areaCondition.conditions[operation ?? 0]
  let conditionCat = areaCondition.conditions[operation ?? 1].category
  let calculation1: Array<any> = new Array(20).fill(0)
  let calculation2: Array<any> = new Array(20).fill(0)
  let foundCondition: any = {}
  let isEndorsed = false
  let colWidth = 0
  let isLeftRight = false
  let titleprefix = ""
  let manuals: Array<any> = new Array(20).fill(0)
  let calc = calculation == undefined ? {} : calculation
  let calendarYear = (calc.calendarYear ?? new Date().getFullYear() + 543).toString() ?? ""
  if (isLeft && isRight) {
    isLeftRight = true
    colWidth = 80
    titleprefix = ""
  }
  if (isRight && !isLeft) titleprefix = ""
  try {
    if ((doc.endorsedLedgers ?? []).length > 0) isEndorsed = true
  } catch (error) {
  }
  let commonText = ""
  if (doc.totalExpenses > doc.totalIncome) {
    if (doc.quarter == 0) {
      commonText = `ยอดที่ อปท. พึงชำระสำหรับปีงบประมาณ `
    } else {
      commonText = `ยอดที่ อปท. พึงชำระสำหรับปีงบประมาณ `
    }
  }
  else {
    if (doc.quarter == 0) {
      commonText = `รวมที่พึงชำระสำหรับปีงบประมาณ `
    } else {
      commonText = `รวมที่พึงชำระสำหรับปีงบประมาณ `
    }
  }
  let commonText2 = "*"
  if (doc.quarter == 0) {
    commonText2 = `${(doc.collectYear ?? 0).toString().formatThai()}`
  } else {
    commonText2 = `${(doc.collectYear ?? 0).toString().formatThai()}ไตรมาสที่ ${(doc.quarter ?? 0).toString().formatThai()}`
  }
  let modifications: Array<any> = []
  let adjustText = ""
  if ((doc.modifications ?? []).length > 0) adjustText = "รายการปรับปรุงค่าใช้จ่าย"
  try {
    modifications = doc.modifications ?? [].map((el: any) => {
      return ["", el.name, el.symbol, { text: (el.amount ?? 0).formatFull().formatThai(), alignment: "right" }, "บาท"]
    })
  } catch (error) {
    console.log(error)
  }
  console.log("state", state)
  var checkModified = () => {
    if ((doc.modifications ?? []).length > 0) {
      let sum = 0
      let eSum = 0
      doc.modifications.forEach((mod: any) => {
        if (mod.symbol == "+") {
          sum += mod.amount
          eSum += mod.eAmount
        }
        else if (mod.symbol == "-") {
          sum -= mod.amount
          eSum -= mod.eAmount
        }
      });
      console.log(sum, eSum)
      doc.contributionAmount = sum + calculation1[calculation1.length - 1] // was originalDoc
      doc.eContributionAmount = eSum + calculation2[calculation2.length - 1] // was this.originalDoc
    } else {
      if (conditionCat == 'สมทบตามเปอร์เซ็นต์') {
        doc.contributionAmount = (calculation1[calculation1.length - 1] ?? 0) - (doc.advanceAmount ?? 0) // was originalDoc
        doc.eContributionAmount = (calculation2[calculation2.length - 1] ?? 0) - (doc.eAdvanceAmount ?? 0)  // was originalDoc
      }
    }
  }

  function insert(str: string, index: number, value: string) {
    return str.substr(0, index) + value + str.substr(index);
  }

  let conText = doc.contributionText ?? ""
  var spaceCount = (conText.split(" ").length - 1);
  if (spaceCount < 5 && conText.length > 80) {
    try {
      for (let i = 0; i < (doc.contributionText.length); i += 40) {
        conText = insert(conText, i, " ")
      }
    } catch (error) {

    }
  }
  let getSymbol = (cal:string) => {
    switch (cal) {
      case "plus":
        return "+"
        break;
      case "minus":
        return "-"
        break;
      case "subtract,0,1":
        return "="
        break;
      case "divide,4,2":
        return "="
        break;
    
      default:
        return ""
        break;
    }
  }
  // console.log(foundCondition)
  // return "ทดสอบเอกสาร 4"
  return {
    stack: [
      pageBreak ? { text: "", pageBreak: "after" } : null,
      {
        text: `รายการคำนวณยอดเงินสมทบที่ อจน. เรียกเก็บจาก อปท. (${area.prefix ?? ''} ${area.name ?? ''})`, alignment: "center", bold: true,
        marginBottom: 10,
      },
      {
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: [conText ?? ""],
                // margin: 10,
                margin: [6, 2, 6, 2],
                alignment: "center"
              }
            ]
          ]
        },
        marginBottom: 10,
      },
      {
        marginTop: 10,
        text: ""
      },
      {
        marginLeft: 5,
        text: foundCondition.name == 'สมทบตามจำนวนเปอร์เซนต์' ? `คิดค่าใช้จ่ายร้อยละ ${condition.contributionPercent}` : ""
      },
      {
        layout: { defaultBorder: false },
        table: {
          widths: ["auto", "*", "*", isLeft ? 80 : 0, isRight ? 80 : 0, "auto"],
          body: [
            [
              "",
              "",
              "",
              isLeftRight ? { text: `${doc.oldText}`, alignment: 'center', border: isLeftRight ? [true, true, true, true] : [false, false, false, false], fillColor: isLeftRight ? "#84D0DC" : "#FFFFFF" } : isLeft ? doc.oldText : "",
              isLeftRight ? { text: `${doc.newText}`, alignment: 'center', border: isLeftRight ? [true, true, true, true] : [false, false, false, false], fillColor: isLeftRight ? "#84D0DC" : "#FFFFFF" } : isRight ? doc.newText : "",
              ""
            ],
            ...(doc.manuals??[]).filter((m:any)=>m.enable).map((m:any,i:number)=>{
              return [
                  m.name.replace("**area**",(area.prefix ?? '') + (area.name ?? '')).replace("**year**", doc.calendarYear).formatThai().replace("**quarter**", doc.quarter).replace('ไตรมาสที่ 0', "").replace('0', ""), 
                  {
                      text: ""
                  }, 
                  {
                    text: getSymbol(m.calculation),
                    alignment:"right"
                  },
                  { text: isLeft ? ((m.value ?? 0).formatFull().formatThai()) : "", border: isLeftRight ? [true, true, true, true] : underline[i], alignment: 'right' },
                  { text: isRight ? ((m.eValue ?? 0).formatFull().formatThai()) : "", border: isLeftRight ? [true, true, true, true] : underline[i], alignment: 'right' },
                  {
                    text: "บาท"
                  },
                ]
            })
            // ...((foundCondition ?? { children: [] }).children ?? [{}]).map((el: any, i: number) => {
            //   let length = ((foundCondition ?? { children: [] }).children ?? [{}]).length
            //   //decoration: "underline"
            //   // console.log("foundCondition",foundCondition)
            //   try {
            //     if ((el.search('เงื่อนไขสมทบค่าใช้จ่าย/ในสัดส่วนเท่าเทียมกันไม่เกิน') == -1 && area.name != 'ลำปาง') || area.name == 'ลำปาง') {
            //       let row = [
            //         (el ?? "").replace("*area*", (area.prefix ?? '') + (area.name ?? '')).replace("*year*", doc.calendarYear).replace("*quarter*", doc.quarter).replace("*contributionLimit*", (condition.contributionLimit ?? 0).formatFull().formatThai()).replace("*contributionPercent*", condition.contributionPercent).replace('ไตรมาสที 0', "").replace('ไตรมาสที่ 0', ""),
            //         {
            //           text: ""
            //         },
            //         {
            //           text: (foundCondition ?? { symbols: new Array(10).fill("") }).symbols[i],
            //           alignment:"right"
            //         },
            //         { text: isLeft ? ((calculation1[i] ?? 0).formatFull().formatThai()) : "", border: isLeftRight ? [true, true, true, true] : underline[i], alignment: 'right' },
            //         { text: isRight ? ((calculation2[i] ?? 0).formatFull().formatThai()) : "", border: isLeftRight ? [true, true, true, true] : underline[i], alignment: 'right' },
            //         {
            //           text: "บาท"
            //         },
            //       ]
            //       return row
            //     } else {
            //       return null
            //     }
            //   } catch (error) {
            //     console.log("condition error", error)
            //     console.log(calculation1, calculation2)
            //     return !isEndorsed ? ["", "", "", "", "", ""] : ["", "", "", "", "", ""]
            //   }
            // }).filter((el: any) => el != null)
          ]
        }
      },
      {
        marginTop: 20,
        text: ""
      },
      !isEndorsed ?
        [{ text: adjustText, colSpan: 2, preserveLeadingSpaces: true, decoration: "underline" }, "", "", "", ""] :
        [{ text: adjustText, colSpan: 2, preserveLeadingSpaces: true, decoration: "underline" }, "", "", "", "", ""],

      {
        layout: { defaultBorder: false },
        table: {
          widths: ["auto", "*", "*", isLeft ? 80 : 0, isRight ? 80 : 0, "auto"],
          body: [
            ...modifications.map((el: any) => {
              if (isLeftRight)
              return [
                "", 
                { text: `\t${el.name}` }, 
                {text: `${el.symbol}`, alignment:"right"}, 
                { text: isLeft ? (el.amount == 0 ? "-" : ((el.amount ?? 0).formatFull().formatThai())) : "", alignment: "right", border: [true, true, true, true] }, 
                { text: isRight ? (el.eAmount == 0 ? "-" : ((el.eAmount ?? 0).formatFull().formatThai())) : "", alignment: "right", border: [true, true, true, true] }, 
                "บาท"
              ]
              else if (isLeft)
                return ["", { text: `\t${el.name}` },  {text:`${el.symbol}`,alignment:"right" }, { text: el.amount == 0 ? "-" : ((el.amount ?? 0).formatFull().formatThai()), alignment: "right" },"", "บาท"]
              else if (isRight)
                return ["", { text: `\t${el.name}` }, "", `${el.symbol}`, { text: el.amount == 0 ? "-" : ((el.amount ?? 0).formatFull().formatThai()), alignment: "right" }, "บาท"]

              else
                return ["","","","","",""]
            }),
            [
              { text: (commonText??"").replace("ปีงบประมาณ ๐",""), colSpan: 2 },
              "",
              "",
              doc.isVat?
              { text: isLeft ? (((doc.contributionAmount*1.07) ?? 0).formatFull().formatThai()).replace("NaN", "๐.๐๐") : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }
              :{ text: isLeft ? ((doc.contributionAmount ?? 0).formatFull().formatThai()).replace("NaN", "๐.๐๐") : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] },
              doc.isVat?
              { text: isRight ? (((doc.eContributionAmount*1.07) ?? 0).formatFull().formatThai()).replace("NaN", "๐.๐๐") : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] }
              :{ text: isRight ? ((doc.eContributionAmount ?? 0).formatFull().formatThai()).replace("NaN", "๐.๐๐") : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] },
              "บาท"
            ],
            isLeftRight ? [{ text: (commonText2??"").replace("๐",""), colSpan: 2 }, "", "", "", "", ""] : [{ text: (commonText2??"").replace("๐",""), colSpan: 2 }, "", "", doubleLine2(), "", ""],
          ]
        }
      },
    ],

  };
};
//พื้นที่อื่นๆ//
let getKrob4Original = (area: any, doc: any, doc2?: any, areaCondition?: any, operation?: number, state?: any, isLeft?: boolean, isRight?: boolean, pageBreak?: boolean) => {
  let oldDoc = doc
  area = JSON.parse(JSON.stringify(area))
  doc = JSON.parse(JSON.stringify(doc))
  areaCondition = JSON.parse(JSON.stringify(areaCondition))
  operation = JSON.parse(JSON.stringify(operation))
  state = JSON.parse(JSON.stringify(state))

  let underline: Array<Array<Boolean>> = []
  let condition = areaCondition.conditions[operation ?? 0]
  let conditionCat = areaCondition.conditions[operation ?? 1].category
  let calculation1: Array<any> = new Array(20).fill(0)
  let calculation2: Array<any> = new Array(20).fill(0)
  let foundCondition: any = {}
  let isEndorsed = false
  let colWidth = 0
  let isLeftRight = false
  let titleprefix = ""
  if (isLeft && isRight) {
    isLeftRight = true
    colWidth = 80
    titleprefix = ""
  }
  if (isRight && !isLeft) titleprefix = ""
  try {
    if ((doc.endorsedLedgers ?? []).length > 0) isEndorsed = true
  } catch (error) {
  }
  let commonText = ""
  if (doc.totalExpenses > doc.totalIncome) {
    if (doc.quarter == 0) {
      commonText = `ยอดที่ อปท. พึงชำระสำหรับปีงบประมาณ `
    } else {
      commonText = `ยอดที่ อปท. พึงชำระสำหรับปีงบประมาณ `
    }
  }
  else {
    if (doc.quarter == 0) {
      commonText = `รวมที่พึงชำระสำหรับปีงบประมาณ `
    } else {
      commonText = `รวมที่พึงชำระสำหรับปีงบประมาณ `
    }
  }
  let commonText2 = "*"
  if (doc.quarter == 0) {
    commonText2 = `${(doc.calendarYear ?? 0).toString().formatThai()}`
  } else {
    commonText2 = `${(doc.calendarYear ?? 0).toString().formatThai()} ไตรมาสที่ ${(doc.quarter ?? 0).toString().formatThai()}`
  }
  let modifications: Array<any> = []
  let adjustText = ""
  if ((doc.modifications ?? []).length > 0) adjustText = "รายการปรับปรุงค่าใช้จ่าย"
  try {
    modifications = doc.modifications ?? [].map((el: any) => {
      return ["", el.name, el.symbol, { text: (el.amount ?? 0).formatFull().formatThai(), alignment: "right" }, "บาท"]
    })
  } catch (error) {
    console.log(error)
  }
  console.log("state", state)
  var checkModified = () => {
    if ((doc.modifications ?? []).length > 0) {
      let sum = 0
      let eSum = 0
      doc.modifications.forEach((mod: any) => {
        if (mod.symbol == "+") {
          sum += mod.amount
          eSum += mod.eAmount
        }
        else if (mod.symbol == "-") {
          sum -= mod.amount
          eSum -= mod.eAmount
        }
      });
      console.log(sum, eSum)
      doc.contributionAmount = sum + calculation1[calculation1.length - 1] // was originalDoc
      doc.eContributionAmount = eSum + calculation2[calculation2.length - 1] // was this.originalDoc
    } else {
      if (conditionCat == 'สมทบตามเปอร์เซ็นต์') {
        doc.contributionAmount = (calculation1[calculation1.length - 1] ?? 0) - (doc.advanceAmount ?? 0) // was originalDoc
        doc.eContributionAmount = (calculation2[calculation2.length - 1] ?? 0) - (doc.eAdvanceAmount ?? 0)  // was originalDoc
      }
    }
  }
  switch (conditionCat) {
    case 'สมทบตามเปอร์เซ็นต์':
      let q = doc.quarter
      // let limit = q == '0' ? condition.contributionLimit : condition.contributionLimit / 4
      // if (limit < 1) limit = 9E10
      let a = (doc.wmaExpenses) * ((condition.contributionPercent) / 100)
      // if (a > limit) doc.contributionAmount = limit
      doc.contributionAmount = a
      doc.contributionAmount = (doc.contributionAmount ?? 0) - (doc.advanceAmount ?? 0)
      calculation1 = [
        doc.advanceAmount,
        doc.contributionAmount,
        doc.contributionAmount * 0.07
      ]
      let b = (doc.eWmaExpenses) * ((condition.contributionPercent) / 100)
      // if (b > limit) doc.eContributionAmount = limit
      doc.eContributionAmount = b
      doc.eContributionAmount = (doc.eContributionAmount ?? 0) - (doc.eAdvanceAmount ?? 0)
      calculation2 = [
        doc.eAdvanceAmount,
        doc.eContributionAmount,
        doc.eContributionAmount * 0.07
      ]
      checkModified()
      underline = [
        [false, false, false, false],
        [false, false, false, true],
      ]
      foundCondition = state.conditionPercent
      break;
    case 'สมทบตามจำนวนคงที่':
      calculation1 = [
        doc.totalExpenses,
        doc.totalIncome,
        doc.totalExpenses - doc.totalIncome,
        doc.advanceAmount,
        doc.contributionAmount,
        doc.contributionAmount * 0.07
      ]
      calculation2 = [
        doc.eTotalExpenses,
        doc.eTotalIncome,
        doc.eTotalExpenses - doc.eTotalIncome,
        doc.eAdvanceAmount,
        doc.contributionAmount,
        doc.eContributionAmount * 0.07
      ]
      checkModified()
      underline = [
        [false, false, false, false],
        [false, false, false, true],
        [false, false, false, true],
        [false, false, false, true],
      ]
      foundCondition = state.conditionFixed
      break;
    case 'สมทบตามค่าใช้จ่ายจริง/สัดส่วนเท่าเทียม':
      let income = 0
      let expense = 0
      if (doc.ledgers.length == 0) {
        income = doc.eTotalIncome
        expense = doc.eTotalExpenses
      } else {
        income = doc.totalIncome
        expense = doc.totalExpenses
      }
      let isProfit = (income - expense) > 0
      if (isProfit) { // กำไร
        console.log("กำไร", condition.profitType)
        if (condition.profitType == 1) {
          doc.contributionAmount = ((doc.totalIncome - (((doc.totalIncome - doc.totalExpenses) - ((doc.totalIncome - doc.totalExpenses) / (100 * 30))) / 2)) - (doc.advanceAmount ?? 0))
          doc.contributionAmount = Math.abs(parseFloat((doc.contributionAmount ?? 0).toFixed(2))) - (doc.advanceAmount ?? 0)
          doc.contributionAmount = Math.abs(parseFloat((doc.contributionAmount ?? 0).toFixed(2)))
          calculation1 = [
            doc.totalIncome,
            doc.totalExpenses,
            doc.totalIncome - doc.totalExpenses,
            (doc.totalIncome - doc.totalExpenses) / (100 * 30),
            (doc.totalIncome - doc.totalExpenses) - ((doc.totalIncome - doc.totalExpenses) / (100 * 30)),
            ((doc.totalIncome - doc.totalExpenses) - ((doc.totalIncome - doc.totalExpenses) / (100 * 30))) / 2,
            doc.totalIncome - (((doc.totalIncome - doc.totalExpenses) - ((doc.totalIncome - doc.totalExpenses) / (100 * 30))) / 2),
            doc.advanceAmount,
            doc.contributionAmount,
            doc.contributionAmount * 0.07
          ]
          doc.eContributionAmount = ((doc.eTotalIncome - (((doc.eTotalIncome - doc.eTotalExpenses) - ((doc.eTotalIncome - doc.eTotalExpenses) / (100 * 30))) / 2)) - (doc.eAdvanceAmount ?? 0))
          doc.eContributionAmount = Math.abs(parseFloat((doc.eContributionAmount ?? 0).toFixed(2))) - (doc.eAdvanceAmount ?? 0)
          doc.eContributionAmount = Math.abs(parseFloat((doc.eContributionAmount ?? 0).toFixed(2)))
          calculation2 = [
            doc.eTotalIncome,
            doc.eTotalExpenses,
            doc.eTotalIncome - doc.eTotalExpenses,
            (doc.eTotalIncome - doc.eTotalExpenses) / (100 * 30),
            (doc.eTotalIncome - doc.eTotalExpenses) - ((doc.eTotalIncome - doc.eTotalExpenses) / (100 * 30)),
            ((doc.totalIncome - doc.totalExpenses) - ((doc.totalIncome - doc.totalExpenses) / (100 * 30))) / 2,
            doc.eTotalIncome - (((doc.eTotalIncome - doc.eTotalExpenses) - ((doc.eTotalIncome - doc.eTotalExpenses) / (100 * 30))) / 2),
            doc.eAdvanceAmount,
            doc.eContributionAmount,
            doc.eContributionAmount * 0.07
          ]
          checkModified()
          foundCondition = state.conditionProfitType1
          underline = [
            [false, false, false, false],
            [false, false, false, false],
            [false, false, false, true],
            [false, false, false, false],
            [false, false, false, true],
            [false, false, false, false],
            [false, false, false, false],
            [false, false, false, true],
            [false, false, false, true],
          ]
        }
        if (condition.profitType == 2) {
          doc.contributionAmount = (doc.totalIncome + ((doc.totalExpenses - doc.totalIncome) / 2))
          doc.contributionAmount = Math.abs(parseFloat((doc.contributionAmount ?? 0).toFixed(2))) - (doc.advanceAmount ?? 0)
          doc.contributionAmount = Math.abs(parseFloat((doc.contributionAmount ?? 0).toFixed(2)))
          calculation1 = [
            doc.totalExpenses,
            doc.totalIncome,
            doc.totalExpenses - doc.totalIncome,
            (doc.totalExpenses - doc.totalIncome) / 2,
            doc.totalIncome + ((doc.totalExpenses - doc.totalIncome) / 2),
            doc.advanceAmount,
            doc.contributionAmount,
            doc.contributionAmount * 0.07
          ]
          doc.eContributionAmount = (doc.eTotalIncome + ((doc.eTotalExpenses - doc.eTotalIncome) / 2))
          doc.eContributionAmount = Math.abs(parseFloat((doc.eContributionAmount ?? 0).toFixed(2))) - (doc.eAdvanceAmount ?? 0)
          doc.eContributionAmount = Math.abs(parseFloat((doc.eContributionAmount ?? 0).toFixed(2)))
          calculation2 = [
            doc.eTotalExpenses,
            doc.eTotalIncome,
            doc.eTotalExpenses - doc.eTotalIncome,
            (doc.eTotalExpenses - doc.eTotalIncome) / 2,
            doc.eTotalIncome + ((doc.eTotalExpenses - doc.eTotalIncome) / 2),
            doc.eAdvanceAmount,
            doc.eContributionAmount,
            doc.eContributionAmount * 0.07
          ]
          checkModified()
          foundCondition = state.conditionProfitType2
          underline = [
            [false, false, false, false],
            [false, false, false, true],
            [false, false, false, false],
            [false, false, false, true],
            [false, false, false, false],
            [false, false, false, true],
            [false, false, false, true],
          ]
        }
      }
      else { //ขาดทุน
        let percent = 100
        if (condition.contributionPercent == 0 || condition.contributionPercent == undefined) percent = 100
        else percent = condition.contributionPercent
        console.log("ขาดทุน", condition.lossType)
        if (condition.lossType == 1) {
          doc.contributionAmount = (doc.totalIncome + ((doc.totalExpenses - doc.totalIncome) / 2))
          doc.contributionAmount = Math.abs(parseFloat((doc.contributionAmount ?? 0).toFixed(2))) - (doc.advanceAmount ?? 0)
          doc.contributionAmount = Math.abs(parseFloat((doc.contributionAmount ?? 0).toFixed(2)))
          doc.contributionAmount = doc.contributionAmount
          calculation1 = [
            doc.totalExpenses,
            doc.totalIncome,
            doc.totalExpenses - doc.totalIncome,
            (doc.totalExpenses - doc.totalIncome) / 2,
            doc.totalIncome + ((doc.totalExpenses - doc.totalIncome) / 2),
            doc.advanceAmount,
            doc.contributionAmount,
            doc.contributionAmount * 0.07
          ]
          doc.eContributionAmount = (doc.eTotalIncome + ((doc.eTotalExpenses - doc.eTotalIncome) / 2))
          doc.eContributionAmount = Math.abs(parseFloat((doc.eContributionAmount ?? 0).toFixed(2))) - (doc.eAdvanceAmount ?? 0)
          doc.eContributionAmount = Math.abs(parseFloat((doc.eContributionAmount ?? 0).toFixed(2)))
          doc.eContributionAmount = doc.eContributionAmount
          calculation2 = [
            doc.eTotalExpenses,
            doc.eTotalIncome,
            doc.eTotalExpenses - doc.eTotalIncome,
            (doc.eTotalExpenses - doc.eTotalIncome) / 2,
            doc.eTotalIncome + ((doc.eTotalExpenses - doc.eTotalIncome) / 2),
            doc.eAdvanceAmount,
            doc.eContributionAmount,
            doc.eContributionAmount * 0.07
          ]
          checkModified()
          foundCondition = state.conditionLossType1
          underline = [
            [false, false, false, false],
            [false, false, false, true],
            [false, false, false, false],
            [false, false, false, true],
            [false, false, false, false],
            [false, false, false, true],
            [false, false, false, true],
          ]
        }
        if (condition.lossType == 2) {
          doc.contributionAmount = (doc.totalExpenses - doc.totalIncome) / 2 * (percent / 100)
          doc.contributionAmount = (doc.contributionAmount > condition.contributionLimit) ? condition.contributionLimit : doc.contributionAmount
          doc.contributionAmount = Math.abs(parseFloat((doc.contributionAmount ?? 0).toFixed(2))) - (doc.advanceAmount ?? 0)
          doc.contributionAmount = Math.abs(parseFloat((doc.contributionAmount ?? 0).toFixed(2)))
          calculation1 = [
            doc.totalExpenses,
            doc.totalIncome,
            doc.totalExpenses - doc.totalIncome,
            (doc.totalExpenses - doc.totalIncome) / 2,
            condition.contributionLimit,
            doc.advanceAmount,
            doc.contributionAmount,
            doc.contributionAmount * 0.07
          ]
          doc.eContributionAmount = (doc.eTotalExpenses - doc.eTotalIncome) / 2 * (percent / 100)
          doc.eContributionAmount = (doc.eContributionAmount > condition.contributionLimit) ? condition.contributionLimit : doc.eContributionAmount
          doc.eContributionAmount = Math.abs(parseFloat((doc.eContributionAmount ?? 0).toFixed(2))) - (doc.eAdvanceAmount ?? 0)
          doc.eContributionAmount = Math.abs(parseFloat((doc.eContributionAmount ?? 0).toFixed(2)))
          calculation2 = [
            doc.eTotalExpenses,
            doc.eTotalIncome,
            doc.eTotalExpenses - doc.eTotalIncome,
            (doc.eTotalExpenses - doc.eTotalIncome) / 2,
            condition.contributionLimit,
            doc.eAdvanceAmount,
            doc.eContributionAmount,
            doc.eContributionAmount * 0.07
          ]
          checkModified()
          foundCondition = state.conditionLossType2

          underline = [
            [false, false, false, false],
            [false, false, false, true],
            [false, false, false, true],
            [false, false, false, true],
            [false, false, false, true],
            [false, false, false, true],
          ]
        }
        if (condition.lossType == 3) {
          doc.contributionAmount = (doc.totalIncome + ((doc.totalExpenses - doc.totalIncome) / 2)) * (((condition.contributionPercent == 0 || condition.contributionPercent == undefined) ? 100 : condition.contributionPercent) / 100),
            doc.contributionAmount = Math.abs(parseFloat((doc.contributionAmount ?? 0).toFixed(2))) - (doc.advanceAmount ?? 0)
          doc.contributionAmount = Math.abs(parseFloat((doc.contributionAmount ?? 0).toFixed(2)))
          doc.contributionAmount = doc.contributionAmount
          calculation1 = [
            doc.totalExpenses,
            doc.totalIncome,
            doc.totalExpenses - doc.totalIncome,
            (doc.totalExpenses - doc.totalIncome) / 2,
            doc.totalIncome + ((doc.totalExpenses - doc.totalIncome) / 2),
            doc.advanceAmount,
            doc.contributionAmount,
            doc.contributionAmount * 0.07
          ]

          doc.eContributionAmount = (doc.eTotalIncome + ((doc.eTotalExpenses - doc.eTotalIncome) / 2)) * (((condition.contributionPercent == 0 || condition.contributionPercent == undefined) ? 100 : condition.contributionPercent) / 100)
          doc.eContributionAmount = Math.abs(parseFloat((doc.eContributionAmount ?? 0).toFixed(2))) - (doc.eAdvanceAmount ?? 0)
          doc.eContributionAmount = Math.abs(parseFloat((doc.eContributionAmount ?? 0).toFixed(2)))
          doc.eContributionAmount = doc.eContributionAmount

          calculation2 = [
            doc.eTotalExpenses,
            doc.eTotalIncome,
            doc.eTotalExpenses - doc.eTotalIncome,
            (doc.eTotalExpenses - doc.eTotalIncome) / 2,
            doc.eTotalIncome + ((doc.eTotalExpenses - doc.eTotalIncome) / 2),
            doc.eAdvanceAmount,
            doc.eContributionAmount,
            doc.eContributionAmount * 0.07
          ]
          checkModified()
          foundCondition = state.conditionLossType3
          underline = [
            [false, false, false, false],
            [false, false, false, false],
            [false, false, false, false],
            [false, false, false, true],
            [false, false, false, true],
            [false, false, false, false],
            [false, false, false, true],
          ]
        }

      }
      break;
  }

  function insert(str: string, index: number, value: string) {
    return str.substr(0, index) + value + str.substr(index);
  }

  let conText = doc.contributionText ?? ""
  var spaceCount = (conText.split(" ").length - 1);
  if (spaceCount < 5 && conText.length > 80) {
    try {
      for (let i = 0; i < (doc.contributionText.length); i += 40) {
        conText = insert(conText, i, " ")
      }
    } catch (error) {

    }
  }

  // console.log(foundCondition)
  // return "ทดสอบเอกสาร 4"
  let displayArray = ((foundCondition ?? { children: [] }).children ?? [{}])
  displayArray = displayArray.filter((da:string) => da!=="คิดค่าใช้จ่ายร้อยละ *contributionPercent*")
  displayArray = displayArray.map(((da:any,i:number)=>{
    return {
      name:da,
      calculation1:calculation1[i],
      calculation2:calculation2[i]
    }
  }))
  displayArray = displayArray.filter((da:any)=>{
    if(da.name == "ภาษีมูลค่าเพิ่ม (ร้อยละ 7)" ) {
      if(doc.isVat) return true
      else return false
    }
    if(da.name == "ทั้งปีคงเหลือ" ) {
      if(doc.isShowRemain) return true
      else return false
    }
    if(da.name == "input") {
      if(doc2.sumFinal > condition.contributionLimit) return true
      else return false
    }
    return true
  })
  return {
    stack: [
      pageBreak ? { text: "", pageBreak: "after" } : null,
      {
        text: `รายการคำนวณยอดเงินสมทบที่ อจน. เรียกเก็บจาก อปท. (${area.prefix ?? ''} ${area.name ?? ''})`, alignment: "center", bold: true,
        marginBottom: 10,
      },
      {
        table: {
          widths: ["*"],
          body: [
            [
              {
                text: [conText ?? ""],
                // margin: 10,
                margin: [6, 2, 6, 2],
                alignment: "center"
              }
            ]
          ]
        },
        marginBottom: 10,
      },
      {
        marginTop: 10,
        text: ""
      },
      {
        marginLeft: 5,
        text: foundCondition.name == 'สมทบตามจำนวนเปอร์เซนต์' ? `คิดค่าใช้จ่ายร้อยละ ${condition.contributionPercent}` : ""
      },
      {
        layout: { defaultBorder: false },
        table: {
          widths: ["auto", "*", "*", isLeft ? 80 : 0, isRight ? 80 : 0, "auto"],
          body: [
            [
              "",
              "",
              "",
              isLeftRight ? { text: `${doc.oldText}`, alignment: 'center', border: isLeftRight ? [true, true, true, true] : [false, false, false, false], fillColor: isLeftRight ? "#84D0DC" : "#FFFFFF" } : isLeft ? doc.oldText : "",
              isLeftRight ? { text: `${doc.newText}`, alignment: 'center', border: isLeftRight ? [true, true, true, true] : [false, false, false, false], fillColor: isLeftRight ? "#84D0DC" : "#FFFFFF" } : isRight ? doc.newText : "",
              ""
            ],
            ...displayArray.map((el: any, i: number) => {
              try {
                if ((el.name.search('เงื่อนไขสมทบค่าใช้จ่าย/ในสัดส่วนเท่าเทียมกันไม่เกิน') == -1 && area.name != 'ลำปาง') || area.name == 'ลำปาง') {
                  let nameColumn = ""
                  if(el.name.search('คืนค่าใช้จ่ายที่')!=-1) nameColumn = (el.name ?? "").replace("input",doc.note1 ?? '').replace("*area*", (area.prefix ?? '') + (area.name ?? '')).replace("*year*", doc.paybackYearText).formatThai().replace("*quarter*", doc.quarter).replace("*contributionLimit*", (condition.contributionLimit ?? 0).formatFull().formatThai()).replace("*contributionPercent*", condition.contributionPercent).replace('ไตรมาสที 0', "").replace('ไตรมาสที่ 0', "")
                  else nameColumn = (el.name ?? "").replace("input",doc.note1 ?? '').replace("*area*", (area.prefix ?? '') + (area.name ?? '')).replace("*year*", doc.calendarYear).formatThai().replace("*quarter*", doc.quarter).replace("*contributionLimit*", (condition.contributionLimit ?? 0).formatFull().formatThai()).replace("*contributionPercent*", condition.contributionPercent).replace('ไตรมาสที 0', "").replace('ไตรมาสที่ 0', "")
                  let row = [
                    {
                      text: nameColumn
                    },
                    {
                      text: ""
                    },
                    {
                      text: (foundCondition ?? { symbols: new Array(10).fill("") }).symbols[i],
                      alignment:"right"
                    },
                    { text: isLeft ? ((calculation1[i] ?? 0).formatFull().formatThai()) : "", border: isLeftRight ? [true, true, true, true] : underline[i], alignment: 'right' },
                    { text: isRight ? ((calculation2[i] ?? 0).formatFull().formatThai()) : "", border: isLeftRight ? [true, true, true, true] : underline[i], alignment: 'right' },
                    {
                      text: "บาท"
                    },
                  ]
                  return row
                } else {
                  return null
                }
              } catch (error) {
                console.log("condition error", error)
                console.log(calculation1, calculation2)
                return !isEndorsed ? ["", "", "", "", "", ""] : ["", "", "", "", "", ""]
              }
            }).filter((el: any) => el != null)
          ]
        }
      },
      {
        marginTop: 20,
        text: ""
      },
      !isEndorsed ?
        [{ text: adjustText, colSpan: 2, preserveLeadingSpaces: true, decoration: "underline" }, "", "", "", ""] :
        [{ text: adjustText, colSpan: 2, preserveLeadingSpaces: true, decoration: "underline" }, "", "", "", "", ""],

      {
        layout: { defaultBorder: false },
        table: {
          widths: ["auto", "*", "*", isLeft ? 80 : 0, isRight ? 80 : 0, "auto"],
          body: [
            ...modifications.map((el: any) => {
              if (isLeftRight)
              return [
                "", 
                { text: `\t${el.name}` }, 
                {text: `${el.symbol}`, alignment:"right"}, 
                { text: isLeft ? (el.amount == 0 ? "-" : ((el.amount ?? 0).formatFull().formatThai())) : "", alignment: "right", border: [true, true, true, true] }, 
                { text: isRight ? (el.eAmount == 0 ? "-" : ((el.eAmount ?? 0).formatFull().formatThai())) : "", alignment: "right", border: [true, true, true, true] }, 
                "บาท"
              ]
              else if (isLeft)
                return ["", { text: `\t${el.name}` },  {text:`${el.symbol}`,alignment:"right" }, { text: el.amount == 0 ? "-" : ((el.amount ?? 0).formatFull().formatThai()), alignment: "right" },"", "บาท"]
              else if (isRight)
                return ["", { text: `\t${el.name}` }, "", `${el.symbol}`, { text: el.amount == 0 ? "-" : ((el.amount ?? 0).formatFull().formatThai()), alignment: "right" }, "บาท"]

              else
                return ["","","","","",""]
            }),

            [
              { text: (commonText??"").replace("ปีงบประมาณ ๐",""), colSpan: 2 },
              "",
              "",
              { text: isLeft ? ((oldDoc.contributionAmount ?? 0).formatFull().formatThai()).replace("NaN", "๐.๐๐") : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] },
              { text: isRight ? ((oldDoc.eContributionAmount ?? 0).formatFull().formatThai()).replace("NaN", "๐.๐๐") : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] },
              "บาท"
            ],
            isLeftRight ? [{ text: commonText2??"", colSpan: 2 }, "", "", "", "", ""] : [{ text: commonText2??"", colSpan: 2 }, "", "", doubleLine2(), "", ""],
          ]
        }
      },
      /*
      /*
      */
    ],

  };
};


let getHeader = (id: string) => {
  return {
    margin: [20, 20, 20, 0],
    stack: [
      {
        columns: [
          {
            image: require("./logo").default,
            height: 60,
            width: 60,
          },
          {
            stack: [
              {
                text: "องค์การจัดการน้ำเสีย",
                style: "header",
                fontSize: 30,
                lineHeight: 0.75,
                color: "#3760A0",
              },
              {
                text: "WASTEWATER MANAGEMENT AUTHORITY",
                style: "header",
                lineHeight: 0.75,
              },
              {
                text: "MINISTRY OF INTERIOR, THAILAND",
                lineHeight: 0.75,
                color: "#666666",
              },
            ],
            relativePosition: { x: 0, y: -5 },
            alignment: "left",
            width: "*",
            marginLeft: 20,
            marginRight: 20,
          },
          // {
          //   qr: id == undefined ? "https://wma.jmandjm.com/portal/combine/form" : "https://wma.jmandjm.com/portal/combine/edit/" + id,
          //   fit: "70",
          //   width: "auto",
          //   alignment: "right",
          //   relativePosition: { x: 0, y: -5 },
          // },
        ],
      },
      {
        canvas: [
          {
            type: "line",
            x1: 80,
            y1: 5,
            x2: 555,
            y2: 5,
            lineColor: "#3760A0",
            lineWidth: 2,
          },
        ],
      },
    ],
  };
};

let getQuarterRange = (year: number, i: number) => {
  let add = i == 0 ? 11 : 2
  let m = 0
  let month = 1//Math.ceil((i-1)/0.3) // 1,4,7,10
  let yearAD = (year - 543) as number
  switch (i) {
    case 1:
      month = 10
      break;
    case 2:
      month = 1
      break;
    case 3:
      month = 4
      break;
    case 4:
      month = 7
      break;
    default:
      month = 10
      m = 1
      break;
  }
  let start = DateTime.fromObject({ year: yearAD - m, month: month }).reconfigure({ outputCalendar: "buddhist" }).setLocale("th");
  let end = start.plus({ months: add }).reconfigure({ outputCalendar: "buddhist" }).setLocale("th");
  let startText = start.toFormat("d LLL yy").formatThai();
  let endText = end.endOf('months').toFormat("d LLL yy").formatThai();
  return `(${startText} - ${endText})`
}

let singleLine = () => {
  return {
    canvas: [
      {
        type: "line",
        x1: -5,
        y1: 0,
        x2: 85,
        y2: 0,
        lineWidth: 1,
      },
    ],
  }
}
let singleLine2 = () => {
  return {
    canvas: [
      {
        type: "line",
        x1: -5,
        y1: 0,
        x2: 100,
        y2: 0,
        lineWidth: 1,
      },
    ],
  }
}
let singleLineType2 = () => {
  return {
    canvas: [
      {
        type: "line",
        x1: -1,
        y1: -6,
        x2: 70,
        y2: -6,
        lineWidth: 1,
      },
    ],
  }
}
let singleLineType3 = () => {
  return {
    canvas: [
      {
        type: "line",
        x1: -1,
        y1: -6,
        x2: 80,
        y2: -6,
        lineWidth: 1,
      },
    ],
  }
}
let doubleLine = () => {
  return {
    canvas: [
      {
        type: "line",
        x1: -5,
        y1: 0,
        x2: 85,
        y2: 0,
        lineWidth: 1,
      },
      {
        type: "line",
        x1: -5,
        y1: 3,
        x2: 85,
        y2: 3,
        lineWidth: 1,
      },
    ],
  }
}
let doubleLine2 = () => {
  return {
    canvas: [
      {
        type: "line",
        x1: -5,
        y1: 0,
        x2: 90,
        y2: 0,
        lineWidth: 1,
      },
      {
        type: "line",
        x1: -5,
        y1: 3,
        x2: 90,
        y2: 3,
        lineWidth: 1,
      },
    ],
  }
}
let doubleLineType2 = () => {
  return {
    canvas: [
      {
        type: "line",
        x1: -1,
        y1: 0,
        x2: 70,
        y2: 0,
        lineWidth: 1,
      },
      {
        type: "line",
        x1: -1,
        y1: 3,
        x2: 70,
        y2: 3,
        lineWidth: 1,
      },
    ],
  }
}


const _export = {
  getCover,
  getKrob2,
  getKrob2Type2,
  getKrob3,
  getKrob4Custom,
  getKrob4Original,
  getHeader
}

export default _export;



/*

    let krob4Paid = (category:string,c:number,a:number) =>{
      let b = c-a
      let quarterText = [
            `${(doc.calculation.calendarYear??0).toString().formatThai()}`+(doc.calculation.quarter!=0&&doc.calculation.quarter!=undefined?` ไตรมาสที่ ${doc.calculation.quarter.toString().formatThai()} `:" ")
            // getQuarterRange(doc.calculation.calendarYear??new Date().getFullYear(),doc.calculation.quarter),
      ]
      let commonText = `ยอดที่ อปท. พึงชำระสำหรับปีงบประมาณ ${quarterText} `
      var res = 0
      var calcPercent = b*condition.contributionPercent
      switch (category) {
        case 'สมทบตามค่าใช้จ่ายจริง (สมทบไม่เกิน)':
          if(b>condition.contributionLimit) res = condition.contributionLimit
          else res = b
          return [
            [{text:commonText,colSpan:2},"","",{text:(b??0).formatFull().formatThai(),alignment:"right"},"บาท"],
            ["","","",doubleLine2(),""],
            ]
          break;
        case 'สมทบตามค่าใช้จ่ายจริง':
          return [
            [{text:commonText,colSpan:2},"","",{text:(b??0).formatFull().formatThai(),alignment:"right"},"บาท"],
            ["","","",doubleLine2(),""],
            ]
          break;
        case 'สมทบตามเปอร์เซ็นต์จากค่าใช้จ่าย':
          return [
            [{text:commonText,colSpan:2},"","",{text:(calcPercent??0).formatFull().formatThai(),alignment:"right"},"บาท"],
            ["","","",doubleLine2(),""],
            ]
          break;
        case 'สมทบตามเปอร์เซ็นต์จากค่าใช้จ่าย (แต่ไม่เกิน...บาท)':
          if(calcPercent>condition.contributionLimit) res = condition.contributionLimit
          else res = calcPercent
          return [
            [{text:commonText,colSpan:2},"","",{text:(res??0).formatFull().formatThai(),alignment:"right"},"บาท"],
            ["","","",doubleLine2(),""],
            ]
          break;
        case 'สมทบตามยอดที่ระบุในข้อตกลง (ต่อปี)':
          return [
            [{text:commonText,colSpan:2},"","",{text:(condition.contributionLimit??0).formatFull().formatThai(),alignment:"right"},"บาท"],
            ["","","",doubleLine2(),""],
            ]
          break;
        case 'สมทบตามผลกำไร - ขาดทุน':
          if(b>0)
          return [
            [{text:"ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอ",colSpan:2},"","",{text:((b/2)??0).formatFull().formatThai(),alignment:"right"},"บาท"],
            [{text:"รายได้จากการจัดเก็บ + ครึ่งหนึ่งของค่าใช้จ่ายที่ไม่เพียงพอฯ (A+B/๒)",colSpan:2},"","",{text:((a+(b/2))??0).formatFull().formatThai(),alignment:"right"},"บาท"],
            [{text:"คืนค่าใช้จ่ายที่ อปท. จ่ายแทน อจน. ในปีงบประมาณ "+quarterText,colSpan:2},"","-",{text:(doc.contributionAmount??0).formatFull().formatThai(),alignment:"right"},"บาท"],
            [{text:"คงเหลือ ",colSpan:2},"","=",{text:(((a+(b/2))-doc.contributionAmount)??0).formatFull().formatThai(),alignment:"right"},"บาท"],
            [{text:"คงเหลือยอดที่ อปท. พึงชำระสำหรับ "+quarterText,colSpan:2},"","",{text:(((a+(b/2))-doc.contributionAmount)??0).formatFull().formatThai(),alignment:"right"},"บาท"]
          ]
          else
          return [
            [{text:"ครึ่งหนึ่งของกำไรหลังหักค่าใช้จ่ายในการดำเนินการ",colSpan:2},"","",{text:((b/2)??0).formatFull().formatThai(),alignment:"right"},"บาท"],
            [{text:"รายได้จากการจัดเก็บ + ครึ่งหนึ่งของกำไรหลังหักค่าใช้จ่ายฯ (A+B/๒)",colSpan:2},"","",{text:((a+(b/2))??0).formatFull().formatThai(),alignment:"right"},"บาท"],
            [{text:"คืนค่าใช้จ่ายที่ อปท. จ่ายแทน อจน. ในปีงบประมาณ "+quarterText,colSpan:2},"","-",{text:(doc.contributionAmount??0).formatFull().formatThai(),alignment:"right"},"บาท"],
            [{text:"คงเหลือ ",colSpan:2},"","=",{text:(((a+(b/2))-doc.contributionAmount)??0).formatFull().formatThai(),alignment:"right"},"บาท"],
            [{text:"คงเหลือยอดที่ อปท. พึงชำระสำหรับ "+quarterText,colSpan:2},"","",{text:(((a+(b/2))-doc.contributionAmount)??0).formatFull().formatThai(),alignment:"right"},"บาท"]
          ]
          break;
        case 'จ่ายสาธารณูปโภค':
          return [[{text:commonText,colSpan:2},"","",{text:(condition.contributionLimit??0).formatFull().formatThai(),alignment:"right"},"บาท"]]
          break;
        default:
          return [[{text:commonText,colSpan:2},"","",{text:(condition.contributionLimit??0).formatFull().formatThai(),alignment:"right"},"บาท"]]
          break;
      }

    }
*/
