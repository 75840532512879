
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon";
import Chart from "chart.js";
import { useStore } from "vuex";

@Options({
  components: {},
  props: {},
})
export default class ReportCollectionStatus extends Vue {
  reportDate: Date = new Date();
  store = useStore();
  doc: any = {};
  docs: Array<any> = [];
  today: Date = new Date();
  myChart!:Chart 
  get todayFormat() {
    return this.today.toThaiShort();
  }
  get budgetYear() {
    return this.reportDate.getFullYear() + 543 + (this.reportDate.getMonth() >= 9 ? 1 : 0);
  }
  get year() {
    return this.reportDate.getFullYear() + 543 + (this.reportDate.getMonth() >= 9 ? 1 : 0);
  }
  get month() {
    return this.reportDate.getMonth() + 1;
  }
  get displayReportDay() {
    let getBudgetYear = (dt: DateTime) => {
      let year = dt.toObject().year! + 543;
      if (dt.toObject().month! >= 10) {
        year++;
      }
      return year;
    };
    let selected = getBudgetYear(DateTime.fromJSDate(this.reportDate));
    let today = getBudgetYear(DateTime.now());
    let dtThai =  DateTime.fromJSDate(this.reportDate)
    .reconfigure({ outputCalendar: "buddhist" })
      .set({day:30,month:9})
      .setLocale("th")
      .toFormat("d LLLL yyyy");
    let currentThai =  DateTime.now()
    .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("d LLLL yyyy");
    if(selected < today) return dtThai
    else if(selected == today) return currentThai
    return this.todayFormat;
    return "ok" + selected + ":" + today;
  }
  get displayReportDayShort() {
    let getBudgetYear = (dt: DateTime) => {
      let year = dt.toObject().year! + 543;
      if (dt.toObject().month! >= 10) {
        year++;
      }
      return year;
    };
    let selected = getBudgetYear(DateTime.fromJSDate(this.reportDate));
    let today = getBudgetYear(DateTime.now());
    let dtThai =  DateTime.fromJSDate(this.reportDate)
    .reconfigure({ outputCalendar: "buddhist" })
      .set({day:30,month:9})
      .setLocale("th")
      .toFormat("d LLL yyyy");
    let currentThai =  DateTime.now()
    .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("d LLL yyyy");
    if(selected < today) return dtThai
    else if(selected == today) return currentThai
    return this.todayFormat;
    // return "ok" + selected + ":" + today;
  }
  mounted() {
    this.initChart()
  }
  initChart(){
    this.docs = []
    this.getData();
  }

  getData() {
    axios.get(`${address}/report-get-green-yellow`).then((response) => {
      this.docs = response.data;
      this.initChart1();
    });
  }
  sumInco(incomes: Array<any>, m: Array<number>, y: number) {
    try {
      let filtered = incomes.filter(
        (el) => m.find((i) => i == el.month) != undefined && y == el.year && el.isDebt != true
      );
      return filtered
        .map((el) => el.amount)
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return -1;
    }
  }
  getInco(incomes: Array<any>, mo: number, y: number) {
    let m = [mo]
    try {
      let filtered = incomes.filter(
        (el) => m.find((i) => i == el.month) != undefined && y == el.year && el.isDebt != true
      );
      return filtered
        .map((el) => el.amount)
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return -1;
    }
  }
  sumColc(collections: Array<any>, m: Array<number>, y: number) {
    try {
      let filtered = collections.filter((el) => {
        let foundMonth = m.find((mo) => mo == el.month) != undefined;
        let elBudgetYear = el.remarkYear + (el.month>=10?1:0)
        let yBudgetYear = this.year + (this.month>=10?1:0)
        return (foundMonth && y == el.year && el.isDebt != true) //||elBudgetYear>yBudgetYear
      });
      return filtered
        .map((el) => el.amount)
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return -1;
    }
  }
  getColc(collections: Array<any>, month: number, y: number) {
    let m = [month]
    try {
        let filtered = collections.filter((el) => {
        let foundMonth = m.find((mo) => mo == el.month) != undefined;
        let elBudgetYear = el.remarkYear + (el.month>=10?1:0)
        let yBudgetYear = this.year + (this.month>=10?1:0)
        return (foundMonth && y == el.year && el.isDebt != true) //||elBudgetYear>yBudgetYear
      });
      return filtered
        .map((el) => el.amount)
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return -1;
    }
  }

  // ชุดเงินค้าง
  yearForPrev(m: Array<number>) {
    let min = Math.min(...m);
    if (min >= 10) return this.year - 1;
    else return this.year;
  }

  sumIncoPrev(incomes: Array<any>, m: Array<number>, y: number) {
    try {
      let filtered = incomes.filter(
        (el) => m.find((i) => i == el.month) != undefined && y == el.year && el.isDebt
      );
      return filtered
        .map((el) => el.amount)
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return -1;
    }
  }
  getIncoPrev(incomes: Array<any>, mo: number, y: number) {
    let m = [mo]
    try {
      let filtered = incomes.filter(
        (el) => m.find((i) => i == el.month) != undefined && y == el.year && el.isDebt
      );
      return filtered
        .map((el) => el.amount)
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return -1;
    }
  }

  sumColcPrev(collections: Array<any>, m: Array<number>, y: number) {
    try {
      let filtered = collections.filter((el) => {
        let foundMonth = m.find((mo) => mo == el.month) != undefined;
        return  el.isDebt == true && foundMonth && y == el.year 
      });
      let result = filtered
        .map((el) => el.amount ?? 0)
        .reduce((a: number, b: number) => a + b, 0);
      return result;
    } catch (error) {
      return -1;
    }
  }
  getColcPrev(collections: Array<any>, m: number, y: number) {
    try {
      let filtered = collections.filter(
        (el) => el.month == m &&  el.isDebt == true && y == el.year 
      );
      filtered = filtered.filter((el) => el.remarkYear > 2500);
      return filtered
        .map((el) => el.amount ?? 0)
        .reduce((a: number, b: number) => a + b, 0);
    } catch (error) {
      return 0;
    }
  }

  initChart1() {
    try {
      this.myChart.destroy()
    } catch (error) {
      console.log(error)
    }
    var element = this.$refs.chart1 as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    this.myChart = new Chart(ctx, {
      type: "pie",
      data: {
        labels: [
          `ยอดลูกหนี้คงค้างก่อนปี ${this.budgetYear-1} ยกมา ${this.budgetYear}`,
          `แผนรายได้ ${this.budgetYear}`,
          `รวมแผนรายได้ปี ${this.budgetYear} (1+2)`,
          `จัดเก็บได้ ${this.displayReportDayShort}`,
          "รายได้ยังจัดเก็บไม่ได้ (3-4)",
        ],
        datasets: [
          {
            label: "ล้านบาท",
            data: [
              this.resolveMB(
                this.docs
                  .map(
                    (el) =>
                      (this.sumIncoPrev(
                        el.incomes,
                        [10, 11, 12],
                        this.year - 1
                      ) ?? 0) +
                      (this.sumIncoPrev(
                        el.incomes,
                        [1, 2, 3, 4, 5, 6, 7, 8, 9],
                        this.year
                      ) ?? 0)
                  )
                  .reduce((a, b) => a + b, 0)
              ),
              this.resolveMB(
                this.docs
                  .map(
                    (doc) =>
                      (this.sumInco(doc.incomes, [10, 11, 12], this.year - 1) ??
                        0) +
                      (this.sumInco(
                        doc.incomes,
                        [1, 2, 3, 4, 5, 6, 7, 8, 9],
                        this.year
                      ) ?? 0)
                  )
                  .reduce((a, b) => a + b, 0)
              ),
              this.resolveMB(
                this.docs
                  .map(
                    (el) =>
                      (this.sumInco(el.incomes, [10, 11, 12], this.year - 1) ??
                        0) +
                      (this.sumInco(
                        el.incomes,
                        [1, 2, 3, 4, 5, 6, 7, 8, 9],
                        this.year
                      ) ?? 0)
                  )
                  .reduce((a, b) => a + b, 0) +
                  this.docs
                    .map(
                      (el) =>
                        (this.sumIncoPrev(
                          el.incomes,
                          [10, 11, 12],
                          this.year - 1
                        ) ?? 0) +
                        (this.sumIncoPrev(
                          el.incomes,
                          [1, 2, 3, 4, 5, 6, 7, 8, 9],
                          this.year
                        ) ?? 0)
                    )
                    .reduce((a, b) => a + b, 0)
              ),
              this.resolveMB(
                this.docs
                  .map(
                    (el) =>
                      (this.sumColc(
                        el.collections,
                        [10, 11, 12],
                        this.year - 1
                      ) ?? 0) +
                      (this.sumColc(
                        el.collections,
                        [1, 2, 3, 4, 5, 6, 7, 8, 9],
                        this.year
                      ) ?? 0)
                  )
                  .reduce((a, b) => a + b, 0) +
                  this.docs
                    .map(
                      (el) =>
                        (this.sumColcPrev(
                          el.collections,
                          [10, 11, 12],
                          this.year - 1
                        ) ?? 0) +
                        (this.sumColcPrev(
                          el.collections,
                          [1, 2, 3, 4, 5, 6, 7, 8, 9],
                          this.year
                        ) ?? 0)
                    )
                    .reduce((a, b) => a + b, 0)
              ),
              this.resolveMB(
                this.docs
                  .map(
                    (el) =>
                      (this.sumInco(el.incomes, [10, 11, 12], this.year - 1) ??
                        0) +
                      (this.sumInco(
                        el.incomes,
                        [1, 2, 3, 4, 5, 6, 7, 8, 9],
                        this.year
                      ) ?? 0)
                  )
                  .reduce((a, b) => a + b, 0) +
                  this.docs
                    .map(
                      (el) =>
                        (this.sumIncoPrev(
                          el.incomes,
                          [10, 11, 12],
                          this.year - 1
                        ) ?? 0) +
                        (this.sumIncoPrev(
                          el.incomes,
                          [1, 2, 3, 4, 5, 6, 7, 8, 9],
                          this.year
                        ) ?? 0)
                    )
                    .reduce((a, b) => a + b, 0) -
                  (this.docs
                    .map(
                      (el) =>
                        (this.sumColc(
                          el.collections,
                          [10, 11, 12],
                          this.year - 1
                        ) ?? 0) +
                        (this.sumColc(
                          el.collections,
                          [1, 2, 3, 4, 5, 6, 7, 8, 9],
                          this.year
                        ) ?? 0)
                    )
                    .reduce((a, b) => a + b, 0) +
                    this.docs
                      .map(
                        (el) =>
                          (this.sumColcPrev(
                            el.collections,
                            [10, 11, 12],
                            this.year - 1
                          ) ?? 0) +
                          (this.sumColcPrev(
                            el.collections,
                            [1, 2, 3, 4, 5, 6, 7, 8, 9],
                            this.year
                          ) ?? 0)
                      )
                      .reduce((a, b) => a + b, 0))
              ),
            ],
            barThickness: 30,
            backgroundColor: [
              "#264D59",
              "#43978D",
              "#F9E07F",
              "#F9AD6A",
              "#D46C4E",
            ],
            // borderColor: "#AAE8BF",
            borderWidth: 1,
          },
        ],
      },
    });
  }
  resolveMB = (n: number) => {
    return parseFloat(Math.abs(n).formatMB());
  };
  print() {
    window.print();
  }
}
