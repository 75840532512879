
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon"
import { useStore } from "vuex";
import ReportRisk from '@/views/incomeReport/ReportRisk.vue'
import ReportCollectionStatus from '@/views/incomeReport/ReportCollectionStatus.vue'
import ReportCollectionStatistic from '@/views/incomeReport/ReportCollectionStatistic.vue'
import ReportComparePlanResult from '@/views/incomeReport/ReportComparePlanResult.vue'

@Options({
  components: {ReportRisk,ReportCollectionStatus,ReportCollectionStatistic,ReportComparePlanResult},
  props: {},
})
export default class IncomeDashboard extends Vue {
  store = useStore();
  docs: Array<any> = [];
  today: Date = new Date();
  get todayFormat(){
    return this.today.toThaiShort()
  }
  getMB(n:number){
    return (Math.random()*100).formatFull()
  }
  print(){
    window.print();
  }
}
