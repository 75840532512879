
import address from "@/variable";
import axios from "axios";
import { DateTime } from "luxon";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";
import * as _ from "lodash"

import PDF from "@/pdf";
import { boolean } from "mathjs";

@Options({})
export default class AlbumExample extends Vue {
  store = useStore();
  docs: any = {
    ledgers:[]
  }
  costCodes:Array<any> = []
  pdfBLOB = "";
  fonts: any = {};
  styles: any = {};
  doc: any = {
    ledgers:[]
  }
  areas:Array<any> = []
  area:any = {}
  calculations:Array<any> = []
  areaCondition:any = {}
  year: number = -1;
  quarter: number = -1;
  operation: number = 0;
  ledgers: Array<any> = [];
  union:Array<any> = []
  isOldNew:boolean = false
  isLeft:boolean = true
  isRight:boolean = true
  mounted() {
    this.year = parseInt(this.$route.params.year as string);
    this.quarter = parseInt(this.$route.params.quarter as string);
    this.operation = parseInt(this.$route.params.operation as string);
    this.getCostCode()

    if(this.$route.params.id!=undefined){
      this.fetchById()
    }
    else{
      this.fetchData();
      this.fetchArea();
    }
  }
  fetchById(){
    axios.get(`${address}/calculation/${this.$route.params.id}`).then(response=>{
      if(response.data!=""){
        this.docs = response.data
        axios.get(`${address}/area/${this.docs.area}`).then(response=>{
          this.area = response.data
          this.generatePDF("preview")
        })
      }
    })
  }
  fetchData(){
    axios.post(`${address}/calculation-find`,{
      search:{
        area:this.area._id,
        calendarYear:this.year,
        quarter:this.quarter,
      }
    }).then(async response=>{
      if(response.data!=""){
        this.generatePDF("preview")
        this.docs = response.data
      }
    })
  }

  fetchArea() {
    axios
      .get(`${address}/area/${this.$route.params.area}`)
      .then((response) => (this.area = response.data));
    axios
      .get(`${address}/areaCondition-by-field/area/${this.$route.params.area}`)
      .then((response) => (this.areaCondition = response.data));
  }


  fetchAreaByDoc() {
    return new Promise((resolve,reject)=>{

      axios
        .get(`${address}/area/${this.doc.area}`)
        .then((response) => {
          console.log(this.doc.area)
          this.area = response.data
          resolve(this.area)
        });
      axios
        .get(`${address}/areaCondition-by-field/area/${this.doc.area}`)
        .then((response) => (this.areaCondition = response.data));
      })
  }

  getCostCode() {
    axios.get(`${address}/costcodes`).then(response=>{
      this.costCodes = response.data
    })
  }


  async generatePDF(mode: string) {
    let mm = (u: number) => {
      return u / 0.352777778;
    };
    var pdfMake = require("pdfmake/build/pdfmake.js");
    this.fonts = this.store.getters.getPDFFonts;
    this.styles = this.store.getters.getPDFStyles;
    console.log(this.area)
    // if(this.docs.endorsedLedgers == []){
    //   this.isRight = false
    // }
    let result:any = PDF.getKrob2(this.area,this.docs,this.isLeft,this.isRight)

    var docDefinition = {
      pageSize: "A4",
      pageMargins: [40, 120, 40, 30],
      header:PDF.getHeader(this.doc._id),
      content: [
        result,
        ],
      styles: this.styles,
      // watermark: { text: 'ฉบับร่าง', angle:"-30",  color: "#3760A0", opacity: 0.15, bold: true, italics: false },
      footer: (currentPage: number, pageCount: number, pageSize: any) => {
        let text = `รายการประมาณการค่าใช้จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย (${currentPage.toString().formatThai()} จาก ${pageCount.toString().formatThai()})`
        return {
          text: text,
          alignment: "right",
          marginRight: 20,
          italics:true,
          fontSize:10,
          relativePosition:{x:0,y:-10}
        };
      },
      defaultStyle: {
        fontSize: "16",
        lineHeight: 0.9,
      },
    };
    if (mode == "preview") {
      let pdfDocGenerator = pdfMake.createPdf(docDefinition, null, this.fonts);
      pdfDocGenerator.getBlob((blob: any) => {
        let blobURL = URL.createObjectURL(blob);
        this.pdfBLOB = blobURL;
      });
    } else {
      let pdfDocGenerator = pdfMake
        .createPdf(docDefinition, null, this.fonts)
        .download(`ใบนำส่ง.pdf`);
    }
  }
}
