
import address,{filePath} from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
  },
  props: {
  },
})
export default class InDocumentList extends Vue {
  filePath=filePath
  mounted(){
  }
}
