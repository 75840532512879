
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";
@Options({})
export default class JMUser extends Vue {
  store = useStore();
  docs: Array<any> = [];
  inputData = ""

  @Watch("inputData")
  checkLength(){
    if(this.inputData.length >= 3){
      this.getData()
    }
  }
  emitUp(item:any){
    this.docs = []
    this.$emit("update:modelValue",item)
    this.inputData = `${item.firstName} ${item.lastName}`
  }
  getData() {
    axios.post(`${address}/users-paginate`,{
      search:{
        firstName:{$regex:this.inputData, $options:"i"},
        lastName:{$regex:this.inputData, $options:"i"},
        username:{$regex:this.inputData, $options:"i"}
      },
      limit:5,
      skip:0,
    }).then((response) => {
      console.log(response)
      this.docs = response.data.docs;
    });
  }
}
