
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import axios from "axios";
import address from "@/variable";
import { DateTime } from "luxon";
import { saveAs } from "file-saver";
import * as _ from "lodash";
import { Watch } from "vue-property-decorator";
import { isNull } from "lodash";
@Options({
  components: {},
  props: {},
})
export default class CustomerList extends Vue {
  store = useStore();
  docs: Array<any> = [];
  latest: Array<any> = [];
  debts: Array<any> = [];
  selected: Array<any> = [];

  ids: Array<string> = [];
  separates: Array<any> = [];
  combines: Array<any> = [];
  separatesArray: Array<any> = [];
  combinesArray: Array<any> = [];

  counter:any = {}

  working: boolean = false;
  selectedDoc: any = {
    debtArray: [],
  };
  doc: any = {};
  selectedSum: any = {};
  id: string = "";
  loading: boolean = false;
  search: any = {
    year: 2564,
    month: 12,
    cat: 0,
    // year: new Date().getFullYear() + 543,
    // month: new Date().getMonth() + 1,
  };
  show: number = 999999999;
  limit: number = 5;
  skip: number = 0;
  total: number = 5;
  page: number = 0;
  pages: number = 0;

  totalCount: number = 0;
  totalQty: number = 0;
  totalAmount: number = 0;
  totalBill: number = 0;
  totalDebt: number = 0;
  totalPayment: number = 0;
  totalInvoice: number = 0;

  isCut: Boolean = false;
  payment: number = 0;
  showPaid: boolean = false;
  processType = "แยก";

  get simYear() {
    let add = 0
    if(this.search.month>10) add = 1
    return String(this.search.year+add).substring(2,4)
  }
  get simCat(){
    try {
      return this.search.cat.split(',')[0]
    } catch (error) {
      return "9"
    }
  }

  get final() {
    let separates = this.separatesArray.filter(el=>!isNull(el)).map((el) => ({
      id: el._id,
      type: "separate",
      meter: el.meter,
      name: el.name,
      address: el.address,
      category: el.category,
      paymentDate: el.paymentDate,
      paids: [{ month: el.month, year: el.year }],
      invoices: [el.invoiceNumber],
      totalAmount: el.totalAmount,
      invoiceNumber: el.invoiceNumber,
      paymentAmount: el.paymentAmount,
      excelNum: el.excelNum,
    }));
    let combines:Array<any> = this.combinesArray.filter(el=>!isNull(el)).map((el) => ({
      id: el._id,
      type: "combine",
      meter: el.meter,
      name: el.name,
      address: el.address,
      category: el.category,
      paymentDate: el.paymentDate,
      paids: [{ month: el.month, year: el.year }],
      invoices: [el.invoiceNumber],
      totalAmount: el.totalAmount,
      invoiceNumber: el.invoiceNumber,
      paymentAmount: el.paymentAmount,
      excelNum: el.excelNum,
    }));
    let uniqueMeter:Array<string> = []
    uniqueMeter = combines.map(com=>com.meter)
    uniqueMeter = _.uniq(uniqueMeter)
    let finalCombine:Array<any> = []
    uniqueMeter.forEach(meter=>{
      try {
        let found = combines.filter((com)=>com.meter==meter)
        // found = found.map(f=>({...f,yearMonth:`${f.year}${f.month}`}))
        // found = found.sort((a,b)=>parseInt(a.yearMonth)>parseInt(b.yearMonth)?1:-1)
        let mappedIn = found.map(f=>f.invoices)
        let flattedIn = mappedIn.flat()
        let mappedPa = found.map(f=>f.paids)
        let flattedPa = mappedPa.flat()
        let mappedPaymentAmount = found.map(f=>f.paymentAmount)
        let reducedPaymentAmount = mappedPaymentAmount.reduce((a,b)=>a+b,0)
        reducedPaymentAmount = parseFloat(reducedPaymentAmount.toFixed(2))

        finalCombine.push({
          ...found[0],
          paids:flattedPa,
          invoices:flattedIn,
          paymentAmount: reducedPaymentAmount,
        })
      } catch (error) {
        
      }
    })
    separates = separates.filter(sep=>sep.meter.includes)
    for(const item of finalCombine){
      for(const inv of item.invoices){
        let index = separates.findIndex(f=>(f.invoices[0]==inv))
        if(index>=0) separates.splice(index,1)
      }
    }
    let arr = separates.concat(finalCombine);
    

    // for(const item of separates){
    //   try {
    //     for(const invoice of item.invoices){
    //       let findIndex = combines.findIndex(com=>com.invoices.includes(invoice))
    //       let findChild = combines[findIndex].invoices.findIndex((child:string)=>child==invoice)
    //       combines[findIndex].invoices.splice(findChild,1)
          
    //     } 
    //   } catch (error) {
    //     console.log("error", error)
    //   }
    // }
    return arr.filter(ar=>ar!=null).filter(ar=>ar.id!=undefined).sort((a:any,b:any)=>a.excelNum-b.excelNum);
  }
  get sumFinal() {
    return this.final.length;
  }
  selectAllSeparate(ev: any) {
    let checked = ev.target.checked;
    if (checked) {
      this.separatesArray = this.docs.map((doc) => doc);
      this.combinesArray = [];
    } else this.separatesArray = [];
  }

  selectAllCombine(ev: any) {
    let checked = ev.target.checked;
    if (checked) {
      this.combinesArray = this.docs.map((doc) => doc);
      this.separatesArray = [];
    } else this.combinesArray = [];
  }

  removeSeparate(i:number) {
    this.separatesArray[i] = null;
  }

  removeCombine(i:number) {
    this.combinesArray[i] = null;
  }

  get DebtFilter() {
    if (this.showPaid == true) return this.debts;
    else return this.debts.filter((el) => !el.isPaid);
  }

  get SumChecked() {
    let result =
      this.debts
        .filter((el) => el.check)
        .map((el) => el.totalAmount)
        .reduce((a, b) => a + b, 0) * 1.07;
    return result;
  }
  get listInvoice() {
    let result = this.debts.filter((el) => el.check).map((el) => el._id);
    return result;
  }
  @Watch("search", { deep: true })
  setQuery() {
    this.$router.push({
      path: this.$route.path,
      query: {
        search: JSON.stringify(this.search),
      },
    });
  }
  getQuery() {
    try {
      // this.search = JSON.parse(this.$route.query.search as string);
    } catch (error) {}
  }
  downloadExcel() {
    let query = {
      search: { _id: { $in: this.selected } },
    };
    axios
      .post(`${address}/payments-excel`, query, { responseType: "blob" })
      .then((response) => {
        // Log somewhat to show that the browser actually exposes the custom HTTP header
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
          suggestedFileName === undefined
            ? "download_report.xlsx"
            : suggestedFileName;
        console.log(
          "Received header [" +
            fileNameHeader +
            "]: " +
            suggestedFileName +
            ", effective fileName: " +
            effectiveFileName
        );

        saveAs(response.data, effectiveFileName);
      });
  }

  patchInvoice() {
    this.listInvoice.forEach((el) => {
      axios
        .patch(`${address}/invoice/${el}`, {
          isPaid: true,
        })
        .then((response) => {
          console.log(response);
        });
    });
    axios
      .patch(`${address}/payment/${this.id}`, {
        isCut: true,
      })
      .then((response) => {
        console.log(response);
      });
  }

  inclVat(n: number, vat: number) {
    return parseFloat((n * (1 + (vat ?? 0.07))).toFixed(2));
  }
  getDebt(meter: string, payment: number) {
    this.debts = [];
    this.payment = payment;
    let query = {
      search: { meter: meter },
      limit: 9999999,
      skip: 0,
    };
    axios.post(`${address}/invoices-paginate`, query).then((response) => {
      this.debts = response.data.docs;
    });
  }

  async postData() {
    try {
      console.log("posting...")
      let result = await axios.post(`${address}/process/create/receipt/v3`, {
        list: this.final,
        requester: this.store.state.userId,
        approver: this.store.state.managerId,
      });
      console.log({result});
    } catch (error) {
      console.log(error)
    }
     alert("ส่งข้อมูลเพื่อจัดทำใบเสร็จแล้ว");
  }
  postSum() {
    axios
      .post(`${address}/receipt-upsert`, { doc: this.getSum })
      .then((response) => {
        console.log(response);
        this.getSum.invoices.forEach((el: any, i: number) => {
          axios
            .patch(`${address}/payment/${el}`, {
              isPaid: true,
              isNextStage: true,
            })
            .then((response2) => {
              console.log(response2);
            })
            .finally(() => {
              try {
                if (i == this.getSum.invoices.length - 1) {
                  this.fetchData();
                  this.selected = [];
                }
              } catch (error) {}
            });
        });
        alert("ส่งข้อมูลเพื่อจัดทำใบเสร็จแล้ว");
        let btn = this.$refs.cancelModal as HTMLElement;
        btn.click();
        this.$router.push("/portal/receipt/list");
      })
      .catch((error) => {
        alert("พบข้อผิดพลาดบนเครื่องแม่ข่าย " + error);
      });
  }
  get getSum() {
    try {
      let result = this.selected.map((el) => {
        let found = this.docs.find((doc) => doc._id === el);
        return found;
      });
      result = _.sortBy(result, ["year", "month"]);
      let prep = {
        ...result[result.length - 1],
        paymentAmount: result
          .map((el) => el.paymentAmount ?? 0)
          .reduce((a: number, b: number) => a + b, 0),
        invoiceAmount: result
          .map((el) => el.invoiceAmount ?? 0)
          .reduce((a: number, b: number) => a + b, 0),
        invoices: result.map((el) => el._id ?? 0),
        payments: result.map((el) => {
          return {
            _id: el._id ?? "",
            month: el.month ?? 0,
            year: el.year ?? 0,
          };
        }),
        _id: undefined,
      };
      prep.totalAmount = parseFloat((prep.qty * prep.rate).toFixed(2));
      prep.vat = parseFloat((prep.qty * prep.rate * 0.07).toFixed(2));
      return prep;
    } catch (error) {
      return {
        paymentAmount: 0,
        invoiceAmount: 0,
      };
    }
  }
  get getCalcRemain() {
    try {
      let sum = (this.selectedDoc.debtArray ?? [])
        .filter((el: any) => el.check)
        .map((el: any) => el.amount ?? 0)
        .reduce((a: any, b: any) => a + b, 0);
      return this.selectedDoc.amount ?? 0 - sum ?? 0;
    } catch (error) {
      return 0;
    }
  }
  get getCalcDebt() {
    try {
      let sum = (this.selectedDoc.debtArray ?? [])
        .filter((el: any) => !el.check)
        .map((el: any) => el.amount ?? 0)
        .reduce((a: any, b: any) => a + b, 0);
      return sum ?? 0;
    } catch (error) {
      return 0;
    }
  }
  calcTotal(item: any) {
    let result =
      (item.rate ?? 0) +
      (item.qty ?? 0) +
      ((item.rate ?? 0) + (item.qty ?? 0)) * (item.vat ?? 0);
    return result;
  }
  toThaiFormat2(year: number, month: number) {
    var dtThai = DateTime.fromObject({ year: year - 543, month })
      .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("LLLyy");
    return dtThai;
  }
  toThaiFormat(dt: string) {
    var dtThai = DateTime.fromISO(dt)
      .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("ddLLLyy");
    return dtThai;
  }
  updatePaginate(i: number) {
    this.page = i;
    console.log(this.page);
    this.fetchData();
  }
  clearPage() {
    if (this.show == 999999999) {
      this.page = 0;
      this.fetchData();
    }
  }
  deleteMany() {
    let userConfirm = confirm("คุณต้องการลบรายการผู้ชำระเงิน!");
    if (userConfirm) {
      axios
        .post(`${address}/payments-delete`, { list: this.final })
        .then((response) => {
          this.fetchData();
        })
        .then((response) => {
          alert("ลบข้อมูลแล้ว");
        });
    }
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
  fetchData() {
    this.docs = [];
    this.loading = true;
    let paymentDate;
    try {
      if (this.search.paymentDate != undefined)
        paymentDate = DateTime.fromJSDate(this.search.paymentDate);
    } catch (error) {}
    // console.log(this.search.paymentDate, paymentDate, typeof paymentDate, paymentDate.toISO())
    let category = null;
    let categoryType = null;
    try {
      category = this.search.cat.split(",")[0];
      categoryType = this.search.cat.split(",")[1];
    } catch (error) {}
    let query = {
      search: {
        code: JSON.parse(JSON.stringify(this.search.code)),
        $and: [
          {
            paymentDate:
              paymentDate != undefined
                ? { $gte: paymentDate.startOf("day").toISO() }
                : undefined,
          },
          {
            paymentDate:
              paymentDate != undefined
                ? { $lte: paymentDate.endOf("day").toISO() }
                : undefined,
          },
          {
            number:
              this.search.numberFrom != undefined
                ? { $gte: this.search.numberFrom }
                : undefined,
          },
          {
            number:
              this.search.numberTo != undefined
                ? { $lte: this.search.numberTo }
                : undefined,
          },
          {
            $or: [
              {
                name:
                  this.search.text != undefined
                    ? { $regex: this.search.text, $options: "-i" }
                    : undefined,
              },
              {
                meter:
                  this.search.text != undefined
                    ? { $regex: this.search.text, $options: "-i" }
                    : undefined,
              },
            ],
          },
          // {
          //   year:
          //     this.search.year != undefined
          //       ? { $eq: this.search.year }
          //       : undefined,
          // },
          // {
          //   month:
          //     this.search.month != undefined
          //       ? { $eq: this.search.month }
          //       : undefined,
          // },
          {
            paymentDate:
              this.search.month != undefined
                ? {
                    $gte: DateTime.fromObject({
                      month: this.search.month,
                      year: this.search.year - 543,
                    })
                      .startOf("month")
                      .plus({ month: 2 })
                      .toISO(),
                    $lte: DateTime.fromObject({
                      month: this.search.month,
                      year: this.search.year - 543,
                    })
                      .endOf("month")
                      .plus({ month: 2, hours:8 })
                      .toISO(),
                  }
                : undefined,
          },
          {
            category: category != undefined ? { $eq: category } : undefined,
          },
          // {
          //   categoryType:
          //     categoryType != undefined ? { $eq: categoryType } : undefined,
          // },
          {
            paymentDate:
              this.search.paymentDate != undefined
                ? {
                    $gte: DateTime.fromJSDate(this.search.paymentDate)
                      .startOf("day")
                      .toISODate(),
                    $lte: DateTime.fromJSDate(this.search.paymentDate)
                      .endOf("day")
                      .toISODate(),
                  }
                : undefined,
          },
        ],
      },
      limit: this.show,
      skip: this.page * this.show,
      sort: { excelNum: 1 },
    };
    console.log(query);
    axios.post(`${address}/payments-paginate`, query).then((response) => {
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
      this.totalCount = response.data.totalCount;
      this.totalQty = response.data.totalQty;
      this.totalAmount = response.data.totalAmount;
      this.totalPayment = response.data.totalPayment;
      this.totalDebt = response.data.totalDebt;
      this.totalBill = response.data.totalBill;
      this.totalInvoice = response.data.totalInvoice;
      this.ids = response.data.ids;
      this.loading = false;
      console.log(response.data);
    });
  }
  // get ids(){
  //   return this.docs.filter((el:any)=>!el.isNextStage).map(el=>el._id);
  // }
  getData() {
    axios.get(`${address}/payments`).then((response) => {
      this.docs = response.data;
      this.pages = 290;
      this.total = response.data.total;
    });
  }
  mounted() {
    this.getQuery();
    this.getLatest();
    this.getCounter()
    let btn = this.$refs.callModal as HTMLElement;
    try {
      console.log("search", this.$route.query.searchparam);
      this.search = JSON.parse(this.$route.query.search as string);
      if (this.search != undefined) this.fetchData();
    } catch (error) {
      console.log("error", error);
    }
    // btn.click()
  }

  getCounter() {
    axios.post(`${address}/counter-find`,{year:2565,month:2,category:"2",name:"Receipt"}).then((response) => {
      console.log("response", response.data);
      this.counter = response.data;
    });
  }
  getLatest() {
    axios.get(`${address}/payments-information`).then((response) => {
      console.log(response);
      this.latest = response.data[0];
    });
  }
  getThaiMonth(n: number) {
    switch (n) {
      case 1:
        return "ม.ค.";
        break;
      case 2:
        return "ก.พ.";
        break;
      case 3:
        return "มี.ค.";
        break;
      case 4:
        return "เม.ย.";
        break;
      case 5:
        return "พ.ค.";
        break;
      case 6:
        return "มิ.ย.";
        break;
      case 7:
        return "ก.ค.";
        break;
      case 8:
        return "ส.ค.";
        break;
      case 9:
        return "ก.ย.";
        break;
      case 10:
        return "ต.ค.";
        break;
      case 11:
        return "พ.ย.";
        break;
      case 12:
        return "ธ.ค.";
        break;

      default:
        return ".";
        break;
    }
  }
}
