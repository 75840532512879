
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { DateTime } from "luxon";
import { useStore } from "vuex";
@Options({
  components: {},
  props: {},
})
export default class Krob4List extends Vue {
  store = useStore();
  docs: Array<any> = [];
  calculations: Array<any> = [];
  search:any = {}
  isSigned: boolean = true;
  isActive: boolean = false;
  loading: boolean = false
  toggle: boolean = false;
  isCustom: boolean = false;
  DateTime = DateTime;
  areas:any = []
  show: number = 50;
  page: number = 0;
  pages: number = 0;
  limit: number = 5;
  total: number = 5;
  changeActive() {
    this.docs.forEach((el) => {
      el.active = !this.isActive;
    });
  }
  mounted() {
    this.fetchData();
    this.fetchArea();
  }

  checkForm(area:any, year:any, j:any, quarter:any){
    let routeData = this.$router.resolve({path: `/portal/summary/form/${area}/${year}/${j}/${quarter}?searchparam=${JSON.stringify(this.search)}`, query: {data: "someData"}});
      window.open(routeData.href, '_blank');

    // if (area == "605c62fc402bbc642fd1d96e"){
    //   let routeData = this.$router.resolve({path: `/portal/summary/form/${area}/${year}/${j}/${quarter}?searchparam=${JSON.stringify(this.search)}`, query: {data: "someData"}});
    //   window.open(routeData.href, '_blank');
      // this.$router.push(`/portal/summary/form/${area}/${year}/${j}/${quarter}?searchparam=${JSON.stringify(this.search)}`)
    // } else {
    //   let routeData = this.$router.resolve({path: `/portal/summary/form/original/${area}/${year}/${j}/${quarter}?searchparam=${JSON.stringify(this.search)}`, query: {data: "someData"}});
    //   window.open(routeData.href, '_blank');
      // this.$router.push(`/portal/summary/form/original/${area}/${year}/${j}/${quarter}?searchparam=${JSON.stringify(this.search)}`)
    // }
  }
  checkPDF(area:any, year:any, j:any, quarter:any){
    let routeData = this.$router.resolve({path: `/portal/summary/pdf/${area}/${year}/${j}/${quarter}`, query: {data: "someData"}});
      window.open(routeData.href, '_blank');

    // if (area == "605c62fc402bbc642fd1d96e"){
    //   let routeData = this.$router.resolve({path: `/portal/summary/pdf/${area}/${year}/${j}/${quarter}`, query: {data: "someData"}});
    //   window.open(routeData.href, '_blank');
      // this.$router.push(`/portal/summary/pdf/${area}/${year}/${j}/${quarter}`)
    // } else {
    //   let routeData = this.$router.resolve({path: `/portal/summary/pdf/original/${area}/${year}/${j}/${quarter}`, query: {data: "someData"}});
    //   window.open(routeData.href, '_blank');
      // this.$router.push(`/portal/summary/pdf/original/${area}/${year}/${j}/${quarter}`)
    // }
  }


  fetchArea() {
    axios
      .get(`${address}/areas`)
      .then((response) => (this.areas = response.data));
  }

  fetchData() {
    this.loading = true
    let query = {
      search:this.search,
      limit: this.show,
      skip: this.page * this.show,
    }
    axios.post(`${address}/render-calculation-list`,query).then((response) => {
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show)
      axios.post(`${address}/calculations-paginate`,{
        search:{
          area:{$in:this.docs.map(el=>el.area)}
        },
        limit:99999,
        skip:0
      }).then((response) => {
        console.log("response",response.data)
        this.calculations = response.data.docs;
        this.mapping()
      });
    });
  }
  mapping(){
    console.log("mapping...")
    this.docs.forEach((el,i)=>{
      let found = this.calculations.find(cal=>{
        // console.log(el,cal)
        return cal.area == el.area
        && cal.operationYear == el.j
        && cal.quarter == el.quarter 
      })
      if(found!=undefined){
        console.log("found!")
        this.docs[i].calculation = found
        this.docs[i].modifiedAt = found.createdAt
        this.docs[i].isKrob3 = found.isKrob3
        this.docs[i].isKrob4 = found.isKrob4
      }
    })
    this.loading = false
  }
  updatePaginate(i: number) {
    this.page = i;
    console.log(this.page);
    this.fetchData();
  }
  get paginate() {
    let arr = new Array(this.pages).fill(0);
    return arr;
  }
}
