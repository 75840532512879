
import { Options, Vue } from "vue-class-component";
import address from "@/variable";
import axios from "axios";
import { Watch } from "vue-property-decorator";
import { DateTime } from "luxon";
import { useStore } from "vuex";


@Options({
  components: {
  },
  props: {
  },
})
export default class ReceiptList extends Vue {
  store = useStore()
  docs:Array<any> = []
  isSigned:boolean = true
  isActive:boolean = false
  search:any={};
  show:number = 999999999;
  total:number = 5;
  pages: number = 0;
  rates: Array<any> = [];
  Date: Date = new Date();
  totalPayment: number = 0;
  totalInvoice: number = 0;
  totalDebt: number = 0;

  downloadExcel() {
    console.log("download excel...");
    let self = this;
    const Excel = require("exceljs");
    var workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet(`รายงาน`);
    let header = [
      'ลำดับ',
      'เลขที่ผู้ใช้น้ำ',
      'ชื่อ-นามสกุล',
      'เลขที่ใบแจ้งหนี้',
      'ประเภท',
      'วันที่รับเงิน',
      'ประจำเดือน',
      'จำนวนเงินตามใบแจ้งหนี้(บาท)',
      'จำนวนเงินที่รับจริง(บาท)',
      'ตัดยอดหนี้',
      'ยอดค้างชำระ(บาท)'
    ];
    worksheet.addRow(['องค์การจัดการน้ำเสีย' + `(${(this.rates.find(el=>el.code==this.search.code)??{}).name})`,...new Array(12).fill(""),'333 อาคารเล้าเป้งง้วน 1 ชั้น 23 ถนนวิภาวดีรังสิต แขวงจอมพล เขตจตุจักร กรุงเทพฯ 10900']);
    worksheet.addRow(['ประจำวันที่' + new Date().toThaiShort(),...new Array(12).fill(""),'หน่วย: [บาท]']);
    worksheet.addRow(['ประจำเดือน' + `${this.getThaiMonth(this.search.month)} ${this.search.year}`,...new Array(12).fill(""),'ประเภท'+this.search.cat]);
    worksheet.addRow(header);
    this.docs.forEach((item, i) => {
      worksheet.addRow([
        i + 1,
        item.meter,
        (item.prefix??"") + (item.name??"-"),
        item.invoiceNumber,
        item.category,
        this.toThaiFormat(item.paymentDate),
        this.getThaiMonth(item.month) + "\r\n" + (item.year).toString().substring(2, 4),
        (item.invoiceAmount??0).formatFull(),
        (item.paymentAmount??0).formatFull(),
        item.debtText + "\r\n" + this.inclVat(item.debtAmount,item.vatRate) + ' บาท',
        this.inclVat((item.billAmount??0)+(item.debtAmount??0),item.vatRate).formatFull()
      ]);
    });
    workbook.xlsx.writeBuffer().then(function(data: any) {
      var blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ตัดยอดหนี้.xlsx");
    });
  }
  @Watch("search", { deep: true })
    updateReport() {
    console.log("deep");
    if (
      this.search.code != undefined &&
      this.search.cat != undefined &&
      this.search.month != undefined &&
      this.search.year != undefined
    ) {
      console.log("ทำงาน")
      this.fetchData();
    }
  }
  print(){
    window.print();
  }
  changeActive(){
    this.docs.forEach(el=>{
      el.active = !this.isActive
    })
  }
  inclVat(n: number, vat: number) {
    return parseFloat((n * (1 + (vat ?? 0.07))).toFixed(2));
  }
  mounted(){
    // this.fetchData()
    // this.search = {
    //   cat: "2,น้ำเสีย",
    //   code: "01-kb",
    //   month: "1",
    //   year: 2564,
    // };
    this.getRates()
  }
  getRates(){
    axios.get(`${address}/arearates`).then((response) => {
      this.rates = response.data;
    });
  }
  fetchData(){
    let category = null
    let categoryType = null
    try {
      category = (this.search.cat).split(",")[0]
      categoryType = (this.search.cat).split(",")[1]
    } catch (error) {
    }
    let query = {
      search:{
        $and:[
          { code: this.search.code!=undefined?{ $eq: this.search.code }:undefined },
          { year: this.search.year!=undefined?{ $eq: this.search.year }:undefined },
          { month: this.search.month!=undefined?{ $eq: this.search.month }:undefined },
          { category: category != undefined ? { $eq: category } : undefined },
          // { categoryType:categoryType != undefined ? { $eq: categoryType } : undefined,},
        ],
      },
      limit:999999999,
      skip:0,
      sort:{
        createdAt:"-1"
      }
    }
    axios.post(`${address}/payments-paginate`,query).then((response) => {
      this.docs = response.data.docs;
      this.total = response.data.total;
      this.pages = Math.ceil(response.data.total / this.show);
      this.totalPayment = response.data.totalPayment;
      this.totalInvoice = response.data.totalInvoice;
      this.totalDebt = response.data.totalDebt;
    });
  }
  toThaiFormat(dt:string){
    var dtThai = DateTime.fromISO(dt)
      .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("ddLLLyy");
    return dtThai
  }
  getThaiMonth(n: number){
      switch (n) {
        case 1:
          return "ม.ค.";
          break;
        case 2:
          return "ก.พ.";
          break;
        case 3:
          return "มี.ค.";
          break;
        case 4:
          return "เม.ย.";
          break;
        case 5:
          return "พ.ค.";
          break;
        case 6:
          return "มิ.ย.";
          break;
        case 7:
          return "ก.ค.";
          break;
        case 8:
          return "ส.ค.";
          break;
        case 9:
          return "ก.ย.";
          break;
        case 10:
          return "ต.ค.";
          break;
        case 11:
          return "พ.ย.";
          break;
        case 12:
          return "ธ.ค.";
          break;

        default:
          return ".";
          break;
      }
  }
}
