
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    step:Number
  },
})
export default class StepBilling extends Vue {
}
