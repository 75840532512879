
import address from "@/variable";
import axios from "axios";
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
@Options({
  components: {},
  props: {},
})
export default class Signature extends Vue {
  store = useStore();
  target = 42834835.7;
  current = 41374835.7;

  docs: Array<any> = [];

  get sum() {
    let filter = this.docs
    let map = filter.map((o) => o.amount??0);
    let reduce = map.reduce((a: number, b: number) => a + b, 0);
    return reduce;
  }
  get diff() {
    return this.target - this.current;
  }
  mounted() {
    this.fetchData();
  }
  fetchData() {
    axios
      .post(`${address}/areacollections-paginate`, {
        limit: 999,
        skip: 0,
        search: {
          year:2564,
          month:12
        },
      })
      .then((response) => {
        this.docs = response.data.docs;
      });
  }
}
