
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    modelValue: [String,Number,Array],
    label: String,
    inputType: String,
    min:Number,
    icon: String,
    placeholder: String,
    value: String,
    allowed: String,
    isDisabled: Boolean,
    nomargin: Boolean,
    readonly:Boolean,
    required:Boolean
  },
})
export default class JMInput extends Vue {
  label!: string;
  inputType!: string;
  icon!: string;
  placeholder!: string;
  value!: string;
  allowed!: string;
  min!:number;
  isDisabled!: boolean;
  nomargin!: boolean;

  inputData: string = "22";
  componentKey: number = 0;
  overlayData: string = "";
  isError: boolean = false;
  mounted() {
    this.inputData = this.value;
  }
  emitup = (event: any) => {
    if (this.inputType == "number") {
      if(this.min==0){
      this.$emit("update:modelValue", Math.abs(parseFloat(event.target.value)));
      }else{
      this.$emit("update:modelValue", parseFloat(event.target.value));
      }
    } else {
      this.$emit("update:modelValue", event.target.value);
    }
  };
}
