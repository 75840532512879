
import { Options, Vue } from 'vue-class-component';
import { useStore } from "vuex";
import axios from "axios";
import address from "@/variable";

@Options({})
export default class LoginExample extends Vue {
  store = useStore();
  search:string = "";
  // numberInit:string = "6420000096";
  // docs: Array<any> =[];
  // search: Array<any> =[];
  // getData() {
  //   let query = {
  //     search:{
  //       meter:this.$route.params.meter,
  //       numberInit:this.$route.params.sequence,   
  //     },
  //     limit:1,
  //     skip:0
  //   }
  //   axios.post(`${address}/invoices-paginate`,query).then(response=>{
  //     this.search = response.data
  //   })
  // }
}

