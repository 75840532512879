import { Document, Packer, Paragraph, TextRun, Table, TableRow, TableCell, WidthType, AlignmentType, BorderStyle, Header, Footer, ImageRun, PageBreak, UnderlineType, Indent, TabStopType, Border } from "docx";
import { DateTime } from "luxon"
import * as _ from "lodash"
import { any } from "cypress/types/bluebird";
let fontName = "TH SarabunIT๙" //"TH Sarabun New"//
let updateCategory = (ledgerLeft: Array<any>, ledgerRight: Array<any>) => {
  let getData = (cat: string) => {
    return _.chain([...(ledgers ?? []).filter(el => el.category == cat), ...(endorsed ?? []).filter(el => el.category == cat)]).groupBy('code').map((items, item) => {
      return {
        code: item,
        left: items.find(el => el.side == "left"),
        right: items.find(el => el.side == "right"),
        category: items.find(el => el.side == "left") != undefined ? items.find(el => el.side == "left").category : items.find(el => el.side == "right").category,
        name: items.find(el => el.side == "left") != undefined ? items.find(el => el.side == "left").name : items.find(el => el.side == "right").name
      }
    }).value()
  }
  var ledgers = _.chain(ledgerLeft).map(el => { return { ...el, side: 'left', months: undefined } }).value()
  var endorsed = _.chain(ledgerRight).map(el => { return { ...el, side: 'right', months: undefined } }).value()
  let u1 = getData("1")
  let u2 = getData("2")
  let u3 = getData("3")
  let u4 = getData("4")
  var union = [u1, u2, u3, u4]

  return union
}
function stripHtml(html: string) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}
export class DocumentCreator {
  tabStops = [
    {
      type: TabStopType.LEFT,
      position: 20 * 2,
    },
  ]
  style = {
    paragraphStyles: [
      {
        id: "Normal",
        name: "Normal",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        indent: {
          left: 720,
        },
        run: {
          bold: true,
          color: "000000",
          size: 17 * 2,
          font: {
            name: fontName,
          },
        },
        paragraph: {

          // spacing: {
          //   after: 120,
          // },
        },
      },
      {
        id: "Bold",
        name: "Bold",
        basedOn: "Bold",
        next: "Bold",
        quickFormat: true,
        run: {
          color: "000000",
          size: 17 * 2,
          font: {
            name: fontName,
          },
        },
        paragraph: {
        },
      },
      {
        id: "Small",
        name: "Small",
        basedOn: "Small",
        next: "Small",
        quickFormat: true,
        run: {
          size: 13 * 2,
          font: {
            name: fontName,
          },
        }
      },
      {
        id: "Distribute",
        name: "Distribute",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: 17 * 2,
          font: {
            name: fontName,
          },
        },
        paragraph: {
          //@ts-ignore
          alignment: "thaiDistribute",
        },
      },
      {
        id: "newLine",
        name: "newLine",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          font: "TH Sarabun New",
          size: 8 * 2,
        },
      },
      {
        id: "Header",
        name: "Header",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          bold: true,
          font: "TH Sarabun New",
          size: 34 * 2,
        },
      },
      {
        id: "HeaderSubtitle",
        name: "HeaderSubtitle",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          bold: true,
          font: "TH Sarabun New",
          size: 18 * 2,
        },
      },
      {
        id: "Footer",
        name: "Footer",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          font: "TH Sarabun New",
          size: 8 * 2,
        },
      },
    ]
  }
  header = {
    default: new Header({
      children: [
        new Paragraph({ text: "\t\t\t องค์การจัดการน้ำเสีย", style: "Header" }),
        new Paragraph({
          children: [
            new TextRun("\t\t\t "),
            new ImageRun({
              data: Buffer.from(require("./line").default, "base64"),
              transformation: {
                width: 330,
                height: 10,
              }
            })],
          spacing: { before: 10, after: 100, line: 10 },
        }),
        new Paragraph({ text: "\t\t\t WASTEWATER MANAGEMENT AUTHORITY", style: "HeaderSubtitle", spacing: { line: 150 } }),
        new Paragraph({ text: "\t\t\t MINISTRY OF INTERIOR, THAILAND", style: "HeaderSubtitle", spacing: { line: 150 } }),
        new Paragraph({
          children: [
            new ImageRun({
              data: Buffer.from(require("./logo").default, "base64"),
              transformation: {
                width: 100,
                height: 100,
              },
              floating: {
                zIndex: 5,
                horizontalPosition: {
                  offset: 814400,
                },
                verticalPosition: {
                  offset: 314400,
                },
              }
            }),
          ],
        }),
      ],
    }),
  }
  footer = {
    default: new Footer({
      children: [
        new Paragraph({ text: "333 อาคารเล้าเป้งง้วน 1 ชั้น 23 ถนนวิภาวดีรังสิต แขวงจอมพล เขตจตุจักร กทม. 10900 โทรศัพท์ 0 2273 8530-9 โทรสาร 0 2273 8577-9", style: "Footer" }),
        new Paragraph({ text: "333 LAO PENG NGUAN TOWER 1 FL 23rd VIBHAVADI-RANGSIT Rd., JOMPHON, CHATUJAK, BANGKOK 10900 Tel. 0 2273 8530-9 FAX 0 2273 8577-9", style: "Footer" }),
        new Paragraph({ text: "URL : http://www.wma.or.th\t\te-mail : wastewtr@wma.or.th", style: "Footer" }),
      ],
    }),
  }
  public create(area: any, doc: any, RCalculation: any, condition: any, state: any, currentConditionCategory:string, isLeft?: boolean, isRight?: boolean, isCustom?: boolean): Document {
    let calculation = JSON.parse(JSON.stringify(RCalculation))
    let cCondition = condition.conditions[calculation.operationYear ?? 0]
    var calculation1: Array<any> = new Array(20).fill(0)
    var calculation2: Array<any> = new Array(20).fill(0)
    console.log("doc", doc)
    let isLeftRight = false
    if (isLeft && isRight) {
      isLeftRight = true
    }
    let modification = calculation.modifications
    let manuals = calculation.manuals
    let ledgers = calculation.ledgers.filter((el: any) => el.selected)
    let eLedgers = (calculation.endorsedLedgers ?? []).filter((el: any) => el.selected)
    let union = updateCategory(ledgers, eLedgers)
    let dt = doc.issueDate ? DateTime.now() : DateTime.fromISO(doc.issueDate)
    let calc = calculation == undefined ? {} : calculation
    let calendarYear = (calc.calendarYear ?? new Date().getFullYear() + 543).toString() ?? ""
    let dateFormat = dt
      .reconfigure({ outputCalendar: "buddhist" })
      .setLocale("th")
      .toFormat("LLLL yyyy").formatThai()
    let newLineSmall = new Paragraph({})
    // console.log(imageBase64Data)
    let quarter = (calc.quarter ?? "-").toString() ?? ""
    let noneBorder = {
      style: BorderStyle.NONE,
      size: 1,
      color: "000000",
    }
    let allNoneBorder = {
      top: noneBorder,
      bottom: noneBorder,
      left: noneBorder,
      right: noneBorder
    }
    let getTableRow = (str: string) => {
      return new TableRow({
        children: [
          new TableCell({
            width: {
              size: 4505,
              type: WidthType.DXA,
            },
            children: [new Paragraph("")],
            borders: allNoneBorder
          }),
          new TableCell({
            width: {
              size: 4505,
              type: WidthType.DXA,
            },
            children: [new Paragraph({ text: (str ?? ""), alignment: AlignmentType.CENTER })],
            borders: allNoneBorder
          }),
        ],
      })
    }
    const table = new Table({
      columnWidths: [4505, 4505],
      rows: [
        getTableRow("ขอแสดงความนับถือ"),
        getTableRow(""),
        getTableRow(doc.approverName),
        getTableRow(doc.approverPosition)
      ]
    });
    let getTableRow2 = (val1: string, val2: string, val3: string) => {
      return new TableRow({
        children: [
          new TableCell({
            width: {
              size: 6000,
              type: WidthType.DXA,
            },
            children: [new Paragraph(val1 ?? "")],
            borders: allNoneBorder
          }),
          new TableCell({
            width: {
              size: 1500,
              type: WidthType.DXA,
            },
            children: [new Paragraph(val2 ?? "")],
            borders: allNoneBorder
          }),
          new TableCell({
            width: {
              size: 1500,
              type: WidthType.DXA,
            },
            children: [new Paragraph({ text: val3 ?? "", alignment: AlignmentType.RIGHT })],
            borders: allNoneBorder
          }),
        ],
      })
    }
    let getTableRow3 = (val1: any, val2: any, val3: any, unit?: string) => {
      return new TableRow({
        children: [
          new TableCell({
            width: {
              size: 6000,
              type: WidthType.DXA,
            },
            children: [typeof val1 === "string" ? new Paragraph({ text: val1 ?? "" }) : val1],
            borders: allNoneBorder
          }),
          new TableCell({
            width: {
              size: 1600,
              type: WidthType.DXA,
            },
            children: [new Paragraph({ text: val2 ?? "", alignment: AlignmentType.RIGHT })],
            borders: allNoneBorder
          }),
          new TableCell({
            width: {
              size: 1500,
              type: WidthType.DXA,
            },
            children: [new Paragraph({ text: val3 ?? "", alignment: AlignmentType.RIGHT })],
            borders: allNoneBorder
          }),
          new TableCell({
            width: {
              size: 500,
              type: WidthType.DXA,
            },
            children: [new Paragraph({ text: unit ?? "", alignment: AlignmentType.RIGHT })],
            borders: allNoneBorder
          }),
        ],
      })
    }
    let krob3Category = [
      "เงินเดือน (ผจก.,พนง.ลูกจ้าง)",
      "ค่าซ่อมแซมและบำรุงรักษาระบบบำบัดน้ำเสีย",
      "ค่าสาธารณูปโภค",
      "ค่าใช้จ่ายอื่นๆ"
    ]
    let krob3Amount: Array<any> = []
    let krob3Middle: Array<any> = []
    let krob3Lower: Array<any> = []
    let krob2Prep: Array<any> = []
    let grandTotalLeft = 0
    let grandTotalRight = 0
    let categories: Array<string> = [
      "เงินเดือน (ผจก.,พนง.ลูกจ้าง)",
      "ค่าซ่อมแซมและบำรุงรักษาระบบบำบัดน้ำเสีย",
      "ค่าสาธารณูปโภค",
      "ค่าใช้จ่ายอื่นๆ"
    ]
    union.forEach((un: Array<any>, i: number) => {
      let totalLeft = 0
      let totalRight = 0
      krob2Prep.push(
        getTableRow3(
          new Paragraph({text:`${(i + 1).toString().formatThai()} .` + categories[i], style: "UnderlineSingle"}),
          "",
          "",
        )
      )
      un.forEach(el => {
        totalLeft += (el.left ?? {}).totalAmount ?? 0
        totalRight += (el.right ?? {}).totalAmount ?? 0

        krob2Prep.push(
          getTableRow3(
            el.name,
            isLeft ? ((el.left ?? {}).totalAmount ?? 0).formatFull().formatThai() : "",
            isRight ? ((el.right ?? {}).totalAmount ?? 0).formatFull().formatThai() : "",
            "บาท",
          ),
        )
      })
      krob2Prep.push(
        getTableRow3(
          "รวม",
          isLeft ?  (totalLeft ?? 0).formatFull().formatThai() as string : "",
          isRight ?  (totalRight ?? 0).formatFull().formatThai() as string: "",
          "บาท",
        )
      )
      grandTotalLeft += totalLeft
      grandTotalRight += totalRight
    })
    krob2Prep.push(
      getTableRow3(
        "รวมค่าใช้จ่ายจากการดำเนินงานขององค์การจัดการน้ำเสีย", 
        isLeft ? ((_.chain([...union[0], ...union[1], ...union[2], ...union[3]]).map('left.totalAmount')).reduce((sum, n) => sum + (n ?? 0), 0).value() ?? 0).formatFull().formatThai() as string : "", 
        isRight ? ((_.chain([...union[0], ...union[1], ...union[2], ...union[3]]).map('right.totalAmount')).reduce((sum, n) => sum + (n ?? 0), 0).value() ?? 0).formatFull().formatThai() as string : "", 
        "บาท",
        )
    )
    krob2Prep.push(
      getTableRow2(
        isLeftRight ? 
        ((calculation.quarter!=0) ? `(ไตรมาส ${(calculation.quarter??0).toString().formatThai()})` : "") +  ` สำหรับปีงบประมาณ ${(calculation.calendarYear ?? 0).toString().formatThai()} (ไตรมาส ${calculation.quarter.toString().formatThai()})`: 
        ((calculation.quarter!=0) ? `(ไตรมาส ${(calculation.quarter??0).toString().formatThai()})` : "") +  `สำหรับปีงบประมาณ ${(calculation.calendarYear ?? 0).toString().formatThai()} (ไตรมาส ${calculation.quarter.toString().formatThai()})`
        ,"",""
      )
    )
    union.forEach((un: Array<any>, i: number) => {
      let totalLeft = 0
      let totalRight = 0
      un.forEach((el, j) => {
        totalLeft += (el.left ?? {}).totalAmount ?? 0
        totalRight += (el.right ?? {}).totalAmount ?? 0
      })
      krob3Amount.push(
        getTableRow3(`${(i + 1).toString().formatThai()} ` + krob3Category[i], isLeft ? (totalLeft ?? 0).formatFull().formatThai() as string : "", isRight ? (totalRight ?? 0).formatFull().formatThai() as string : "","บาท")
      )
    })
    krob3Amount.push(getTableRow3("รวมค่าใช้จ่ายในการดำเนินการขององค์กรการจัดการน้ำเสีย", isLeft ? (grandTotalLeft ?? 0).formatFull().formatThai() as string : "", isRight ? (grandTotalRight ?? 0).formatFull().formatThai() as string : "" ,"บาท"))
    krob3Amount.push(getTableRow3("    ปริมาณน้ำบำบัดได้ในปีงบประมาณ " + calculation.calendarYear, isLeft ? (calculation.treatmentQty ?? 0).formatFull().formatThai() as string : "", isRight ? (calculation.eTreatmentQty ?? 0).formatFull().formatThai() as string : "","ลบ.ม."))
    krob3Middle.push(getTableRow3("    ต้นทุนปันส่วนต่อหน่วย", isLeft ? (calculation.allocationPerUnit ?? 0).formatFull().formatThai() as string : "x", isRight ? (calculation.eAllocationPerUnit ?? 0).formatFull().formatThai() as string : "","บาท/ลบ.ม."))
    krob3Middle.push(getTableRow3("    ค่าใช้จ่ายปันส่วนตามปริมาณน้ำ", isLeft ? (calculation.allocationCost ?? 0).formatFull().formatThai() as string : "=", isRight ? (calculation.eAllocationCost ?? 0).formatFull().formatThai() as string : "","บาท"))
    krob3Middle.push(getTableRow3("    รวมค่าใช้จ่ายทั้งสิ้นของ อจน.", isLeft ? (calculation.wmaExpenses ?? 0).formatFull().formatThai() as string : "", isRight ? (calculation.eWmaExpenses ?? 0).formatFull().formatThai() as string : "","บาท"))
    krob3Middle.push(getTableRow3("", "", "", ""))
    krob3Middle.push(getTableRow3(new Paragraph({ text: "ค่าใช้จ่ายในการดำเนินงานของ " + area.prefix + area.name, style: "UnderlineSingle" }), "", "","")),
    krob3Middle.push(getTableRow3("    ค่าใช้จ่ายในการดำเนินงานของ  " + area.prefix + area.name, "", "", ""))
    krob3Middle.push(getTableRow3("        ค่าใช้จ่ายในการจัดเก็บค่าบริการ", isLeft ? (calculation.areaCost02 ?? 0).formatFull().formatThai() as string : "", isRight ? (calculation.eAreaCost02 ?? 0).formatFull().formatThai() as string : "","บาท"))
    krob3Middle.push(getTableRow3("        ค่าสื่อประชาสัมพันธ์", isLeft ? (calculation.areaCost03 ?? 0).formatFull().formatThai() as string : "+", isRight ? (calculation.eAreaCost03 ?? 0).formatFull().formatThai() as string : "","บาท"));
    let expenses = (doc.expenses ?? [])
    expenses.forEach((el: any) => {
      if ((doc.endorsedLedgers ?? []).length > 0)
        krob3Middle.push(getTableRow3("      " + el.name, (el.amount ?? 0).formatFull().formatThai(), (el.eAmount ?? 0).formatFull().formatThai()))
      else
        krob3Middle.push(getTableRow3("      " + el.name, (el.amount ?? 0).formatFull().formatThai(), (el.eAmount ?? 0).formatFull().formatThai()))
    })
    krob3Middle.push(getTableRow3("    รวมค่าใช้จ่ายทั้งสิ้นของ " + area.prefix + area.name, isLeft ? (calculation.areaExpenses ?? 0).formatFull().formatThai() as string : "=", isRight ? (calculation.eAreaExpenses ?? 0).formatFull().formatThai() as string : "","บาท"));
    krob3Middle.push(getTableRow3("รวมค่าใช้จ่ายทั้งสิ้นของ อจน. และ " + area.prefix + area.name, isLeft ? (calculation.wmaExpenses ?? 0).formatFull().formatThai() as string : "=", isRight ? (calculation.eWmaExpenses ?? 0).formatFull().formatThai() as string : "","บาท"));

    krob3Lower.push(getTableRow3("", "", "", ""))
    krob3Lower.push(getTableRow3(new Paragraph({ text: "รายได้ในการดำเนินงานของ " + area.prefix + area.name, style: "UnderlineSingle" }), "", "","")),
    krob3Lower.push(getTableRow3("    รายได้จากการจัดเก็บค่าบริการ (บำบัดน้ำเสีย,เชื่อมท่อ,ฯลฯ) ของ " + area.prefix + area.name, "", "", ""))
    krob3Lower.push(getTableRow3("    รายได้จากการจัดเก็บค่าบริการ", isLeft ? (calculation.income01 ?? 0).formatFull().formatThai() as string : "", isRight ? (calculation.eIncome01 ?? 0).formatFull().formatThai()as string : "","บาท"));
    krob3Lower.push(getTableRow3("    รายได้จากการต่อท่อเชื่อม", isLeft ?  (calculation.income02 ?? 0).formatFull().formatThai() as string : "+", isRight ? (calculation.eIncome02 ?? 0).formatFull().formatThai()as string : "","บาท"));
    krob3Lower.push(getTableRow3("รวมรายได้จากการจัดเก็บค่าบริการ", isLeft ?  (calculation.totalIncome ?? 0).formatFull().formatThai() as string : "=", isRight ? (calculation.eTotalIncome ?? 0).formatFull().formatThai()as string : "","บาท"));

    const krob2 = new Table({
      columnWidths: [4000, 2000, 2000],
      rows: krob2Prep
    });
    // let currentConditionCategory = (condition.conditions[calculation.operationYear] ?? { category: "สมทบตามจำนวนคงที่" }).category ?? "สมทบตามจำนวนคงที่"
    let currentCondition = condition.conditions[calculation.operationYear] ?? {}
    const krob3 = new Table({
      columnWidths: [4000, 2000, 2000],
      rows: [...krob3Amount,
      ...(currentConditionCategory != 'สมทบตามเปอร์เซ็นต์' ? krob3Middle : []),
      ...(currentConditionCategory != 'สมทบตามเปอร์เซ็นต์' ? krob3Lower : [])

      ]
    });


    let modifications: Array<any> = []
    let adjustText = ""
    if ((calculation.modifications ?? []).length > 0) adjustText = "รายการปรับปรุงค่าใช้จ่าย"
    try {
      modifications = calculation.modifications ?? [].map((el: any) => {
        return ["", el.name, el.symbol, { text: (el.amount ?? 0).formatFull().formatThai(), alignment: "right" }, "บาท"]
      })
    } catch (error) {
      console.log(error)
    }
    console.log("calculation",calculation)
    // var checkModified = () => {
    //   if ((calculation.modifications ?? []).length > 0) {
    //     let sum = 0
    //     let eSum = 0
    //     calculation.modifications.forEach((mod: any) => {
    //       if (mod.symbol == "+") {
    //         sum += mod.amount
    //         eSum += mod.eAmount
    //       }
    //       else if (mod.symbol == "-") {
    //         sum -= mod.amount
    //         eSum -= mod.eAmount
    //       }
    //     });
    //     console.log(sum, eSum)
    //     // calculation.contributionAmount = sum + calculation1[calculation1.length - 1] // was originalDoc
    //     // calculation.eContributionAmount = eSum + calculation2[calculation2.length - 1] // was this.originalDoc
    //   } else {
    //     if (currentConditionCategory == 'สมทบตามเปอร์เซ็นต์') {
    //       console.log(calculation1[calculation1.length - 1])
    //       console.log(calculation2[calculation2.length - 1])
    //       console.log("calculation1",calculation.advanceAmount)
    //       console.log("calculation2",calculation.eAdvanceAmount)
    //       calculation.contributionAmount = (calculation1[calculation1.length - 1] ?? 0) - (calculation.advanceAmount ?? 0) // was originalDoc
    //       calculation.eContributionAmount = (calculation2[calculation2.length - 1] ?? 0) - (calculation.eAdvanceAmount ?? 0)  // was originalDoc
          
    //     }
    //   }
    // }
    var foundCondition: any = state.conditionLossType1
    let underline: Array<Array<Boolean>> = []
    let krob4Array: Array<any> = []
    if(Number.isNaN(calculation.contributionAmount)){
      calculation.contributionAmount = 0
    }
    if(Number.isNaN(calculation.eContributionAmount)){
      calculation.eContributionAmount = 0
    }
    // checkModified()
    console.log("checkModified",calculation1)

    let conditionsArray = ((foundCondition ?? { children: [] }).children ?? [{}])??[]

    // conditionsArray.forEach((el: any, i: number) => {
    //   let val1 = 0
    //   let val2 = 0
    //   try {
    //     val1 = calculation1[i] ?? 0
    //   } catch (error) {

    //   }
    //   try {
    //     val2 = calculation2[i] ?? 0
    //   } catch (error) {

    //   }
    //   krob4Array.push(
    //     getTableRow3((el ?? "").replace("*area*", (area.prefix ?? '') + (area.name ?? '')).replace("*year*", doc.calendarYear).replace("*quarter*", doc.quarter).replace("*contributionLimit*", (condition.contributionLimit ?? 0).formatFull().formatThai()).replace("*contributionPercent*", condition.contributionPercent).replace('ไตรมาสที่ 0', "").replace('ไตรมาสที่ undefined', "")
    //       , isLeft ? val1.formatFull().formatThai().replace("NaN", "๐.๐๐") as string : ""
    //       , isRight ? val2.formatFull().formatThai().replace("NaN", "๐.๐๐") as string : ""
    //       , "บาท"))
    // })
    // krob4Array.push(getTableRow(calculation.contributionText))
    krob4Array.push(getTableRow3("","","",""))
    if(isCustom){
      manuals.filter((el:any)=>el.enable)
        .forEach((el:any, i: number) => {
          krob4Array.push(
            getTableRow3((el.name??"").replace("**area**", (area.prefix ?? '') + (area.name ?? '')).replace("**year**", calculation.calendarYear).replace("**quarter**", calculation.quarter).replace('ไตรมาสที่ 0', "").replace('0', "").replace("input",doc.note1 ?? ''), 
            isLeft ? (el.value??0).formatFull().formatThai() :"", 
            isRight ? (el.eValue??0).formatFull().formatThai() :"", "บาท")
        )});
    } else {

      var checkModified = () => {
        if ((calculation.modifications ?? []).length > 0) {
          let sum = 0
          let eSum = 0
          calculation.modifications.forEach((mod: any) => {
            if (mod.symbol == "+") {
              sum += mod.amount
              eSum += mod.eAmount
            }
            else if (mod.symbol == "-") {
              sum -= mod.amount
              eSum -= mod.eAmount
            }
          });
          console.log(sum, eSum)
          calculation.contributionAmount = sum + calculation1[calculation1.length - 1] // was originalDoc
          calculation.eContributionAmount = eSum + calculation2[calculation2.length - 1] // was this.originalDoc
        } else {
          if (currentConditionCategory == 'สมทบตามเปอร์เซ็นต์') {
            console.log(calculation1[calculation1.length - 1])
            console.log(calculation2[calculation2.length - 1])
            console.log("calculation1",calculation.advanceAmount)
            console.log("calculation2",calculation.eAdvanceAmount)
            // calculation.contributionAmount = (calculation1[calculation1.length - 1] ?? 0) - (calculation.advanceAmount ?? 0) // was originalDoc
            // calculation.eContributionAmount = (calculation2[calculation2.length - 1] ?? 0) - (calculation.eAdvanceAmount ?? 0)  // was originalDoc
            
          }
        }
      }
      if(Number.isNaN(calculation.contributionAmount)){
        calculation.contributionAmount = 0
      }
      if(Number.isNaN(calculation.eContributionAmount)){
        calculation.eContributionAmount = 0
      }
      switch (currentConditionCategory) {
        case 'สมทบตามเปอร์เซ็นต์':
          let q = calculation.quarter
          let limit = q == '0' ? cCondition.contributionLimit : cCondition.contributionLimit / 4
          if (limit < 1) limit = 9E10
          let a = (calculation.totalExpenses) * ((cCondition.contributionPercent) / 100)
          if (a > limit) calculation.contributionAmount = limit
          else calculation.contributionAmount = a
          calculation.contributionAmount = (calculation.contributionAmount ?? 0) - (calculation.advanceAmount ?? 0)
          calculation1 = [
            -1,
            calculation.advanceAmount??0,
            calculation.contributionAmount,
            -1
          ]
          let b = (calculation.eTotalExpenses) * ((cCondition.contributionPercent) / 100)
          if (b > limit) calculation.eContributionAmount = limit
          else calculation.eContributionAmount = b
          calculation.eContributionAmount = (calculation.eContributionAmount ?? 0) - (calculation.eAdvanceAmount ?? 0)
          calculation2 = [
            -1,
            calculation.eAdvanceAmount??0,
            calculation.eContributionAmount,
            -1
          ]
          console.log("คิดตามสมทบเปอร์เซ็นต์",calculation)
          console.log("calculation1",calculation1)
          console.log("calculation2",calculation2)
          checkModified()
          underline = [
            [false, false, false, false],
            [false, false, false, true],
          ]
          foundCondition = state.conditionPercent
          break;
        case 'สมทบตามจำนวนคงที่':
          calculation1 = [
            calculation.totalExpenses,
            calculation.totalIncome,
            calculation.totalExpenses - calculation.totalIncome,
            calculation.advanceAmount,
            calculation.contributionAmount
          ]
          calculation2 = [
            calculation.eTotalExpenses,
            calculation.eTotalIncome,
            calculation.eTotalExpenses - calculation.eTotalIncome,
            calculation.eAdvanceAmount,
            calculation.contributionAmount
          ]
          checkModified()
          underline = [
            [false, false, false, false],
            [false, false, false, true],
            [false, false, false, true],
            [false, false, false, true],
          ]
          foundCondition = state.conditionFixed
          break;
        case 'สมทบตามค่าใช้จ่ายจริง/สัดส่วนเท่าเทียม':
          let income = 0
          let expense = 0
          if ((calculation.ledgers ?? []).length == 0) {
            income = calculation.eTotalIncome
            expense = calculation.eTotalExpenses
          } else {
            income = calculation.totalIncome
            expense = calculation.totalExpenses
          }
          let isProfit = (income - expense) > 0
          if (isProfit) { // กำไร
            console.log("กำไร", cCondition.profitType)
            if (cCondition.profitType == 1) {
              calculation.contributionAmount = ((calculation.totalIncome - (((calculation.totalIncome - calculation.totalExpenses) - ((calculation.totalIncome - calculation.totalExpenses) / (100 * 30))) / 2)) - (calculation.advanceAmount ?? 0))
              calculation.contributionAmount = Math.abs(parseFloat((calculation.contributionAmount ?? 0).toFixed(2))) - (calculation.advanceAmount ?? 0)
              calculation.contributionAmount = Math.abs(parseFloat((calculation.contributionAmount ?? 0).toFixed(2)))
              calculation1 = [
                calculation.totalIncome,
                calculation.totalExpenses,
                calculation.totalIncome - calculation.totalExpenses,
                (calculation.totalIncome - calculation.totalExpenses) / (100 * 30),
                (calculation.totalIncome - calculation.totalExpenses) - ((calculation.totalIncome - calculation.totalExpenses) / (100 * 30)),
                ((calculation.totalIncome - calculation.totalExpenses) - ((calculation.totalIncome - calculation.totalExpenses) / (100 * 30))) / 2,
                calculation.totalIncome - (((calculation.totalIncome - calculation.totalExpenses) - ((calculation.totalIncome - calculation.totalExpenses) / (100 * 30))) / 2),
                calculation.advanceAmount,
                calculation.contributionAmoun
              ]
              calculation.eContributionAmount = ((calculation.eTotalIncome - (((calculation.eTotalIncome - calculation.eTotalExpenses) - ((calculation.eTotalIncome - calculation.eTotalExpenses) / (100 * 30))) / 2)) - (calculation.eAdvanceAmount ?? 0))
              calculation.eContributionAmount = Math.abs(parseFloat((calculation.eContributionAmount ?? 0).toFixed(2))) - (calculation.eAdvanceAmount ?? 0)
              calculation.eContributionAmount = Math.abs(parseFloat((calculation.eContributionAmount ?? 0).toFixed(2)))
              calculation2 = [
                calculation.eTotalIncome,
                calculation.eTotalExpenses,
                calculation.eTotalIncome - calculation.eTotalExpenses,
                (calculation.eTotalIncome - calculation.eTotalExpenses) / (100 * 30),
                (calculation.eTotalIncome - calculation.eTotalExpenses) - ((calculation.eTotalIncome - calculation.eTotalExpenses) / (100 * 30)),
                ((calculation.totalIncome - calculation.totalExpenses) - ((calculation.totalIncome - calculation.totalExpenses) / (100 * 30))) / 2,
                calculation.eTotalIncome - (((calculation.eTotalIncome - calculation.eTotalExpenses) - ((calculation.eTotalIncome - calculation.eTotalExpenses) / (100 * 30))) / 2),
                calculation.eAdvanceAmount,
                calculation.eContributionAmount
              ]
              checkModified()
              foundCondition = state.conditionProfitType1
              underline = [
                [false, false, false, false],
                [false, false, false, false],
                [false, false, false, true],
                [false, false, false, false],
                [false, false, false, true],
                [false, false, false, false],
                [false, false, false, false],
                [false, false, false, true],
                [false, false, false, true],
              ]
            }
            if (cCondition.profitType == 2) {
              calculation.contributionAmount = (calculation.totalIncome + ((calculation.totalExpenses - calculation.totalIncome) / 2))
              calculation.contributionAmount = Math.abs(parseFloat((calculation.contributionAmount ?? 0).toFixed(2))) - (calculation.advanceAmount ?? 0)
              calculation.contributionAmount = Math.abs(parseFloat((calculation.contributionAmount ?? 0).toFixed(2)))
              calculation1 = [
                calculation.totalExpenses,
                calculation.totalIncome,
                calculation.totalExpenses - calculation.totalIncome,
                (calculation.totalExpenses - calculation.totalIncome) / 2,
                calculation.totalIncome + ((calculation.totalExpenses - calculation.totalIncome) / 2),
                calculation.advanceAmount,
                calculation.contributionAmount
              ]
              calculation.eContributionAmount = (calculation.eTotalIncome + ((calculation.eTotalExpenses - calculation.eTotalIncome) / 2))
              calculation.eContributionAmount = Math.abs(parseFloat((calculation.eContributionAmount ?? 0).toFixed(2))) - (calculation.eAdvanceAmount ?? 0)
              calculation.eContributionAmount = Math.abs(parseFloat((calculation.eContributionAmount ?? 0).toFixed(2)))
              calculation2 = [
                calculation.eTotalExpenses,
                calculation.eTotalIncome,
                calculation.eTotalExpenses - calculation.eTotalIncome,
                (calculation.eTotalExpenses - calculation.eTotalIncome) / 2,
                calculation.eTotalIncome + ((calculation.eTotalExpenses - calculation.eTotalIncome) / 2),
                calculation.eAdvanceAmount,
                calculation.eContributionAmount
              ]
              checkModified()
              foundCondition = state.conditionProfitType2
              underline = [
                [false, false, false, false],
                [false, false, false, true],
                [false, false, false, false],
                [false, false, false, true],
                [false, false, false, false],
                [false, false, false, true],
                [false, false, false, true],
              ]
            }
          }
          else { //ขาดทุน
            let percent = 100
            if (cCondition.contributionPercent == 0 || cCondition.contributionPercent == undefined) percent = 100
            else percent = cCondition.contributionPercent
            console.log("ขาดทุน", cCondition.lossType)
            if (cCondition.lossType == 1) {
              calculation.contributionAmount = (calculation.totalIncome + ((calculation.totalExpenses - calculation.totalIncome) / 2))
              calculation.contributionAmount = Math.abs(parseFloat((calculation.contributionAmount ?? 0).toFixed(2))) - (calculation.advanceAmount ?? 0)
              calculation.contributionAmount = Math.abs(parseFloat((calculation.contributionAmount ?? 0).toFixed(2)))
              calculation.contributionAmount = calculation.contributionAmount
              calculation1 = [
                calculation.totalExpenses,
                calculation.totalIncome,
                calculation.totalExpenses - calculation.totalIncome,
                (calculation.totalExpenses - calculation.totalIncome) / 2,
                calculation.totalIncome + ((calculation.totalExpenses - calculation.totalIncome) / 2),
                calculation.advanceAmount,
                calculation.contributionAmount
              ]
              calculation.eContributionAmount = (calculation.eTotalIncome + ((calculation.eTotalExpenses - calculation.eTotalIncome) / 2))
              calculation.eContributionAmount = Math.abs(parseFloat((calculation.eContributionAmount ?? 0).toFixed(2))) - (calculation.eAdvanceAmount ?? 0)
              calculation.eContributionAmount = Math.abs(parseFloat((calculation.eContributionAmount ?? 0).toFixed(2)))
              calculation.eContributionAmount = calculation.eContributionAmount
              calculation2 = [
                calculation.eTotalExpenses,
                calculation.eTotalIncome,
                calculation.eTotalExpenses - calculation.eTotalIncome,
                (calculation.eTotalExpenses - calculation.eTotalIncome) / 2,
                calculation.eTotalIncome + ((calculation.eTotalExpenses - calculation.eTotalIncome) / 2),
                calculation.eAdvanceAmount,
                calculation.eContributionAmount
              ]
              checkModified()
              foundCondition = state.conditionLossType1
              underline = [
                [false, false, false, false],
                [false, false, false, true],
                [false, false, false, false],
                [false, false, false, true],
                [false, false, false, false],
                [false, false, false, true],
                [false, false, false, true],
              ]
            }
            if (cCondition.lossType == 2) {
              calculation.contributionAmount = (calculation.totalExpenses - calculation.totalIncome) / 2 * (percent / 100)
              calculation.contributionAmount = (calculation.contributionAmount > cCondition.contributionLimit) ? cCondition.contributionLimit : calculation.contributionAmount
              calculation.contributionAmount = Math.abs(parseFloat((calculation.contributionAmount ?? 0).toFixed(2))) - (calculation.advanceAmount ?? 0)
              calculation.contributionAmount = Math.abs(parseFloat((calculation.contributionAmount ?? 0).toFixed(2)))
              calculation1 = [
                calculation.totalExpenses,
                calculation.totalIncome,
                calculation.totalExpenses - calculation.totalIncome,
                (calculation.totalExpenses - calculation.totalIncome) / 2,
                cCondition.contributionLimit,
                calculation.advanceAmount,
                calculation.contributionAmount
              ]
              calculation.eContributionAmount = (calculation.eTotalExpenses - calculation.eTotalIncome) / 2 * (percent / 100)
              calculation.eContributionAmount = (calculation.eContributionAmount > cCondition.contributionLimit) ? cCondition.contributionLimit : calculation.eContributionAmount
              calculation.eContributionAmount = Math.abs(parseFloat((calculation.eContributionAmount ?? 0).toFixed(2))) - (calculation.eAdvanceAmount ?? 0)
              calculation.eContributionAmount = Math.abs(parseFloat((calculation.eContributionAmount ?? 0).toFixed(2)))
              calculation2 = [
                calculation.eTotalExpenses,
                calculation.eTotalIncome,
                calculation.eTotalExpenses - calculation.eTotalIncome,
                (calculation.eTotalExpenses - calculation.eTotalIncome) / 2,
                cCondition.contributionLimit,
                calculation.eAdvanceAmount,
                calculation.eContributionAmount
              ]
              checkModified()
              foundCondition = state.conditionLossType2
  
              underline = [
                [false, false, false, false],
                [false, false, false, true],
                [false, false, false, true],
                [false, false, false, true],
                [false, false, false, true],
                [false, false, false, true],
              ]
            }
            if (cCondition.lossType == 3) {
              calculation.contributionAmount = (calculation.totalIncome + ((calculation.totalExpenses - calculation.totalIncome) / 2)) * (((condition.contributionPercent == 0 || condition.contributionPercent == undefined) ? 100 : condition.contributionPercent) / 100),
                calculation.contributionAmount = Math.abs(parseFloat((calculation.contributionAmount ?? 0).toFixed(2))) - (calculation.advanceAmount ?? 0)
              calculation.contributionAmount = Math.abs(parseFloat((calculation.contributionAmount ?? 0).toFixed(2)))
              calculation.contributionAmount = calculation.contributionAmount
              calculation1 = [
                calculation.totalExpenses,
                calculation.totalIncome,
                calculation.totalExpenses - calculation.totalIncome,
                (calculation.totalExpenses - calculation.totalIncome) / 2,
                calculation.totalIncome + ((calculation.totalExpenses - calculation.totalIncome) / 2),
                calculation.advanceAmount,
                calculation.contributionAmount
              ]
  
              calculation.eContributionAmount = (calculation.eTotalIncome + ((calculation.eTotalExpenses - calculation.eTotalIncome) / 2)) * (((condition.contributionPercent == 0 || condition.contributionPercent == undefined) ? 100 : condition.contributionPercent) / 100)
              calculation.eContributionAmount = Math.abs(parseFloat((calculation.eContributionAmount ?? 0).toFixed(2))) - (calculation.eAdvanceAmount ?? 0)
              calculation.eContributionAmount = Math.abs(parseFloat((calculation.eContributionAmount ?? 0).toFixed(2)))
              calculation.eContributionAmount = calculation.eContributionAmount
  
              calculation2 = [
                calculation.eTotalExpenses,
                calculation.eTotalIncome,
                calculation.eTotalExpenses - calculation.eTotalIncome,
                (calculation.eTotalExpenses - calculation.eTotalIncome) / 2,
                calculation.eTotalIncome + ((calculation.eTotalExpenses - calculation.eTotalIncome) / 2),
                calculation.eAdvanceAmount,
                calculation.eContributionAmount
              ]
              checkModified()
              foundCondition = state.conditionLossType3
              underline = [
                [false, false, false, false],
                [false, false, false, false],
                [false, false, false, false],
                [false, false, false, true],
                [false, false, false, true],
                [false, false, false, false],
                [false, false, false, true],
              ]
            }
  
          }
          break;
      }
  
      // checkModified()
      console.log("checkModified",calculation1)
  
      let conditionsArray = ((foundCondition ?? { children: [] }).children ?? [{}])??[]
  
      conditionsArray.forEach((el: any, i: number) => {
        let val1 = 0
        let val2 = 0
        try {
          val1 = calculation1[i] ?? 0
        } catch (error) {
  
        }
        try {
          val2 = calculation2[i] ?? 0
        } catch (error) {
  
        }
        let pushKrob4 = () => {
          krob4Array.push(
            getTableRow3((el ?? "").replace("*area*", (area.prefix ?? '') + (area.name ?? '')).replace("*year*", doc.calendarYear).replace("*quarter*", doc.quarter).replace("*contributionLimit*", (condition.contributionLimit ?? 0).formatFull().formatThai()).replace("*contributionPercent*", cCondition.contributionPercent).replace('ไตรมาสที่ 0', "").replace('ไตรมาสที่ undefined', "").replace("input",doc.note1 ?? '')
              , isLeft ? val1 == -1 ? "": val1.formatFull().formatThai().replace("NaN", "๐.๐๐") as string : ""
              , isRight ? val2 == -1 ? "": val2.formatFull().formatThai().replace("NaN", "๐.๐๐") as string : ""
              , val1 == -1 ? "": "บาท"))
        }
        if(calculation.isVat){
            pushKrob4()
        } else {
          if(conditionsArray.length !== i+1){
            pushKrob4()
          }
        }
      })
    }

    let commonText = ""
    if (calculation.totalExpenses > calculation.totalIncome) {
      if (calculation.quarter == 0) {
        commonText = `ยอดที่ อปท. พึงชำระสำหรับปีงบประมาณ `
      } else {
        commonText = `ยอดที่ อปท. พึงชำระสำหรับปีงบประมาณ `
      }
    }
    else {
      if (calculation.quarter == 0) {
        commonText = `รวมที่พึงชำระสำหรับปีงบประมาณ ${(calculation.calendarYear ?? 0).toString().formatThai()} `
      } else {
        commonText = `รวมที่พึงชำระสำหรับปีงบประมาณ ${(calculation.calendarYear ?? 0).toString().formatThai()} (ไตรมาส ${calculation.quarter.toString().formatThai()})`
      }
    }
    let commonText2 = "*"
    if (calculation.quarter == 0) {
      commonText2 = `${(calculation.calendarYear ?? 0).toString().formatThai()} `
    } else {
      commonText2 = `${(calculation.calendarYear ?? 0).toString().formatThai()} (ไตรมาส ${calculation.quarter.toString().formatThai()})}`
    }
    console.log(calculation.contributionAmount,"calculation.contributionAmount")
    console.log(calculation.eContributionAmount,"calculation.eContributionAmount")
    krob4Array.push(getTableRow3("","","","")),
    krob4Array.push(getTableRow3(new Paragraph({ text: adjustText ,style: "UnderlineSingle"}), "", "","")),
    modifications.forEach((el:any,i:number) => {
      krob4Array.push(getTableRow3((el.name ?? "").formatThai(),(el.amount ?? 0).toString().formatThai(),(el.eAmount ?? 0).toString().formatThai(),"บาท"))
    })
    krob4Array.push(
      getTableRow3(
        (commonText ?? "").replace("ปีงบประมาณ ๐ ", ""),
         isLeft ? ((calculation.contributionAmount ?? 0).formatFull().formatThai()).replace("NaN", "๐.๐๐") : "",
         isRight ? ((calculation.eContributionAmount ?? 0).formatFull().formatThai()).replace("NaN", "๐.๐๐") : "",
        "บาท"
      )
    )
    /*
 [
              { text: (commonText??"").replace("ปีงบประมาณ ๐",""), colSpan: 2 },
              "",
              "",
              { text: isLeft ? ((doc.contributionAmount ?? 0).formatFull().formatThai()).replace("NaN", "๐.๐๐") : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] },
              { text: isRight ? ((doc.eContributionAmount ?? 0).formatFull().formatThai()).replace("NaN", "๐.๐๐") : "", alignment: "right", border: isLeftRight ? [true, true, true, true] : [false, false, false, false] },
              "บาท"
            ],
            isLeftRight ? [{ text: commonText2??"", colSpan: 2 }, "", "", "", "", ""] : [{ text: commonText2??"", colSpan: 2 }, "", "", doubleLine2(), "", ""],
    */
    const krob4 = new Table({
      columnWidths: [4000, 2000, 2000, 2000],
      rows: krob4Array
    });
    const document = new Document({
      styles: {
        paragraphStyles: [
          {
            id: "Normal",
            name: "Normal",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              color: "000000",
              size: 15 * 2,
              font: {
                name: fontName,
              },
            },
            paragraph: {
              // spacing: {
              //   after: 120,
              // },
            },
          },
          {
            id: "Distribute",
            name: "Distribute",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              color: "000000",
              size: 17 * 2,
              font: {
                name: fontName,
              },
            },
            paragraph: {
              //@ts-ignore
              alignment: "thaiDistribute",
            },
          },
          {
            id: "newLine",
            name: "newLine",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              color: "000000",
              font: "TH Sarabun New",
              size: 8 * 2,
            },
          },
          {
            id: "Header",
            name: "Header",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              color: "000000",
              bold: true,
              font: "TH Sarabun New",
              size: 34 * 2,
            },
          },
          {
            id: "HeaderSubtitle",
            name: "HeaderSubtitle",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              color: "000000",
              bold: true,
              font: "TH Sarabun New",
              size: 18 * 2,
            },
          },
          {
            id: "CenterBold",
            name: "CenterBold",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              color: "000000",
              bold: true,
              font: "TH Sarabun New",
              size: 18 * 2,
            },
          },
          {
            id: "UnderlineSingle",
            name: "UnderlineSingle",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              color: "000000",
              bold: true,
              underline: { color: "000000", type: UnderlineType.SINGLE },
              font: "TH Sarabun New",
              size: 18 * 2,
            },
          },
          {
            id: "UnderlineDouble",
            name: "UnderlineDouble",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              color: "000000",
              bold: true,
              underline: { color: "000000", type: UnderlineType.DOUBLE },
              font: "TH Sarabun New",
              size: 18 * 2,
            },
          },
          {
            id: "Footer",
            name: "Footer",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              color: "000000",
              font: "TH Sarabun New",
              size: 8 * 2,
            },
          },
        ]
      },
      sections: [
        {
          headers: this.header,
          footers: this.footer,
          children: [
            new Paragraph({ text: "", style: "newLine" }),
            new Paragraph(`ที่ ${doc.documentNumber}`),
            new Paragraph({ text: `                                        ${dateFormat}`, alignment: AlignmentType.CENTER }),
            new Paragraph({ text: "", style: "newLine" }),
            new Paragraph({
              children: [
                new TextRun("เรื่อง\t"),
                new TextRun(doc.subject),
              ]
            }),
            new Paragraph({ text: "", style: "newLine" }),
            new Paragraph({
              children: [
                new TextRun("เรียน\t"),
                new TextRun(doc.to),
              ]
            }),
            new Paragraph({ text: "", style: "newLine" }),
            new Paragraph({
              children: [
                new TextRun("อ้างถึง\t"),
                new TextRun(area.contractNumber ?? ""),
              ]
            }),
            new Paragraph({ text: "", style: "newLine" }),
            new Paragraph({
              children: [
                new TextRun("สิ่งที่ส่งมาด้วย"),
                new TextRun(`\tเอกสารการคำนวณ ${(calendarYear).formatThai()} ไตรมาส ${(quarter).formatThai()}\r\n`),
              ]
            }),
            ...(doc.attachmentsName ?? []).map((el: any, i: number) => {
              return new Paragraph(`\t\t${(i + 1).toString().formatThai()}) ${el}\r\n`)
            }),
            new Paragraph({ text: "", style: "newLine" }),
            new Paragraph({ text: `\t\t${stripHtml(doc.content)}`, style: "Distribute" }),
            new Paragraph(`จึงเรียนมาเพื่อโปรด${(doc.notifyTo ?? "")}`),

            new Paragraph({ text: "", style: "newLine" }),
            table,
            new Paragraph({ text: "", style: "newLine" }),

            new Paragraph(`ฝ่ายบริการการจัดเก็บรายได้`),
            new Paragraph(`กองการจัดเก็บรายได้`),
            new Paragraph(`โทร. ๐ ๒๒๗๓ ๘๕๗๔`),
            new Paragraph(`โทรสาร ๐ ๒๒๗๓ ๘๕๘๐`),
            new Paragraph({ text: "สรุปรายรับ - จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย " + area.prefix + area.name, style: "CenterBold", pageBreakBefore: true, alignment: AlignmentType.CENTER }),
            new Paragraph({ text: `ประจำปี ${(calculation.calendarYear ?? 0).toString().formatThai()} ` + (calculation.quarter != 0 && calculation.quarter != undefined ? ` ไตรมาสที่ ${(calculation.quarter ?? "1").toString().formatThai()} ` : " "), style: "CenterBold", alignment: AlignmentType.CENTER }),
            new Paragraph({ text: getQuarterRange(doc.calendarYear ?? new Date().getFullYear(), doc.quarter), style: "CenterBold", alignment: AlignmentType.CENTER }),
            krob3,
            new Paragraph({ text: "รายการคำนวณยอดเงินสมทบที่ อจน. เรียกเก็บจาก อปท. " + area.prefix + area.name, style: "CenterBold", pageBreakBefore: true, alignment: AlignmentType.CENTER }),
            new Paragraph({ text: `ประจำปี ${(calculation.calendarYear ?? 0).toString().formatThai()} ` + (calculation.quarter != 0 && calculation.quarter != undefined ? ` ไตรมาสที่ ${(calculation.quarter ?? "1").toString().formatThai()} ` : " "), style: "CenterBold", alignment: AlignmentType.CENTER }),
            new Paragraph(""),
            new Paragraph({
              text: `${(calculation.contributionText??"")}`,
                alignment: AlignmentType.CENTER,
                border: {
                  top: {
                      color: "auto",
                      space: 8,
                      value: "single",
                      size: 10,
                  },
                  bottom: {
                      color: "auto",
                      space: 8,
                      value: "single",
                      size: 10,
                  },
                  left: {
                    color: "auto",
                    space: 8,
                    value: "single",
                    size: 10,
                  },
                  right: {
                    color: "auto",
                    space: 8,
                    value: "single",
                    size: 10,
                  },
                },
            }),
            // new Paragraph({ text: `${(calculation.contributionText??"")}`,alignment: AlignmentType.CENTER, }),

            // new Paragraph({ text: getQuarterRange(doc.calendarYear ?? new Date().getFullYear(), doc.quarter), style: "CenterBold", alignment:AlignmentType.CENTER }),
            krob4,
            new Paragraph({ text: "ค่าใช้จ่ายในการบริหารจัดการระบบบำบัดน้ำเสีย " + area.prefix + area.name, style: "CenterBold", pageBreakBefore: true, alignment: AlignmentType.CENTER }),
            new Paragraph({ text: `ประจำปี ${(calculation.calendarYear ?? 0).toString().formatThai()} ` + (calculation.quarter != 0 && calculation.quarter != undefined ? ` ไตรมาสที่ ${(calculation.quarter ?? "1").toString().formatThai()} ` : " "), style: "CenterBold", alignment: AlignmentType.CENTER }),
            new Paragraph({ text: getQuarterRange(doc.calendarYear ?? new Date().getFullYear(), doc.quarter), style: "CenterBold", alignment: AlignmentType.CENTER }),
            krob2

            // new Paragraph({
            //   children: [
            //     new TextRun("Hello World"),
            //     new TextRun({
            //       text: "Foo Bar",
            //       bold: true,
            //     }),
            //     new TextRun({
            //       text: "\tGithub is the best",
            //       bold: true,
            //     }),
            //   ],
            // }),
          ],
        },
      ],
    });


    return document;
  }


}

let getQuarterRange = (year: number, i: number) => {
  let add = i == 0 ? 11 : 2
  let m = 0
  let month = 1//Math.ceil((i-1)/0.3) // 1,4,7,10
  let yearAD = (year - 543) as number
  switch (i) {
    case 1:
      month = 10
      break;
    case 2:
      month = 1
      break;
    case 3:
      month = 4
      break;
    case 4:
      month = 7
      break;
    default:
      month = 10
      m = 1
      break;
  }
  let start = DateTime.fromObject({ year: yearAD - m, month: month }).reconfigure({ outputCalendar: "buddhist" }).setLocale("th");
  let end = start.plus({ months: add }).reconfigure({ outputCalendar: "buddhist" }).setLocale("th");
  let startText = start.toFormat("d LLL yy").formatThai();
  let endText = end.endOf('months').toFormat("d LLL yy").formatThai();
  return `(${startText} - ${endText})`
}