
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { useStore } from "vuex";
import JMUpload from "@/components/JMUpload.vue";
import axios from "axios";
import address from "@/variable";
@Options({
  components: {
    JMUpload,
  },
})
export default class ContractForm extends Vue {
  store = useStore();
  doc: any = {
  };
  docs: any = {};
  conditions: Array<any> = [];
  address = address;
  thailand = require("@/data/thailand.json");
  provinceSelected: any = {};
  districtSelected: any = {};
  subDistrictSelected: any = {};

  deleteData(){
    let con = confirm("คุณต้องการลบข้อมูลใช่หรือไม่?")
    if(con==true){
      axios.delete(`${address}/costCode/${this.$route.params.id}`).then(response=>{
        this.$router.push('/portal/code/list')
      })
    }
  }
  getData() {
    axios
      .get(`${address}/costCode/${this.$route.params.id}`)
      .then((response) => {
        this.doc = response.data;
      });
  }
  submit() {
    if (this.$route.params.id != undefined) this.patchData();
    else this.postData();
  }
  postData() {
    axios.post(`${address}/costCode`, this.doc).then((response) => {
      console.log(response);
      alert("บันทึกข้อมูลแล้ว!");
    });
  }
  patchData() {
    axios
      .patch(`${address}/costCode/${this.$route.params.id}`, {
        ...this.doc,
        _id: undefined,
      })
      .then((response) => {
        console.log(response);
        alert("แก้ไขข้อมูลแล้ว!");
      });
  }
  mounted() {
    if (this.$route.params.id != undefined) this.getData();
  }
}
