
import { Options, Vue } from "vue-class-component";
import Chart from "chart.js";
import axios from "axios";
import address from "@/variable";
import { useStore } from "vuex";
import { DateTime } from "luxon";

@Options({
  components: {},
})
export default class Home extends Vue {
  rates: Array<any> = [];
  address = address
  search: any = {
    code:['01-kb'],
    fromDate: DateTime.now().startOf("day").toISO(),
    toDate: DateTime.now().endOf("day").toISO(),
    // year:[2564,2563,2562,2561],
    // month:[1,2,3,4,5,6,7,8,9,10,11,12],
    // fromYear:new Date().getFullYear()+542,
    // toYear:new Date().getFullYear()+543,
    // fromMonth:1,
    // toMonth:12
  };
  doc: any = {
    paid12mo: [],
    invoices12mo: [],
    amounts12mo: [],
  };
  chart1:any
  chart2:any
  chart3:any
  chart4:any
  chart5:any
  chart6:any

  downloadExcel() {
    console.log("download excel...");
    let self = this;
    const Excel = require("exceljs");
    var workbook = new Excel.Workbook();
    var worksheet1 = workbook.addWorksheet(`ปริมาณการใช้น้ำย้อนหลัง`);
    var worksheet2 = workbook.addWorksheet(`ค่าเรียกเก็บที่เรียกเก็บย้อนหลัง`);
    var worksheet3 = workbook.addWorksheet(`จำนวนผู้ใช้น้ำจำแนกเป็นประเภทย้อนหลัง`);
    var worksheet4 = workbook.addWorksheet(`ค่าเรียกเก็บที่เรียกเก็บจำแนกเป็นประเภทย้อนหลัง`);
    var worksheet5 = workbook.addWorksheet(`ยอดชำระที่เรียกเก็บจำแนกเป็นประเภทย้อนหลัง`);
    var worksheet6 = workbook.addWorksheet(`เปรียบเทียบยอดที่ต้องชำระกับยอดที่ชำระแล้วต่อเดือนแบ่งตามประเภท`);
    let header1 = ["เดือน","ลบ.ม"];
    let header2 = ["เดือน","บาท"];
    let header3 = ["เดือน","ประเภท 1 (ราย)","ประเภท 2 (ราย)","ประเภท 3 (ราย)"];
    let header4 = ["เดือน","ประเภท 1 (บาท)","ประเภท 2 (บาท)","ประเภท 3 (บาท)"];
    let header5 = ["เดือน","ประเภท 1 (บาท)","ประเภท 2 (บาท)","ประเภท 3 (บาท)","รวม"];
    let header6 = ["เดือน","เรียกเก็บประเภท 1 (บาท)","เรียกเก็บประเภท 2 (บาท)","เรียกเก็บประเภท 3 (บาท)","รวมยอดเรียกเก็บ","ยอดชำระประเภท 1 (บาท)","ยอดชำระประเภท 2 (บาท)","ยอดชำระประเภท 3 (บาท)","รวมยอดชำระ"];
    worksheet1.addRow(header1);
    worksheet2.addRow(header2);
    worksheet3.addRow(header3);
    worksheet4.addRow(header4);
    worksheet5.addRow(header5);
    worksheet6.addRow(header6);

    this.doc.usages12mo.forEach((item:any, i:number) => { worksheet1.addRow([item.name,item.value]) })
    this.doc.invoices12mo.forEach((item:any, i:number) => { worksheet2.addRow([item.name,item.value]) })
    this.doc.types12mo.forEach((item:any, i:number) => { worksheet3.addRow([item.name,item.category1,item.category2,item.category3]) })
    this.doc.amounts12mo.forEach((item:any, i:number) => { worksheet4.addRow([item.name,item.category1,item.category2,item.category3]) })
    this.doc.paid12mo.forEach((item:any, i:number) => { worksheet5.addRow([item.name,item.category1,item.category2,item.category3,item.category1+item.category2+item.category3]) })
    this.doc.paid12mo.forEach((item:any, i:number) => { worksheet6.addRow([item.name,item.category1,item.category2,item.category3,item.category1+item.category2+item.category3,this.doc.amounts12mo[i].category1,this.doc.amounts12mo[i].category2,this.doc.amounts12mo[i].category3,this.doc.amounts12mo[i].category1+this.doc.amounts12mo[i].category2+this.doc.amounts12mo[i].category3]) })

    workbook.xlsx.writeBuffer().then(function(data: any) {
      var blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "BillingDashboard.xlsx");
    });
  }
  get yearRange(){
    let from = this.search.fromYear
    let to = this.search.toYear
    let result:Array<number> = []
    for (let i = from; i <= to; i++) {
      result.push(i)
    }
    return result
  }
  get monthRange(){
      let from = this.search.fromMonth
      let to = this.search.toMonth
      let result:Array<number> = []
      for (let i = from; i <= to; i++) {
        result.push(i)
      }
      return result
    }
  get dateRange(){
    let arr:Array<string> = []
    let current = DateTime.fromJSDate(this.search.fromDate)
    do{
      console.log(current.toISO())
      let year = current.toObject().year! + 543
      let month = current.toObject().month!
      arr.push(`${month}:${year}`)
      current = current.plus({month:1})
    }while(current < DateTime.fromJSDate(this.search.toDate))
    return arr
  }
  getRates() {
    axios.get(`${address}/arearates`).then((response) => {
      this.rates = response.data;
      this.search.code = this.rates.map(el=>el.code)
    });
  }
  print(){
    window.print();
  }

  fetchData() {
    let query = {
      monthyear: this.dateRange,
      code:JSON.parse(JSON.stringify(this.search.code)),
      limit:999999,
    }
    console.log(query)
    axios.post(`${address}/report-billing-dashboard/`,query).then((response) => {
      this.doc = response.data;
      try { this.chart1.destroy() } catch (error) { }
      try { this.chart2.destroy() } catch (error) { }
      try { this.chart3.destroy() } catch (error) { }
      try { this.chart4.destroy() } catch (error) { }
      try { this.chart5.destroy() } catch (error) { }
      try { this.chart6.destroy() } catch (error) { }
      this.initChart1();
      this.initChart2();
      this.initChart3();
      this.initChart4();
      this.initChart5();
      this.initChart6();
    });
  }
  mounted() {
    this.getRates()
    this.fetchData();
  }
  initChart1() {
    var element = this.$refs.chart1 as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    this.chart1 = new Chart(ctx, {
      type: "bar",
      data: {
        labels: this.doc.usages12mo.map((el: any) => {
          return el.name ?? "";
        }),
        datasets: [
          {
            label: "ปริมาณการใช้น้ำ (ลบ.ม)",
            data: this.doc.usages12mo.map((el: any) => el.value ?? 0),
            barThickness: 30,
            backgroundColor: "#AA6afc",
            borderColor: "#AAE8BF",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'ลบ.ม'
            }
          }]
        }     
      }
    });
  }
  initChart2() {
    let data = [5, 4, 4.5, 3.5, 4.1, 4.3, 4.3, 5, 5, 5.3, 6, 6.4];
    data.forEach((el, i) => {
      // data[i] = el * 20000
    });
    var element = this.$refs.chart2 as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    this.chart2 = new Chart(ctx, {
      type: "line",
      data: {
        labels: this.doc.invoices12mo.map((el: any) => {
          return el.name ?? "";
        }),
        datasets: [
          {
            label: "จำนวนเงินที่เรียกเก็บจากผู้ใช้น้ำ (บาท)",
            data: this.doc.invoices12mo.map((el: any) => el.value ?? 0),
            barThickness: 40,
            backgroundColor: "red",
            fill: false,
            borderColor: "red",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'บาท'
            }
          }]
        }     
      }
    });
  }

  initChart3() {
    var element = this.$refs.chart3 as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    console.log(this.doc.types12mo);
    this.chart3 = new Chart(ctx, {
      type: "bar",
      data: {
        labels: this.doc.types12mo.map((el: any) => {
          return el.name ?? "";
        }),
        datasets: [
          {
            label: "จำนวนผู้ใช้น้ำประเภท 1",
            data: this.doc.types12mo.map((el: any) => el.category1 ?? 0),
            // barThickness: 40,
            backgroundColor: "red",
            borderWidth: 1,
          },
          {
            label: "จำนวนผู้ใช้น้ำประเภท 2",
            data: this.doc.types12mo.map((el: any) => el.category2 ?? 0),
            // barThickness: 40,
            backgroundColor: "#65C3A1",
            borderWidth: 1,
          },
          {
            label: "จำนวนผู้ใช้น้ำประเภท 3",
            data: this.doc.types12mo.map((el: any) => el.category3 ?? 0),
            // barThickness: 40,
            backgroundColor: "#194D9B",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'ราย'
            }
          }]
        }     
      }
    });
  }

  initChart4() {
    var element = this.$refs.chart4 as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    this.chart4 = new Chart(ctx, {
      type: "bar",
      data: {
        labels: this.doc.amounts12mo.map((el: any) => {
          return el.name ?? "";
        }),
        datasets: [
          {
            label: "ค่าเรียกเก็บผู้ใช้น้ำประเภท 1",
            data: this.doc.amounts12mo.map((el: any) => el.category1 ?? 0),
            backgroundColor: "red",
            borderWidth: 1,
          },
          {
            label: "ค่าเรียกเก็บผู้ใช้น้ำประเภท 2",
            data: this.doc.amounts12mo.map((el: any) => el.category2 ?? 0),
            backgroundColor: "#65C3A1",
            borderWidth: 1,
          },
          {
            label: "ค่าเรียกเก็บผู้ใช้น้ำประเภท 3",
            data: this.doc.amounts12mo.map((el: any) => el.category3 ?? 0),
            backgroundColor: "#194D9B",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'บาท'
            }
          }]
        }     
      }
    });
  }

  initChart5() {
    var element = this.$refs.chart5 as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    this.chart5 = new Chart(ctx, {
      type: "bar",
      data: {
        labels: this.doc.paid12mo.map((el: any) => {
          return el.name ?? "";
        }),
        datasets: [
          {
            label: "ยอดชำระผู้ใช้น้ำประเภท 1",
            data: this.doc.paid12mo.map((el: any) => el.category1 ?? 0),
            backgroundColor: "cyan",
            borderWidth: 1,
          },
          {
            label: "ยอดชำระผู้ใช้น้ำประเภท 2",
            data: this.doc.paid12mo.map((el: any) => el.category2 ?? 0),
            backgroundColor: "#65C3A1",
            borderWidth: 1,
          },
          {
            label: "ยอดชำระผู้ใช้น้ำประเภท 3",
            data: this.doc.paid12mo.map((el: any) => el.category3 ?? 0),
            backgroundColor: "#194D9B",
            borderWidth: 1,
          },
          {
            label: "รวมทุกประเภท",
            type: "line",
            data: this.doc.paid12mo.map(
              (el: any) =>
                (el.category1 ?? 0) + (el.category2 ?? 0) + (el.category3 ?? 0)
            ),
            borderColor: "red",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'บาท'
            }
          }]
        }     
      }
    });
  }

  initChart6() {
    var element = this.$refs.chart6 as HTMLCanvasElement;
    var ctx = element.getContext("2d") as CanvasRenderingContext2D;
    this.chart6 = new Chart(ctx, {
      type: "bar",
      data: {
        labels: this.doc.paid12mo.map((el: any) => {
          return el.name ?? "";
        }),
        datasets: [
          {
            label: "ค่าเรียกเก็บผู้ใช้น้ำประเภท 1",
            data: this.doc.amounts12mo.map((el: any) => el.category1 ?? 0),
            backgroundColor: "#7854F7",
            borderWidth: 1,
          },
          {
            label: "ยอดชำระผู้ใช้น้ำประเภท 1",
            data: this.doc.paid12mo.map((el: any) => el.category1 ?? 0),
            backgroundColor: "#F5325b",
            borderWidth: 1,
          },
          {
            label: "ค่าเรียกเก็บผู้ใช้น้ำประเภท 2",
            data: this.doc.amounts12mo.map((el: any) => el.category2 ?? 0),
            backgroundColor: "#12A5ED",
            borderWidth: 1,
          },
          {
            label: "ยอดชำระผู้ใช้น้ำประเภท 2",
            data: this.doc.paid12mo.map((el: any) => el.category2 ?? 0),
            backgroundColor: "#FF8400",
            borderWidth: 1,
          },
          {
            label: "ค่าเรียกเก็บผู้ใช้น้ำประเภท 3",
            data: this.doc.amounts12mo.map((el: any) => el.category3 ?? 0),
            backgroundColor: "#7ED321",
            borderWidth: 1,
          },
          {
            label: "ยอดชำระผู้ใช้น้ำประเภท 3",
            data: this.doc.paid12mo.map((el: any) => el.category3 ?? 0),
            backgroundColor: "#FFCA00",
            borderWidth: 1,
          },
          {
            label: "รวมค่าเรียกเก็บทุกประเภท",
            type: "line",
            data: this.doc.amounts12mo.map(
              (el: any) =>
                (el.category1 ?? 0) + (el.category2 ?? 0) + (el.category3 ?? 0)
            ),
            borderColor: "blue",
            backgroundColor:"transparent",
            borderWidth: 1,
          },
          {
            label: "รวมยอดชำระทุกประเภท",
            type: "line",
            data: this.doc.paid12mo.map(
              (el: any) =>
                (el.category1 ?? 0) + (el.category2 ?? 0) + (el.category3 ?? 0)
            ),
            borderColor: "red",
            backgroundColor:"transparent",
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'บาท'
            }
          }]
        }     
      }
    });
  }

  fonts: any = {};
  styles: any = {};
  store = useStore()
  async generatePDF(mode: string) {
    let mm = (u: number) => {
      return u / 0.352777778;
    };
    var pdfMake = require("pdfmake/build/pdfmake.js");
    this.fonts = this.store.getters.getPDFFonts;
    this.styles = this.store.getters.getPDFStyles;

    var chart1 = this.$refs.chart1 as any;
    var chartImg1 = chart1!.toDataURL();
    var chart2 = this.$refs.chart2 as any;
    var chartImg2 = chart2!.toDataURL();
    var chart3 = this.$refs.chart3 as any;
    var chartImg3 = chart3!.toDataURL();
    var chart4 = this.$refs.chart4 as any;
    var chartImg4 = chart4!.toDataURL();
    var chart5 = this.$refs.chart5 as any;
    var chartImg5 = chart5!.toDataURL();
    var chart6 = this.$refs.chart6 as any;
    var chartImg6 = chart6!.toDataURL();
    let val1 = (this.doc.paid12mo.map((el: any) => (el.total??0)).reduce((a:number,b:number)=>a+b,0)/12)??0
    let val2 = (this.doc.amounts12mo.map((el: any)=>(el.total??0)).reduce((a:number,b:number)=>a+b,0))??0
    let val3 = (this.doc.paid12mo.map((el: any)=>(el.total??0)).reduce((a:number,b:number)=>a+b,0))??0
    let val4 = ((this.doc.amounts12mo.map((el: any)=>(el.total??0)).reduce((a:number,b:number)=>a+b,0)-this.doc.paid12mo.map((el: any)=>(el.total??0)).reduce((a:number,b:number)=>a+b,0))??0)??0
    var docDefinition = {
      pageSize: "A4",
      pageOrientation: "landscape",
      pageMargins: [0, 4, 0, 0],
      content: [
        { text: "สรุปข้อมูลรายงานหน้า DASHBOARD", alignment: "center",decoration: 'underline', margin:[0,0,0,10] },
        {
          layout:"border",
          marginLeft:80,
          alignment:"center",
          table:{
          widths:[200,300,200],
          body:[
            ["เฉลี่ยยอดชำระ",val1.formatFull(),"บาท/เดือน"],
            ["ยอดเรียกเก็บ",val2.formatFull(),"บาท"],
            ["ยอดค่าเรียกเก็บที่ชำระแล้ว",val3.formatFull(),"บาท"],
            ["ค่าบำบัดน้ำเสียที่ยังจัดเก็บไม่ได้",val4.formatFull(),"บาท"],
          ],
        }},
        { image: chartImg1, margin:[80,70,0,0], width:600 },
        { image: chartImg2, margin:[80,200,0,0], width:600 },
        { image: chartImg3, margin:[80,300,0,0], width:600 },
        { image: chartImg4, margin:[80,400,0,0], width:600 },
        { image: chartImg5, margin:[80,500,0,0], width:600 },
        { image: chartImg6, margin:[80,600,0,0], width:600 },
      ],
      styles: this.styles,
      defaultStyle: {
        fontSize: "16",
        lineHeight: 0.8,
      },
    };
    if (mode == "preview") {
      let pdfDocGenerator = pdfMake.createPdf(docDefinition, null, this.fonts);
      pdfDocGenerator.getBlob((blob: any) => {
        let blobURL = URL.createObjectURL(blob);
        // this.pdfBLOB = blobURL;
      });
    } else if(mode=="print") {
      let pdfDocGenerator = pdfMake
        .createPdf(docDefinition, null, this.fonts)
        .print();
    } else{

      let pdfDocGenerator = pdfMake
        .createPdf(docDefinition, null, this.fonts)
        .download(`dashboard.pdf`);
    }
  }
}
